import initialState from '../initialState'

//TODO - get rid of combine state so can access state by doing this.state.columns.searchResults.food, etc

export default function(state=initialState, action){
    switch(action.type){
        case 'MENU_ADD_MENU_PAGE':
            return{
                ...state,
                build_menu:{
                    ...state.build_menu,
                    [action.payload.meal_type]:{
                        ...state.build_menu[action.payload.meal_type],
                            [action.payload.dish_num]:{
                                ...state.build_menu[action.payload.meal_type][action.payload.dish_num],
                                items_list:action.payload.items_list
                        }
                    }
                }
            }
        case 'ADD_MENU_NAME_SEARCH_RESULTS':
            return{
                ...state,
                menu_search:action.payload.menu_search,
                display_idx:action.payload.display_idx,
                build_menu:{
                    ...state.build_menu,
                    ...action.payload.menu_dict,
                    pref_menu_dict: action.payload.pref_menu_dict,
                    menu_id:action.payload.id,
                }

            }
        case 'ADD_MENU_TO_PREVIEW':
            return{
                ...state,
                display_idx:action.payload.idx,
                build_menu:{
                    ...state.build_menu,
                    ...action.payload.menu_dict,
                    menu_id: action.payload.id,
                    pref_menu_dict:action.payload.pref_menu_dict,
                }
            }
        case 'TOGGLE_SUCCESS_SNACKBAR':
            return{
                ...state,
                snackbar_open:action.payload.snackbar_open,
            }
        default:
            return state;
    }
}