import React, {Component} from 'react';
import {connect} from 'react-redux';
import NavBar from '../common/navbar';
import {
    Button,
    Input,
    Menu,
    MenuItem,
    Paper,
    Select,
    TextField,
    Typography,
}from '@material-ui/core/';
import GenericSnackbar from './generic_snackbar';
import {saveFeedback} from '../../actions/auth';

class ContactPage extends Component{
// props - circle/square, image
    constructor(props){
        super(props)
        this.state = {
            message_type:"delete",
            placeholder:"No message required to delete personal data",
            message:'', // Not null: is automatically added to database
        } 
    }

    onSubmit = (event)=>{
        this.props.saveFeedback({
            message:this.state.message,
            message_type:this.state.message_type
        })
    }

    onChangeType = (event) =>{
        let placeholder_dict ={
            delete:"No message required to delete personal data",
            bug:"Describe the broken feature or process",
            feature:"Tell us what you'd like to be able to do",
            data: "Tell us what foods you would like. If possible include description, brand and serving unit.",
            help: "What can we help you do?",
            other:"Tell us how we can help!"
        }
        this.setState({
            message_type:event.target.value,
            placeholder:placeholder_dict[event.target.value]
        })
    }

    render(){        
        return (
            <div
                style={{
                    backgroundColor:"var(--sec_light)",
                    height:"100vh",
                }}
            >
                <NavBar/>
                <Paper
                    style={{
                        maxWidth:450,
                        margin:"auto",
                        marginTop:50,
                    }}
                >
                    <div
                        style={{
                            display:"flex",
                            justifyContent:"space-evenly",
                        }}
                    >
                    <Typography>
                        I Want To...
                    </Typography>
                    <Select
                        label="Message Type"
                        value={this.state.message_type}
                        onChange={this.onChangeType}
                    >
                        <MenuItem value={"delete"} dense={true}>Delete My Personal Data</MenuItem>
                        <MenuItem value={"bug"} dense={true}>Report An Issue</MenuItem>
                        <MenuItem value={"feature"} dense={true}>Suggest A Feature</MenuItem>
                        <MenuItem value={"data"} dense={true}>Request Food/Restaurant</MenuItem>
                        <MenuItem value={"help"} dense={true}>Get Help</MenuItem>
                        <MenuItem value={"other"} dense={true}>Other</MenuItem>
                    </Select>
                    </div>
                    <TextField
                        placeholder={this.state.placeholder}
                        multiline={true}
                        fullWidth
                        value={this.state.message}
                        onChange={(event)=>{this.setState({message:event.target.value})}}
                        variant="outlined"
                        InputProps={{
                            style:{
                                height:"6em",
                            }
                        }}
                    />
                    <Button
                        fullWidth
                        onClick={this.onSubmit}
                    >Submit</Button>
                </Paper>
                <GenericSnackbar/>
            </div>
        )
    }
}

const mapStateToProps = (state,ownProps) => ({

})

export default connect(null,{saveFeedback})(ContactPage);