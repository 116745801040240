import React, {Component} from 'react';
import {Input,
}from '@material-ui/core/';
import {withStyles} from '@material-ui/core/styles';

const useStyles = theme=> ({
    pricing_page_bg:{
        // height:"100vh",
        // width:"100vw",
        backgroundColor:"lightYellow",
        position:"static",
    },
})

class PricingPage extends Component{
// props - circle/square, image
    constructor(props){
        super(props)
        this.state = {
        } 
    }

    render(){    
        const {classes} = this.props
        return (
            <div
                className={classes.pricing_page_bg}
            >
                <div
                    className={classes.pricing_title}
                >
                    All Features
                </div>
                <div
                    className={classes.pricing_body}
                >
                    <ul>
                        <li>Automatic Weekly Meal Planning</li>
                        <li>100% Customizeable Suggestions Menu</li>
                        <li>Weekly Shopping List</li>
                        <li>Adjustable Macros and Micronutrients</li>
                        <li>Easily Toggle Nutrients Included In Autoplanner</li>
                        <li>Thousands of Recipes</li>
                        <li>Cloneable Keto, Low Carb, High Protein, etc Suggestion Menus</li>
                        <li>Hundreds of Thousands of Raw Ingredient, Restaurant and Grocery Foods</li>
                        <li>Custom Meal Saving</li>
                        <li>Portions Adjuster Tool</li>
                        <li>Undo / Redo Tool</li>
                        <li>Drag and Drop Plan Builder</li>
                        <li>Progress Tracking (coming soon)</li>
                        <li>New Features Constantly Added</li>
                    </ul>

                </div>
            </div>
        )
    }
}


export default withStyles(useStyles)(PricingPage);