import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Input,
}from '@material-ui/core/';
import NavBar from '../common/navbar';
import InjectedCheckoutForm from './payment';


class PaymentPage extends Component{
// props - circle/square, image
    constructor(props){
        super(props)
        this.state = {
        } 
    }

    onChangeInput = (event)=>{

    }

    render(){        
        return (
            <div>
                <NavBar />
                <InjectedCheckoutForm/>
            </div>
        )
    }
}

const mapStateToProps = (state,ownProps) => ({
    
})

export default connect(null,null)(PaymentPage);