import React, {Component} from 'react';
import {
    Typography,
} from '@material-ui/core/';

class SinglePodcast extends Component{
// props - circle/square, image
    constructor(props){
        super(props)
        this.state = {
        } 
    }


    render(){        
        return (
            <div
            style={{
                marginTop:20,
            }}
            >
            <Typography 
                    variant="subtitle1"
                    style={{
                        color:"darkGrey"
                    }}
                >
                    {this.props.title}
                </Typography>
                <img 
                    src={this.props.photo_url}
                    alt={this.props.title}
                    style={{
                        height:320,
                        width:320,
                        margin:"auto"
                    }} 
                />
                <div dangerouslySetInnerHTML={{__html:this.props.embed_link}}
                >
                </div>
            </div>
  
        )
    }
}


export default SinglePodcast;