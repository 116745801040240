import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Snackbar} from '@material-ui/core/';
import {modifyAutoplanFailDialog} from '../../actions/dialog';

class AutoplanSnackbar extends Component{
    // props - circle/square, image
        constructor(props){
            super(props)
            this.state = {
            } 
        }
    
        onChangeInput = (event)=>{
            
        }
    
        render(){        
            return (
                <Snackbar
                    anchorOrigin={{
                        vertical:"top",
                        horizontal:"center",
                    }}
                    open={this.props.open}
                    message={this.props.message}
                    onClose={(event)=>{this.props.modifyAutoplanFailDialog(false,'')}}
                >
                </Snackbar>
            )
        }
    }
    
    const mapStateToProps = (state,ownProps) => ({
        open:state.dialog.autoplan_snackbar.open,
        message:state.dialog.autoplan_snackbar.message,
    })
    
    export default connect(mapStateToProps,{modifyAutoplanFailDialog})(AutoplanSnackbar);