import React, {Component} from 'react';
import { makeStyles, withStyles} from '@material-ui/core/styles';
import {connect} from 'react-redux';
import {
    Checkbox,
    Chip,
    Paper,
    TextField,
    FormControlLabel,
    IconButton,
    Popper,
    Select,
    Menu,
    MenuItem
} from '@material-ui/core/';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Droppable, Draggable} from 'react-beautiful-dnd';
import {toggleIncludeMeal, autocompleteMenus, saveMenuChange} from '../../actions/autoplan';
import Meal from './meal';
import CheckIcon from '@material-ui/icons/Check';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import ImportContactsIcon from '@material-ui/icons/ImportContacts'; // Menu Icon
import RestaurantIcon from '@material-ui/icons/Restaurant'; // Restaurant Icon
import LocalOfferIcon from '@material-ui/icons/LocalOffer'; // Tag icon
import EventIcon from '@material-ui/icons/Event'; // Leftovers icon
import RepeatIcon from '@material-ui/icons/Repeat'; // Repeat icon

const useStyles = makeStyles(theme=>({
    root:{
        
    },
    'div&:-webkit-scrollbar':{
        width:7,
        height:7,
    }
}))

class MealPlan extends Component{

    constructor(props){
        super(props)

        this.state={
            meals_list:['Breakfast','Lunch','Dinner','Snack'],
            plannum_list:['day1','day2','day3','day4','day5','day6','day7'],
            repeatAnchorEl:null,
            repeatSelected:null
        }
     }

    shouldComponentUpdate(nextProps,nextState){
        if((this.props.autoplan==nextProps.autoplan)&
            (this.props.autoplan_include==nextProps.autoplan_include)&
            (this.props.prefmenus==nextProps.prefmenus)&
            (this.props.search_results==nextProps.search_results)&
            (this.props.meal_as_list[0].foods_list==nextProps.meal_as_list[0].foods_list)&
            (this.state.repeatAnchorEl==nextState.repeatAnchorEl)){
            return false
        }
        else{
            return true
        }
    }

    chooseChipIcon =option=>{
        switch(option.type){
            case 'tag': return <LocalOfferIcon style={{color:"var(--sec_contrast)"}}/>
            case 'menu':return <ImportContactsIcon style={{color:"var(--sec_contrast)"}}/>
            case 'restaurant': return <RestaurantIcon style={{color:"var(--sec_contrast)"}}/>
            case 'leftovers': return <EventIcon style={{color:"var(--sec_contrast)"}}/>
            default: return <ImportContactsIcon style={{color:"var(--sec_contrast)"}}/>
        }
    }

    onToggleInclude = value =>{
        let curr_incl = this.props.autoplan_include
        return this.props.toggleIncludeMeal({
                    autoplan_include:!curr_incl,
                    plannum:this.props.plannum,
                    meal_type: this.props.meal_type
        })
    }

    onMenuChange = (event, value) =>{
        return this.props.saveMenuChange({
            menus:value,
            plannum:this.props.plannum,
            meal_type: this.props.meal_type
        })
    }

    onDeleteChip = index =>{
        let new_prefmenus = Array.from(this.props.prefmenus)
        new_prefmenus.splice(index,1)
        return this.props.saveMenuChange({
            menus:new_prefmenus,
            plannum:this.props.plannum,
            meal_type: this.props.meal_type
        })
    }

    sendAutoCompleteQry = (search_query) =>{
        let search_len = search_query.length
        if((search_len%3===0)&(search_len!=0)){
            return this.props.autocompleteMenus({
                search_query:search_query,
                plannum:this.props.plannum,
                meal_type:this.props.meal_type
            })
        }else{return null}
    }
    handleRepeatClick = (event) =>{
        this.setState({repeatAnchorEl:event.target})
    }

    handleRepeatClose = (event) =>{
        this.setState({repeatAnchorEl:null})
    }

    handleSelectRepeat = (day_idx,date, meal) =>{
        // build leftover object
        // update state with Redux
        // Will remove all menus and replace with a leftovers chip
        let new_prefmenus = [{
            type:'leftovers',
            day:this.props.plannum,
            meal:this.props.meal_type,
            name:"Same As - "+this.props.weekday+" - "+this.props.meal_type
        }]
        let menuObj = {
           plannum:this.state.plannum_list[day_idx],
           meal_type: meal,
           menus:new_prefmenus
        }
        this.props.saveMenuChange(menuObj)
    }

    render(){

        const isRepeatMenuOpen = Boolean(this.state.repeatAnchorEl)
        let weekday_list = ['Monday','Tuesday','Wednesday','Thursday','Friday','Saturday','Sunday']
        let days_list = this.props.days_list.map((day,idx)=>{
            let dt = new Date(day)
            return weekday_list[dt.getDay()]
        })
        return(
        <div
            style={{
                backgroundColor:"var(--sec_contrast)",
                // height:135,
            }}
        >
            <div
                style={{
                    display:this.props.autoplan ? "": "none",
                    display:"flex",
                    flexDirection:"row",
                    alignItems:"stretch",
                    alignContent:"stretch",
                    justifyContent:"stretch",
                    verticalAlign:"bottom"
                }}
            > 
                <FormControlLabel
                    style={{
                            padding:0, 
                            paddingRight:10,
                            margin:0,
                            display:this.props.autoplan ? "": "none",
                            color:"var(--main_contrast)",
                            fontSize:8
                        }}
                    control={
                    <Checkbox
                        style={{padding:0,margin:0}}
                        icon={<CheckBoxOutlineBlankIcon style={{color:"var(--main_contrast)"}} />}
                        checkedIcon={<CheckBoxIcon style={{color:"var(--main_contrast)"}} />}
                        value={this.props.plannum+"_"+this.props.meal_type}
                        checked={this.props.autoplan_include}
                        onChange={this.onToggleInclude}
                    />
                    }
                    //label="Include"
                />
                    <Autocomplete
                        style={
                            {width:"82%",
                            padding:0,
                            margin:0,
                            display:((this.props.autoplan_include)&(this.props.autoplan)) ? "": "none",
                            fontSize:8
                        }}
                        multiple
                        disableClearable={true}
                        id={"prefmenu_"+this.props.plannum+"_"+this.props.meal_type}
                        options={this.props.search_results}
                        getOptionLabel={option=>(option.name)}
                        //defaultValue={this.state.menus}
                        value={this.props.prefmenus}
                        groupBy={option=>option.type}
                        onChange={(event,value)=>{this.onMenuChange(event,value)}}
                        onInputChange={(event, value)=>{this.sendAutoCompleteQry(value)}}
                        renderTags={
                            (value,getTagProps)=>
                            value.map((option, index)=>(
                                <Chip 
                                    style={{
                                        backgroundColor:"var(--main_contrast)",
                                        color:"var(--sec_contrast)"
                                    }}
                                    variant="default"
                                    size="small"
                                    icon={this.chooseChipIcon(option)}
                                    onDelete={()=>{this.onDeleteChip(index)}}
                                    label={option.name}
                                    key={String(option.id)+"_"+option.type}
                                />
                            )
                            )
                        }
                        renderInput={params=>(
                            <TextField
                            {...params}
                                style={{fontSize:8, color:"var(--sec_contrast)"}}
                                placeholder="  menus, tags, restaurants"
                                margin="dense"
                                fullWidth
                            />
                        )}
                    />

                        <IconButton
                            size="small" 
                            style={{
                                padding:0,
                                display:((this.props.autoplan_include)&(this.props.autoplan)) ? "": "none",
                            }}
                            onClick={this.handleRepeatClick}
                        >
                            <RepeatIcon
                                // Should have an active and inactive styling
                                style={{
                                    color:"var(--main_contrast)",
                                    cursor:"pointer",
                                    padding:0,
                                }} 
                                fontsize = 'small'
                            />
                        </IconButton>
    
                        <Menu
                            style={{
                                display:((this.props.autoplan_include)&(this.props.autoplan)) ? "": "none",
                            }}
                            multiple
                            open={isRepeatMenuOpen}
                            onClose={this.handleRepeatClose}
                            anchorEl={this.state.repeatAnchorEl}
                        >
                            {days_list.map((day, day_idx)=>(
                                        <div>
                                            {this.state.meals_list.map( (meal, meal_idx) => {
                                                if((day_idx>this.props.daynum)||
                                                    ((day_idx==this.props.daynum)
                                                     &(meal_idx>this.props.mealnum))){
                                                    return(
                                                        <MenuItem
                                                            onClick={(event)=>(this.handleSelectRepeat(day_idx,day, meal))}
                                                        >{day} - {meal}</MenuItem>
                                                    )
                                                }
                                            })}
                                        </div>
                                )
                            )}
                        </Menu>
                    

            </div>
                <Droppable
                    type="MEAL"
                    droppableId={"meal_"+String(this.props.plannum)+"_"+this.props.meal_type}
                    isCombineEnabled={true}
                    style={{
                        minWidth:"100%"
                    }}
                >
                {(provided, snapshot)=>(
                    <div
                    className={'meal_droppable'}
                    style={{
                            backgroundColor: snapshot.isDraggingOver? "var(--contrast_light)": "var(--contrast_mid)", 
                            height:125, 
                            verticalAlign:"middle",
                            margin:"0 auto",
                            display:"flex",
                            padding:"grid",
                            minWidth:"100%",
                            overflowX:"auto",
                            overflowY:"visible",
                            scrollbarWidth:"thin",
                            WebkitScrollbar:"thin",
                    }}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                >
                {this.props.meal_as_list.map( (result, idx) => {
                    return(
                    <Draggable
                        draggableId={result['meal_drag_key']}
                        index={idx}
                        key={result['meal_drag_key']}
                        isDragDisabled={true}
                    >
                        {(provided, snapshot)=>(
                        <React.Fragment
                        >
                        <div
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                            style={{
                                margin:"0 auto",
                                minWidth:"100%" // critical for full screen on mobile
                            }}
                        >
                        <Meal 
                            meal_drag_key = {result['meal_drag_key']}
                            plannum={this.props.plannum} 
                            meal_type={this.props.meal_type}
                            foods_list={result['foods_list']}
                            isLoading={result['isLoading']}
                            foodChangeDisabled={false}
                            mealname={result['mealname']}
                        />
                        </div>
                        </React.Fragment>
                        )}
                    </Draggable>
                )})}
                {provided.placeholder}
                </div>
            )}
            </Droppable>
        </div>
        )
    }
}

const mapStateToProps = (state,ownProps)=>{
    let meal_dict= {'Breakfast':0, 'Lunch':1, 'Dinner':2, 'Snack':3}
    let daynum = parseInt(ownProps.plannum.substring(3,4))-1
    let mealnum = meal_dict[ownProps.meal_type]
    return {
        search_results:state.user[ownProps.plannum][ownProps.meal_type].search_results,
        prefmenus: state.user[ownProps.plannum][ownProps.meal_type].menus,
        meal_as_list:[state.columns.present[ownProps.plannum][ownProps.meal_type]],
        foods_list:state.columns.present[ownProps.plannum][ownProps.meal_type].foods_list,
        autoplan:state.user.autoplan,
        autoplan_day:state.user[ownProps.plannum].autoplan,
        autoplan_include: state.user[ownProps.plannum][ownProps.meal_type].autoplan_include,
        days_list: state.user.days_list,
        daynum:daynum,
        mealnum:mealnum
    }
}

export default connect(mapStateToProps,{toggleIncludeMeal,autocompleteMenus, saveMenuChange})(MealPlan);