import React, {Component} from 'react';
import {connect} from 'react-redux';
// MATERIAL UI
import {Button,
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
    IconButton,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
        Typography,
}from '@material-ui/core/';
// IMAGES
import toggle_nutrients from './toggle_nutrients.png';
import Single_Column_HowTo from './Single_Column_HowTo.png';
import edit_menu from './edit_menu.png';
import autoplan_week_image from './autoplan_week_image.png';
import autoplan_day_button from './autoplan_day_button.png';
import searching_chipotle_menu from './searching_chipotle_menu.png';
import chipotle_menu from './chipotle_menu.png';
import default_menu from './default_menu.png';
import alternatives from './alternatives.png';
import alternative_options from './alternative_options.png';

// ACTIONS
import {toggleAutoplan, solveWeekPlan} from '../../actions/autoplan'
import {closedOnboardingModal} from '../../actions/start';
// ICONS
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import LoopIcon from '@material-ui/icons/Loop';
import VerticalAlignCenterIcon from '@material-ui/icons/VerticalAlignCenter'; // Better adjust


class AutoplannerHowTo extends Component{
// props - circle/square, image
    constructor(props){
        super(props)
        this.state = {
            get_plan:true,
            adjust_amounts:false,
            change_menu:false,
            see_alternatives:false,
            edit_default_menu:false,
            include_leftovers:false,
        } 
    }

    onClick = (event)=>{
        // turn autoplan to true
        // autoplan menus
        this.props.toggleAutoplan({autoplan:true})
        this.props.solveWeekPlan()
        this.props.closedOnboardingModal()
    }

    render(){        
        return (
            <div>
            <div
                style={{
                    maxHeight:450,
                    overflowY:"auto",
                }}
            >
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell
                                style={{
                                    width:"50%"
                                }}
                            >Task</TableCell>
                            <TableCell>Method</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>
                                <Typography>
                                    Get A Weekly MealPlan
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <div
                                    style={{
                                        textAlign:"center",
                                        display:"flex",
                                        flexDirection:"column",
                                        alignItems:"center",
                                    }}
                                >
                                    <img
                                        src={autoplan_week_image}
                                    />
                                    <ArrowDownwardIcon/>
                        <IconButton
                            disabled={true}
                            size="small"
                            style={{
                                display:"flex",
                                flexDirection:"column",
                                backgroundColor:"white",
                                opacity:0.5,
                                width:60,
                                border:"1px solid var(--main_contrast)",
                        }}
                    >
                        <div
                        style={{
                            display:"flex",
                            flexDirection:"column",
                            justifyContent:"center",
                        }}
                        disabled={true}
                        >
                        <LoopIcon
                            fontSize="default"
                            style={{
                                color:"var(--main_contrast)",
                                margin:"auto",
                            }}
                        />
                        <Typography 
                            variant="subtitle2" 
                            style={{
                                color:"var(--main_contrast)",
                            }}
                        >
                        Suggest
                        </Typography>
                        </div>
                        </IconButton>
                                </div>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <Typography>
                                    Adjust Amounts To Fit Goals
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <div
                                    style={{
                                        textAlign:"center",
                                        display:"flex",
                                        flexDirection:"column",
                                        alignItems:"center",
                                    }}
                                >
                                    <img
                                        src={autoplan_week_image}
                                    />
                                    <ArrowDownwardIcon style={{color:"grey"}} />
                        <IconButton
                        size="small"
                        style={{
                            display:"flex",
                            flexDirection:"column",
                            backgroundColor:"white",
                            opacity:0.5,
                            width:60,
                            border:"1px solid var(--main_contrast)",
                        }}
                        disabled={true}
                    >
                        <div
                        style={{
                            display:"flex",
                            flexDirection:"column",
                            justifyContent:"center",
                        }}
                        >
                        <VerticalAlignCenterIcon
                            fontSize="default"
                            style={{
                                color:"var(--main_contrast)",
                                margin:"auto",
                            }}
                        />
                        <Typography
                            variant="subtitle2"
                            style={{
                                color:"var(--main_contrast)",
                            }}
                        >
                        Adjust
                        </Typography>
                        </div>
                        {/* Adjust */}
                    </IconButton>

                                </div>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <Typography>
                                    Open Autoplanner For Single Day
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <div
                                    style={{
                                        textAlign:"center",
                                        display:"flex",
                                        flexDirection:"column",
                                        alignItems:"center",    
                                    }}
                                >
                                    <img
                                        src={autoplan_day_button}
                                        style={{
                                            margin:"auto"
                                        }}
                                    />
                                </div>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <Typography>
                                    Change Menu For Autoplanner Suggestions
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <div
                                style={{
                                        textAlign:"center",
                                        display:"flex",
                                        flexDirection:"column",
                                        alignItems:"center",    
                                    }}  
                                > 
                                <img 
                                    src={default_menu}
                                    width={190}
                                />
                                <ArrowDownwardIcon style={{color:"grey"}}/>
                                <img src={searching_chipotle_menu} width={190}/>
                                <ArrowDownwardIcon style={{color:"grey"}} />
                                <img src={chipotle_menu} width={190}/>
                                </div>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <Typography>
                                    Get Alternatives For Single Food
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <div
                                style={{
                                        textAlign:"center",
                                        display:"flex",
                                        flexDirection:"column",
                                        alignItems:"center",    
                                    }} 
                                >
                                
                                <img 
                                    src={alternatives}
                                    width={190}
                                />
                                <ArrowDownwardIcon style={{color:"grey"}}/>
                                <img src={alternative_options} width={190}/>                                
                                </div>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <Typography>
                                    Edit Default Menu
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <div
                                style={{
                                        textAlign:"center",
                                        display:"flex",
                                        flexDirection:"column",
                                        alignItems:"center",    
                                    }}   
                                >          
                                <img src={edit_menu} width={190}/>   
                                </div>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <Typography>
                                    Toggle Nutrients Included In Autoplanner
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <div
                                style={{
                                        textAlign:"center",
                                        display:"flex",
                                        flexDirection:"column",
                                        alignItems:"center",    
                                    }}   
                                >          
                                <img src={toggle_nutrients} width={190}/>   
                                </div>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                </div>
                <div
                    style={{
                        width:"80%",
                        margin:"auto",
                        marginBottom:10,
                    }}
                >
                    <Button
                        onClick={this.onClick}
                        fullWidth
                        variant="raised"
                        style={{
                            backgroundColor:"var(--main_bright)",
                            color:"white",
                        }}
                    >
                        I'm ready to get started!</Button>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state,ownProps) => ({

})

export default connect(null,{toggleAutoplan, solveWeekPlan,closedOnboardingModal})(AutoplannerHowTo);