import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
    Button,
    Typography,
}from '@material-ui/core/';


class LandingPage extends Component{
// props - circle/square, image
    constructor(props){
        super(props)
        this.state = {
        } 
    }

    render(){        
        return (
            <div>
                <div
                    style={{
                        
                    }}
                >Main Tagline Goes Here
                </div>
                <Button>Get Started</Button>
            </div>
        )
    }
}

const mapStateToProps = (state,ownProps) => ({

})

export default connect(null,null)(LandingPage);