import React, {Component} from 'react';
import {connect} from 'react-redux';
import Nutrient_Requirement from './nutrient_requirement'
import {
    Typography,
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
    Input,
    TextField,
    Radio,
    RadioGroup,
    FormControl,
    FormLabel,
    FormControlLabel,
    FormGroup,
    Switch,
    Button,
    IconButton,
    Popper,
    InputAdornment,
    InputLabel
} from '@material-ui/core/';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import HelpIcon from '@material-ui/icons/Help';
import InfoIcon from '@material-ui/icons/Info';
import {updateCalReq,updateUserMacro,saveMacros} from '../../actions/nutrients';


class MyNutrients extends Component{

    constructor(props){
        super(props)
        // fill in
        let weight_unit = this.props.user_stats.weight_unit
        if(!weight_unit){
            weight_unit = "lb"
        }
        let sex = this.props.user_stats.sex
        if(!sex){
            sex = "male"
        }
        let activity = this.props.user_stats.activity
        if(!activity){
            activity = "1"
        }
        let cal_adj = this.props.user_stats.cal_adj
        if(!cal_adj){
            cal_adj = "1"
        }
        this.state = {
            //can also input from props from 'profile'
            weight:this.props.user_stats.weight,
            weight_unit:weight_unit,
            height_feet:this.props.user_stats.height_feet,
            height_inches:this.props.user_stats.height_inches,
            age:this.props.user_stats.age,
            sex:sex,
            activity:activity,
            cal_adj:cal_adj,
            fat_perc:this.props.user_stats.fat_perc,
            car_perc:this.props.user_stats.car_perc,
            pro_perc:this.props.user_stats.pro_perc,
            //error props
            weight_error:false,
            weight_unit_error:false
          }
    }

    onChangeMacro = (macro_abr, new_val) =>{
        this.props.updateUserMacro(macro_abr, new_val)
    }

    onUpdateMacros = () =>{
        let macro_sum = parseFloat(this.props.user_stats.fat_perc) + parseFloat(this.props.user_stats.pro_perc) + parseFloat(this.props.user_stats.car_perc)
        if((macro_sum!=1.0)){
            alert("Macro Proportions Must Add Up To One")
        }else{
            this.props.saveMacros()
        }
    }

    onSubmitCalQuestionaire = () =>{
        // error checks
        let user_stats = {
            weight:this.state.weight,
            weight_unit:this.state.weight_unit,
            height_feet:this.state.height_feet,
            height_inches:this.state.height_inches,
            age:this.state.age,
            sex:this.state.sex,
            activity:this.state.activity,
            cal_adj:this.state.cal_adj,
            pro_perc:this.props.user_stats.pro_perc, // necessary for how it's used. Poor naming tbh
            fat_perc:this.props.user_stats.fat_perc,
            car_perc:this.props.user_stats.car_perc,
        }
        this.props.updateCalReq(user_stats)
    }

    render(){
        // Called via modal
        return(
        <div>
            {/* <Typography>My Nutrients</Typography> */}
            <div>
                <ExpansionPanel
                    style={{
                        width:350,
                        margin:"auto",
                    }}
                >
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                    >
                        <Typography
                            // variant="subtitle"
                        >Take Calorie Target Questionaire</Typography>
                    </ExpansionPanelSummary>
                    
                    <ExpansionPanelDetails>
                    <div
                        style={{
                            display:"flex",
                            flexDirection:"column",
                            fontSize:12,
                            textAlign:"center",
                            width:320,
                        }}
                    >
                        <FormLabel
                            style={{
                                width:320,
                                marginTop:10
                            }}
                        >
                            Metrics
                            <IconButton size="small">
                                <HelpIcon/>
                            </IconButton>
                        </FormLabel>
                    <div
                        style={{
                            // display:"flex",
                            // flexDirection:"row",
                            // flexWrap:"wrap",
                            display:"grid",
                            gridTemplateColumns: "repeat(auto-fit, minmax(150px, 1fr))",
                            gridGap:"5px 10px",
                            margin:"auto",
                            width:320,
                        }}
                    >

                        <TextField
                            style={{fontSize:12,width:150}}
                            size="small"
                            label="Weight"
                            variant="outlined"
                            value={this.state.weight}
                            type="tel"
                            onChange={(event)=>{this.setState({'weight':event.target.value})}}
                        />
                        <RadioGroup
                            style={{
                                width:150,
                                display:"flex",
                                flexDirection:"row",
                            }}
                            defaultValue="lb"
                            onChange={(event)=>{this.setState({'weight_unit':event.target.value})}}
                        >
                            <FormControlLabel
                                style={{fontSize:12,}}
                                 size="small"
                                value="lb"
                                control={<Radio/>}
                                label="lbs"
                            />
                            <FormControlLabel
                                style={{fontSize:12,}}
                                 size="small"
                                value="kg"
                                control={<Radio/>}
                                label="kg"
                            />
                        </RadioGroup>
                        {/* <Typography>
                            Height
                        </Typography> */}
                        <TextField
                            style={{fontSize:'12px',width:150}}
                            size="small"
                            type="tel"
                            variant="outlined"
                            label ="Height - Feet"
                            onChange={(event)=>{this.setState({'height_feet':event.target.value})}}
                        />
                        <TextField
                            style={{fontSize:'12px',width:150}}
                            size="small"
                            type="tel"
                            variant="outlined"
                            label="Height - Inches"
                            onChange={(event)=>{this.setState({'height_inches':event.target.value})}}
                        />
                        <TextField 
                             style={{fontSize:'12px',width:150}}
                            size="small"
                            type="tel"
                            variant="outlined"
                            label="Age"
                            onChange={(event)=>{this.setState({'age':event.target.value})}}
                        />
                        <RadioGroup
                             style={{
                                 fontSize:'12px',
                                 width:150,
                                 display:"flex",
                                 flexDirection:"row",
                            }}
                            defaultValue={this.state.sex}
                            onChange={(event)=>{this.setState({'sex':event.target.value})}}
                        >
                            <FormControlLabel
                                size="small"
                                value="male"
                                control={<Radio/>}
                                label="male"
                            />
                            <FormControlLabel
                                size="small"
                                value="female"
                                control={<Radio/>}
                                label="female"
                            />
                        </RadioGroup> 
                    </div>
                    <FormLabel
                            style={{
                                width:"100%",
                                marginTop:10,
                            }}
                        >
                            Exercise Level
                            <IconButton size="small">
                                <HelpIcon/>
                            </IconButton>
                        </FormLabel>
                    <div
                        style={{
                            // display:"flex",
                            // flexDirection:"row",
                            // flexWrap:"wrap",
                            display:"grid",
                            gridTemplateColumns: "repeat(auto-fit, minmax(150px, 1fr))",
                            gridGap:"5px 10px",
                            width:320,
                        }}
                    >
                        <RadioGroup
                            value={String(this.state.activity)}
                            onChange={(event)=>{this.setState({'activity':event.target.value})}}
                            style={{
                                display:"flex",
                                flexDirection:"row",
                                // width:150,
                            }}
                        >
                            <FormControlLabel
                                style={{
                                    width:150,
                                }}
                                value="0"
                                control={<Radio/>}
                                label="Little To None"
                            />
                            <FormControlLabel
                                style={{
                                    width:150,
                                }}
                                value="1"
                                control={<Radio/>}
                                label="Light Cardio/Moderate Weights"
                            />
                            <FormControlLabel
                                style={{
                                    width:150,
                                }}
                                value="2"
                                control={<Radio/>}
                                label="Moderate Cardio/Heavy Weights"
                            />
                            <FormControlLabel
                                style={{
                                    width:150,
                                }}
                                value="3"
                                control={<Radio/>}
                                label="Heavy Cardio"
                            />
                            <FormControlLabel
                                style={{
                                    width:150,
                                }}
                                value="4"
                                control={<Radio/>}
                                label="Continuous Exercise"
                            />
                        </RadioGroup> 
                    </div>
                    <FormLabel
                        style={{
                            width:320,
                            marginTop:10,
                        }}
                    >
                            Pounds Change Per Week Goal
                            <IconButton size="small">
                                <HelpIcon/>
                            </IconButton>
                        </FormLabel>
                    <div
                        style={{
                            display:"flex",
                            flexDirection:"row",
                            flexWrap:"wrap",
                        }}
                    >
                        <RadioGroup 
                            name="cal_adj"
                            value={String(this.state.cal_adj)}
                            onChange={(event)=>{this.setState({'cal_adj':event.target.value})}}
                            style={{
                                display:"flex",
                                flexDirection:"row",
                                margin:"auto"
                            }}
                        >
                            <FormControlLabel
                                value="0"
                                control={<Radio/>}
                                label="-1 pound"
                            />
                            <FormControlLabel
                                value="1"
                                control={<Radio/>}
                                label="-0.5 pounds"
                            />
                            <FormControlLabel
                                value="2"
                                control={<Radio/>}
                                label="0 pounds"
                            />
                            <FormControlLabel
                                value="3"
                                control={<Radio/>}
                                label="+0.5 pounds"
                            />
                            <FormControlLabel
                                value="4"
                                control={<Radio/>}
                                label="+1 pound"
                            />
                        </RadioGroup>
                        </div>
                    <div>
                        <Button
                            style={{width:"100%",
                            backgroundColor:"var(--main_bright)",
                            color:"var(--sec_white)",
                        }}
                            onClick={this.onSubmitCalQuestionaire}
                        >Update</Button>
                    </div>
                    </div>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </div>
            <div
                style={{
                    display:"flex",
                    flexDirection:"column"
                }}
            >
            {/* Macro Percentages and calorie target */}
            <div style={{display:"flex", marginTop:10, margin:"auto"}}>
                <Typography>Macros</Typography>
                <IconButton size="small">
                    <HelpIcon/>
                </IconButton>
            </div>
            <div
                style={{
                    display:"flex",
                    flexDirection:"column",
                    margin:"auto",
                }}
            >
            <div
                style={{
                    display:"flex",
                    margin:"auto",
                    width:350,
                }}
            >
                <TextField
                    style={{
                        width:115
                    }}
                    type="tel"
                    label="Protein"
                    variant="outlined"
                    size="small"
                    InputLabelProps ={{
                        shrink:true
                    }}
                    value={this.props.user_stats.pro_perc}
                    endAdornment={<InputAdornment position="end">%</InputAdornment>}
                    onChange={(event)=>{this.onChangeMacro('pro',event.target.value)}}
                />
                <TextField
                    style={{
                        width:115
                    }}
                    InputLabelProps ={{
                        shrink:true
                    }}
                    type="tel"
                    label="Fat"
                    variant="outlined"
                    size="small"
                    value={this.props.user_stats.fat_perc}
                    endAdornment={<InputAdornment position="end">%</InputAdornment>}
                    onChange={(event)=>{this.onChangeMacro('fat',event.target.value)}}
                />
                <TextField
                    style={{
                        width:115
                    }}
                    InputLabelProps ={{
                        shrink:true
                    }}
                    type="tel"
                    label="Carbs"
                    variant="outlined"
                    size="small"
                    value={this.props.user_stats.car_perc}
                    endAdornment={<InputAdornment position="end">%</InputAdornment>}
                    onChange={(event)=>{this.onChangeMacro('car',event.target.value)}}
                />
                </div>
                <div
                    style={{
                        
                    }}
                >
                <Button
                    style={{
                        width:"100%",
                        backgroundColor:"var(--main_bright)",
                        color:"var(--sec_white)",
                    }}
                    onClick={this.onUpdateMacros}
                >Update Macros</Button>

                </div>
            </div>
            </div>
            <div
                style={{
                    marginTop:50,
                    margin:"auto",
                    display:"flex",
                    flexDirection:"column",
                    // display:"grid",
                    // gridTemplateColumns: "repeat(auto-fit, minmax(150px, 1fr))",
                    // gridGap:"10px 20px"
                       
                    //change to grid 4 - accross
                }}
            >
                {this.props.requirements_order.map((nut_abrev, idx)=>{
                    return(
                        <Nutrient_Requirement
                            key = {nut_abrev}
                            nut_abrev={nut_abrev}
                            nut_name={this.props.abrev_to_display_dict[nut_abrev]}
                            nut_unit={this.props.abrev_to_units_dict[nut_abrev]}
                        />
                    )
                })}
            </div>
        </div>
        )
    }
}

const mapStateToProps = state => ({
    requirements_order: state.user.requirements_order,
    abrev_to_nut_dict: state.util.abrev_to_nut_dict,
    abrev_to_display_dict: state.util.abrev_to_display_dict,
    abrev_to_units_dict: state.util.abrev_to_units_dict,
    all_nutrients_list: state.util.all_nutrients_list,
    user_stats:state.user.user_stats
  })

export default connect(mapStateToProps, {updateCalReq,updateUserMacro,saveMacros})(MyNutrients);
