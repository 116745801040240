import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
    TextField,
    Radio,
    RadioGroup,
    FormLabel,
    FormControlLabel,
    Button,
}from '@material-ui/core/';
import {updateCalReq,saveUserStat} from '../../actions/nutrients';

class CalorieQuestionaire extends Component{
// props - circle/square, image
    constructor(props){
        super(props)
        this.state = {
            //can also input from props from 'profile'
          }
    }

    onSubmitCalQuestionaire = () =>{
        // TODO - add error checks
        // let field_check =['weight', 
        //                 'height_feet',
        //                 'height_inches',
        //                 'age',
        //                 'sex',
        //                 'activity',
        //                 'cal_adj',
        //                 'pro_perc',
        //                 'fat_perc',
        //                 'car_perc'
        //             ]
        // let user_stats = this.props.user_stats
        // for(stat in user_stats){
        //     if(user_stats[stat]===null)||(user_stats[stat]===''){
        //         return alert(`{stat}`)
        //     }
        // }
        // if(
        //     ((user_stats.weight===null)||(user_stats.weight===''))
        // ){
        //     return alert("Please fill out each field")
        // }
        // TODO - change to use this.props.user_stats
        let user_stats = {
            weight:this.props.user_stats.weight,
            weight_unit:'lb',
            height_feet:this.props.user_stats.height_feet,
            height_inches:this.props.user_stats.height_inches,
            age:this.props.user_stats.age,
            sex:this.props.user_stats.sex,
            activity:this.props.user_stats.activity,
            cal_adj:this.props.user_stats.cal_adj,
            pro_perc:this.props.user_stats.pro_perc, // necessary for how it's used. Poor naming tbh
            fat_perc:this.props.user_stats.fat_perc,
            car_perc:this.props.user_stats.car_perc,
        }
        this.props.updateCalReq(user_stats)
    }

    updateIndReq = (stat, value) =>{
        let ret_dict = {}
        ret_dict[stat] = value
        this.props.saveUserStat(ret_dict)
    }

    render(){       
        // TODO - change all to update global state only
        return (
            <div
                style={{}}
            >
                    <div
                        style={{
                            display:"flex",
                            flexDirection:"column",
                            fontSize:12,
                            textAlign:"center",
                            width:320,
                            margin:"auto"
                        }}
                    >
                        <FormLabel
                            style={{
                                width:320,
                                marginTop:10,
                                textDecoration:"underline",
                            }}
                        >
                            Metrics
                        </FormLabel>
                    <div
                        style={{
                            margin:"auto",
                            width:320,
                        }}
                    >
                        <TextField
                            style={{
                                fontSize:12,
                                width:150,
                                margin:5,
                            }}
                            size="small"
                            label="Weight (lbs)"
                            variant="outlined"
                            value={this.props.user_stats.weight}
                            type="tel"
                            onChange={(event)=>{this.updateIndReq('weight',event.target.value)}}
                        />
                        <TextField 
                            style={{
                                fontSize:'12px',
                                width:150,
                                margin:5,
                            }}
                            size="small"
                            type="tel"
                            variant="outlined"
                            label="Age"
                            value={this.props.user_stats.age}
                            onChange={(event)=>{this.updateIndReq('age',event.target.value)}}
                        />
                        <TextField
                            style={{
                                fontSize:'12px',
                                width:150,
                                margin:5,
                            }}
                            size="small"
                            type="tel"
                            variant="outlined"
                            label ="Height - Feet"
                            value ={this.props.user_stats.height_feet}
                            onChange={(event)=>{this.updateIndReq('height_feet',event.target.value)}}
                        />
                        <TextField
                            style={{
                                fontSize:'12px',
                                width:150,
                                margin:5,
                            }}
                            size="small"
                            type="tel"
                            variant="outlined"
                            label="Height - Inches"
                            value ={this.props.user_stats.height_inches}
                            onChange={(event)=>{this.updateIndReq('height_inches',event.target.value)}}
                        />
                        <div
                            style={{
                                margin:"auto",
                                width:"100%",
                                textAlign:"center",
                            }}
                        >
                        <RadioGroup
                             style={{
                                 fontSize:'12px',
                                width:200,
                                display:"flex",
                                flexDirection:"row",
                                margin:"auto",
                            }}
                            row={true}
                            value ={this.props.sex}
                            onChange={(event)=>{this.updateIndReq('sex',event.target.value)}}
                        >
                            <FormControlLabel
                                size="small"
                                value="male"
                                style={{
                                    color:"grey",
                                    fontSize:10,
                                }}
                                control={<Radio
                                    style={{
                                        color:"var(--main_bright)",
                                    }}
                                />}
                                label="male"

                            />
                            <FormControlLabel
                                style={{
                                    color:"grey",
                                    fontSize:10,
                                }}
                                size="small"
                                value="female"
                                control={<Radio
                                    style={{
                                        color:"var(--main_bright)",
                                    }}
                                />}
                                label="female"
                            />
                        </RadioGroup> 
                        </div>
                    </div>
                    <FormLabel
                            style={{
                                width:"100%",
                                marginTop:10,
                                marginBotton:10,
                                textDecoration:"underline",
                            }}
                        >
                            Exercise Level
                        </FormLabel>
                    <div
                        style={{
                            marginTop:10,
                            display:"flex",
                            flexDirection:"column",
                            // flexWrap:"wrap",
                            // display:"grid",
                            // gridTemplateColumns: "repeat(auto-fit, minmax(150px, 1fr))",
                            // gridGap:"5px 10px",
                            width:320,
                        }}
                    >
                        <RadioGroup
                            value ={String(this.props.user_stats.activity)}
                            onChange={(event)=>{this.updateIndReq('activity',event.target.value)}}
                            style={{
                                // width:150,
                            }}
                        >
                            <FormControlLabel
                                style={{
                                    width:300,
                                    marginLeft:10,
                                    fontSize:10,
                                    color:"grey",
                                }}
                                value={"0"}
                                control={<Radio
                                    style={{
                                        padding:0,
                                        margin:0,
                                        color:"var(--main_bright)",
                                    }}
                                />}
                                label="Little To None"
                            />
                            <FormControlLabel
                                style={{
                                    width:320,
                                    marginLeft:10,
                                    fontSize:10,
                                    color:"grey",
                                }}
                                value={"1"}
                                control={<Radio
                                    style={{
                                        padding:0,
                                        margin:0,
                                        color:"var(--main_bright)",
                                    }}
                                />}
                                label="Light Cardio/Moderate Weights"
                            />
                            <FormControlLabel
                                style={{
                                    width:320,
                                    marginLeft:10,
                                    fontSize:10,
                                    color:"grey",
                                }}
                                value="2"
                                control={<Radio
                                    style={{
                                        padding:0,
                                        margin:0,
                                        color:"var(--main_bright)",
                                    }}
                                />}
                                label="Moderate Cardio/Heavy Weights"
                            />
                            <FormControlLabel
                                style={{
                                    width:320,
                                    marginLeft:10,
                                    fontSize:10,
                                    color:"grey",
                                }}
                                value="3"
                                control={<Radio
                                    style={{
                                        padding:0,
                                        margin:0,
                                        color:"var(--main_bright)",
                                    }}
                                />}
                                label="Heavy Cardio"
                            />
                            <FormControlLabel
                                style={{
                                    width:320,
                                    marginLeft:10,
                                    fontSize:10,
                                    color:"grey",
                                }}
                                value="4"
                                control={<Radio
                                    style={{
                                        padding:0,
                                        margin:0,
                                        
                                    }}
                                />}
                                label="Continuous Exercise"
                            />
                        </RadioGroup> 
                    </div>
                    <FormLabel
                        style={{
                            width:320,
                            marginTop:10,
                            marginBottom:10,
                            textDecoration:"underline",
                        }}
                    >
                            Pounds Change Per Week Goal
                            {/* <IconButton size="small">
                                <HelpIcon/>
                            </IconButton> */}
                        </FormLabel>
                    <div
                        style={{
                            display:"flex",
                            flexDirection:"row",
                            flexWrap:"wrap",
                        }}
                    >
                        <RadioGroup 
                            name="cal_adj"
                            value ={this.props.user_stats.cal_adj}
                            onChange={(event)=>{this.updateIndReq('cal_adj',event.target.value)}}
                            style={{
                                display:"flex",
                                flexDirection:"row",
                                margin:"auto",
                                justifyContent:"center",
                            }}
                        >
                            <FormControlLabel
                                value={"0"}
                                style={{
                                    fontSize:10,
                                    color:"grey"
                                }}
                                control={<Radio
                                    style={{
                                        padding:5,
                                        margin:0,
                                        color:"var(--main_bright)",
                                    }}
                                />}
                                label="-1 lb"
                            />
                            <FormControlLabel
                                value={"1"}
                                style={{
                                    fontSize:10,
                                    color:"grey"
                                }}
                                control={<Radio
                                    style={{
                                        padding:5,
                                        margin:0,
                                        color:"var(--main_bright)",
                                    }}                               
                                />}
                                label="-0.5 lbs"
                            />
                            <FormControlLabel
                                value={"2"}
                                style={{
                                    fontSize:10,
                                    color:"grey"
                                }}
                                control={<Radio
                                    style={{
                                        padding:5,
                                        margin:0,
                                        color:"var(--main_bright)",
                                    }}                                
                                />}
                                label="0 lbs"
                            />
                            <FormControlLabel
                                value={"3"}
                                style={{
                                    fontSize:10,
                                    color:"grey"
                                }}
                                control={<Radio
                                    style={{
                                        padding:5,
                                        margin:0,
                                        color:"var(--main_bright)",

                                    }}                                
                                />}
                                label="+0.5 lbs"
                            />
                            <FormControlLabel
                                value={"4"}
                                style={{
                                    fontSize:10,
                                    color:"grey"
                                }}
                                control={<Radio
                                    style={{
                                        padding:5,
                                        margin:0,
                                        color:"var(--main_bright)",
                                    }}                                
                                />}
                                label="+1 lb"
                            />
                        </RadioGroup>
                        </div>
                    <div>
                        <Button
                            style={{
                                width:"100%",
                                backgroundColor:"var(--main_bright)",
                                color:"var(--sec_white)",
                                display:this.props.show_button?"":"none",
                                marginBottom:5,
                        }}
                            onClick={this.onSubmitCalQuestionaire}
                        >Update</Button>
                    </div>
                    </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    user_stats:state.user.user_stats,
    sex: state.user.user_stats.sex
})

export default connect(mapStateToProps, {updateCalReq,saveUserStat})(CalorieQuestionaire);