import axios from 'axios'// since fetch api not supported in all browsers
import store from '../store'
import {url_root, configHeaders, is_prod} from './util';

export const openFoodModal = (food_key) =>{
    //set to 'open'
    return dispatch =>{
        dispatch(togglingFoodModal({
            open:true,
            food_key:food_key
        }))
        dispatch(togglingFoodModalLoading({
            loading:true
        }))
        axios.post(url_root+'/food/get_food_modal/',
        {
            params:{
                food_key:food_key,
             }
        },
        {                                 
            headers:configHeaders(is_prod),
        }
    )
    .then(function (response){
        var results = response['data']
        dispatch(updatingFoodModal({
            food_dict:results["food_dict"],
            tag_list:results["tag_list"],
            pref_list:results["pref_list"],
            prob_r_dict:results["prob_r_dict"],
            is_recipe:results["is_recipe"],
            recipe_list:results["recipe_list"],
            loading:false
        }))
    })
    .catch(function (error){
        console.log(error)
    })
    .finally(function(){
        console.log("request complete")
    })

    }
}

const togglingFoodModal = dataObj => ({
    type:'TOGGLING_FOOD_MODAL',
    payload: dataObj
})

const togglingFoodModalLoading = dataObj => ({
    type:'TOGGLING_FOOD_MODAL_LOADING',
    payload: dataObj
})

const updatingFoodModal = dataObj => ({
    type:'UPDATING_FOOD_MODAL',
    payload: dataObj
})

export const closeFoodModal = () =>{
    return dispatch=>{
        dispatch(togglingFoodModal({
            open:false,
            food_key:null
        }))
    }
}

export const changeFoodMaxServings = (food_key,maxServings) =>{
    return dispatch =>{
        dispatch(updatingMaxServings({
            max_servings:maxServings
        }))
        axios.post(url_root+'/core/update_food_max_servings/',
        {
            params:{
                food_key:food_key,
                maxServings:maxServings
             }
        },
        {                                 
            headers:configHeaders(is_prod),
        }
    )
    .then(function (response){
        console.log(response)
    })
    .catch(function (error){
        console.log(error)
    })
    .finally(function(){
        console.log("request complete")
    })
    }
}

const updatingMaxServings = dataObj => ({
    type:'UPDATING_MAX_SERVINGS',
    payload: dataObj
})

export const addFoodTag = (new_tag_list, is_new) =>{
    return dispatch=>{
        // let new_tag_list = Array.from(store.getState().modal.food_modal.tag_list)
        // new_tag_list.push(tag_obj)
        dispatch(addingFoodTags({
            tag_list: new_tag_list
        }))
        let food_key = store.getState().modal.food_modal.food_key
        let tag_obj = new_tag_list[new_tag_list.length-1]
        axios.post(url_root+'/food/add_food_tag/',
        {
            params:{
                tag_name:tag_obj.name,
                food_key:food_key
             }
        },
        {                                 
            headers:configHeaders(is_prod),
        }
        )
        .then(function (response){
            console.log(response)
        })
        .catch(function (error){
            console.log(error)
        })
        .finally(function(){
            console.log("request complete")
        })

    }
}

const addingFoodTags = dataObj => ({
    type:'ADDING_FOOD_TAGS',
    payload: dataObj
})

export const removeFoodTag = (idx) =>{
    let new_tag_list = Array.from(store.getState().modal.food_modal.tag_list)
    let tag_obj = new_tag_list[idx]
    new_tag_list.splice(idx,1)
    return dispatch=>{
        dispatch(addingFoodTags({
            tag_list: new_tag_list
        }))
        let food_key = store.getState().modal.food_modal.food_key
        axios.post(url_root+'/food/remove_food_tag/',
        {
            params:{
                tag_name:tag_obj.name,
                food_key:food_key
             }
        },
        {                                 
            headers:configHeaders(is_prod),
        }
        )
        .then(function (response){
            console.log(response)
        })
        .catch(function (error){
            console.log(error)
        })
        .finally(function(){
            console.log("request complete")
        })

    }
}

export const searchingTagsAutocomplete = (search_qry) =>{
    return dispatch =>{
        axios.post(url_root+'/food/get_tag_autocomplete/',
        {
            params:{
                search_qry:search_qry,
             }
        },
        {                                 
            headers:configHeaders(is_prod),
        }
        )
        .then(function (response){
            let results=response["data"]
            dispatch(addingFoodTagSearchList({
                tag_search_list:results["tag_search_list"]
            }))
        })
        .catch(function (error){
            console.log(error)
        })
        .finally(function(){
            console.log("request complete")
        })

    }
}

const addingFoodTagSearchList = dataObj => ({
    type:'ADDING_FOOD_TAG_SEARCH_LIST',
    payload: dataObj
})

export const add_pref_list =(menuObj)=>{
    let new_pref_list = Array.from(store.getState().modal.food_modal.pref_list)
    new_pref_list.push(menuObj)
    return dispatch=>{
        dispatch(updatingFoodModalPrefList({
            pref_list:new_pref_list
        }))
    }
}
export const remove_pref_list =(idx)=>{
    let new_pref_list = Array.from(store.getState().modal.food_modal.pref_list)
    new_pref_list.splice(idx,1)
    return dispatch=>{
        dispatch(updatingFoodModalPrefList({
            pref_list:new_pref_list
        }))
    }
}

const updatingFoodModalPrefList = listObj =>({
    type:'UPDATING_FOOD_MODAL_PREF_LIST',
    payload:listObj
})