import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Paper,
        Divider
} from '@material-ui/core/';

const useStyles = theme=> ({
    demo_bg:{
        // height:"100vh",
        width:"100vw",
        padding:50,
    },
    demo_title:{
        fontSize:55,
        fontWeight:"bold",
        width:"100%",
        color:"white",
        textAlign:"center",
        [theme.breakpoints.down('md')]:{
            fontSize:24
        },

    },
    demo_modules_holder:{
        display:"flex",
        justifyContent:"space-evenly", //for now, unless add a spacer object
        flexWrap:"wrap",
    },
    demo_module:{
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        flexWrap:"wrap",
        [theme.breakpoints.down('md')]:{
            flexDirection:"column",
            paddingTop:40,
        },
    },
    demo_subtitle:{
        fontSize:38,
        fontWeight:"bold",
        color:"darkGrey",
        [theme.breakpoints.down('md')]:{
            fontSize:18,
        },
    },
    demo_text:{
        backgroundColor:"linear-gradient(white, var(--sec_light)",
        padding:15,
        borderRadius:50,
        // width:"30%",
        // height:"60%",
        maxWidth:320,

    },  
    demo_triangle:{
        backgroundColor:"var(-sec_mid)",
    },
    demo_body:{
        fontSize:36,
        [theme.breakpoints.down('md')]:{
            fontSize:16,
        },
    },
    demo_gif_div:{

    }
})

class DemoModule extends Component{
// props - circle/square, image
    constructor(props){
        super(props)
        this.state = {
        } 
    }

    render(){        
        const {classes} = this.props  
        return (
            <div
                className={classes.demo_bg}
                style={{
                    backgroundColor:this.props.bg_col,
                }}
            >
                <div
                    className={classes.demo_title}
                >
                    {this.props.title}
                </div>
                <div
                    className={classes.demo_modules_holder}
                >

                <div
                    className={classes.demo_module}
                >
                    <Paper
                        className={classes.demo_text}
                    >
                        <div
                            className={classes.demo_subtitle}
                        >
                            {this.props.subtitle1}
                        </div>
                        <Divider 

                        />
                        <div
                            classname={classes.demo_body}
                        >
                            {this.props.subheader1}
                        </div>
                    </Paper>
                    {/* <div
                        className={classes.demo_gif_div}
                    > */}
                            <img 
                                src={this.props.demo_gif1}
                                height={480}
                                width={this.props.width1}
                                style={{
                                    borderRadius:25,
                                    border:"4px solid black",
                                    // boxShadow:
                                }}
                            />
                    {/* </div> */}
                </div>
                <div
                    className={classes.demo_module}
                >
                    <Paper
                        className={classes.demo_text}
                    >
                        <div
                            className={classes.demo_subtitle}
                        >
                            {this.props.subtitle2}
                        </div>
                        <Divider 

                        />
                        <div
                            classname={classes.demo_body}
                        >
                            {this.props.subheader2}
                        </div>
                    </Paper>
                    {/* <div
                        className={classes.demo_gif_div}
                    > */}
                            <img 
                                src={this.props.demo_gif2}
                                height={480}
                                width={this.props.width2}
                                style={{
                                    borderRadius:25,
                                    border:"4px solid black",
                                }}
                            />
                    {/* </div> */}
                </div>
                </div>
            </div>
        )
    }
}

export default withStyles(useStyles)(DemoModule);