import axios from 'axios'// since fetch api not supported in all browsers
import store from '../store'

export const meals = ['Breakfast', 'Lunch','Dinner', 'Snack'] 

// export const url_root = 'http://127.0.0.1:8000'
export const url_root = 'https://api.planyourmeals.com'
export const is_prod = false // always keep false

export const sumNutrientTotalsFromList = (foods_mega_list) =>{

        let nut_list = store.getState().user.requirements_order
        let abrev_to_nut_dict = store.getState().util.abrev_to_nut_dict
        let new_nutrient_totals = {}
        nut_list.map((nut_abrev, idx)=>{
            let nut = abrev_to_nut_dict[nut_abrev]
            new_nutrient_totals[nut] = 0
            foods_mega_list.forEach((curr_elem,idx)=>{
                let ss_idx = curr_elem['serving_size_idx']
                let amt = curr_elem['amt']/(curr_elem.serving_sizes.ss_amts[ss_idx])
                let adj_amt = (amt/(curr_elem.serving_sizes.ss_amts[ss_idx]))*curr_elem.serving_size_val
                new_nutrient_totals[nut]+=Math.round(curr_elem[nut]*adj_amt*100)/100
            })
        })
        return new_nutrient_totals
}


// TODO - can just make a flag for passing whole list or not to this function
export const sumNutrientTotals = (state_slice, changed_meal, changed_foods_list) =>{
    let foods_mega_list = Array.from(changed_foods_list)
    meals.forEach((meal,idx)=>{
        if(meal != changed_meal){
            foods_mega_list = foods_mega_list.concat(state_slice[meal].foods_list)
        }
    })
    let new_nutrient_totals = sumNutrientTotalsFromList(foods_mega_list)
    return new_nutrient_totals
}

export const updateSingleMeal = (plannum,meal_type,foods_list)=>{
    let date_str = store.getState().util.days_dict[plannum]
    axios.post(
                url_root+'/plan/update_meal_from_list/', // address
                {                                               // body
                    params:{
                        plan_date: date_str,
                        meal_type: meal_type,
                        foods_list:foods_list
                    }
                },
                {                                               // config
                    headers: configHeaders(is_prod),
                }
            )
            .then(res=>{})
            .catch(err=>{})
}

export const titleCase =(string)  =>{
    if(string){
        let sentence = string.toLowerCase().split(" ")
        for(let i=0; i<sentence.length;i++){
            sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1)
        }
        let result = sentence.join(" ")
        return result
    }else{
        return null
    }
}

export const checkStorageTokenAndLogin =() =>{

}

export const configHeaders = (prod)  =>{

    let token = store.getState().auth.accessToken
    // keep  for page reloads
    if(!token){
            token = localStorage.getItem('accessToken')
    }
    const headers = {
            Accept:'application/json',
            "Content-Type":"application/json",
    }
    if(prod){
        headers["Access-Control-Allow-Headers"] = "Content-Type"
        headers["Access-Control-Allow-Headers"] = "*"
        headers["Access-Control-Allow-Headers"] = "Accept"
    }
    if(token){
        headers["Authorization"] = `Token  ${token}`
    }
    return headers;
}
