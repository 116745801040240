import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
    Button,
    Input,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
}from '@material-ui/core/';
import {togglePasswordDialog} from '../../actions/dialog';
import {toggleAuthSnackbar, sendPasswordReset} from '../../actions/auth';


class PasswordReset extends Component{
// props - circle/square, image
    constructor(props){
        super(props)
        this.state = {
            email:this.props.email
        } 
    }

    closeOnly = () =>{
        this.props.togglePasswordDialog({open:false})
    }

    sendReset =() =>{
        if (this.state.email === null || this.state.email === ''){
            return alert("Please enter an email")
        }
        this.props.sendPasswordReset({email:this.state.email})
        this.props.togglePasswordDialog({open:false})
    }

    render(){        
        return (
            <Dialog
                open={this.props.open}
                onClose={this.closeOnly}
            >
                <DialogTitle>Please Enter Your Email</DialogTitle>
                <DialogContent>
                    <Input  
                        placeholder={"Email"}
                        value={this.state.email}
                        onChange={(event)=>{this.setState({email:event.target.value})}}
                    />
                </DialogContent>
                <DialogActions
                    style={{
                        display:"flex",
                        justifyContent:"space-between",
                    }}
                >
                    <Button
                        onClick={(event)=>{this.props.togglePasswordDialog({open:false})}}
                    >Cancel</Button>
                    <Button 
                        onClick={this.sendReset}
                    >Email Reset</Button>
                </DialogActions>
            </Dialog>
        )
    }
}

const mapStateToProps = (state,ownProps) => ({
    email:state.user.account.email,
    open:state.dialog.password_dialog.open,
})

export default connect(mapStateToProps,{togglePasswordDialog,
                                        toggleAuthSnackbar, 
                                        sendPasswordReset})(PasswordReset);