import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';
import {
    Toolbar,
    Checkbox,
    CircularProgress,
    Typography,
    IconButton,
    Menu,
    MenuItem,
    Dialog,
    DialogTitle
} from '@material-ui/core/';
import MoreVert from '@material-ui/icons/MoreVert';
import {Droppable, Draggable} from 'react-beautiful-dnd';
import FoodObj from './food';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import ClearIcon from '@material-ui/icons/Clear';
import {clearMeals} from '../../actions/plan';
import {addNewMeal} from '../../actions/input';
import {toggleMealNameDialog} from '../../actions/dialog';


const useStyles = theme =>({
    //logic about mobile menu vs reg menu goes here
        meal_div:{
            margin:"0 auto"
        }
})


class Meal extends Component{
    /*
    Props: 
        * mealtype
        * default menu id
    Methods:
        * 
    */
   constructor(props){
    super(props)
    this.state = {
        mealname:null,
        mealnum: this.props.mealnum,
        meal_type:this.props.meal_type,
        isSideMenuOpen: false,
        menu_anchor_el:null,
      }
    }

    onClickDelete =() =>{
        this.props.clearMeals(this.props.plannum,[this.props.meal_type])
        this.setState({
            menu_anchor_el:null,
            isSideMenuOpen:false
        })
    }

    onClickSaveMeal = () =>{
        this.props.toggleMealNameDialog(true, this.props.plannum, this.props.meal_type)
        this.setState({
            menu_anchor_el:null,
            isSideMenuOpen:false
        })
    }

    openSideMenu = (event) =>{
        this.setState({
            menu_anchor_el:event.target,
            isSideMenuOpen:true
        })
    }

    handleSideMenuClose =() =>{
        this.setState({
            menu_anchor_el:null,
            isSideMenuOpen:false
        })
    }

    onClickAlternatives = ()=>{

    }

    render() {
        /*
        Meal header
        Plan filter list
        An outer div with a fixed width
        Inner div with scrollable overflow and list of 
        */
       const {classes} = this.props
       if((this.props.solve_in_progress) &(this.props.autoplan_include)){
        return(
        <div
            style={{
                textAlign:"center",
            }}
        >
            
            <CircularProgress
                style={{
                    color:"var(--main_contrast)",
                    margin:"auto"
                }}
            />
        </div>
        )}else{
        return (      
            <div
                style={{
                    // minWidth:"100%"
                }}
            >
                <div
                    style={{
                        paddingLeft:5,
                        // color:"var(--main_dark)",
                        color:"black",
                        // backgroundColor:"var(--sec_mid)",
                        // backgroundColor:"var(--sec_light)",
                        backgroundImage: "linear-gradient(white, var(--sec_light)",
                        // borderBottom:"1px solid var(--sec_light)",
                        fontFamily:"Tahoma, Geneva, sans-serif",
                        fontWeight:"bold",
                        display:"flex",
                        flexDirection:"row",
                        alignContent:"space-between",
                        justifyContent:"space-between",
                        height:25,
                    }}
                >
                    <section
                        style={{
                            // minWidth:"100%"
                        }}
                    >
                        <Typography
                            // variant="caption"
                        >
                            {this.props.meal_type}
                        </Typography>
                    </section>
                    <section
                        style={{
                            display:"flex",
                            alignContent:"flexend",
                            // width:"100%"
                        }}
                    >
                        {/*<Typography
                        >
                            {this.props.mealname}
                        </Typography>*/}

                        {/* <IconButton 
                            size="small"
                            edge="end"
                            style={{
                                margin:"auto"
                            }}
                            onClick={this.onClickDelete}
                        >
                            <DeleteIcon
                                fontSize="small"
                                style={{
                                        color:"var(--main_dark)",
                                        cursor:"pointer",
                                }}
                            />
                        </IconButton>
                        <IconButton
                            size="small"
                            edge="end"
                            style={{
                                margin:"auto"
                            }}
                            onClick={this.onClickSaveMeal}
                        >
                            <SaveIcon
                                fontSize="small"
                                style={{
                                    color:"var(--main_dark)",
                                    cursor:"pointer",
                            }}
                            />
                        </IconButton> */}
                        <IconButton
                            size="small" 
                            edge="end"
                            style={{
                                margin:"auto"
                            }}
                            onClick={this.openSideMenu}
                        > 
                            <MoreVert
                                fontSize="small"
                                style={{
                                    color:"var(--main_dark)",
                                    cursor:"pointer"
                                }}
                            />
                        </IconButton>
                        <Menu
                            open={this.state.isSideMenuOpen}
                            onClose={this.handleSideMenuClose}
                            anchorEl={this.state.menu_anchor_el}
                        >
                            {/* <MenuItem
                                onClick={this.onClickAlternatives}
                            >See Alternatives</MenuItem> */}
                            <MenuItem
                                onClick={this.onClickDelete}
                            >
                                Clear
                                <DeleteIcon
                                fontSize="small"
                                style={{
                                        color:"var(--sec_light)",
                                        cursor:"pointer",
                                        fontSize:10,
                                }}
                            />
                            </MenuItem>
                            <MenuItem
                                onClick={this.onClickSaveMeal}
                            >
                            Save
                            <SaveIcon
                                fontSize="small"
                                style={{
                                    color:"var(--sec_light)",
                                    cursor:"pointer",
                                    fontSize:10,
                            }}
                            />                            
                            </MenuItem>
                        </Menu>
                    </section>
                </div>
            <div
                style={{
                    // width:"100%"
                }}
            >   
            <Droppable 
                droppableId={String(this.props.plannum)+"_"+String(this.props.meal_type)}
                direction="horizontal"
                type="FOOD"
                style={{
                    // width:"100%"
                }}
            >
            {(provided, snapshot)=>(
                <div
                    style={{
                            backgroundColor: snapshot.isDraggingOver? "var(--sec_mid)": "var(--sec_white)", 
                            height:100, 
                            verticalAlign:"middle",
                            margin:"0 auto",
                            display:"flex",
                            padding:"grid",
                            // minWidth:320,
                            // minWidth:"100%",
                            overflowX:"auto",
                            overflowY:"hidden",
                            scrollbarWidth:"thin"
                    }}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                >
                {/*TODO - add logic to show all meals*/}
                {this.props.foods_list.map( (result, idx) => (
                   // return (
                        <Draggable
                            draggableId={result['drag_id']+"_"+this.props.plannum+"_"+this.props.meal_drag_key}
                            index={idx}
                            key={result['drag_id']}
                            isDragDisabled={this.props.foodChangeDisabled}
                        >
                        {(provided, snapshot)=>(
                            <React.Fragment
                            >
                            <div
                                style={{
                                    //backgroundColor:"var(--sec_white)"
                                }}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                ref={provided.innerRef}
                            >
                                <FoodObj 
                                    food_dict = {result}
                                    amt = {result['amt']}
                                    serving_size_idx = {result['serving_size_idx']}
                                    component_num ={0}
                                    col={this.props.plannum}
                                    subcol={this.props.meal_type}
                                    drag_idx = {idx}
                                    isChangeDisabled={this.props.foodChangeDisabled}
                                />
                            </div>
                            </React.Fragment>
                        )}
                        </Draggable>
                    //)
                )
                )}
                {provided.placeholder}
                </div>
             )}
            </Droppable>
            </div>   
            </div>
        )
        }//else
    }
}

const mapStateToProps = (state, ownProps) => {
    let autoplan_include= false
    if(ownProps.foodChangeDisabled){
        autoplan_include = false // this is an ugly hack to deal with 'meal_type' being 'mealname' when in search results
    }
    else{
        autoplan_include = state.user[ownProps.plannum][ownProps.meal_type].autoplan_include
    }
    // Leaving space in case logic is needed here
        return ({
            solve_in_progress: state.user.solve_in_progress,
            autoplan_include: autoplan_include,
        })
}

export default connect(mapStateToProps, {clearMeals,addNewMeal,toggleMealNameDialog})(withStyles(useStyles)(Meal));
//export default withStyles(useStyles)(Meal);