import axios from 'axios'// since fetch api not supported in all browsers
import store from '../store'
import {url_root, configHeaders,is_prod} from './util';

export const get_shopping_list =()=>{
    // if page is refreshed, days dict is empty
    let days_dict = store.getState().util.days_dict 
    // for now, include today, and today + 1
    let start_date = new Date()
    let end_date = new Date(+start_date+6*86400000)
    return dispatch=>{
        axios.post(
            url_root+'/plan/get_shopping_list_from_plan/', // address
            {params:{
                start_date:start_date,
                end_date:end_date,
            }},
            {headers:configHeaders(is_prod),
            }
        )
        .then(res=>{
            let data = res['data']
            dispatch(settingShoppingList({
                list:data['list']
            }))
        })
        .catch(err=>{console.log(err)})
        .finally(res=>{})

    }
}

const settingShoppingList = dataObj => ({
    type:'SETTING_SHOPPING_LIST',
    payload: dataObj
})

export const deleteShoppingListItem =(idx)=>{
    let new_list = Array.from(store.getState().shopping_list.list)
    new_list.splice(idx, 1)
    return dispatch =>{
        dispatch(settingShoppingList({
            list:new_list
        }))
    }
}

export const addShoppingListItem = (itemObj)=>{
    let new_list = Array.from(store.getState().shopping_list.list)
    // appends to the top
    new_list.splice(0, 0, itemObj) 
    return dispatch =>{
        dispatch(settingShoppingList({
            list:new_list
        }))
    }
}

export const changeShoppingListItemAmt = (value,idx)=>{
    let new_list = Array.from(store.getState().shopping_list.list)
    // appends to the top
    let new_obj = {}
    Object.assign(new_obj,new_list[idx])
    new_obj['total_amount'] = value
    new_list.splice(idx, 1, new_obj) 
    return dispatch =>{
        dispatch(settingShoppingList({
            list:new_list
        }))
    }
}