import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
    TextField,
    Typography,
    Slider,
    ExpansionPanel,
    Checkbox,
    IconButton,
    FormControlLabel
} from '@material-ui/core/';
import InfoIcon from '@material-ui/icons/Info';
import {updateNutrientReq,updateCalReqFromSlider,changeDefaultReq} from '../../actions/nutrients';

class Nutrient_Requirement extends Component{

    constructor(props){
        super(props)
        this.state = {

          }
    }

    componentDidMount = () =>{
        //console.log("re-rendering "+this.props.nut_abrev)
    }

    // shouldComponentUpdate =(nextProps, nextState) =>{
    //     // if((nextProps.lower_bound===this.props.lower_bound)&
    //     // (nextProps.upper_bound===this.props.upper_bound)){
    //     //     return false
    //     // }else{
    //     //     return true
    //     // }
    //     return true
    // }

    onChangeCheckBox = () =>{
        this.props.changeDefaultReq(this.props.nut_abrev)
    }

    onChangeSlider =(event, value, nut_abrev) =>{
        // triggered by onChangeCommitted
        if(this.props.nut_abrev==='cal'){
            this.props.updateCalReqFromSlider(value)
        }else{
            this.props.updateNutrientReq(nut_abrev, value)
        }
    }

    onChangeInput = (value, bound) =>{
        let req_list = [value, this.props.upper_bound]
        if(bound==="upper"){
            req_list = [this.props.lower_bound, value]
        }
        if(this.props.nut_abrev==='cal'){
            this.props.updateCalReqFromSlider(req_list)
        }else{
            this.props.updateNutrientReq(this.props.nut_abrev, req_list)

        }
    }

    render(){
        // Called via modal
        return(
        <div
            style={{
                width:320,
                margin:"auto",
                textAlign:"center",
                display:"flex",
                flexDirection:"column",
                marginBottom:10,
            }}
        >
            <div
                style={{
                    display:"flex",
                    flexDirection:"column",
                    justifyContent:"center"
                }}
            >
                <Typography style={{margin:"auto"}}>{this.props.nut_name}</Typography>
                {/* <IconButton>
                    <InfoIcon fontsize="small"/>
                </IconButton> */}

                {/* <InfoIcon fontSize="small"/> */}
                {/* Checkbox for default include in plan */}
            </div>
            <div
                style={{
                    display:"flex",
                    justifyContent:"space-between",
                }}
            >
                <TextField
                style={{
                    width:50,
                    color:"var(--main_dark)",
                }}
                inputProps={{
                    style:{
                        textAlign:"center"
                    }
                }}
                    value={this.props.lower_bound}
                    onChange={(event)=>{this.onChangeInput(event.target.value, "lower")}}
                    // onChange={}
                />
            <Slider
                style={{
                    width:200,
                    margin:"auto",
                    color:"var(--main_dark)",
                }}
                orientation="horizontal"
                //defaultValue={[this.props.lower_bound, this.props.upper_bound]}
                value={[this.props.lower_bound, this.props.upper_bound]}
                marks={[
                    // {value:this.props.range_lb,label:String(this.props.range_lb)},
                    {value:this.props.lower_bound,label:String(this.props.lower_bound)},
                    {value:this.props.upper_bound,label:String(this.props.upper_bound)},
                    //{value:this.props.range_ub,label:String(this.props.range_ub)},
                ]}
                //marks={true}
                min={this.props.range_lb}
                max={this.props.range_ub}
                valueLabelDisplay="off"
                onChangeCommitted={(event,value)=>{this.onChangeSlider(event,value, this.props.nut_abrev)}}
            />
                <TextField
                style={{
                    width:50,
                    textAlign:"center",
                }}
                inputProps={{
                    style:{
                        textAlign:"center"
                    }
                }}
                    value={this.props.upper_bound}
                    onChange={(event)=>{this.onChangeInput(event.target.value, "upper")}}
                />
        `   </div>
        <div>
            <Typography
                variant="caption"
                style={{
                    color:"grey"
                }}
            >
                Include in autoplan by default
            </Typography>
        <Checkbox
                    checked={this.props.autoplan_default}
                    onChange={this.onChangeCheckBox}
                    style={{
                        color:"var(--main_contrast)",
                    }}
                />
        </div>
        </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    upper_bound: state.user.requirements_dict[ownProps.nut_abrev+"_ub"],
    lower_bound: state.user.requirements_dict[ownProps.nut_abrev+"_lb"],
    autoplan_default: state.user.autoplan_nutrients[ownProps.nut_abrev],
    // range_lb: state.util.nutrient_ranges[ownProps.nut_abrev][0],
    // range_ub: state.util.nutrient_ranges[ownProps.nut_abrev][1],
    range_lb:Math.round(state.user.requirements_dict[ownProps.nut_abrev+"_lb"]*.9),
    range_ub:Math.round(state.user.requirements_dict[ownProps.nut_abrev+"_ub"]*1.1),
 })

export default connect(mapStateToProps, {updateNutrientReq,updateCalReqFromSlider,changeDefaultReq})(Nutrient_Requirement);
