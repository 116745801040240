import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
        Typography,
    } from '@material-ui/core/';
import SinglePodcast from './single_podcast';
import {NavLink} from 'react-router-dom';

class Podcast extends Component{

    constructor(props){
        super(props)
        this.state = {
        } 
    }

    render (){
        return(
            <div
                style={{
                    width:350,
                    margin:"0 auto",
                }}
            >
                <NavLink to='/'>
                    <Typography>Back To Home</Typography>
                </NavLink>
                
            <SinglePodcast
                photo_url={'https://planyourmealsmedia.s3.amazonaws.com/podcast/What_To_Eat_In_A_Crisis.png'}
                title={'Episode 9: What To Eat In A Crisis'}
                embed_link={'<iframe style="border: none" src="//html5-player.libsyn.com/embed/episode/id/13554878/height/90/theme/custom/thumbnail/yes/direction/backward/render-playlist/no/custom-color/87A93A/" height="90" width="100%" scrolling="no"  allowfullscreen webkitallowfullscreen mozallowfullscreen oallowfullscreen msallowfullscreen></iframe>'}
            />
            <SinglePodcast
                photo_url={'https://planyourmealsmedia.s3.amazonaws.com/podcast/All_About_Vitamin_A.png'}
                title={'Episode 8: All About Vitamin A'}
                embed_link={'<iframe style="border: none" src="//html5-player.libsyn.com/embed/episode/id/13456448/height/90/theme/custom/thumbnail/yes/direction/backward/render-playlist/no/custom-color/87A93A/" height="90" width="100%" scrolling="no"  allowfullscreen webkitallowfullscreen mozallowfullscreen oallowfullscreen msallowfullscreen></iframe>'}
            />
            <SinglePodcast
                photo_url={'https://planyourmealsmedia.s3.amazonaws.com/podcast/The_Most_Crucial_Part_Of_Diet_Success.png'}
                title={'Episode 7: The Most Crucial Part Of Diet Success'}
                embed_link={'<iframe style="border: none" src="//html5-player.libsyn.com/embed/episode/id/13266020/height/90/theme/custom/thumbnail/yes/direction/backward/render-playlist/no/custom-color/87A93A/" height="90" width="100%" scrolling="no"  allowfullscreen webkitallowfullscreen mozallowfullscreen oallowfullscreen msallowfullscreen></iframe>'}
            />
            <SinglePodcast
                photo_url={'https://planyourmealsmedia.s3.amazonaws.com/podcast/The_Fallacy_Of_Low_Calorie_Foods.png'}
                title={'Episode 6: The Fallacy Of Low Calorie Foods And Other Dumb Dieting Trends'}
                embed_link={'<iframe style="border: none" src="//html5-player.libsyn.com/embed/episode/id/13188788/height/90/theme/custom/thumbnail/yes/direction/backward/render-playlist/no/custom-color/87A93A/" height="90" width="100%" scrolling="no"  allowfullscreen webkitallowfullscreen mozallowfullscreen oallowfullscreen msallowfullscreen></iframe>'}
            />
            <SinglePodcast
                photo_url={'https://planyourmealsmedia.s3.amazonaws.com/podcast/Choosing_A_Macro_Balance.png'}
                title={'Episode 5: Choosing A Macro Balance'}
                embed_link={'<iframe style="border: none" src="//html5-player.libsyn.com/embed/episode/id/13079417/height/90/theme/custom/thumbnail/yes/direction/backward/render-playlist/no/custom-color/87A93A/" height="90" width="100%" scrolling="no"  allowfullscreen webkitallowfullscreen mozallowfullscreen oallowfullscreen msallowfullscreen></iframe>'}
            />
            <SinglePodcast
                photo_url={'https://planyourmealsmedia.s3.amazonaws.com/podcast/Why_Its_All_Worth_It.png'}
                title={"Episode 4: Why It's Worth It"}
                embed_link={'<iframe style="border: none" src="//html5-player.libsyn.com/embed/episode/id/12798641/height/90/theme/custom/thumbnail/yes/direction/backward/render-playlist/no/custom-color/87A93A/" height="90" width="100%" scrolling="no"  allowfullscreen webkitallowfullscreen mozallowfullscreen oallowfullscreen msallowfullscreen></iframe>'}
            />
            <SinglePodcast
                photo_url={'https://planyourmealsmedia.s3.amazonaws.com/podcast/Stick_To_Your_Plan.jpg'}
                title={"Episode 3: How To Stick To Your First Plan"}
                embed_link={'<iframe style="border: none" src="//html5-player.libsyn.com/embed/episode/id/12708431/height/90/theme/custom/thumbnail/yes/direction/backward/render-playlist/no/custom-color/87A93A/" height="90" width="100%" scrolling="no"  allowfullscreen webkitallowfullscreen mozallowfullscreen oallowfullscreen msallowfullscreen></iframe>'}
            />
            <SinglePodcast
                photo_url={'https://planyourmealsmedia.s3.amazonaws.com/podcast/Building_Your_First_Meal_Plan.png'}
                title={"Episode 2: How To Make Your First Plan"}
                embed_link={'<iframe style="border: none" src="//html5-player.libsyn.com/embed/episode/id/12623645/height/90/theme/custom/thumbnail/yes/direction/backward/render-playlist/no/custom-color/87A93A/" height="90" width="100%" scrolling="no"  allowfullscreen webkitallowfullscreen mozallowfullscreen oallowfullscreen msallowfullscreen></iframe>'}
            />
            <SinglePodcast
                photo_url={'https://planyourmealsmedia.s3.amazonaws.com/podcast/Meal_Planning_Vs_Dieting.png'}
                title={"Episode 1: Why Meal Planning Is Better Than Dieting"}
                embed_link={'<iframe style="border: none" src="//html5-player.libsyn.com/embed/episode/id/12587837/height/90/theme/custom/thumbnail/yes/direction/backward/render-playlist/no/custom-color/87A93A/" height="90" width="100%" scrolling="no"  allowfullscreen webkitallowfullscreen mozallowfullscreen oallowfullscreen msallowfullscreen></iframe>'}
            />
            </div>
        )
    }
}

export default connect(null, {})(Podcast);
