import axios from 'axios'// since fetch api not supported in all browsers
import {url_root,sumNutrientTotalsFromList, sumNutrientTotals,is_prod, configHeaders} from './util'
import store from '../store'

export const mountDayPlan = (plannum, date) =>{
    // can pull down day's plan also
    return dispatch => {
        // let token = store.getState().auth.accessToken
        // if(!token){
        //     token = localStorage.getItem('accessToken')
        // }
        // dispatch(addingToken({
        //     accessToken:token
        //     // should add isAuthenticated
        //     // should ask for login on 401 error
        // }))
        dispatch(mountingDayPlan ({
            col:plannum, 
            date: date
        }))

        axios.post(url_root+'/plan/get_day_plan/',
                {
                    params:{
                        plannum:plannum,
                        plan_date:date
                    }
                },
                {                                               // config
                    headers:configHeaders(is_prod),
                }
        )
        .then(res=>{

            let foods_mega_list = res['data']['Breakfast']['foods_list']
                                    .concat(res['data']['Lunch']['foods_list'])
                                    .concat(res['data']['Dinner']['foods_list'])
                                    .concat(res['data']['Snack']['foods_list'])
            if(foods_mega_list.length!=0){
                // only dispatch update if there are foods
                let new_nutrient_totals = sumNutrientTotalsFromList(foods_mega_list)
                dispatch(loadingDayPlan ({
                    col:plannum, 
                    date: date,
                    br_foods_list:res['data']['Breakfast']['foods_list'],
                    lu_foods_list:res['data']['Lunch']['foods_list'],
                    di_foods_list:res['data']['Dinner']['foods_list'],
                    sn_foods_list:res['data']['Snack']['foods_list'],
                    br_drag_key: res['data']['Breakfast']['meal_drag_key'],
                    lu_drag_key: res['data']['Lunch']['meal_drag_key'],
                    di_drag_key: res['data']['Dinner']['meal_drag_key'],
                    sn_drag_key: res['data']['Snack']['meal_drag_key'],
                    nutrient_totals: new_nutrient_totals
                }))
            }
        }) 
        .catch(err=>{
            console.log(err)
            // if 401 error should return to login screen
        })
        .finally(function(){
            console.log("finished")
        })
    }
}

const mountingDayPlan = mountObj => ({
    type:'MOUNT_DAY_PLAN',
    payload: mountObj
})

const loadingDayPlan = mountObj => ({
    type:'LOAD_DAY_PLAN',
    payload: mountObj
})

const addingToken = tokenObj => ({
    type:'ADD_TOKEN',
    payload: tokenObj
})

export const getUserInfo = (today, days_list, days_dict) =>{

    return dispatch => {
        dispatch(updatingDaysDict({
            days_dict:days_dict
        }))
        // let token = store.getState().auth.accessToken
        // if(!token){
        //     token = localStorage.getItem('accessToken')
        // }
        // dispatch(addingToken({
        //     accessToken:token
        // }))
        axios.post(url_root+'/user/get_user_reqs_and_menus/',
            {
                params:{
                    week_start_dt: today,
                    date_list:days_list
                }
            },
            {                                               // config
                headers:configHeaders(is_prod),
            }
        )
        .then(res =>{
            let data = res['data']
            // TODO - combine into one and add user isAuthenticated
            dispatch(addingUserInfo({
                requirements_dict:data['requirements_dict'],
                autoplan_nutrients:data['autoplan_nutrients'],
                br_menu:data['default_menus']['Breakfast'],
                lu_menu:data['default_menus']['Lunch'],
                di_menu:data['default_menus']['Dinner'],
                sn_menu:data['default_menus']['Snack'],
                days_list:days_list
            }))
            let columns_dict_obj = {}
            let columns_dict = Object.assign(columns_dict_obj,data["weekplan"],data["menu_dict"])
            // TODO - remove this and only pull it when user enters nutrient page
            dispatch(changingUserStats({
                user_stats:data["user_stats"]
            }))
            // TODO - seriously consider putting this in auth, not user.account
            dispatch(updatingAccountStatus({
                email: data["user_stats"]["email"],
                status: data["user_stats"]["status"],
            }))
            dispatch(recievedWeekPlan({
                columns:columns_dict
            }))

        })
        .catch(err=>{
            console.log(err)
        })
        .finally(()=>{
            // TODO - update nutrient totals
            
            let day1_nutrient_totals = sumNutrientTotals(store.getState().columns.present['day1'], "none", [])
            let day2_nutrient_totals = sumNutrientTotals(store.getState().columns.present['day2'], "none", [])
            let day3_nutrient_totals = sumNutrientTotals(store.getState().columns.present['day3'], "none", [])
            let day4_nutrient_totals = sumNutrientTotals(store.getState().columns.present['day4'], "none", [])
            let day5_nutrient_totals =  sumNutrientTotals(store.getState().columns.present['day5'], "none", [])
            let day6_nutrient_totals = sumNutrientTotals(store.getState().columns.present['day6'], "none", [])
            let day7_nutrient_totals = sumNutrientTotals(store.getState().columns.present['day7'], "none", [])

            dispatch(updatingAllNutrientTotals({
                day1_nutrient_totals:day1_nutrient_totals,
                day2_nutrient_totals:day2_nutrient_totals,
                day3_nutrient_totals:day3_nutrient_totals,
                day4_nutrient_totals:day4_nutrient_totals,
                day5_nutrient_totals:day5_nutrient_totals,
                day6_nutrient_totals:day6_nutrient_totals,
                day7_nutrient_totals:day7_nutrient_totals,
            }))
        }
        )
    }
}

// getting user stats can come on nutrients page load also

const addingUserInfo= tokenObj => ({
    type:'ADD_USER_INFO',
    payload: tokenObj
})

const updatingDaysDict= daysObj => ({
    type:'UPDATE_DAYS',
    payload: daysObj
})

const updatingAllNutrientTotals = nutObj => ({
    type:'UPDATE_ALL_NUTRIENT_TOTALS',
    payload: nutObj
})
export const recievedWeekPlan = colObj => ({
    type:'UPDATE_WEEK',
    payload: colObj
})
export const changingUserStats = userObj => ({
    type:'CHANGE_USER_STATS',
    payload: userObj
})

export const updatingAccountStatus= usrObj => ({
    type:'UPDATE_ACCOUNT',
    payload: usrObj
})

// const settingMenu = menuObj => ({
//     type:'SET_MENU',
//     payload: menuObj
// })

export const mountNutrientsPage =()=>{

    return dispatch=>{
    axios.post(url_root+'/core/get_user_nut_reqs/',
    {
        params:{
        }
    },
    {                                               // config
        headers:configHeaders(is_prod),
    }
    )
    .then(res=>{
        let data = res["data"]
        dispatch(changingUserStats({
            user_stats:data["user_stats"]
        }))

        dispatch(changingNutrientsAll({
            requirements_dict:data["requirements_dict"]
        }))
    })
    .catch(err=>{
    console.log(err)
    })
    .finally(function(){
    console.log("finished")
    })
}

}

export const changingNutrientsAll = nutObj => ({
    type:'CHANGE_NUTRIENTS_ALL',
    payload: nutObj
})

export const closedOnboardingModal = () =>{
    return dispatch=>{
        dispatch(changingUserStats({
            user_stats:{
                first_session:false,
            }
        }))
        // TODO - dispatch open all the poppers
        axios.post(url_root+'/core/close_onboarding/',
        {},
        {headers:configHeaders(is_prod),})
        .then(res=>{
        })
        .catch(err=>{
        })
        .finally(function(){
        })
    }
}

// const changingNutrientsAll = nutObj => ({
//     type:'CHANGE_NUTRIENTS_ALL',
//     payload: nutObj
// })