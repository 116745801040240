import initialState from '../initialState'

export default function(state=initialState, action){
    switch(action.type){
        case 'SETTING_SHOPPING_LIST':
            return {
                ...state, 
                list:action.payload.list
            }
        default:
            return state;
    }
}
