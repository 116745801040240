import axios from 'axios'// since fetch api not supported in all browsers
import {url_root, configHeaders, is_prod} from './util';

// Login
export const fetchToken = userObj=>{
    return dispatch => {
        return fetch(url_root+'/rest-auth/login/',
              {
                  method: 'POST',
                  headers:{
                      Accept:'application/json',
                      'Content-Type':'application/json',
                  },
                  body:JSON.stringify(userObj)
              }
          )
          .then(resp => resp.json())
          .then(data => {
              if(data.non_field_errors){
                //   this is where you display the bad login snackbar
                dispatch(SignUpFail({
                    open:true,
                    message:data['non_field_errors'][0],
                }))
              }
              else {
                  localStorage.setItem("accessToken", data.key)
                  localStorage.setItem("username", userObj.username)
                  localStorage.setItem("isAuthenticated", true)
                  dispatch(loginUser({
                      accessToken:data.key,
                      username:userObj.username,
                      isAuthenticated:true
                }))
              }
          })
     }
}

export const loginUser = authObj => ({
    type:'LOGIN_SUCCESS',
    payload: authObj
})

//Logout
export const logout = () => (dispatch, getState) => {
        fetch(url_root+'/rest-auth/logout/',
            {
                method: 'POST',
                headers:configHeaders(is_prod),
                body:null
            }
        ).then(resp => resp.json())
        .then(data=>{
            localStorage.removeItem("accessToken")
            dispatch({
                type:'LOGOUT_SUCCESS'
            })
        })
}

// SignUp
export const postUser = userObj => {
    return dispatch=>{
        return fetch(url_root+'/rest-auth/registration/',
              {
                  method: 'POST',
                  headers:{
                      Accept:'application/json',
                      'Content-Type':'application/json',
                 },
                  body:JSON.stringify(userObj)
              }
          )
          .then(resp => resp.json())
          .then(data => {
              console.log(data)
              if(!data.key){
                //   this is where you display the bad login snackbar
                    let resp_str = ''
                    if(data['email']){
                        resp_str = resp_str + data['email'] +'\n'
                    }
                    if(data['password1']){
                        resp_str = resp_str + data['password1'] +"\r\n"
                    }
                    if(data['password2']){
                        resp_str = resp_str + data['password2'] +"\r\n"
                    }
                    if(resp_str===''){
                        resp_str = resp_str + data['username'] +"\r\n"
                    }     
                    if(resp_str===''){
                        resp_str = 'An unknown error occurred'
                    }                 
                    // send to dialog
                    dispatch(SignUpFail({
                        open:true,
                        message:resp_str,
                    }))
              }
              else {
                  localStorage.setItem("accessToken", data.key)
                  localStorage.setItem("username", userObj.username)
                  localStorage.setItem("isAuthenticated", true)
                  dispatch(loginUser({
                      accessToken:data.key,
                      username:userObj.username,
                      isAuthenticated:true
                }))
                // trigger a success dialog
              }
          })
    }
}

const SignUpFail = authObj => ({
    type:'AUTH_SNACKBAR',
    payload: authObj
})

export const toggleAuthSnackbar = (obj)=>{
    return dispatch =>{
        dispatch(SignUpFail(obj))
    }
}

export const closeAuthDialog =() =>{
    return dispatch=>{
        dispatch(SignUpFail({
        open:false,
        message:null,
    }))
    }
}

// 'rest-auth/facebook'
export const fetchTokenFacebook = userObj=>{
    return dispatch => {
        fetch(url_root+'/rest-auth/facebook/',
              {
                  method: 'POST',
                  headers:{
                      Accept:'application/json',
                      'Content-Type':'application/json',
                  },
                  body:JSON.stringify({
                      access_token:userObj.accessToken,
                        code: userObj.code,
                    })
              }
          )
          .then(resp => resp.json())
          .then(data => {
              console.log(data)
              if(data.non_field_errors){
                //   this is where you display the bad login snackbar
                dispatch(SignUpFail({
                    open:true,
                    message:data['non_field_errors'][0],
                }))
              }
              else {
                  localStorage.setItem("accessToken", data.key)
                  localStorage.setItem("username", userObj.username)
                  localStorage.setItem("isAuthenticated", true)
                  dispatch(loginUser({
                      accessToken:data.key,
                      username:userObj.username,
                      isAuthenticated:true
                }))
              }
          })
     }
}

// 'rest-auth/google'
export const fetchTokenGoogle = userObj=>{
    return dispatch => {
        fetch(url_root+'/rest-auth/google/',
              {
                  method: 'POST',
                  headers:{
                      Accept:'application/json',
                      'Content-Type':'application/json',
                  },
                  body:JSON.stringify({
                      access_token:userObj.tokenId,
                  })
              }
          )
          .then(resp => resp.json())
          .then(data => {
              console.log(data)
              if(data.non_field_errors){
                //   this is where you display the bad login snackbar
                dispatch(SignUpFail({
                    open:true,
                    message:data['non_field_errors'][0],
                }))
              }
              else {
                  localStorage.setItem("accessToken", data.key)
                  localStorage.setItem("username", userObj.username)
                  localStorage.setItem("isAuthenticated", true)
                  dispatch(loginUser({
                      accessToken:data.key,
                      username:userObj.username,
                      isAuthenticated:true
                }))
              }
          })
     }
}
          
export const sendPasswordReset = (emailObj) =>{
    return dispatch=>{
    fetch(url_root+'/api/password_reset/',
    {
        method: 'POST',
        headers:{
            Accept:'application/json',
            'Content-Type':'application/json',
        },
        body:JSON.stringify(emailObj)
    }
    )
    .then(resp => resp.json())
    .then(data => {
        console.log(data)
        // catch errors
        dispatch(SignUpFail({
            open:true,
            message:"Your reset email is on the way!",
        }))
       })
    }
}

export const validatePasswordToken = (tokenObj) =>{
    return dispatch=>{
    fetch(url_root+'/api/password_reset/validate_token/',
    {
        method: 'POST',
        headers:{
            Accept:'application/json',
            'Content-Type':'application/json',
        },
        body:JSON.stringify(tokenObj)
    }
    )
    .then(resp => resp.json())
    .then(data => {
        console.log(data['status'])
        // if status!=OK trigger snackbar and go to auth
        if (data['status']!='OK'){
        dispatch(SignUpFail({
            open:true,
            message:"Password token expired. Please try again",
        }))
        dispatch(togglePasswordPageRedirect({
            redirect_to_auth:true
        }))
        }

    })
    }
}

export const changePassword = (passObj) =>{
    return dispatch=>{
    fetch(url_root+'/api/password_reset/confirm/',
    {
        method: 'POST',
        headers:{
            Accept:'application/json',
            'Content-Type':'application/json',
        },
        body:JSON.stringify(passObj)
    }
    )
    .then(resp => resp.json())
    .then(data => {
        dispatch(SignUpFail({
            open:true,
            message:"Password Reset Successful!",
        }))
        dispatch(togglePasswordPageRedirect({
            redirect_to_auth:true
        }))
       })
    }
}

export const togglePasswordPageRedirect = authObj => ({
    type:'TOGGLE_PW_PG_REDIRECT',
    payload: authObj
})

export const saveFeedback = (feedbackObj) =>{
    return dispatch =>{
        axios.post(
            url_root+'/core/save_user_message/', // address
            {                                               // body
                params:{
                    message:feedbackObj.message,
                    message_type:feedbackObj.message_type
                }
            },
            {                                               // config
                headers:configHeaders(is_prod),
            }
        )
        .then(res=>{
            let data = res['data']
            dispatch(togglingGenericSnackbar({
                open:true,
                message:"Your message has been sent!"
            }))
        })
        .catch(err=>{})
    }
}

const togglingGenericSnackbar = snackObj => ({
    type:'TOGGLE_GENERIC_SNACKBAR',
    payload: snackObj
})

export const updateUserSettings = (feedbackObj) =>{
    return dispatch =>{
        axios.post(
            url_root+'/core/save_user_message/', // address
            {                                               // body
                params:{
                    message:feedbackObj.message,
                    message_type:feedbackObj.message_type
                }
            },
            {                                               // config
                headers:configHeaders(is_prod),
            }
        )
        .then(res=>{
            let data = res['data']
            dispatch(togglingGenericSnackbar({
                open:true,
                message:"Your message has been sent!"
            }))
        })
        .catch(err=>{})
    }
}

export const attemptLogin = ({})=>{
    // localStorage.getItem('accessToken')
    // sessionStorage
    // document.cookie
    return dispatch=>{
    console.log("sending request")
    axios.post(url_root+'/user/return_acct_status/',
                {params:{}},
                {                                              
                    headers:configHeaders(is_prod), // will use localStorage cookie if exists
                }
        )
        .then(res=>{
            // dispatch status
            let data = res['data']
            dispatch(updateAccountStatus({
                status:data.status
            }))
            dispatch(loginUser({
                accessToken:data.key,
                username:data.username,
                isAuthenticated:true
          }))
        }) 
        .catch(err=>{
            localStorage.removeItem("accessToken")
        })
    }
}

export const updateAccountStatus = acctObj => ({
    type:'UPDATE_ACCOUNT_STATUS',
    payload: acctObj
})

export const togglingAuthLoading = acctObj => ({
    type:'TOGGLE_AUTH_LOADING',
    payload: acctObj
})
