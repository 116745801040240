import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Input,
        Typography,
        Button,
        Paper,
}from '@material-ui/core/';
import { Redirect } from "react-router-dom";
import AuthSnackbar from './auth_snackbar';
import logo from '../auth/assets/images/02-01.png';
import {validatePasswordToken,changePassword} from '../../actions/auth';


class PasswordTokenPage extends Component{
// props - circle/square, image
    constructor(props){
        super(props)
        this.state = {
            password1:null,
            password2:null,
        } 
    }

    componentDidMount =() =>{
        this.props.validatePasswordToken({token:this.props.match.params.token})
    }

    handleSubmit = (event)=>{
        if (this.state.password1!=this.state.password2){
            return alert("Passwords don't match!")
        }else if (this.state.password1===null || this.state.password1.length<5){
            return alert("Password is too short. Please choose another password")
        }
        this.props.changePassword({
            token:this.props.match.params.token,
            password: this.state.password1
        })

        // redirect - can't use because is a hook
        // let history = useHistory();
        // history.push("/auth")
    }

    render(){    
        if (this.props.redirect){
            return <Redirect to='/auth'/>
        }   
        return (
            <div
                style={{
                    backgroundColor:"var(--sec_light)",
                    height: "100vh",/* Fallback for browsers that do not support Custom Properties */
                    height: "calc(var(--vh, 1vh) * 100)",
                }}
            >
                <Paper
                    style={{
                        margin:"auto",
                        width:320,
                        paddingTop:30,
                        textAlign:"center",
                    }}
                >
                    <img
                            src={logo}
                            width={320}
                            height={160}
                    />
                <Typography
                    fullWidth
                >Choose Your New Password</Typography>
                <Input 
                
                    type="password"
                    fullWidth
                    placeholder={"New Password"}
                    onChange={(event)=>{this.setState({password1:event.target.value})}}
                    value={this.state.password1}
                />
                <Input 
                    type="password"
                    fullWidth
                    placeholder={"Re-enter Password"}
                    onChange={(event)=>{this.setState({password2:event.target.value})}}
                    value={this.state.password2}
                />
                <Button 
                    style={{
                        // backgroundColor:"var(--main_bright)",
                        // color:"white",
                    }}
                    fullWidth
                    onClick={this.handleSubmit}
                >Update Password</Button>
                </Paper>
                <AuthSnackbar/>
            </div>
        )
    }
}
const mapStateToProps = (state,ownProps) => ({
    redirect:state.auth.redirect_to_auth
})

export default connect(mapStateToProps,{validatePasswordToken, changePassword})(PasswordTokenPage);