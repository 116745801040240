import React, {Component} from 'react';
import {connect} from 'react-redux';
import ReactImageFallback from "react-image-fallback";
import {
    Chip,
    Input,
    InputBase,
    InputLabel,
    IconButton,
    TextField,
    Typography,
    Modal,
    MenuItem,
    Button,
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    FormControl,
    CircularProgress,
    Divider,
    Select,
    Tooltip,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    FormLabel,
} from '@material-ui/core/';
import {withStyles} from '@material-ui/core/styles';
import {ToggleButton, ToggleButtonGroup, Rating,Autocomplete} from '@material-ui/lab';
import {closeFoodModal,
        changeFoodMaxServings,
        addFoodTag,
        removeFoodTag, 
        searchingTagsAutocomplete,
        add_pref_list,
        remove_pref_list,
    } from '../../actions/food_modal';
import {add_menu_item,remove_menu_item} from'../../actions/menu'; 
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ClearIcon from '@material-ui/icons/Clear';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';

const useStyles = {
    root:{
        //color:"var(--main_dark)"
    },
    inputStyling:{
        fontSize:8,
        marginBottom:0,
        color:"darkGrey",
        textAlign:"center",
    },
    condensedExpansionPanel:{
        margin:"0 auto",
        padding:0,
    }
}

class FoodModal extends Component{
    constructor(props){
        super(props)
        this.state = {
            add_meal:"Breakfast",
            add_dish:"Main Courses",
            snack_dish:false,
            dish_nums:["Whole Meals", "Main Courses", "Sides"]
        } 
    }
    
    titleCase =(string)  =>{
        if(string){
            let sentence = string.toLowerCase().split(" ")
            for(let i=0; i<sentence.length;i++){
                sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1)
            }
            let result = sentence.join(" ")
            return result
        }else{
            return null
        }
    }

    handlecloseModal = () =>{
        this.props.closeFoodModal()
    }

    handleMaxServingsChange = (event) =>{
        this.props.changeFoodMaxServings(this.props.food_dict.food_key,event.target.value)
    }

    renderRecipeDrawer =(is_recipe, recipe_list, instructions)=>{
        
        if(is_recipe){
            return(
                <ExpansionPanel>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon/>}
                        IconButtonProps={{
                            margin:0,
                            padding:0,
                            fontSize:12
                        }}
                    >
                        <Typography variant="subtitle2">Recipe details</Typography>
                        <Typography variant="caption" style={{color:"darkGrey", marginLeft:10}}>
                            {recipe_list[0].num_ingred+" ingredients"}
                        </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails
                        style={{
                            display:"flex",
                            flexDirection:"column"
                        }}
                    >   
                    {/* <div>
                        <div>
                                <Typography variant="subtitle2" style={{color:"darkGrey"}}
                                >
                                Description
                            </Typography>
                            <Typography variant="caption">
                                {recipe_list[0].description}
                            </Typography>
                        </div>
                    </div> */}
                    <div
                        style={{
                            display:"flex",
                            flexDirection:"row"
                        }}
                    >
                        {/* <div
                            style={{
                                display:"flex",
                                flexDirection:"column",
                            }}
                        >
                        <Typography variant="caption" style={{color:"darkGrey"}}
                                >
                                Cook Time
                            </Typography>
                            <div
                                style={{
                                    boxShadow: "0 0 2px darkGrey",
                                    padding:2,
                                    borderRadius:"5px",
                                    width:100,
                                }}
                            >
                            <Typography variant="body2" style={{marginLeft:5}}>
                                {recipe_list[0].cook_time["hrs"]+" hours, "+recipe_list[0].cook_time["mins"]+" minutes"}
                            </Typography>    
                            </div>
                        </div> */}
                        <div
                            style={{
                                display:"flex",
                                flexDirection:"column",
                            }}                            
                        >
                            <Typography variant="subtitle2" style={{color:"darkGrey"}}
                                >
                               Yield
                            </Typography>
                            <Typography variant="caption" style={{marginLeft:5}}>
                                {recipe_list[0].recipe_yield+" servings"}
                            </Typography>        
                        </div>
                    </div>
                    <Typography variant="subtitle2" style={{color:"darkGrey"}}
                    >
                        Instructions
                    </Typography>
                        {instructions.map((instr, idx)=>(
                            <div
                                key={{instr}}
                            >
                                <Typography variant="caption"
                                    style={{}}
                                >{(String(idx+1)+". "+instr).replace("[","").replace("]","")}
                                </Typography>
                            </div>
                        ))}
                    <div>
                        <Typography
                            variant="subtitle2" style={{color:"darkGrey"}}
                        >
                            Ingredients
                        </Typography>
                        <Table
                            size="small"
                            padding="none" 
                        >
                        <TableBody>
                        {recipe_list.map((ingred, idx)=>(
                            <TableRow
                                style={{
                                }}
                            >
                                <TableCell>
                                    <Typography variant="caption">
                                        {ingred.ingred_amt}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="caption">
                                        {ingred.ingred_serving_size_unit}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="caption">
                                        {ingred.ingred_food_desc}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                        </Table>

                    </div>
                    </ExpansionPanelDetails>
            </ExpansionPanel>
            )
        }else{} // nothing else yet
    }

    renderBrand = (food_type_grp, brand)=>{
        if((food_type_grp=='grocery')|(food_type_grp=='restaurant')){
            return (
                <div
                    style={{
                        display:"flex",
                        flexDirection:"column"
                    }}
                >
                    <Typography 
                        style={{
                            color:"darkGrey"
                        }} 
                        variant="caption"
                        align="left"
                    >
                        Brand
                    </Typography>
                    
                    <Typography variant="subtitle2" 
                    noWrap={true}
                        style={{
                            boxShadow: "0 0 2px darkGrey",
                            padding:2,
                            borderRadius:"5px",
                            width:100,
                        }}
                    >{this.titleCase(brand)}</Typography>
                </div>
            )
        }
    }

    calcNutrientBarHeights = ()=>{

    }

    renderMenuSummary = (pref_list) =>{
        
        if(pref_list.length==0){
            return(
                <Typography variant="caption" 
                    style={{
                        color:"darkGrey",
                        marginLeft:10,

                         }}
                >
                    Not In Default Menu
                </Typography>
            )
        }else{
            return(
                <Typography variant="caption"
                    style={{
                        color:"darkGrey",
                        marginLeft:10,
                    }}
                >
                    In Default Menu
                    {/* {pref_list.map((item,idx)=>(
                        <span>{" "+item.meal_type+", "}</span>
                    ))}. */}
                </Typography>
            )
        }
    }

    sendTagAutoCompleteQry = (value) =>{
        if(value){
            if((value.length %3==0)&(value.length!=0)){
                return this.props.searchingTagsAutocomplete(value)
            }
        }
    }

    onAddTag = (event, value)=>{
        let tag_obj = {}
        let is_new = false
        let new_val = value[value.length-1]
        if(typeof new_val == 'string'){
            tag_obj ={
                food_tag_id:0, // always means added in this session
                user_id:0,    // always means added in this session
                name:new_val,
                user_added_flg:1,
            }
            is_new=true
        }else{
            tag_obj=new_val
            is_new=false
        }
        value.splice(value.length-1,1,tag_obj)
        this.props.addFoodTag(value, is_new)//for now
    }

    onDeleteTag = (idx)=>{
        this.props.removeFoodTag(idx)
    }

    onChangeAddMeal = (event) =>{
        if(event.target.value=="Snack"){
            this.setState({
                    add_meal:event.target.value,
                    snack_dish:true,
                    add_dish:"Snacks",
                    dish_nums:["Snacks"]
                })
        }else{
            this.setState({
                add_meal:event.target.value,
                snack_dish:false,
                dish_nums:["Whole Meals", "Main Courses", "Sides"]
            })
        }
    }

    onChangeAddDish =(event)=>{
        this.setState({
            add_dish:event.target.value
        })
    }

    onClickAddMenu =()=>{
        //need another action to remove from modal pref_list
        this.props.add_pref_list({
            unique_id:this.props.food_key,
            meal_type:this.state.add_meal,
            dish_num:this.state.add_dish,
            name:this.props.food_dict.food_description,
            item_type:"food",
            default:true,
        })
        this.props.add_menu_item({
            unique_id:this.props.food_key,
            meal_type:this.state.add_meal,
            dish_num:this.state.add_dish.toLowerCase().replace(" ","_"),
            name:this.props.food_dict.food_description,
            item_type:"food",
            default:true,   
        })
    }
    onDeleteMenu=(itemObj, idx)=>{
        this.props.remove_pref_list(idx)
        this.props.remove_menu_item({
            unique_id:this.props.food_key,
            meal_type:itemObj.meal_type,
            dish_num:itemObj.dish_num.toLowerCase().replace(" ","_"),
            name:this.props.food_dict.food_description,
            item_type:"food",
            default:true,
        })
    }

   render(){   
       const classes = this.props
        if(this.props.is_loading){
            return (
                <Modal 
                open={this.props.open}
                onBackdropClick={this.handlecloseModal}
                style={{
                    width:320,
                    margin:"auto",
                }}
                >
                    <CircularProgress/>
                </Modal>
            )
        }else{
        return (
            <Modal
                open={this.props.open}
                onBackdropClick={this.handlecloseModal}
                style={{
                    width:320,
                    margin:"auto",
                    overflowX:"scroll",
                    scrollbarWidth:"thin"
                }}
            >
            <div
                style={{
                    backgroundColor:"var(--sec_white)",

                    //match viewport
                }}
            >
            {/* food description,picture, brand, food type
                
                Nutrition data
                Tags CRUD
                Meal Preferences CRUD
                Max Servings CRUD
                if recipe, recipe details
                User / global stars
                Amazon Fresh link
                Comments / reviews
                Default serving size description
                Exclude this week
                "Flag for bad data drop-down" - bad image, bad nutrients, other 
            */}
                <div
                    style={{
                        display:"flex",
                        alignContent:"space-between"
                    }}
                >
                    {/* Title / Header */}
                    <Typography
                        style={{
                            margin:"auto"
                        }}
                    >
                        {this.titleCase(this.props.food_dict.food_description)}
                    </Typography>
                    <IconButton
                        onClick={this.handlecloseModal}
                    >
                        <ClearIcon/>
                    </IconButton>
                </div>
                <Divider variant="middle" />
                <div
                    style={{
                        display:"flex",
                        justifyContent:"space-between",
                        margin:"auto",
                        width:300,
                    }}
                >
                    {/* Subtitle */}
                    <ReactImageFallback
                                    src={this.props.food_dict.image_url} 
                                    fallbackImage={"https://planyourmealsmedia.s3.amazonaws.com/food/default_thumbnail.png"}
                                    initialImage={"https://planyourmealsmedia.s3.amazonaws.com/food/default_thumbnail.png"}
                                    alt={'Food'}
                                    style={{
                                        "width":160,"height":160,
                                    "margin":"auto"}}
                    />
                    <div>

                    <div
                        style={{
                            display:"flex",
                            flexDirection:"column",
                            paddingRight:15,
                            textAlign:"center",
                            marginRight:10,
                        }}
                    >
                        <Typography 
                            align="left"
                            variant="caption"
                            style={{
                                // textDecoration:"underline"
                                color:"darkGrey"
                            }}
                        >Type</Typography>
                        <Typography 
                            align="center"
                            variant="subtitle2"
                            style={{
                                // outline:"1px solid darkGrey",
                               boxShadow: "0 0 2px darkGrey",
                               padding:2,
                               borderRadius:"5px",
                               width:100
                            }}
                        >{this.titleCase(this.props.food_dict.food_type_grp)}</Typography>
                        
                    </div>
                    {this.renderBrand(this.props.food_dict.food_type_grp, this.props.food_dict.brand)}
                    <div
                        style={{
                            display:"flex",
                            flexDirection:"column"
                        }}
                    >
                    <Typography 
                        variant="caption"
                        align="left"
                        style={{
                            // marginTop:5,
                            color:"darkGrey",
                        }}
                    >
                        Max Serving Size
                    </Typography>
                    <div
                        style={{
                            display:"flex",
                            alignContent:"center",
                            boxShadow: "0 0 2px darkGrey",
                            borderRadius:"5px",
                            width:100
                        }}
                    >
                        <TextField
                            // variant="outlined"
                            // label={"Max Servings"}
                            placeholder={"x "+String(this.props.food_dict.serving_size_val)+" "+ this.props.food_dict.serving_size_unit}
                            style={{
                                width:100,
                                marginLeft:5,
                            }}
                            value={this.props.prob_r_dict.max_servings}
                            InputProps={{
                                className:classes.inputStyling,
                            }}
                            onChange={(event)=>{this.handleMaxServingsChange(event)}}
                        />
                        {/* <Typography variant="subtitle" 
                        style={{
                            marginRight:3,
                            marginLeft:3,
                            marginTop:6,
                            height:"100%",
                            verticalAlign:"bottom",
                            color:"darkGrey",
                            fontFamily:"Sans-Serif",
                        }}>x</Typography>
                        <Typography 
                            variant="subtitle2"
                            noWrap={true}
                            style={{
                                // color:"darkGrey",
                                marginTop:6,
                                width:80,
                                height:"100%",
                                verticalAlign:"bottom",
                            }}
                        >
                        
                        {String(this.props.food_dict.serving_size_val)+" "+ this.props.food_dict.serving_size_unit}
                        </Typography>
 */}
                        {/* label */}
                    </div>
                    </div>
                    <div
                        style={{
                            display:"flex",
                            flexDirection:"column",
                            textAlign:"center",
                        }}
                    >
                        <Tooltip 
                            title={"Helps the autoplanner know how much to recommend"}
                        >
                        <Typography variant="caption" align="left"
                            style={{color:"darkGrey"}}
                        >Your Rating</Typography>
                        </Tooltip>
                        <Rating size="small" value={2.5} />
                    </div>
                    </div>
                    

                </div>
                <div>
                    {/* Nutrients */}
                    
                </div>
                
                {/* <Divider variant="middle" /> */}
                <div>
                    {/* Tags */}
                    {/* <div
                        style={{
                            display:"flex",
                            alignContent:"center",
                            textAlign:"center",
                        }}
                    >
                        <Typography variant="body2">Tags</Typography>
                        <Typography variant="caption">(Press Enter To Add)</Typography>
                    </div> */}
                    <div>
                        <Autocomplete
                            style={{
                                width:300,
                                margin:"auto",
                            }}
                            multiple
                            freeSolo={true}
                            value={this.props.tag_list}
                            options={this.props.tag_search_list}
                            getOptionLabel={option=>(option.name)}
                            onChange={(event,value)=>{this.onAddTag(event,value)}}
                            onInputChange={(event, value)=>{this.sendTagAutoCompleteQry(value)}}    
                            renderTags={
                                (value,getTagProps)=>
                                value.map((tag, index)=>{
                                    // shouldn't be ( should be { and return for onDelete
                                    if((tag.user_added_flg==1)){
                                        return(
                                        <Chip 
                                            style={{
                                                backgroundColor:"var(--sec_dark)",
                                                color:"var(--main_dark)"
                                            }}
                                            variant="default"
                                            size="small"
                                            onDelete={()=>{this.onDeleteTag(index)}}
                                            // deleteIcon={<}
                                            label={tag.name}
                                            key={tag.name}
                                        />
                                        )
                                    }else{
                                        return(
                                        <Chip 
                                            style={{
                                                backgroundColor:"var(--sec_dark)",
                                                color:"var(--main_dark)"
                                            }}
                                            variant="default"
                                            size="small"
                                            label={tag.name}
                                            key={tag.name}
                                        />
                                        )
                                    }
                                }
                                )
                            }
                            renderInput={params=>(
                                <TextField
                                {...params}
                                    style={{fontSize:8, color:"var(--sec_dark)"}}
                                    placeholder="  Add Tag"
                                    margin="dense"
                                    fullWidth
                                />
                            )}
    
                        />
                    </div>
                </div>
                {/* Start of Nutrient Expansion PAnnel */}
                <ExpansionPanel 
                    className={classes.condensedExpansionPanel}
                >
                    <ExpansionPanelSummary
                    className={classes.condensedExpansionPanel}
                    expandIcon={<ExpandMoreIcon/>}
                    IconButtonProps={{
                        margin:0,
                        padding:0,
                        fontSize:12
                    }}
                    >
                    <Typography variant="subtitle2">Nutrient Details</Typography>
                    <Typography variant="caption" style={{color:"darkGrey",marginLeft:10}} >{"High in "+this.props.curr_highest_display}</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails
                className={classes.condensedExpansionPanel}
                >
                <div
                    style={{
                        overflowX:"auto",
                        overflowY:"hidden",
                        width:"100%",
                        height:220,
                        scrollbarWidth:"thin",
                        backgroundColor:"var(--sec_white)",
                        margin:"auto",
                        display:"flex",
                        flexDirection:"column"
                    }}

                >
                <div>
                    <Typography
                        variant="caption"
                        align="left"
                        style={{
                            color:"darkGrey",
                        }}
                    >
                        Minimum Requirement
                    </Typography>
                    </div>
                <div
                    style={{
                        display:"flex"
                    }}
                >

                    {this.props.requirements_order.map((req,idx)=>(
                        // <div
                        //     // shell div
                        //     style={{
                        //         width:35,
                        //         height:220,
                        //         // overflow:"hidden",
                        //         marginRight:1,
                        //         display:"flex",
                        //         flexDirection:"column"
                        //     }}
                        // >
                            <div
                            // graph div
                            style={{
                                width:35,
                                height:220,
                                width:35,
                                height:220,
                                // overflow:"hidden",
                                marginRight:1,
                            }}
                            >
                            <div
                            style={{
                               backgroundColor:"lightGrey",
                               height:20,
                               textAlign:"center",
                               borderBottom:"1px solid black",
                               width:35,
                            }}
                                // target div
                            >
                                <Typography
                                    align="center"
                                    style={{
                                        fontSize:11,
                                        fontWeight:"bold",

                                    }}
                                    variant="caption"
                                >
                               {Math.round(this.props.requirements_dict[req+"_lb"])}
                               </Typography>
                                    
                            </div>
                            <div
                            // remaining div
                                style={{
                                    backgroundColor:"darkGrey",
                                    height:(this.props.requirements_dict[req+"_lb"]==0)||(this.props.food_dict[this.props.abrev_to_nut_dict[req]]>this.props.requirements_dict[req+"_lb"])? 0: 100-Math.round((this.props.food_dict[this.props.abrev_to_nut_dict[req]]/this.props.requirements_dict[req+"_lb"])*100),
                                    verticalAlign:"bottom",
                                    textAlign:"left",
                                    width:35,
                                }}
                            >
                                <Typography
                                    align="center"
                                    style={{
                                        fontSize:12,
                                        verticalAlign:"bottom",
                                        color:"white",
                                        fontWeight:"bold",
                                        paddingTop:"95%",
                                    }}
                                >
                                    {this.props.requirements_dict[req+"_lb"]==0? " ": Math.round((this.props.food_dict[this.props.abrev_to_nut_dict[req]]/this.props.requirements_dict[req+"_lb"])*100)+"%"}
                                </Typography>
                                {/* <Typography
                                align="center"
                                style={{
                                    fontSize:12,
                                    verticalAlign:"bottom",
                                    color:"white",
                                    fontWeight:"bold",
                                }}
                                >
                                    %
                                </Typography> */}
                            </div>
                            <div
                                // amount div
                                style={{
                                    fontSize:10,
                                    color:"darkBlue",
                                    height:(this.props.requirements_dict[req+"_lb"]==0)||(this.props.food_dict[this.props.abrev_to_nut_dict[req]]>this.props.requirements_dict[req+"_lb"])?100:Math.round((this.props.food_dict[this.props.abrev_to_nut_dict[req]]/this.props.requirements_dict[req+"_lb"])*100),
                                    backgroundColor:"lightBlue",
                                    width:35,
                                    textAlign:"center",
                                }}
                            >
                                <Typography
                                    align="center"
                                    variant="caption"
                                    style={{
                                        fontSize:12,
                                        fontWeight:"bold",
                                        textAlign:"center",
                                    }}
                                >
                                {Math.round(this.props.food_dict[this.props.abrev_to_nut_dict[req]])}
                                </Typography>
                            </div>
                            {/* </div> */}
                            <div style={{
                                        height:80,
                                        width:35,
                                        writingMode:"vertical-rl",
                                        textOrientation:"mixed",
                                        font:"Roboto",
                                        fontFamily:"Sans-Serif",
                                        fontSize:12,
                                        fontWeight:"bold",
                                        textAlign:"center",
                                        verticalAlign:"bottom",
                                        marginTop:20,
                                        display:"flex",
                                        alignItems:"center",
                                    }}>
                                {this.props.abrev_to_display_dict[req]}
                            </div>
                        </div>
                    ))}
                    </div>
                </div>
                </ExpansionPanelDetails>
                </ExpansionPanel>
                {/* Start of Menu Expansion Panel */}
                <ExpansionPanel
                >
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon/>}
                        IconButtonProps={{
                            margin:0,
                            padding:0,
                            fontSize:12
                        }}
                    >
                        <Typography variant="subtitle2">Menu Settings</Typography>
                        {this.renderMenuSummary(this.props.pref_list)}
                    </ExpansionPanelSummary>
                <ExpansionPanelDetails
                >
                <div
                    
                >
                    <Table
                        size="small"
                        padding="none" 
                        style={{
                            fontSize:12,
                            margin:"auto",
                            width:300,
                        }}   
                    >
                        <TableBody>
                    {this.props.pref_list.map((pref,idx)=>{
                        return(
                            <TableRow
                                key={pref.meal_type+"-"+pref.dish_num}
                            >
                                <TableCell>
                                    <Typography style={{fontSize:12}} variant="body2">{pref.meal_type}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography style={{fontSize:12}} variant="body2">{pref.dish_num}</Typography>
                                </TableCell>
                            {/* // </div> */}
                            <TableCell>
                                <IconButton 
                                    size="small"  
                                    edge="end"
                                    onClick={(event)=>{this.onDeleteMenu(pref,idx)}}
                                >
                                    <DeleteIcon fontSize="small"/>
                                </IconButton>
                            </TableCell>
                            </TableRow>
                        )
                    })}
                    <TableRow>
                    <TableCell>
                    <Select
                        name={"Meal"}
                        value={this.state.add_meal}
                        InputProps={{
                            className:classes.inputStyling,
                        }}
                        inputProps ={{
                            name:'add_meal',
                            id:'add_meal',
                            fontSize:12,
                        }}
                        style={{
                            fontSize:12,
                        }}
                        onChange={this.onChangeAddMeal}
                    >
                        <MenuItem value={"Breakfast"} dense={true} style={{fontSize:12}}>Breakfast</MenuItem>
                        <MenuItem value={"Lunch"} dense={true} style={{fontSize:12}}>Lunch</MenuItem>
                        <MenuItem value={"Dinner"} dense={true} style={{fontSize:12}}>Dinner</MenuItem> 
                        <MenuItem value={"Snack"} dense={true} style={{fontSize:12}}>Snack</MenuItem>
                    </Select>
                    </TableCell>
                    <TableCell>
                    <Select
                        name={"Dish"}
                        value={this.state.add_dish}
                        onChange={this.onChangeAddDish}
                        style={{fontSize:12,}}
                    >
                        {/* Need to Render differently for snack */}
                        {this.state.dish_nums.map((dish_num,idx)=>(
                            <MenuItem value={dish_num} dense={true} style={{fontSize:12,}}>{dish_num}</MenuItem>
                        ))}
                    </Select>
                    </TableCell>
                    <TableCell>
                        <IconButton 
                            size="small" 
                            edge="end"
                            onClick={this.onClickAddMenu}
                        >
                            <AddIcon fontSize="small"/>
                        </IconButton>
                    </TableCell>
                    </TableRow>
                    </TableBody>
                    </Table>
                    {/* </div> */}
                    {/* default dish */}

                </div>
                </ExpansionPanelDetails>
                </ExpansionPanel>
                <div>
                    {this.renderRecipeDrawer(this.props.is_recipe,this.props.recipe_list, this.props.instructions)}
                </div>
                <div>
                    <Button 
                        onClick={this.handlecloseModal}
                        style={{
                            color:"var(--main_dark)",
                            width:"100%",
                        }}
                    >
                        Close
                    </Button>
                </div>
            </div>
            </Modal>
        )
        }
    }
}

const mapStateToProps = (state, ownProps) => {
    // Leaving space in case logic is needed here
    let instructions = []
    if(state.modal.food_modal.is_recipe){
        instructions = state.modal.food_modal.recipe_list[0]["instructions"]
    }
    let highest_nut = ''
    let curr_highest = 0
    let loop_idx = 0
    state.user.requirements_order.map((req,idx)=>{
        if(req!="sod"&&req!="sug"&&req!="stf"&&state.user.requirements_dict[req]!=0){
            loop_idx = state.modal.food_modal.food_dict[state.util.abrev_to_nut_dict[req]]/state.user.requirements_dict[req+"_lb"]
            if(loop_idx > curr_highest){
                highest_nut = req
                curr_highest = loop_idx
            }
        }
    })
        return ({
            open:state.modal.food_modal.open,
            loading:state.modal.food_modal.loading,
            food_key:state.modal.food_modal.food_key,
            is_recipe:state.modal.food_modal.is_recipe,
            food_dict:state.modal.food_modal.food_dict,
            tag_list:state.modal.food_modal.tag_list,
            pref_list:state.modal.food_modal.pref_list,
            prob_r_dict:state.modal.food_modal.prob_r_dict,
            recipe_list:state.modal.food_modal.recipe_list,
            requirements_order: state.user.requirements_order,
            requirements_dict: state.user.requirements_dict,
            abrev_to_display_dict: state.util.abrev_to_display_dict,
            abrev_to_nut_dict:state.util.abrev_to_nut_dict,
            instructions: instructions,
            tag_search_list:state.modal.food_modal.tag_search_list,
            curr_highest_display:state.util.abrev_to_display_dict[highest_nut],
        })
}

export default connect(mapStateToProps, {closeFoodModal,
    changeFoodMaxServings,
    addFoodTag,
    removeFoodTag,
    searchingTagsAutocomplete,
    add_menu_item,
    remove_menu_item,
    add_pref_list,
    remove_pref_list,})(withStyles(useStyles)(FoodModal));

