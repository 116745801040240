import initialState from '../initialState'

export default function(state=initialState, action){
    switch(action.type){
        case 'UPDATE_DAYS':
            return{
                ...state,
                days_dict:action.payload.days_dict
            }
        default:
            return state

    }
}