import initialState from '../initialState'

//TODO - get rid of combine state so can access state by doing this.state.columns.searchResults.food, etc

export default function(state=initialState, action){
    switch(action.type){
        case 'TOGGLING_FOOD_MODAL':
            return{
                ...state,
                    food_modal:{
                        ...state.food_modal,
                        open:action.payload.open,
                        food_key:action.payload.food_key
                    }
            }
        case 'TOGGLING_FOOD_MODAL_LOADING':
            return{
                ...state,
                    food_modal:{
                        ...state.food_modal,
                        loading:action.payload.loading
                    }
            }
        case 'UPDATING_FOOD_MODAL':
            return{
                ...state,
                    food_modal:{
                        ...state.food_modal,
                        ...action.payload
                    }
            }
        case 'UPDATING_MAX_SERVINGS':
            return{
                ...state,
                food_modal:{
                    ...state.food_modal,
                    prob_r_dict:{
                        ...state.food_modal.prob_r_dict,
                        max_servings:action.payload.max_servings
                    }
                }
            }
        case 'ADDING_FOOD_TAGS':
            return{
                ...state,
                food_modal:{
                    ...state.food_modal,
                    tag_list:action.payload.tag_list
                }
            }
        case 'ADDING_FOOD_TAG_SEARCH_LIST':
            return{
                ...state,
                food_modal:{
                    ...state.food_modal,
                    tag_search_list:action.payload.tag_search_list
                }
            }
        case 'UPDATING_FOOD_MODAL_PREF_LIST':
            return{
                ...state,
                food_modal:{
                    ...state.food_modal,
                    pref_list:action.payload.pref_list
                }
            }
            case 'TOGGLE_PAYMENT_MODAL':
                return{
                    ...state,
                    payment_modal:{
                        ...state.payment_modal,
                        open:action.payload.open,
                    }
                }
            case 'TOGGLE_TERMS_MODAL':
                return{
                    ...state,
                    terms_and_conditions:{
                        ...state.terms_and_conditions,
                        open:action.payload.open,
                    }
                }
        default:
            return state;
    }
}
    