import axios from 'axios'// since fetch api not supported in all browsers
import store from '../store'
import {meals, sumNutrientTotals,updateSingleMeal} from './util';
import {resetNthSolve} from './types';

// Add To Meal
export const addToMeal = mealAddObj =>{
    // shortening variables
    let draggableId = mealAddObj.draggableId
    let source_col = mealAddObj.source.droppableId.split("_")[0]
    let source_subcol = mealAddObj.source.droppableId.split("_")[1]
    let destination_col = mealAddObj.destination.droppableId.split("_")[0]
    let destination_subcol = mealAddObj.destination.droppableId.split("_")[1]
    // get needed state
    let source_state_slice = store.getState().columns.present[source_col]
    let dest_state_slice = store.getState().columns.present[destination_col]
    
    let source_list = []
    let destination_list = []
    let food_obj = {} 
    if(source_col.includes('search')){
        Object.assign(food_obj, source_list[mealAddObj.source.index])
        destination_list = Array.from(dest_state_slice[destination_subcol].foods_list)
        source_list = source_state_slice.foods_list
        destination_list.splice(mealAddObj.destination.index,0,source_list[mealAddObj.source.index])
        // reset drag id to avoid multiple key error for beautiful-dnd
        let new_drag_id = food_obj['drag_id']+String(Date.now())
        food_obj['drag_id'] = new_drag_id
        food_obj['drag_key'] = new_drag_id
        let new_nutrient_totals = sumNutrientTotals(dest_state_slice , destination_subcol, destination_list)
        //date_str,meal_type,foods_list
        updateSingleMeal(destination_col, destination_subcol, destination_list)
        return dispatch => {
            dispatch(addingToMealFromSearch({
                destination_col: destination_col,
                destination_subcol: destination_subcol,
                destination_list: destination_list
            }))
            dispatch(updatingSingleDayNutrients({
                plannum:destination_col,
                nutrient_totals:new_nutrient_totals
            }))
            dispatch(resetNthSolve({}))
        }
    }else{
        destination_list = Array.from(dest_state_slice[destination_subcol].foods_list)
        source_list = Array.from(source_state_slice[source_subcol].foods_list)
        let source_list_clone = Array.from(source_state_slice[source_subcol].foods_list)
        Object.assign(food_obj, source_list_clone[mealAddObj.source.index])
        source_list.splice(mealAddObj.source.index,1)
        destination_list.splice(mealAddObj.destination.index,0,food_obj) // not working
        updateSingleMeal(source_col, source_subcol, source_list)
        updateSingleMeal(destination_col, destination_subcol, destination_list)
        if(source_col!=destination_col){
            return dispatch => {
                dispatch(addingToMealFromMeal({
                    source_col: source_col,
                    source_subcol: source_subcol,
                    source_list: source_list,
                    destination_col: destination_col,
                    destination_subcol: destination_subcol,
                    destination_list: destination_list
                }))
                let source_nutrient_totals = sumNutrientTotals(source_state_slice , source_subcol, source_list)
                let destination_nutrient_totals = sumNutrientTotals(dest_state_slice , destination_subcol, destination_list)
                dispatch(updatingSingleDayNutrients({
                    plannum:source_col,
                    nutrient_totals:source_nutrient_totals
                }))
                dispatch(updatingSingleDayNutrients({
                    plannum:destination_col,
                    nutrient_totals:destination_nutrient_totals
                }))
                dispatch(resetNthSolve({}))
            }
            }else{
                return dispatch =>{
                    dispatch(addingToMealFromMealSameDay({
                        col:source_col,
                        source_subcol: source_subcol,
                        source_list:source_list,
                        destination_subcol:destination_subcol,
                        destination_list:destination_list
                    }))
                    dispatch(resetNthSolve({}))
                }
            }
    }
}


export const reorderSameColumn = mealObj =>{
    let col = mealObj.source.droppableId.split("_")[0]
    let subcol = mealObj.source.droppableId.split("_")[1]
    if(col==='searchResults'){
        let foods_list = Array.from(store.getState().columns.present[col].foods_list)
        let food_obj = foods_list[mealObj.source.index]
        foods_list.splice(mealObj.source.index,1) // remove old spot
        foods_list.splice(mealObj.destination.index,0,food_obj)
        return dispatch=>{dispatch(reorderingInSameColumnSearch({
            col:'searchResults',
            foods_list: foods_list
        }))}
    }else{
        let foods_list = Array.from(store.getState().columns.present[col][subcol].foods_list)
        let food_obj = foods_list[mealObj.source.index]
        foods_list.splice(mealObj.source.index,1) // remove old spot
        foods_list.splice(mealObj.destination.index,0,food_obj)
        return dispatch=>{dispatch(reorderingInSameColumn({
            col:col,
            subcol:subcol,
            foods_list:foods_list
        }))}
    }
}

export const removeFromMeal = mealObj =>{
    // only sent if comes from day-meal
    // TODO - post to remove from meal
    let col = mealObj.source.droppableId.split("_")[0]
    let subcol = mealObj.source.droppableId.split("_")[1]
    let state_slice = store.getState().columns.present[col]
    let foods_list = Array.from(state_slice[subcol].foods_list)
    foods_list.splice(mealObj.source.index,1) // remove old spot
    let new_nutrient_totals = sumNutrientTotals(state_slice, subcol, foods_list)
    updateSingleMeal(col, subcol, foods_list)
    
     return dispatch => {
        dispatch(removingFromMeal({
            col:col,
            subcol:subcol,
            foods_list:foods_list
        }))
        dispatch(updatingSingleDayNutrients({
            plannum:col,
            nutrient_totals:new_nutrient_totals
        }))
        dispatch(resetNthSolve({}))
}
}



const addingToMeal = mealAddObj => ({
    type:'ADD_TO_MEAL',
    payload: mealAddObj
})

const addingToMealFromMeal = mealAddObj => ({
    type:'ADD_TO_MEAL_FROM_MEAL',
    payload: mealAddObj
})

const addingToMealFromMealSameDay = mealAddObj => ({
    type:'ADD_TO_MEAL_FROM_MEAL_SAME_DAY',
    payload: mealAddObj
})

const addingToMealFromSearch = mealAddObj => ({
    type:'ADD_TO_MEAL_FROM_SEARCH',
    payload: mealAddObj
})

const reorderingInSameColumn = mealAddObj => ({
    type:'REORDER_IN_SAME_COLUMN',
    payload: mealAddObj
})


const reorderingInSameColumnSearch = mealAddObj => ({
    type:'REORDER_IN_SAME_COLUMN_SEARCH',
    payload: mealAddObj
})

const removingFromMeal = mealRemObj => ({
    type:'UPDATE_FOODS_LIST',
    payload: mealRemObj
})

export const mealToMeal = mealObj =>{
    // Adds a meal from meal search results
    // add mealname to mealname of this meal
    // send meal id to server to update "n_cloned"
    // basics - copy foods to new meal
    let col = mealObj.combine.droppableId.split("_")[1]
    let subcol = mealObj.combine.droppableId.split("_")[2]
    let state_slice = store.getState().columns.present[col]
    let source_slice = store.getState().columns.present["searchResults"]["meals_list"][mealObj.source.index]
    let foods_list = source_slice["foods"]
    let mealname = source_slice["mealname"]+" (copy)"
    let meal_id = source_slice["meal_id"]
    let new_nutrient_totals = sumNutrientTotals(state_slice, subcol, foods_list)
    updateSingleMeal(col, subcol, foods_list)
    return dispatch => {
        dispatch(combiningMealToMeal({
            col:col,
            subcol:subcol,
            foods_list:foods_list,
            mealname:mealname
        }))
        dispatch(updatingSingleDayNutrients({
            plannum:col,
            nutrient_totals:new_nutrient_totals
        }))
        dispatch(resetNthSolve({}))
    }
}

const combiningMealToMeal = mealCombObj => ({
    type:'MEAL_TO_MEAL',
    payload: mealCombObj
})

export const updatingSingleDayNutrients = nutObj => ({
    type:'UPDATE_SINGLE_DAY_NUTRIENTS',
    payload: nutObj
})
