import React, {Component} from 'react';
import {connect} from 'react-redux';
import NavBar from '../common/navbar';
import FoodModal from '../plan/food_modal';
import {
    Input,
    InputBase,
    IconButton,
    TextField,
    RadioGroup,
    Radio,
    FormControl,
    FormControlLabel,
    Select,
    MenuItem,
    Button,
    Typography,
    Table,
    TableBody,
    TableHead,
    TableRow,
    TableCell,
    CircularProgress,
} from '@material-ui/core/';
import {Slider,ToggleButton, ToggleButtonGroup} from '@material-ui/lab';
import Cropper from 'react-easy-crop';
import SearchIcon from '@material-ui/icons/Search';
import InfoIcon from '@material-ui/icons/Info';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import {openFoodModal} from '../../actions/food_modal';
import {searchIngredients,
    searchRecipesEdit,
    searchRecipesClone,
    searchMealFoods,
    searchMeals,
} from '../../actions/search';
import {addNewRecipe, 
    getRecipe,
    updateRecipeField,
    updateRecipeIngredientsField,
    removeRecipeIngredient,
    changeIngredientAmt,
    changeRecipeServingSizeIndex,
    addRecipeInstruction,
    deleteRecipeInstruction,
    addEditedRecipe,
    getMeal,
    removeMealFood,
    changeMealServingSizeIndex,
    updateMealFoodsField,
    addNewMeal,
    addEditedMeal,
    changeMealFoodAmt,
    updateMealField,
} from '../../actions/input';

const sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
}

class InputPage extends Component{
    constructor(props){
        super(props)
        this.state = {
            // form type radio buttons
            action_type:"add_new",
            item_type:"recipe",
            // item search
            item_search_qry:null,
            // recipe search
            ingred_search_qry:null,
            loadingIngredSearch: this.props.loadingIngredSearch,
            hasIngredSearch:this.props.hasIngredSearch,
            ingredSearchResults:this.props.ingredSearchResults,
            // instructions input
            instructions_input:null,
            // recipe fields
            // meal fields
            foods_list:[],
            mealname:null,
            default_meal_type:null,
            // success message
            show_success_message:false
        } 
    }

    shouldComponentUpdate =()=>{
        return true
    }

    changeActionState = (value) =>{
        this.setState({action_type:value})
    }

    changeItemType = (value) =>{
        this.setState({item_type:value})
    }

    onImageUpload =(event)=>{
        // this.setState({
        //     recipe_image:event.target.files[0]
        // })
        this.props.updateRecipeField({
            recipe_image:event.target.files[0],
            recipe_image_url:URL.createObjectURL(event.target.files[0])
        })
    }

    renderItemSearch = () =>{
        if(this.state.action_type==='add_new'){
            return (<div></div>)
        }else if((this.state.item_type==='recipe')){
            return (
            <div>
                <div
                    style={{
                        display:(this.state.item_type==='recipe')?"":"none",
                    }}
                >
                    <InputBase
                    style={{"verticalAlign":"bottom",
                        paddingLeft:3,
                        width:"85%",
                    }}
                    placeholder={"Search Recipes"}
                    fullWidth={false}
                    onChange={(event)=>{this.setState({item_search_qry:event.target.value})}}
                    />
                    <IconButton
                        size= {"small"}
                        onClick={this.getRecipeItemSearchResult}
                    >
                        <SearchIcon/>
                    </IconButton>  
                    <div>
                        <Typography variant="caption"
                            style={{
                                color:'darkGrey'
                            }}
                        >
                            You can only edit recipes you entered. Please use "clone" to change someone else's recipe.
                        </Typography>
                    </div>
                </div>   
                <div
                        style={{
                            display:this.props.loadingRecipeSearch?"":"none",
                            width:"100%",
                            textAlign:"center",
                        }}
                    >
                        <CircularProgress style={{margin:"auto"}}/>
                </div>
                <div
                        style={{
                            display:this.props.hasRecipeSearch?"":"none",
                            maxHeight:100,
                            border:"1 px solid darkGrey",
                            overflowY:"scroll",
                            scrollbarWidth:"thin",
                        }}
                    >
                        {/* <Typography variant="subtitle1">Ingredients</Typography>   */}
                        <Table
                            size="small"
                            padding="none"                        
                        >
                            <TableBody>
                            {this.props.recipeSearchResults.map((result, idx)=>(
                                <TableRow 
                                    key={result.food_key}
                                >
                                    <TableCell>
                                        <IconButton
                                             size="small"
                                             edge="end"    
                                             onClick={(event)=>{this.handleAddRecipeClick(result.food_key)}}                                   
                                        >
                                            <AddIcon 
                                                fontSize="small"
                                            />
                                        </IconButton>
                                    </TableCell>
                                    <TableCell>
                                        <IconButton
                                            size="small"
                                            edge="end"
                                            onClick={(event)=>{this.handleModalClick(result.food_key)}}
                                        >
                                        <InfoIcon
                                            fontSize="small"
                                        />
                                        </IconButton>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>
                                            {result.food_description}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </div>
            </div>
            )
        }else if(this.state.item_type==='meal'){
            return (<div>
                <div
                    style={{
                        display:(this.state.item_type==='meal')?"":"none",
                    }}
                >
                    <InputBase
                    style={{"verticalAlign":"bottom",
                        paddingLeft:3,
                        width:"85%",
                    }}
                    placeholder={"Search Meals"}
                    fullWidth={false}
                    onChange={(event)=>{this.setState({item_search_qry:event.target.value})}}
                    />
                    <IconButton
                        size= {"small"}
                        onClick={this.getMealItemSearchResult}
                    >
                        <SearchIcon/>
                    </IconButton>  
                    <div>
                        <Typography variant="caption"
                            style={{
                                color:'darkGrey'
                            }}
                        >
                            You can only edit meals you entered. Please use "clone" to change someone else's meal.
                        </Typography>
                    </div>
                </div>   
                <div
                        style={{
                            display:this.props.loadingMealSearch?"":"none",
                            width:"100%",
                            textAlign:"center",
                        }}
                    >
                        <CircularProgress style={{margin:"auto"}}/>
                </div>
                <div
                        style={{
                            display:this.props.hasMealSearch?"":"none",
                            maxHeight:100,
                            border:"1 px solid darkGrey",
                            overflowY:"scroll",
                            scrollbarWidth:"thin",
                        }}
                    >
                        {/* <Typography variant="subtitle1">Ingredients</Typography>   */}
                        <Table
                            size="small"
                            padding="none"                        
                        >
                            <TableBody>
                            {this.props.mealSearchResults.map((result, idx)=>(
                                <TableRow 
                                    key={result.meal_id}
                                >
                                    <TableCell>
                                        <IconButton
                                             size="small"
                                             edge="end"    
                                             onClick={(event)=>{this.handleAddMealClick(result.meal_id)}}                                   
                                        >
                                            <AddIcon 
                                                fontSize="small"
                                            />
                                        </IconButton>
                                    </TableCell>
                                    <TableCell>
                                        <IconButton
                                            size="small"
                                            edge="end"
                                            onClick={(event)=>{this.handleMealModalClick(result.meal_id)}}
                                        >
                                        <InfoIcon
                                            fontSize="small"
                                        />
                                        </IconButton>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>
                                            {result.mealname}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </div>
            </div>)
        }
    }

    // Adding from search
    handleAddRecipeClick = (food_key)=>{
        this.props.getRecipe(food_key)
    }

    handleAddMealClick = (meal_id)=>{
        this.props.getMeal(meal_id)
    }
    
    // Searching items
    getRecipeItemSearchResult = (event)=>{
        if(this.state.action_type=='edit'){
            this.props.searchRecipesEdit(this.state.item_search_qry)
        }else{
            this.props.searchRecipesClone(this.state.item_search_qry)
        }
    }

    getMealItemSearchResult = (event) =>{
        if(this.state.action_type=='edit'){
            this.props.searchMeals({
                search_qry:this.state.item_search_qry,
                nth_search:0,
                user_added_only:true,
                destination:'clone_or_edit'
            })
        }else{
            this.props.searchMeals({
                search_qry:this.state.item_search_qry,
                nth_search:0,
                user_added_only:false,
                destination:'clone_or_edit',
            })
        }
        
    }
    
    // Food modal
    handleModalClick = (food_key) =>{
        this.props.openFoodModal(food_key)
    }

    // Adding ingredient to list
    handleAddIngredClick =(idx)=>{
        if(this.state.item_type === 'recipe'){
            this.props.updateRecipeIngredientsField(idx)
        }else{
            this.props.updateMealFoodsField(idx)
        }
    }

    handleRemoveIngredClick =(idx)=>{
        this.props.removeRecipeIngredient(idx)
    }

    getIngredSearchResult = () =>{
        this.props.searchIngredients(this.state.ingred_search_qry)
    }

    changeIngredAmt = (list_idx, new_amt) =>{
        this.props.changeIngredientAmt(list_idx,new_amt)
    }

    changeServingSizeIndex = (list_idx, new_serving_size_index) =>{
        this.props.changeRecipeServingSizeIndex(list_idx, new_serving_size_index)
    }

    addInstruction =()=>{
        if(this.state.instructions_input){
            this.props.addRecipeInstruction(this.state.instructions_input)
            this.setState({instructions_input:''})
        }else{
            alert("Instruction cannot be empty")
        }
    }

    deleteInstruction =(idx)=>{
        this.props.deleteRecipeInstruction(idx)
    }

    editInstruction =(idx)=>{
    }

    saveEntry =()=>{
    // For Edited Meals
    // For New/Cloned Meals
    // For Edited Recipes
    // For New/Cloned Recipes
    if((this.state.item_type =='recipe')){
            if(!this.props.recipe_name){
                alert("Please Enter A Recipe Name")
            }else if((!this.props.yield)||(typeof(this.props.yield)!='number')){
                alert("Please enter a Numeric Recipe Yield (number of servings made)")
            }
            // check cook time
            else if(this.props.ingredients_list.length===0){
                alert("Please enter at least one ingredient")
            }
            else if(this.props.instructions_list.length===0){
                alert("Please enter at least one instruction")
            }else{
                if(this.state.action_type==='edit'){
                    this.props.addEditedRecipe(this.props)
                    this.setState({show_success_message:true})

                }else{
                    this.props.addNewRecipe(this.props)
                    this.setState({show_success_message:true})
                }
                sleep(3000).then(()=>{
                    this.setState({show_success_message:false})
                })
            }
    }else{
        // check meal name
        if((this.props.mealname===null)||(this.props.mealname==='')){
            alert("Please enter a meal name")
        }else if(this.props.foods_list.length===0){
            alert("Please enter at least one food")
        }else{
            if(this.state.action_type==='edit'){
                this.props.addEditedMeal(this.props)
                this.setState({show_success_message:true})
            }else{
                this.props.addNewMeal(this.props)
                this.setState({show_success_message:true})
            }
            sleep(3000).then(()=>{
                this.setState({show_success_message:false})
            })
        }

    }
}

    renderInputForm =() =>{
        if(this.state.item_type==='recipe'){
            return(
                <div>
                    <TextField 
                    InputLabelProps ={{
                        shrink:true
                    }}
                    value={this.props.recipe_name}
                    size="small"
                    margin="none"
                        placeholder="Recipe Name"
                        style={{
                            width:"100%"
                        }}
                        variant="outlined"
                        onChange={(event)=>{this.props.updateRecipeField({recipe_name:event.target.value})}}
                    />
                    <TextField 
                    size="small"
                        value={this.props.recipe_description}
                        placeholder="Recipe Description"
                        style={{
                            width:"100%",
                            marginTop:5,
                        }}
                        variant="outlined"
                        onChange={(event)=>{this.props.updateRecipeField({recipe_description:event.target.value})}}
                    />
                    <div
                        style={{display:"flex", marginTop:5}}
                    >
                        <TextField 
                            InputLabelProps ={{
                                shrink:true
                            }}
                            value={this.props.yield}
                            label={"Yield"}
                            placeholder="Servings"
                            variant="outlined"
                            onChange={(event)=>{this.props.updateRecipeField({yield:parseInt(event.target.value)})}}
                            style={{
                            }}
                        />
                        <TextField
                            InputLabelProps ={{
                                shrink:true
                            }}
                            value={this.props.cook_time_hours}
                            label={"Cook Time"}
                            placeholder="Hours"
                            variant="outlined"
                            onChange={(event)=>{this.props.updateRecipeField({cook_time_hours:event.target.value})}}
                            style={{
                            }}  
                        />
                        <TextField
                        InputLabelProps ={{
                            shrink:true
                        }}
                            value={this.props.cook_time_minutes}
                            label={"Cook Time"}
                            placeholder="Mins"
                            variant="outlined"
                            onChange={(event)=>{this.props.updateRecipeField({cook_time_minutes:event.target.value})}}
                            style={{
                            }}  
                        />
                    </div>
                    <div>
                        <Button 
                            variant="contained"
                            component="label"                            
                        >
                            Upload Image 
                            {/* <Typography 
                            variant="caption"
                                style={{
                                    fontSize:10
                                }}
                            > (300 x 300)</Typography> */}
                            <input 
                                type="file"
                                style={{
                                    display:"none"
                                }}
                                onChange={this.onImageUpload}
                            />
                        </Button>
                        <img 
                            style={{
                                display:this.props.recipe_image_url?"":"none",
                                height:65,
                                width:65,
                                margin:"auto",
                            }}
                            src={this.props.recipe_image_url}
                        />
                        <span 
                            style={{
                                display:this.props.recipe_image?"none":"",
                            }}
                        >
                           <Typography 
                            variant="caption"
                            style={{
                                color:"darkGrey"
                            }}
                           >
                                    No Image Selected...
                               </Typography>
                        </span>
                    </div>
                    <div
                        style={{
                            marginTop:20,
                        }}                    
                    >
                    <InputBase
                    style={{"verticalAlign":"bottom",
                        paddingLeft:3,
                        width:"85%",
                    }}
                    placeholder={"Search Ingredients"}
                    fullWidth={false}
                    onChange={(event)=>{this.setState({ingred_search_qry:event.target.value})}}
                    />
                    <IconButton
                        size= {"small"}
                        onClick={this.getIngredSearchResult}
                    >
                        <SearchIcon/>
                    </IconButton>    
                    <div
                        style={{
                            display:this.props.loadingIngredSearch?"":"none",
                            width:"100%",
                            textAlign:"center",
                        }}
                    >
                        <CircularProgress style={{margin:"auto"}}/>
                    </div>
                    <div
                        style={{
                            display:this.props.hasIngredSearch?"":"none",
                            maxHeight:100,
                            border:"1 px solid darkGrey",
                            overflowY:"scroll",
                            scrollbarWidth:"thin",
                        }}
                    >
                        {/* <Typography variant="subtitle1">Ingredients</Typography>   */}
                        <Table
                            size="small"
                            padding="none"                        
                        >
                            <TableBody>
                            {this.props.ingredSearchResults.map((result, idx)=>(
                                <TableRow 
                                    key={result.food_key}
                                >
                                    <TableCell>
                                        <IconButton
                                             size="small"
                                             edge="end"    
                                             onClick={(event)=>{this.handleAddIngredClick(idx)}}                                   
                                        >
                                            <AddIcon 
                                                fontSize="small"
                                            />
                                        </IconButton>
                                    </TableCell>
                                    <TableCell>
                                        <IconButton
                                            size="small"
                                            edge="end"
                                            onClick={(event)=>{this.handleModalClick(result.food_key)}}
                                        >
                                        <InfoIcon
                                            fontSize="small"
                                        />
                                        </IconButton>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>
                                            {result.food_description}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>
                                            {result.serving_size_val}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>
                                            {result.serving_size_unit}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </div>
                                      {/* Ingredient Table */}
                    <div
                        style={{
                            display:this.props.ingredients_list.length>0?"":"none",
                        }}
                    >
                        <Typography variant="subtitle1">Ingredients</Typography>
                        <Table
                            size="small"
                            padding="none"                           
                        >
                            <TableBody>
                            {this.props.ingredients_list.map((result,idx)=>(
                            <TableRow
                                key={result.food_key}
                            >
                                <TableCell>
                                    <IconButton
                                        size="small"
                                        edge="end"
                                        onClick={(event)=>{this.handleRemoveIngredClick(idx)}}
                                    >
                                        <DeleteIcon
                                            fontSize="small"
                                        />
                                    </IconButton>
                                </TableCell>
                                <TableCell>
                                    <TextField 
                                        label="Amt"
                                        style={{width:50,}}
                                        value={result.amt}
                                        onChange={(event)=>{this.changeIngredAmt(idx, event.target.value)}}
                                        inputProps={{style: {fontSize: 12, width:"100%"}}} 
                                    />
                                </TableCell>
                                <TableCell>
                                    <Select
                                        label="Unit"
                                        value={result.serving_size_idx}
                                        name="Serving Size Unit"
                                        onChange = {(event)=>{this.changeServingSizeIndex(idx,event.target.value)}}
                                        multiple={false}
                                        renderValue={(value)=>{return result.serving_sizes.ss_units[value]}}
                                        >
                                        {result.serving_sizes.ss_units.map( (unit, idx) => (
                                            <MenuItem
                                                value={idx}
                                                key={unit}
                                            >{unit}
                                            </MenuItem>
                                        ))}
                                    >
                            
                                    </Select>
                                    {result.food_description}
                                </TableCell>
                                <TableCell>
                                    {result.serving_sizes.ss_amts[result.serving_size_index]}
                                </TableCell>
                                <TableCell>
                                    {result.serving_sizes.ss_units[result.serving_size_index]}
                                </TableCell>
                            </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </div>
                    </div>
                   {/* Instructions Div */}
                   <div
                        style={{
                            marginTop:20,
                        }}                   
                   >
                            <Typography 
                                variant="subtitle1" 
                                style={{color:"darkGrey"}}
                            >Instructions</Typography>
                        <div
                            style={{
                                display:"flex",
                                justifyContent:"stretch",
                            }}
                        >
                            <IconButton
                                size="small"
                                edge="end"
                                onClick={this.addInstruction}
                            >
                                <AddIcon 
                                    fontSize="small"
                                />
                            </IconButton>
                            <TextField
                                style={{
                                    width:"95%",
                                }}
                                multiline={true}
                                onChange={(event)=>{this.setState({instructions_input:event.target.value})}}
                            />
                        </div>
                        <div>
                            <Table
                                size="small"
                                padding="none"
                                style={{
                                    display:this.props.instructions_list.length===0?"none":"",
                                }}
                            >
                                <TableBody>
                                {this.props.instructions_list.map((instr, idx)=>(
                                    <TableRow
                                        key={instr}
                                    >
                                        <TableCell>
                                            <IconButton
                                                size="small"
                                                onClick={(event)=>{this.deleteInstruction(idx)}}
                                            >
                                                <DeleteIcon 
                                                    fontSize="small"
                                                />
                                            </IconButton>
                                        </TableCell>
                                        {/* <TableCell>
                                            <IconButton 
                                                size="small"
                                                onClick={(event)=>{this.editInstruction(idx)}}
                                            >
                                                <EditIcon 
                                                    fontSize="small"
                                                />
                                            </IconButton>
                                        </TableCell> */}
                                        <TableCell>
                                            <Typography
                                                variant="caption"
                                            >
                                            {instr}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                            </Table>
                        </div>

                    </div>
                    <div
                        style={{
                            marginTop:20,
                        }}
                    >
                        <Typography variant="subtitle1"
                            style={{
                                color:"darkGrey"
                            }}
                        >AutoPlanner Default Settings</Typography>
                        <div
                            style={{
                                display:"flex",
                                justifyContent:"space-between",
                                
                            }}
                        >
                            <TextField 
                                value={this.props.max_servings}
                                size="small"
                                variant="outlined"
                                label="Max Servings"
                                placeholder={"x 1 serving"}
                                style={{
                                    width:"45%"
                                }}
                                onChange={(event)=>{this.props.updateRecipeField({max_servings:event.target.value})}}
                            />  
                            <Select
                                value={this.props.default_dish_num}
                                size="small"
                                style={{
                                    width:"45%",
                                }}
                                onChange={(event)=>{this.props.updateRecipeField({default_dish_num:event.target.value})}}
                                inputProps={{
                                        style:{
                                            textAlign:"center"
                                        },
                                }}
                            >
                                <MenuItem
                                    value={"Whole Meals"}
                                    style={{
                                        textAlign:"center",
                                    }}
                                >
                                    Whole Meals
                                </MenuItem>
                                <MenuItem
                                    value={"Main Courses"}
                                    style={{
                                        textAlign:"center",
                                    }}
                                >
                                    Main Courses
                                </MenuItem>
                                <MenuItem
                                    value={"Sides"}
                                    style={{
                                        textAlign:"center",
                                    }}
                                >
                                    Sides
                                </MenuItem>
                            </Select>
                        </div>
                        <div>
                        </div>
                    </div>

                </div>
            )
        }else{
            return(
                <div>
                    <TextField
                        variant="outlined"
                        placeholder="Meal Name"
                        style={{
                            width:"100%"
                        }}
                        value={this.props.mealname}
                        onChange={this.changeMealName}
                    />
                    <div
                        style={{
                            marginTop:20,
                        }}                    
                    >
                    <InputBase
                    style={{"verticalAlign":"bottom",
                        paddingLeft:3,
                        width:"85%",
                    }}
                    placeholder={"Search Foods"}
                    fullWidth={false}
                    onChange={(event)=>{this.setState({ingred_search_qry:event.target.value})}}
                    />
                    <IconButton
                        size= {"small"}
                        onClick={(event)=>{this.props.searchMealFoods(this.state.ingred_search_qry)}}
                    >
                        <SearchIcon/>
                    </IconButton>    
                    <div
                        style={{
                            display:this.props.loadingMealFoodSearch?"":"none",
                            width:"100%",
                            textAlign:"center",
                        }}
                    >
                        <CircularProgress style={{margin:"auto"}}/>
                    </div>
                    <div
                        style={{
                            display:this.props.hasIngredSearch?"":"none",
                            maxHeight:100,
                            border:"1 px solid darkGrey",
                            overflowY:"scroll",
                            scrollbarWidth:"thin",
                        }}
                    >
                        {/* <Typography variant="subtitle1">Ingredients</Typography>   */}
                        <Table
                            size="small"
                            padding="none"                        
                        >
                            <TableBody>
                            {this.props.ingredSearchResults.map((result, idx)=>(
                                <TableRow 
                                    key={result.food_key}
                                >
                                    <TableCell>
                                        <IconButton
                                             size="small"
                                             edge="end"    
                                             onClick={(event)=>{this.handleAddIngredClick(idx)}}                                   
                                        >
                                            <AddIcon 
                                                fontSize="small"
                                            />
                                        </IconButton>
                                    </TableCell>
                                    <TableCell>
                                        <IconButton
                                            size="small"
                                            edge="end"
                                            onClick={(event)=>{this.handleModalClick(result.food_key)}}
                                        >
                                        <InfoIcon
                                            fontSize="small"
                                        />
                                        </IconButton>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>
                                            {result.food_description}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>
                                            {result.serving_size_val}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>
                                            {result.serving_size_unit}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </div>
                                      {/* Ingredient Table */}
                    <div
                        style={{
                            display:this.props.foods_list.length>0?"":"none",
                        }}
                    >
                        <Typography variant="subtitle1">Foods</Typography>
                        <Table
                            size="small"
                            padding="none"                           
                        >
                            <TableBody>
                            {this.props.foods_list.map((result,idx)=>(
                            <TableRow
                                key={result.food_key}
                            >
                                <TableCell>
                                    <IconButton
                                        size="small"
                                        edge="end"
                                        onClick={(event)=>{this.handleRemoveMealFoodClick(idx)}}
                                    >
                                        <DeleteIcon
                                            fontSize="small"
                                        />
                                    </IconButton>
                                </TableCell>
                                {/* add icon button */}
                                <TableCell>
                                    <TextField 
                                        label="Amt"
                                        style={{width:50,}}
                                        value={result.amt}
                                        onChange={(event)=>{this.changeMealFoodItemAmt(idx, event.target.value)}}
                                        inputProps={{style: {fontSize: 12, width:"100%"}}} 
                                    />
                                </TableCell>
                                <TableCell>
                                    <Select
                                        label="Unit"
                                        value={result.serving_size_idx}
                                        name="Serving Size Unit"
                                        onChange = {(event)=>{this.changeMealFoodServingSizeIndex(idx,event.target.value)}}
                                        multiple={false}
                                        renderValue={(value)=>{return result.serving_sizes.ss_units[value]}}
                                        >
                                        {result.serving_sizes.ss_units.map( (unit, idx) => (
                                            <MenuItem
                                                value={idx}
                                                key={unit}
                                            >{unit}
                                            </MenuItem>
                                        ))}
                                    >
                            
                                    </Select>
                                    {result.food_description}
                                </TableCell>
                                <TableCell>
                                    {result.serving_sizes.ss_amts[result.serving_size_index]}
                                </TableCell>
                                <TableCell>
                                    {result.serving_sizes.ss_units[result.serving_size_index]}
                                </TableCell>
                            </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </div>
                    <div
                        style={{
                            width:"100%",
                            display:"flex",
                            justifyContent:"space-between",
                        }}
                    >
                        <Typography
                        // align="center"
                        variant="subtitle1"
                        style={{
                            color:"darkGrey",
                            textAlign:"right",
                        }}
                        >
                            Default Meal:
                        </Typography>
                    <Select
                                value={this.props.default_meal}
                                size="small"
                                style={{
                                    width:"45%",
                                }}
                                onChange={(event)=>{this.props.updateMealField({default_meal:event.target.value})}}
                                inputProps={{
                                        style:{
                                            textAlign:"center"
                                        },
                                }}
                            >
                                <MenuItem
                                    value={"Breakfast"}
                                    style={{
                                        textAlign:"center",
                                    }}
                                >
                                    Breakfast
                                </MenuItem>
                                <MenuItem
                                    value={"Lunch"}
                                    style={{
                                        textAlign:"center",
                                    }}
                                >
                                    Lunch
                                </MenuItem>
                                <MenuItem
                                    value={"Dinner"}
                                    style={{
                                        textAlign:"center",
                                    }}
                                >
                                    Dinner
                                </MenuItem>
                            </Select>
                        </div>
                    </div>
                </div>
            )
        }

    }

    changeMealName = (event) =>{
        this.props.updateMealField({mealname:event.target.value})
    }

    handleRemoveMealFoodClick = (idx) =>{
        this.props.removeMealFood(idx)
    }

    changeMealFoodItemAmt = (idx, new_amt) =>{
        this.props.changeMealFoodAmt(idx, new_amt)
    }

    changeMealFoodServingSizeIndex = (list_idx, new_ss_idx) =>{
        this.props.changeMealServingSizeIndex(list_idx, new_ss_idx)
    }
    
    render(){   
        return (
            <div>
                <NavBar

                />
                <div
                    style={{
                        width:320,
                        margin:"auto"
                    }}
                >
                    <div
                        style={{
                            display:"flex",
                            flexDirection:"column"
                            // justifyContent:"space-between",
                        }}
                    >
                    <div
                        style={{
                            
                        }}                    
                    >
                    <RadioGroup
                        
                        value={this.state.action_type}
                        onChange={(event, value)=>{this.changeActionState(value)}}
                        style={{
                            display:"flex",
                             flexDirection:"row",
                             alignItems:"center",
                             justifyContent:"center",
                        }}
                    >
                        <FormControlLabel
                            style={{fontSize:12,}}
                            size="small"
                            value="lb"
                            control={<Radio/>}
                            label="Add New"
                            value={"add_new"}
                        />
                        <FormControlLabel
                            style={{fontSize:12,}}
                            size="small"
                            value="lb"
                            control={<Radio/>}
                            label="Edit"     
                            value={"edit"}                  
                        />
                        <FormControlLabel
                            style={{
                                fontSize:12,
                            }}       
                            size="small"
                            value="lb"
                            control={<Radio/>}
                            label="Clone"              
                            value={"clone"}                  
                       />
                    </RadioGroup>
                    </div>
                    <div
                        style={{
                        }}
                    >
                    <RadioGroup
                        size="small"
                        value={this.state.item_type}
                        onChange={(event, value)=>{this.changeItemType(value)}}
                        exclusive={true}
                        style={{
                            display:"flex",
                            flexDirection:"row", 
                            alignItems:"center",
                            justifyContent:"center",
                        }}
                    >
                        <FormControlLabel
                        size="small"
                            value={"recipe"}
                            style={{
                                
                            }}       
                            control={<Radio/>}
                            label={"Recipe"}                    
                        />
                        <FormControlLabel
                        size="small"
                            value={"meal"}
                            style={{
                                
                            }}     
                            control={<Radio/>}
                        label={"Meal"}         
                        />
                    </RadioGroup>
                    </div>
                    </div>
                     <div>
                        {this.renderItemSearch()}
                        {this.renderInputForm()}
                        <Button 
                            style={{
                                width:"100%",
                                color:"var(--sec_white)",
                                backgroundColor:"var(--main_bright)"
                            }}
                        onClick={this.saveEntry}
                        >Save</Button>
                    </div>
                    <div
                        style={{
                            display:this.state.show_success_message?"":"none",
                        }}
                    >
                        <Typography
                            style={{
                                color:"darkGreen"
                            }}
                            variant="subtitle2"
                        >
                            {this.state.item_type+" saved successfully!"}
                        </Typography>
                    </div>
                </div>
                <FoodModal />
            </div>
        ) 
    }

}
const mapStateToProps = (state, ownProps) => {
    // Leaving space in case logic is needed here
    
        return ({
            // ingredient search
            hasIngredSearch:state.searchWidgets.ingredientSearch.hasSearchResults,
            loadingIngredSearch:state.searchWidgets.ingredientSearch.isLoading,
            ingredSearchResults:state.searchWidgets.ingredientSearch.results_list,
            // item search
            // recipes
            hasRecipeSearch:state.searchWidgets.recipeSearch.hasSearchResults,
            loadingRecipeSearch:state.searchWidgets.recipeSearch.isLoading,
            recipeSearchResults:state.searchWidgets.recipeSearch.results_list,
            // meals
            hasMealSearch:state.searchWidgets.mealSearch.hasSearchResults,
            loadingMealSearch:state.searchWidgets.mealSearch.isLoading,
            mealSearchResults:state.searchWidgets.mealSearch.results_list,
            // recipe fields
            food_key:state.searchWidgets.singleRecipeSearch.food_key,
            recipe_name:state.searchWidgets.singleRecipeSearch.recipe_name,
            recipe_description:state.searchWidgets.singleRecipeSearch.recipe_description,
            yield:state.searchWidgets.singleRecipeSearch.yield,
            serving_description:state.searchWidgets.singleRecipeSearch.serving_description,
            cook_time_hours:state.searchWidgets.singleRecipeSearch.cook_time_hours,
            cook_time_minutes:state.searchWidgets.singleRecipeSearch.cook_time_minutes,
            ingredients_list:state.searchWidgets.singleRecipeSearch.ingredients_list,
            instructions_list:state.searchWidgets.singleRecipeSearch.instructions_list,
            recipe_image_url:state.searchWidgets.singleRecipeSearch.recipe_image_url,
            recipe_image:state.searchWidgets.singleRecipeSearch.recipe_image,
            max_servings:state.searchWidgets.singleRecipeSearch.max_servings,
            default_dish_num:state.searchWidgets.singleRecipeSearch.default_dish_num,
            // Meal Search
            meal_id:state.searchWidgets.singleMealSearch.meal_id,
            mealname:state.searchWidgets.singleMealSearch.mealname,
            foods_list:state.searchWidgets.singleMealSearch.foods_list,
            default_meal:state.searchWidgets.singleMealSearch.default_meal,
            // hasMealFoodSearch:state.searchWidgets.mealFoodSearch.hasSearchResults,
            // loadingMealFoodSearch:state.searchWidgets.mealFoodSearch.isLoading,
            // mealSearchResults:state.searchWidgets.mealFoodSearch.results_list,
            // Meal Fields
        })
}
export default connect(mapStateToProps, {openFoodModal,
                                    searchIngredients,
                                    addNewRecipe,
                                    searchRecipesEdit,
                                    getRecipe,
                                    updateRecipeField,
                                    updateRecipeIngredientsField,
                                    removeRecipeIngredient,
                                    changeIngredientAmt,
                                    changeRecipeServingSizeIndex,
                                    addRecipeInstruction,
                                    deleteRecipeInstruction,
                                    addEditedRecipe,
                                    searchRecipesClone,
                                    searchMealFoods,
                                    getMeal,
                                    searchMeals,
                                    removeMealFood,
                                    changeMealFoodAmt,
                                    changeMealServingSizeIndex,
                                    updateMealFoodsField,
                                    addNewMeal,
                                    addEditedMeal,
                                    updateMealField,
                                })(InputPage);