import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Input,
    TextField,
    Dialog,
    DialogTitle,
    Button,
    Typography,
}from '@material-ui/core/';
import {toggleMealNameDialog,helpTextMealNameDialog,changeMealNameInDialog} from '../../actions/dialog';
import {addNewMeal} from '../../actions/input';

const sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
}

class MealNameDialog extends Component{
// props - circle/square, image
    constructor(props){
        super(props)
        this.state = {
            mealname:null,
            open:false
        } 
    }

    onChangeInput = (event)=>{
        this.props.changeMealNameInDialog(event.target.value)
    }

    handleClose=  (event)=>{
        this.props.toggleMealNameDialog(false, 'day1','Breakfast')
    }

    saveName = ()=>{
        // close modal
        // check if meal is empty
        if((this.props.mealname==='')||(this.props.mealname===null)){
            this.props.helpTextMealNameDialog(true, 'Please Enter A Name')
        }else if(this.props.foods_list.length===0){
            this.props.helpTextMealNameDialog(true, 'Meal Cannot Be Empty. Please Close And Add At Least One Food')
            // close modal
        }else{
            this.props.addNewMeal({
                mealname:this.props.mealname,
                foods_list:this.props.foods_list,
                default_meal: this.props.meal_type,
            })
            this.props.helpTextMealNameDialog(true, 'Meal Saved!')
            sleep(2500)
            this.props.helpTextMealNameDialog(false, null)
            this.props.changeMealNameInDialog(null)
            this.props.toggleMealNameDialog(false, 'day1', 'Breakfast') //defaults
        }
        // post to server
        // show success message
    }

    render(){        
        return (
            <Dialog
                open={this.props.open}
                onClose={(event)=>{this.handleClose()}}
            >
                <DialogTitle>
                    Please Provide A Meal Name:
                </DialogTitle>
                <div>
                <TextField
                    style={{
                        width:"100%",
                    }}
                    placeholder={'Meal Name'}
                    onChange={this.onChangeInput}
                    value={this.props.mealname}
                />
                <Typography
                    variant="subtitle1"
                   style={{
                        width:"100%",
                        color:"darkGrey",
                        display:this.props.show_help_text?"":"none",
                    }}
                >
                    {this.props.help_text}
                </Typography>
                <Button 
                    style={{
                        width:"100%"
                    }}
                    onClick={this.saveName}
                >Save</Button>
                </div>
            </Dialog>
        )
    }
}

const mapStateToProps = (state,ownProps) => {
    let meal_type = state.dialog.mealname_dialog.meal_type
    let plannum = state.dialog.mealname_dialog.plannum
return {
    meal_type: meal_type,
    plannum: plannum,
    foods_list:state.columns.present[plannum][meal_type].foods_list,
    mealname:state.dialog.mealname_dialog.mealname,
    open:state.dialog.mealname_dialog.open,
    show_help_text: state.dialog.mealname_dialog.show_help_text,
    help_text: state.dialog.mealname_dialog.help_text,
}
}

export default connect(mapStateToProps,{toggleMealNameDialog,
                        helpTextMealNameDialog,
                        addNewMeal,
                        changeMealNameInDialog,
                    })(MealNameDialog);