import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';
import {
    Toolbar,
    Checkbox,
    Typography
} from '@material-ui/core/';
import {Droppable, Draggable} from 'react-beautiful-dnd';
import FoodObj from './food';


const useStyles = theme =>({
    //logic about mobile menu vs reg menu goes here
        meal_div:{
            margin:"0 auto"
        },
        meal_food_list:{
            backgroundColor: "var(--sec_mid)",
            minHeight:100,
            minWidth:400,
            margin:"auto",
        }
})


class MealClone extends Component{
    /*
    Props: 
        * mealtype
        * default menu id
    Methods:
        * 
    */
   constructor(props){
    super(props)
    this.state = {
        mealnum: this.props.mealnum,
        meal_type:this.props.meal_type
      }
}
    render() {
        /*
        Meal header
        Plan filter list
        An outer div with a fixed width
        Inner div with scrollable overflow and list of 
        */
       const {classes} = this.props
        return (      
            <div>
            <Typography>{this.props.meal_type}</Typography>
            <div
            style={{}}
            >   
                <div
                    style={{
                            backgroundColor: "var(--sec_mid)", 
                            height:100, 
                            verticalAlign:"middle",
                            margin:"0 auto",
                            display:"flex",
                            padding:"grid",
                            minWidth:400,
                            overflow:"auto",
                            scrollbarWidth:"thin"
                    }}
                >
                {this.props.foods_list.map( (result, idx) => (
                    <React.Fragment
                            >
                            <div
                                style={{
                                    backgroundColor:"var(--sec_light)"
                                }}
                            >
                                <FoodObj 
                                    food_dict = {result}
                                    amt = {result['amt']}
                                    serving_size_idx = {result['serving_size_idx']}
                                    component_num ={0}
                                    col={this.props.plannum}
                                    subcol={this.props.meal_type}
                                    drag_idx = {idx}
                                    isChangeDisabled={true}
                                />
                            </div>
                    </React.Fragment>
                )
                )}
                </div>
            </div>   
            </div>
        )
    }
}


export default (withStyles(useStyles)(MealClone));