import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Input,
    TextField,
    Typography,
}from '@material-ui/core/';
import {updateCalReqFromSlider,saveUserStat} from '../../actions/nutrients';


class CalorieConfirm extends Component{
// props - circle/square, image
    constructor(props){
        super(props)
        this.state = {
        } 
    }

    updateTargetCal = (value) =>{
        this.props.saveUserStat({daily_cal:value})
        let upper = value*1.05
        let lower = value*0.95
        let reqs_list = [lower, upper]
        this.props.updateCalReqFromSlider(reqs_list)
    }

    updateBound = (value, type)=>{
        let reqs_list = [value, this.props.cal_ub]
        if(type==='upper'){
            reqs_list = [this.props.cal_lb, value]
        }
        this.props.updateCalReqFromSlider(reqs_list)
    }

    render(){        
        return (
            <div
                style={{
                    display:"flex",
                    flexDirection:"column",
                    justifyContent:"center",
                }}
            >

            <div
                style={{
                    display:"flex",
                    // flexDirection:"column",
                    justifyContent:"space-evenly",
                    margin:5,
                    marginTop:20,
                    marginBottom:10,
                }}
            >
                <TextField
                
                size="small"
                    variant="outlined"
                    style={{
                        width:75
                    }}
                    label={"Lower"}
                    value={this.props.cal_lb}
                    onChange={(event)=>{this.updateBound(event.target.value, "lower")}}
                />
                <TextField 
                    size="small"
                    style={{
                        width:75,
                        marginBottom:5,
                        margin:"auto"
                    }}
                    variant="outlined"
                    label="Target Cal"
                    inputProps={{
                        style:{
                            textAlign:"center"
                        }
                    }}
                    value={this.props.daily_cal}
                    onChange={(event)=>{this.updateTargetCal(event.target.value)}}
                />
                <TextField
                    variant="outlined"
                    size="small"
                    style={{
                        width:75
                    }}
                    label={"Upper"}
                    value={this.props.cal_ub}
                    onChange={(event)=>{this.updateBound(event.target.value, "upper")}}
                />
            </div>
            <div>
                <Typography
                    style={{
                        color:"grey",
                        marginBottom:10,
                    }}                
                >If that looks good, let's move on to macros.
                </Typography>
            </div>
            <div>
            </div>
            </div>
        )
    }
}

const mapStateToProps = (state,ownProps) => ({
    daily_cal:state.user.user_stats.daily_cal,
    cal_lb:state.user.requirements_dict.cal_lb,
    cal_ub:state.user.requirements_dict.cal_ub,
})

export default connect(mapStateToProps,{updateCalReqFromSlider,saveUserStat})(CalorieConfirm);