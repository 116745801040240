import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Input, Divider,
}from '@material-ui/core/';
import {withStyles} from '@material-ui/core/styles';
// import SimpleSolveGif from 'https://planyourmealsmedia.s3.amazonaws.com/landing_page/single_day_solve_reframed.gif';
// import MenuGif from 'https://planyourmealsmedia.s3.amazonaws.com/landing_page/menu_1.gif';
// import AdjustAmountsGif from 'https://planyourmealsmedia.s3.amazonaws.com/landing_page/adjust_amounts_better.gif';
// import AlternativesGif from 'https://planyourmealsmedia.s3.amazonaws.com/landing_page/alternatives.gif';
// import ResolveGif from 'https://planyourmealsmedia.s3.amazonaws.com/landing_page/solve_w_2_resolves.gif';
// import RestaurantsGif from 'https://planyourmealsmedia.s3.amazonaws.com/landing_page/restaurants_two_resolves.gif';
// import ManualDnD from 'https://planyourmealsmedia.s3.amazonaws.com/landing_page/manual_drag_and_drop.gif';
// import LeftoversGif from 'https://planyourmealsmedia.s3.amazonaws.com/landing_page/leftovers_2.gif'

// import 
import DemoModule from './demo_module';

const useStyles = theme=> ({
    demo_page1:{
        // height:"100vh",
        width:"100vw",
        // backgroundColor:"lightGrey",
    },
})
class DemoPage1 extends Component{
// props - circle/square, image
    constructor(props){
        super(props)
        this.state = {
        } 
    }

    render(){      
        const {classes} = this.props 
        return (
            <div
                className={classes.demo_page1}
            >
                <div
                    style={{
                        display:"flex",
                        justifyContent:"space-evenly",
                        flexWrap:"wrap",
                    }}
                >
                    <DemoModule 
                        title = {"Automatic Meal Planning, Focused on Flexibility"}
                        subtitle1 ={"Considers 1000s of Possible Combinations"}
                        subheader1 = {"Planyourmeals is an arranges foods and their portions in the most optimal way to fit your nutrient goals. Plan up to seven days at a time."}
                        demo_gif1 = {'https://planyourmealsmedia.s3.amazonaws.com/landing_page/single_day_solve_reframed.gif'}
                        width1 = {225}
                        width2 = {240}                        
                        bg_col={"var(--sec_contrast)"}
                        subtitle2={"Allows complete control over suggestions"}
                        subheader2={"Each menu is 100% customizeable and users can enter their own recipes and saved meals."}
                        demo_gif2 = {'https://planyourmealsmedia.s3.amazonaws.com/landing_page/menu_1.gif'}
                        width2 = {240}
                    />
                    <DemoModule 
                        title = {"Plans Authored By You - Perfected By Us"}
                        subtitle1={"Easily Build Your Plans Manually"}
                        subheader1 = {"Search foods or whole meals and drag And drop accross meals and days."}
                        demo_gif1 = {'https://planyourmealsmedia.s3.amazonaws.com/landing_page/manual_drag_and_drop.gif'}
                        subtitle2={"Perfect Your Portions With The Adjustor Tool"}
                        subheader2 = {"If you know what you want for every meal, the adjustor tool can help fix the proportions to still meet your nutrition goals."}
                        demo_gif2 = {'https://planyourmealsmedia.s3.amazonaws.com/landing_page/adjust_amounts_better.gif'}
                        bg_col={"var(--sec_mid)"}
                        width1 = {225}
                        width2 = {225} 
                    />
                    <DemoModule 
                        title = {"See Multiple Options Beyond The First Suggestion"}
                        subtitle1 = {"See All Alternatives For a Given Food"}
                        subheader1 = {"PLM will give you all alternatives from your menu that fit in place of a given food. If none exist, it will show the five closest in nutritional properties."}
                        demo_gif1 = {'https://planyourmealsmedia.s3.amazonaws.com/landing_page/alternatives.gif'}
                        subtitle2={"Run Through Multiple Plan Suggestions"}
                        subheader2 = {"Hit the suggestions button again to see if there are more plans that fit your needs. Don't worry, you can always revert back to the original plan."}
                        demo_gif2 = {'https://planyourmealsmedia.s3.amazonaws.com/landing_page/solve_w_2_resolves.gif'}
                        bg_col={"var(--sec_contrast)"}
                        width1 = {240}
                        width2 = {225} 

                    />             
                    <DemoModule 
                        title = {"Handle Restaurants, Leftovers and More With Ease"}
                        subtitle1={"Eating Out? Easily Incorporate Restaurants"}
                        subheader1 = {"Quickly lookup restaurants and swap out your default menu for theirs."}
                        demo_gif1 = {'https://planyourmealsmedia.s3.amazonaws.com/landing_page/restaurants_two_resolves.gif'}
                        subtitle2={"Easily Handles Leftovers"}
                        subheader2 = {"Select the meal(s) you want and the planner will make sure they"}
                        demo_gif2 = {'https://planyourmealsmedia.s3.amazonaws.com/landing_page/leftovers_2.gif'}
                        bg_col={"var(--sec_mid)"}
                        width1 = {225}
                        width2 = {400} 
                    />
                </div>
            </div>
        )
    }
}



export default withStyles(useStyles)(DemoPage1);