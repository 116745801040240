import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
    Avatar,
    Button,
    Dialog,
    Checkbox,
    DialogTitle,
    Divider,
    FormControlLabel,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    ListItemSecondaryAction,
    Radio,
    RadioGroup,
    Typography,
}from '@material-ui/core/';
import {closeAltsDialog} from '../../actions/autoplan';
import {swapAlt} from '../../actions/dialog';
import ClearIcon from '@material-ui/icons/Clear';
import FastfoodIcon from '@material-ui/icons/Fastfood';


class FoodAltsDialog extends Component{
// props - circle/square, image
    constructor(props){
        super(props)
        this.state = {
            item_idx:0,
            list_type:this.props.list_type,
        } 
    }

    handleClose = () =>{
        this.props.closeAltsDialog()
    }

    onClickSwap =()=>{
        this.props.swapAlt(this.state.item_idx, this.props.list_type)
        this.props.closeAltsDialog()
    }

    changeSelection =(value, list_type)=>{
        this.setState({item_idx:value,
                        list_type:list_type})
    }

    render(){       
        return (
            <Dialog
                open={this.props.open}
                onClose={(event)=>{this.handleClose()}}
                style={{
                    width:"100%",
                    // width:320,
                    margin:"auto",
                }}
            >
                <DialogTitle

                >
                    <div
                    style={{
                        display:"flex",
                        justifyContent:"space-between",
                        width:"100%",
                    }}
                    >
                    <IconButton
                        onClick={(event)=>{this.handleClose()}}
                    >
                        <ClearIcon

                        />
                    </IconButton>
                    {/* <Typography>
                        Alternatives
                    </Typography> */}
                    <Button
                        variant="contained"
                        style={{
                            backgroundColor:"var(--main_bright)",
                            color:"var(--sec_white)",
                            width:100,
                        }}
                        onClick={this.onClickSwap}
                    >
                        Swap
                    </Button>
                    </div>
                </DialogTitle>
                <div
                    style={{
                        display: this.props.alts_menu_list.length===0? "":"none",
                    }}
                >
                    <Typography>
                        Sorry, No Alternatives Found In Your Menu
                    </Typography>

                </div>
                <div
                    style={{
                        display: this.props.alts_menu_list.length===0? "none":"",
                    }}
                >
                <Typography
                    style={{
                        margin:"auto",
                    }}
                >
                    Alternatives From Your Menu
                </Typography>

                <List
                >
                    {this.props.alts_menu_list.map((food_dict, idx)=>(
                        <div>
                        <ListItem>
                        <ListItemSecondaryAction>
                            <Radio
                                onChange={(event)=>{this.changeSelection(event.target.value,'alts_menu_list')}}
                                checked={this.state.item_idx==idx}
                                value={idx}
                                style={{
                                    color:"var(--main_bright)",
                                }}
                            />
                            </ListItemSecondaryAction>
                            <ListItemAvatar>
                                <Avatar 
                                    alt={"F"}// some kind of food icon
                                    src={food_dict.image_url}
                                />
                            </ListItemAvatar>
                            <div
                                style={{
                                    overflow:'ellipsis',
                                    textOverflow:'ellipsis',
                                    whiteSpace:"nowrap",
                                    width:"60%",                                    
                                }}
                            >
                            <ListItemText 
                                style={{
                                    overflow:'ellipsis',
                                    textOverflow:'ellipsis',
                                    whiteSpace:"nowrap",
                                    width:"60%",
                                }}
                                primaryTypographyProps={{
                                    style:{
                                        overflow:'hidden',
                                        textOverflow:'ellipsis',
                                        whiteSpace:"nowrap",
                                        // width:"60%",
                                    }
                                }}
                                primary={food_dict.food_description}
                                secondary={food_dict.amt+" "+food_dict.serving_size_unit}
                            />
                            </div>
                        </ListItem>
                        <Divider/>
                        </div>
                    ))}
                </List>
                </div>
                <div
                    style={{
                        display:this.props.similar_list.length===0?"none":"",
                    }}
                >
                <Typography
                    style={{
                        margin:"auto",
                    }}
                    align="center"
                >
                    Most Similar Items From Your Menu
                </Typography>
                <List>
                {this.props.similar_list.map((food_dict, idx)=>{
                    // let mod_idx= idx+this.props.alts_menu_list.length
                    return(
                        <div>
                        <ListItem>
                        <ListItemSecondaryAction>
                            <Radio
                                onChange={(event)=>{this.changeSelection(event.target.value,'similar_list')}}
                                checked={this.state.item_idx==idx}
                                value={idx}
                                style={{
                                    color:"var(--main_bright)",
                                }}
                            />
                            </ListItemSecondaryAction>
                            <ListItemAvatar>
                                <Avatar 
                                    alt={<FastfoodIcon/>}// some kind of food icon
                                    src={food_dict.image_url}
                                />
                            </ListItemAvatar>
                            <ListItemText 

                                style={{
                                    overflow:'ellipsis',
                                    textOverflow:'ellipsis',
                                    whiteSpace:"nowrap",
                                    width:"60%",
                                }}
                                primaryTypographyProps={{
                                    style:{
                                        overflow:'ellipsis',
                                        textOverflow:'ellipsis',
                                        whiteSpace:"nowrap",
                                        width:"60%",
                                    }
                                }}
                                primary={food_dict.food_description}
                                secondary={food_dict.amt+" "+food_dict.serving_size_unit}
                            />

                        </ListItem>
                        <Divider/>
                        </div>
                    )})}
                </List>
                </div>
            </Dialog>
        )
    }
}

const mapStateToProps = (state,ownProps) => {
    let list_type = 'alts_menu_list'
    if(state.dialog.alternatives_dialog.similar_list.length>0){
        list_type = 'similar_list'
    }
    return{
    open:state.dialog.alternatives_dialog.open,
    alts_menu_list: state.dialog.alternatives_dialog.alts_menu_list,
    similar_list: state.dialog.alternatives_dialog.similar_list,
    alts_other_list: state.dialog.alternatives_dialog.alts_other_list,
    list_type:list_type,
}}

export default connect(mapStateToProps,{closeAltsDialog,swapAlt})(FoodAltsDialog);