import {combineReducers} from 'redux';
import undoable, { includeAction, excludeAction }  from 'redux-undo';
import auth from './auth';
import columns from './columns';
import user from './user';
import util from './util';
import modal from './modal';
import searchWidgets from './search_widget';
import dialog from './dialog';
import shopping_list from './shopping_list';
import menu_page from './menu_page';
import personal_profile from './personal_profile'

export default combineReducers({
    auth,
    columns:undoable(columns,{
        limit:10,
        filter:includeAction('UPDATE_WEEK_AUTOPLAN'),
        syncFilter:true,
    }),
    user,
    util,
    modal,
    searchWidgets,
    dialog,
    shopping_list,
    menu_page,
    personal_profile,
})
