import React, {Component} from 'react';
import {Button, 
    Input,
    TextField,
    Typography,
} from '@material-ui/core/';
import {Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import {fetchToken,fetchTokenFacebook} from '../../actions/auth';
import PasswordReset from './password_reset';
import {togglePasswordDialog} from '../../actions/dialog';


class Login extends Component{

  constructor(props){
      super(props)
      this.state = {
            username:'',
            password:''
        }
  }

  SaveUserNameInput = (event) =>{
    const uname = event.target.value
    this.setState({username:uname})
  }

  SavePasswordInput = (event)=>{
    const pword = event.target.value
    this.setState({password:pword})
  }

  onPressLogin = event =>{
    if(this.state.username===''){
      alert("Must Enter A Username :)")
    }
    else if(this.state.password===''){
      alert("Must Enter A Password :)")
    }
    else{
      // call login action
      event.preventDefault()
      this.props.fetchToken(this.state)
    }
  }

  loginFacebook = () =>{
    this.props.fetchTokenFacebook({})
  }

  render(){
  if(this.props.isAuthenticated){
    return <Redirect to="/plan" /> // was '/plan'
  }
  else{
    return (
      <div 
        style={{
            display:'flex', 
            flexDirection:'column', 
            justifyContent:'center',
            alignItems:'center'}}
    >
          <Input
            style={{width:"320px", marginBottom:"10px"}}
            placeholder="Username"
            onChange={this.SaveUserNameInput}
            value = {this.state.username}
          />
          <Input
            style={{width:"320px", marginBottom:"10px"}}
            type="password"
            placeholder="Password"
            onChange={this.SavePasswordInput}
            value = {this.state.password}
          />
          <Button
            variant="outlined"
            fullWidth={true}
            style= {{color:'white',
              backgroundColor:"var(--main_bright)", 
              fontWeight:"bold",
              textTransform:"none",
              fontSize:16,
              height:45,
            }}
            onClick={this.onPressLogin}
          >
          
            Login</Button>
          <div>
            {/* <Typography
              variant="subheader2"
            >
              Forgot your password? 
            </Typography> */}
            <Button
              onClick={(event)=>{this.props.togglePasswordDialog({open:true})}}
            >Forgot my password</Button>
            <PasswordReset />
          </div>
      </div>
      
    )
  }
  }
  componentDidMount(){
  }
}

//const Login_W_Router = withRouter(Login)

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
})

export default connect( mapStateToProps, {fetchToken,fetchTokenFacebook,togglePasswordDialog})(Login);