import React, {Component} from 'react';
import HomeBanner from './home_banner';
import {withStyles} from '@material-ui/core/styles';
import LPMenu from './lp_menu';
import DemoPage1 from './demo_page1';
import PricingPage from './pricing_page';
// import FitWomanPhone from '../auth/assets/images/fit_woman_phone_cropped.png';

class HomePage extends Component{
    render(){        
        return (
            <div
                style={{
                    display:"flex",
                    flexDirection:"column",
                    height:"100vh",
                }}
            >
                <LPMenu />
                <HomeBanner 
                    tagLine={'Millions Of Combinations At Your Fingertips'}
                    // heroImage={'https://planyourmealsmedia.s3.amazonaws.com/landing_page/fit_woman_phone_cropped.png'}
                />
                <DemoPage1 />
                <PricingPage />
            </div>
        )
    }
}

export default HomePage;