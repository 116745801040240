import initialState from '../initialState'

export default function(state=initialState, action){

    switch(action.type){
        case 'LOGIN_SUCCESS':
            return {
                ...state,
                ...action.payload,
                isLoading:false
            }
        case 'SIGNUP_SUCCESS':
            return{
                ...state,
                ...action.payload,
                isLoading:false
            }
        case 'LOGOUT_SUCCESS':
            return {
                ...state,
                isAuthenticated: false,
                accessToken: null
            }
        case 'ADD_TOKEN':
            return {
                ...state, 
                isAuthenticated: true,
                accessToken: action.payload.accessToken
            }
        case 'TOGGLE_PW_PG_REDIRECT':
            return {
                ...state,
                redirect_to_auth: action.payload.redirect_to_auth,
            }
        case 'TOGGLE_AUTH_LOADING':
            return{
                ...state,
                isLoading:action.payload.isLoading
            }            
        default:
            return state;
    }
}

