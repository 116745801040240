import axios from 'axios'// since fetch api not supported in all browsers
import {url_root,sumNutrientTotalsFromList,sumNutrientTotals,configHeaders, is_prod } from './util'
import store from '../store'
import {resetNthSolve} from './types';

export const toggleAutoplan = toggleObj =>{
    let days = ['day1','day2','day3','day4','day5','day6','day7']
    
    return dispatch => {
    if(toggleObj.autoplan ===true){
        let dayplan_incl = true
        let user_state = store.getState().user
        let days_list = days.map((day, idx)=>{
             if((user_state.requirements_dict.cal_lb<=user_state[day].nutrient_totals.calories)&
                (user_state[day].nutrient_totals.calories<=user_state.requirements_dict.cal_ub)){
                return false
            }else{
                return true
            }
        })
        let meals = ['Breakfast', 'Lunch','Dinner', 'Snack']
        let meals_list_of_lists=days.map((day, idx)=>{
            if(days_list[idx]){
                let meal_list =[]
                meals.map((meal,idx)=>{
                    if(store.getState().columns.present[day][meal].foods_list.length===0){
                        meal_list.push(true)
                    }else{
                        meal_list.push(false)
                    }
                })
                return meal_list
            }else{
                return [false,false,false,false]
            }
        })
        dispatch((updatingEachDayAutoPlan({
            day1_incl:days_list[0],
            day1_br_incl:meals_list_of_lists[0][0],
            day1_lu_incl:meals_list_of_lists[0][1],
            day1_di_incl:meals_list_of_lists[0][2],
            day1_sn_incl:meals_list_of_lists[0][3],
            day2_incl:days_list[1],
            day2_br_incl:meals_list_of_lists[1][0],
            day2_lu_incl:meals_list_of_lists[1][1],
            day2_di_incl:meals_list_of_lists[1][2],
            day2_sn_incl:meals_list_of_lists[1][3],
            day3_incl:days_list[2],
            day3_br_incl:meals_list_of_lists[2][0],
            day3_lu_incl:meals_list_of_lists[2][1],
            day3_di_incl:meals_list_of_lists[2][2],
            day3_sn_incl:meals_list_of_lists[2][3],
            day4_incl:days_list[3],
            day4_br_incl:meals_list_of_lists[3][0],
            day4_lu_incl:meals_list_of_lists[3][1],
            day4_di_incl:meals_list_of_lists[3][2],
            day4_sn_incl:meals_list_of_lists[3][3],
            day5_incl:days_list[4],
            day5_br_incl:meals_list_of_lists[4][0],
            day5_lu_incl:meals_list_of_lists[4][1],
            day5_di_incl:meals_list_of_lists[4][2],
            day5_sn_incl:meals_list_of_lists[4][3],
            day6_incl:days_list[5],
            day6_br_incl:meals_list_of_lists[5][0],
            day6_lu_incl:meals_list_of_lists[5][1],
            day6_di_incl:meals_list_of_lists[5][2],
            day6_sn_incl:meals_list_of_lists[5][3],
            day7_incl:days_list[6],
            day7_br_incl:meals_list_of_lists[6][0],
            day7_lu_incl:meals_list_of_lists[6][1],
            day7_di_incl:meals_list_of_lists[6][2],
            day7_sn_incl:meals_list_of_lists[6][3],
        })))
    }
        dispatch(togglingAutoPlan({
            autoplan:toggleObj.autoplan
        }))
        dispatch(resetNthSolve({}))
    }
}
const updatingEachDayAutoPlan = toggleObj => ({
    type:'UPDATING_EACH_DAY_AUTO_PLAN',
    payload: toggleObj
})


const togglingAutoPlan = toggleObj => ({
    type:'TOGGLING_AUTO_PLAN',
    payload: toggleObj
})

export const  toggleIncludeDay = toggleObj =>{
    let br_incl =false
    let lu_incl =false
    let di_incl =false

    if(toggleObj.autoplan===true){
    let day_plan = store.getState().columns.present[toggleObj.plannum]
        br_incl = day_plan['Breakfast'].foods_list.length===0
        lu_incl = day_plan['Lunch'].foods_list.length===0
        di_incl = day_plan['Dinner'].foods_list.length===0
    }
    return dispatch => {
        dispatch(togglingIncludeDay({
            plannum:toggleObj.plannum, 
            autoplan:toggleObj.autoplan,
            br_incl:br_incl,
            lu_incl:lu_incl,
            di_incl:di_incl,
        }))
        dispatch(resetNthSolve({}))
    }
}

const togglingIncludeDay = toggleObj => ({
    type:'TOGGLING_INCLUDE_DAY',
    payload: toggleObj
})

export const  toggleIncludeMeal = toggleObj =>{
    return dispatch => {
        dispatch(togglingIncludeMeal({
            plannum:toggleObj.plannum, 
            meal_type:toggleObj.meal_type,
            autoplan_include:toggleObj.autoplan_include,
        }))
        dispatch(resetNthSolve({}))
    }
}

const togglingIncludeMeal = toggleObj => ({
    type:'TOGGLING_INCLUDE_MEAL',
    payload: toggleObj
})

export const autocompleteMenus = searchObj =>{
    return dispatch => {
        axios.post(url_root+'/user/autocomplete_menus/',
            {
                params:{
                    meal_type:searchObj.meal_type,
                    search_query:searchObj.search_query
                }
            },
            {                                               // config
                headers:configHeaders(is_prod),
            }
        )
        .then(res=>{
            dispatch(recievedAutoCompleteMenus({
                plannum:searchObj.plannum,
                meal_type:searchObj.meal_type,
                search_results:res['data']['search_results']
            }))
        })
        .catch(err=>{
            console.log(err)
        })
        .finally(()=>{
        })
    }
}

const updatingAllNutrientTotals = nutObj => ({
    type:'UPDATE_ALL_NUTRIENT_TOTALS',
    payload: nutObj
})

const fetchingAutoCompleteMenus = toggleObj => ({
    type:'FETCHING_MENUS',
    payload: toggleObj
})

const recievedAutoCompleteMenus = toggleObj => ({
    type:'RECIEVED_MENUS',
    payload: toggleObj
})

export const saveMenuChange = menuObj=>{
    return dispatch =>{
        dispatch(savingMenuChange({
            plannum:menuObj.plannum,
            meal_type:menuObj.meal_type,
            menus:menuObj.menus
        }))
        dispatch(resetNthSolve({}))
    }
}

const savingMenuChange = menuObj => ({
    type:'CHANGE_MENU',
    payload: menuObj
})

export const solveWeekPlan = () =>{
    //TODO - dispatch loading
    //TODO - build the inputs:
    // - days could be built and stored in state also
    
    const days = ['day1', 'day2', 'day3', 'day4', 'day5', 'day6', 'day7']
    const meals = [ 'Breakfast', 'Lunch', 'Dinner', 'Snack']
    const nuts = store.getState().user.requirements_order
    const abrev_nut_dict = store.getState().util.abrev_to_nut_dict
    const abrev_display_dict = store.getState().util.abrev_to_display_dict
    const user_reqs = store.getState().user.requirements_dict
    const days_dict = store.getState().util.days_dict
    let requirements_dict = {}
    let menus_dict_list = []
    let week_date_list = store.getState().user.days_list
    // check if requirements already infeasible
    let curr_plan_list = []
    let n_repl_dict = {}
    let incl_replace = false
    for (let i = 0; i < days.length; i++) {
        let day = days[i]
        let day_store = store.getState().user[day]
        // Set Nutrients
        if(day_store['autoplan']){
            // get foods_list
            requirements_dict[day] = {}
            for(let j = 0; j<nuts.length; j++){
                let nut = nuts[j]
                if(day_store.autoplan_nutrients[nut]){
                    let loop_req = [user_reqs[nut+"_lb"]-day_store.nutrient_totals[abrev_nut_dict[nut]],user_reqs[nut+"_ub"]-day_store.nutrient_totals[abrev_nut_dict[nut]]]
                    if(loop_req[1]<0){
                         return dispatch =>{
                            alert("Requirements already exceeded on "+days_dict[day]+" for "+abrev_display_dict[nut]+". Please remove a food or uninclude "+abrev_display_dict[nut]+" from requirements")
                            dispatch(empty_dispatch({}))
                        }
                    }
                    requirements_dict[day][abrev_nut_dict[nut]] = loop_req
                }
            }
            let cur_menu_dict = {}
            cur_menu_dict['day'] = day
            cur_menu_dict['meals'] = []
            let nth_repl_meal = 0
            for (let k = 0; k < meals.length; k++) {
                let meal = meals[k]
                
                if(day_store[meal].autoplan_include){
                    if(store.getState().columns.present[day][meal].foods_list.length>0){
                        incl_replace = true
                        curr_plan_list.push({
                            day:day,
                            meal:meal,
                            foods_list:store.getState().columns.present[day][meal].foods_list
                        })
                        nth_repl_meal = nth_repl_meal+1
                    }
                    let cur_meal_dict = {}
                    cur_meal_dict['meal'] = meal
                    cur_meal_dict['menus'] = day_store[meal]['menus']
                    cur_menu_dict['meals'].push(cur_meal_dict)
                }
            }
            menus_dict_list.push(cur_menu_dict)
            n_repl_dict[day] = nth_repl_meal
        }
    }
    if((menus_dict_list.length===0)||(menus_dict_list[0]['meals'].length===0)){
        return dispatch =>{
            alert("No Meals Selected. Please select at least one meal to include")
            dispatch(empty_dispatch({}))
        }
    }

    let week_start_dt = week_date_list[0]
    let week_end_dt = week_date_list[week_date_list.length-1]
    let data ={}
    return dispatch => {
        // lock buttons and show loading spinners
        dispatch(togglingSolveInProgress({
            solve_in_progress:true
        }))
        axios.post(url_root+'/autoplan/autoplan_week/',
            {
                params:{
                    requirements_dict:requirements_dict,
                    menus_dict_list:menus_dict_list,
                    week_start_dt:week_start_dt,
                    week_end_dt:week_end_dt,
                    curr_plan_list: curr_plan_list,
                    incl_replace: incl_replace,
                    n_repl_dict:n_repl_dict,
                    nth_solve: store.getState().user.nth_solve,
                    solver_params_dict:{
                        maxSolutions:3,
                        cuts:'off',
                        maxSeconds:35,
                        nThreads:1,
                    }
                }
            },
            {                                               // config
                headers:configHeaders(is_prod),
            }
        )
        .then(res=>{
            // check if empty
            dispatch(togglingSolveInProgress({
                solve_in_progress:false,
            }))
            data = res["data"]
            if(data['is_empty']){
                // trigger a snackbar
                dispatch(togglingAutoplanFailDialog({
                    open:true,
                    message:data['message'],
                }))
            }
            else{
                dispatch(recievedWeekPlanAuto({
                    columns:data
                }))
            }
        })
        .catch(err=>{
            console.log(err)
            data ={'is_empty':true}
            dispatch(togglingSolveInProgress({
                solve_in_progress:false,
            }))
        })
        .finally(()=>{
            if(!data['is_empty']){
                let day1_nutrient_totals = sumNutrientTotals(store.getState().columns.present['day1'], "none", [])
                let day2_nutrient_totals = sumNutrientTotals(store.getState().columns.present['day2'], "none", [])
                let day3_nutrient_totals = sumNutrientTotals(store.getState().columns.present['day3'], "none", [])
                let day4_nutrient_totals = sumNutrientTotals(store.getState().columns.present['day4'], "none", [])
                let day5_nutrient_totals =  sumNutrientTotals(store.getState().columns.present['day5'], "none", [])
                let day6_nutrient_totals = sumNutrientTotals(store.getState().columns.present['day6'], "none", [])
                let day7_nutrient_totals = sumNutrientTotals(store.getState().columns.present['day7'], "none", [])

                dispatch(updatingAllNutrientTotals({
                    day1_nutrient_totals:day1_nutrient_totals,
                    day2_nutrient_totals:day2_nutrient_totals,
                    day3_nutrient_totals:day3_nutrient_totals,
                    day4_nutrient_totals:day4_nutrient_totals,
                    day5_nutrient_totals:day5_nutrient_totals,
                    day6_nutrient_totals:day6_nutrient_totals,
                    day7_nutrient_totals:day7_nutrient_totals,
                }))
                dispatch((increment_nth_solve({})))
            }//if !is_empty
        }
        )
    }
}

const empty_dispatch = emptObj =>({
    type:'EMPTY_DISPATCH',
    payload: emptObj
})

const increment_nth_solve = nthObj =>({
    type:'INCREMENT_NTH_SOLVE',
    payload: nthObj
})

const togglingAutoplanFailDialog = dataObj => ({
    type:'MODIFYING_AUTOPLAN_FAIL_DIALOG',
    payload: dataObj
})

const togglingSolveInProgress = dataObj => ({
    type:'TOGGLING_SOLVE_IN_PROGRESS',
    payload: dataObj
})



const recievedWeekPlanAuto = colObj => ({
    type:'UPDATE_WEEK_AUTOPLAN',
    payload: colObj
})

export const solveAdjustAmounts = () =>{
    let week_date_list = store.getState().user.days_list
    const days = ['day1', 'day2', 'day3', 'day4', 'day5', 'day6', 'day7']
    const meals = [ 'Breakfast', 'Lunch', 'Dinner', 'Snack']
    const nuts = store.getState().user.requirements_order
    const abrev_nut_dict = store.getState().util.abrev_to_nut_dict
    const abrev_display_dict = store.getState().util.abrev_to_display_dict
    const days_dict = store.getState().util.days_dict
    let menus_dict_list = []
    let day_incl_list = []
    // check if requirements already infeasible
    for (let i = 0; i < days.length; i++) {
        let day = days[i]
        let day_store = store.getState().user[day]
        // Set Nutrients
        let nut_incl = []
        if(day_store['autoplan']){
            for(let j = 0; j<nuts.length; j++){
                let nut = nuts[j]
                if(day_store.autoplan_nutrients[nut]){
                    nut_incl.push(abrev_nut_dict[nut])
                }
            }
            let day_obj ={}
            day_obj['req_cols'] = nut_incl
            day_obj['plannum'] = day
            day_obj['date'] = days_dict[day]
            let meals_list = []
            meals.map((meal, idx)=>{
                if(day_store[meal].autoplan_include){
                    meals_list.push(meal)
                }
            })
            if(meals_list.length===0){
                return dispatch =>{
                    alert("No Meals Selected. Please select at least one meal to include for "+days_dict[day])
                    dispatch(empty_dispatch({}))
                }
            }
            day_obj['meals'] = meals_list
            day_incl_list.push(day_obj)   
        }
    }
    if(day_incl_list.length===0){
        return dispatch =>{
            alert("No days selected. Please select at least one day.")
            dispatch(empty_dispatch({}))
        }
    }

    let data ={}
    return dispatch =>{
        dispatch(togglingSolveInProgress({
            solve_in_progress:true,
        }))
        axios.post(url_root+'/autoplan/auto_adjust_week/',
            {
                params:{
                    day_incl_list:day_incl_list,
                    week_start_dt:week_date_list[0]
                }
            },
            {                                               // config
                headers:configHeaders(is_prod),
            }
        )
        .then(res=>{
            // check if empty
            dispatch(togglingSolveInProgress({
                solve_in_progress:false,
            }))
            data = res["data"]
            if(data['is_empty']){
                // trigger a snackbar
                dispatch(togglingAutoplanFailDialog({
                    open:true,
                    message:data['message'],
                }))
            }
            else{
                dispatch(recievedWeekPlanAuto({
                    columns:data
                }))
            }
        })
        .catch(err=>{
            console.log(err)
            data ={'is_empty':true}
            dispatch(togglingSolveInProgress({
                solve_in_progress:false,
            }))
        })
        .finally(()=>{
            if(!data['is_empty']){
                let day1_nutrient_totals = sumNutrientTotals(store.getState().columns.present['day1'], "none", [])
                let day2_nutrient_totals = sumNutrientTotals(store.getState().columns.present['day2'], "none", [])
                let day3_nutrient_totals = sumNutrientTotals(store.getState().columns.present['day3'], "none", [])
                let day4_nutrient_totals = sumNutrientTotals(store.getState().columns.present['day4'], "none", [])
                let day5_nutrient_totals =  sumNutrientTotals(store.getState().columns.present['day5'], "none", [])
                let day6_nutrient_totals = sumNutrientTotals(store.getState().columns.present['day6'], "none", [])
                let day7_nutrient_totals = sumNutrientTotals(store.getState().columns.present['day7'], "none", [])

                dispatch(updatingAllNutrientTotals({
                    day1_nutrient_totals:day1_nutrient_totals,
                    day2_nutrient_totals:day2_nutrient_totals,
                    day3_nutrient_totals:day3_nutrient_totals,
                    day4_nutrient_totals:day4_nutrient_totals,
                    day5_nutrient_totals:day5_nutrient_totals,
                    day6_nutrient_totals:day6_nutrient_totals,
                    day7_nutrient_totals:day7_nutrient_totals,
                }))
                dispatch(resetNthSolve({}))
            }//if !is_empty
        }
        )
    }
}

const singleMealLoading = colObj => ({
    type:'MEAL_LOADING',
    payload: colObj
})

export const toggleSingleDaySolve =(plannum)=>{
    // make autoplan true
    let meal_skel = {
        day1_incl:false,
        day1_br_incl:false,
        day1_lu_incl:false,
        day1_di_incl:false,
        day1_sn_incl:false,
        day2_incl:false,
        day2_br_incl:false,
        day2_lu_incl:false,
        day2_di_incl:false,
        day2_sn_incl:false,
        day3_incl:false,
        day3_br_incl:false,
        day3_lu_incl:false,
        day3_di_incl:false,
        day3_sn_incl:false,
        day4_incl:false,
        day4_br_incl:false,
        day4_lu_incl:false,
        day4_di_incl:false,
        day4_sn_incl:false,
        day5_incl:false,
        day5_br_incl:false,
        day5_lu_incl:false,
        day5_di_incl:false,
        day5_sn_incl:false,
        day6_incl:false,
        day6_br_incl:false,
        day6_lu_incl:false,
        day6_di_incl:false,
        day6_sn_incl:false,
        day7_incl:false,
        day7_br_incl:false,
        day7_lu_incl:false,
        day7_di_incl:false,
        day7_sn_incl:false,
    }
    meal_skel[plannum+'_incl'] = true
    let day_plan = store.getState().columns.present[plannum]
    meal_skel[plannum+'_br_incl'] = day_plan['Breakfast'].foods_list.length===0
    meal_skel[plannum+'_lu_incl'] = day_plan['Lunch'].foods_list.length===0
    meal_skel[plannum+'_di_incl'] = day_plan['Dinner'].foods_list.length===0
    meal_skel[plannum+'_sn_incl'] = day_plan['Snack'].foods_list.length===0
    return dispatch=>{
        dispatch(updatingEachDayAutoPlan(meal_skel))
        dispatch(togglingAutoPlan({
            autoplan:true
        }))
    }
}

export const singleFoodAlternatives = (food_dict, plannum, meal_type, idx) =>{
    let menu_id = store.getState().user.default_menu_dict[meal_type].id
    let requirements_dict = store.getState().user.requirements_dict
    let day_totals = store.getState().user[plannum].nutrient_totals
    let requirements_order = store.getState().user.requirements_order
    let abrev_to_nut_dict = store.getState().util.abrev_to_nut_dict
    let autoplan_nutrients = store.getState().user[plannum].autoplan_nutrients
    let reqs_dict = {}
    requirements_order.map((nut_abrev, idx)=>{
        if(autoplan_nutrients[nut_abrev]){
            let nutrient = abrev_to_nut_dict[nut_abrev]
            reqs_dict[nut_abrev+"_lb"] = requirements_dict[nut_abrev+"_lb"] - day_totals[nutrient] + food_dict[nutrient]*(food_dict.amt/(food_dict.serving_sizes.ss_amts[food_dict.serving_size_idx]))
            reqs_dict[nut_abrev+"_ub"] = requirements_dict[nut_abrev+"_ub"] - day_totals[nutrient] + food_dict[nutrient]*(food_dict.amt/(food_dict.serving_sizes.ss_amts[food_dict.serving_size_idx]))
        }
    })
    return dispatch =>{
        axios.post(url_root+'/autoplan/get_food_alternative/',
            {
                params:{
                    food_key:food_dict.food_key,
                    menu_id:menu_id,
                    reqs_dict:reqs_dict,
                    plannum:plannum,
                    meal_type:meal_type,
                },
            },
            {
                headers:configHeaders(is_prod),
            }
        ).then((res)=>{
            let data = res['data']
            dispatch(showAlternativesDialog({
                alts_menu_list:data['alt_list'],
                alts_other_list:data['alt_oth_list'],
                similar_list:data['sim_list'],
                alt_idx:idx,
                plannum:plannum,
                meal_type:meal_type,
            }))
        }).catch((err)=>{
            console.log(err)
        }).finally(()=>{

        })
    }
}

const showAlternativesDialog = colObj => ({
    type:'SHOW_ALTERNATIVES_DIALOG',
    payload: colObj
})

export const closeAltsDialog =() =>{
    return dispatch =>{
        dispatch(closeAlternativesDialog({}))
    }
}
const closeAlternativesDialog = colObj => ({
    type:'CLOSE_ALTERNATIVES_DIALOG',
    payload: colObj
})

export const resolve =(resolveObj) =>{
    /*
        resolveObj
            * update_list - list of objects containing day, meal
    */
    // get nth_solve
    // get 
}
