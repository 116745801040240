import React, {Component} from 'react';
import {connect} from 'react-redux';
import { Link } from "react-scroll";
import LogoIcon from '../auth/assets/images/IconOnly-01.png'
import {withStyles} from '@material-ui/core/styles';

const useStyles = theme=> ({
    nav:{
        color:"white",
        zIndex:3,
        width:"100vw",
        position:"fixed",
        // backgroundColor:"white",
        display:"flex",
        fontSize:24,
        [theme.breakpoints.down('md')]:{
            color:"darkGrey",
            marginTop:0,
            fontSize:18,
            marginBottom:20,
        }
    },
    rightNav:{
      display:"flex",
      width:"50%",
      fontSize:36,
      display:"none",
      // fontWeight:"bold",
      [theme.breakpoints.down('md')]:{
        display:"none",
      }
    },
    site_title:{
      display:"none",
      marginRight:5,
    },

    leftNav:{
      display:"flex",
      justifyContent:"space-evenly",
      alignContent: "space-evenly",
      width:"100%",
      // justifyContent:"flex-end",
      // alignContent: "flex-end",
      // width:"50%",
      [theme.breakpoints.down('md')]:{

      }
    },
    pageLink:{
      marginRight:20,
      [theme.breakpoints.down('md')]:{
        marginRight:10,
      }
    }

})
class LPMenu extends Component{
// props - circle/square, image
    constructor(props){
        super(props)
        this.state = {
            scrolled: false
        } 
    }

    componentDidMount() {
        window.addEventListener("scroll", () => {
          const isTop = window.scrollY < 100;
          if (isTop !== true) {
            this.setState({ scrolled: true });
          } else {
            this.setState({ scrolled: false });
          }
        });
      }
    
      // componentWillUnmount() {
      //   window.removeEventListener("scroll");
      // }

    render(){    
        const {classes} = this.props     
        return (
            <div
              // className={this.state.scrolled ? "main_menu scrolled" : "main_menu"}
              className={classes.nav}
            >
              <div
                className={classes.rightNav}
              >
                {/* <div
                  style={{
                    marginTop:-10,
                    backgroundColor:"white",
                  }}
                >
                <img 
                  src={LogoIcon}
                  width={65}
                  height={65}

                />
                </div> */}
                <div
                  className={classes.site_title}
                >
                  Plan
                </div>
                <div
                  className={classes.site_title}         
                >
                  Your
                </div>
                <div
                  className={classes.site_title}                
                >
                  Meals
                </div>
              </div>
              <div
                className={classes.leftNav}
              >
                <Link
                  activeClass="active"
                  to="home"
                  spy={true}
                  smooth={true}
                  offset={0}
                  duration={500}
                  className={"landing_nav"}
                  className={classes.pageLink}
                >
                  Home
                </Link>
                <Link
                  activeClass="active"
                  to="features"
                  spy={true}
                  smooth={true}
                  offset={0}
                  duration={500}
                  className={classes.pageLink}
                >
                  Features
                </Link>
                <Link
                  activeClass="active"
                  to="pricing"
                  spy={true}
                  smooth={true}
                  offset={0}
                  duration={500}
                  className={classes.pageLink}
                >
                  Pricing
                </Link>
                {/* TODO - Testimonials */}
                <Link 
                  activeClass="active"
                  to="blog"
                  spy={true}
                  smooth={true}
                  offset={0}
                  duration={500}     
                  className={classes.pageLink}          
                >
                  Blog
                </Link>
                <Link
                  activeClass="active"
                  to="podcast"
                  spy={true}
                  smooth={true}
                  offset={0}
                  duration={500}     
                  className={classes.pageLink} 
                >
                  Podcast
                </Link>
              </div>
            </div>
        )
    }
}

export default withStyles(useStyles)(LPMenu);