import React, {Component} from 'react';
import {connect} from 'react-redux';
import Auth from '../auth/auth.js';
import {withStyles} from '@material-ui/core/styles';
import {Typography} from '@material-ui/core/';
// import FitWomanPhone from 'https://planyourmealsmedia.s3.amazonaws.com/landing_page/fit_woman_phone_cropped.png';


const useStyles = theme=> ({
    hero:{
        // marginTop:50,
        position:"static",
        [theme.breakpoints.up('md')]:{
            // position:"relative",
            // marginTop:10,
            height:"100vh",
            width:"100vw",
            // background:`url(${FitWomanPhone}) no-repeat cover center rgba(12, 155, 60, 1.2)`
            // background:`rgba(12, 155, 60, 0.3)`,
            backgroundColor:"rgba(12, 155, 60, 0.3)",
            backgroundImage:`url(${'https://planyourmealsmedia.s3.amazonaws.com/landing_page/fit_woman_phone_cropped.png'})`,
            backgroundSize:"cover",
            backgroundRepeat:"no-repeat",
            backgroundPosition:"center",
            zIndex:0,
        }
    },
    // hero_img:{
    //     position:"absolute",
    //     height:"100vh",
    //     width:"100vw",
    //     zIndex:-1,
    //     [theme.breakpoints.down('md')]:{
    //         display:"none",
    //         position:"static",
    //         marginTop:10,
    //     }
    // },
    hero_title:{
        // position:"absolute",
        fontSize:66,
        paddingTop: 150,
        width:"100%",
        color:"white",
        zIndex:1,
        textAlign:"center",
        [theme.breakpoints.down('md')]:{
            display:"none",
            // color:"black",
            // fontSize:30,
            // zIndex:1,
            // position:"relative",
        }
    },
    hero_bg:{
        
        // display:"none",
        backgroundColor:"rgba(12, 155, 60, 0.3)",
        // position:"absolute",
        width:"100%",
        height:"100%",
        zIndex:1,
        // opacity:0.25,
        [theme.breakpoints.down('md')]:{
            // display:"none",
            position:"static",
        }
    },
    hero_login:{
        margin:"auto",
        zIndex:3,
        position:"static",
        marginTop:0,
        [theme.breakpoints.up('md')]:{
            marginLeft:"50%",
            marginTop:20,
            // marginTop:125,
            // position:"absolute",
        }
    },
})
class HomeBanner extends Component{
// props - circle/square, image
    constructor(props){
        super(props)
        this.state = {
        } 
    }

    onChangeInput = (event)=>{

    }

    render(){      
        const {classes} = this.props  
        return (
            <div
                style={{
                    position:"static"
                }}
            >
                <div
                    className={classes.hero}
                >
                    <div
                        className={classes.hero_bg}
                    >
                        <div
                            className={classes.hero_title}
                        >
                            Precise Meal Plans From Foods You Love
                        </div>

                        {/* <img 
                            className={classes.hero_img}
                            src={this.props.heroImage}
                        /> */}
                        <div
                            className={classes.hero_login}
                        >
                            <Auth />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withStyles(useStyles)(HomeBanner);