import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
    Button,
    FormLabel,
    InputAdornment,
    TextField,
    Typography,
}from '@material-ui/core/';
import {withStyles} from '@material-ui/core/styles';
import {saveMacros,saveUserStat, updateUserMacro} from '../../actions/nutrients';

const useStyles = {
    root:{
    },
    trybuttonDiv:{
        width:320,
        margin:"auto",
        display:"flex",
    },
    trybuttonSupportText:{
        width:300,
    },
    tryButton:{
        width:20,
        border:"1px solid var(--main_bright)",
        color:"var(--main_bright)",
    },
}

class MacroForm extends Component{
// props - circle/square, image
    constructor(props){
        super(props)
        this.state = {
        } 
    }

    onChangeMacro = (macro_abr, new_val) =>{
        this.props.updateUserMacro(macro_abr, new_val)
    }

    fillInMacros =(val) =>{
        let stat_obj = {}
        if (val === "fnb"){
                stat_obj['pro_perc'] = 0.2
                stat_obj['car_perc'] = 0.55
                stat_obj['fat_perc'] = 0.25
        }else if(val==="bodymass"){
                let user_stats = this.props.user_stats
                let daily_cal = user_stats.daily_cal
                console.log(daily_cal)
                let protein_g = user_stats.weight // 1 gram per gram of bodyweight
                stat_obj['pro_perc'] = ((protein_g*4)/daily_cal).toFixed(2)
                stat_obj['car_perc'] = 0.8 - stat_obj['pro_perc']
                stat_obj['fat_perc'] = 0.2
        }else if(val==="keto"){
            stat_obj['pro_perc'] = 0.25
            stat_obj['car_perc'] = 0.1
            stat_obj['fat_perc'] = 0.65
        }else if (val==="low-carb"){
            stat_obj['pro_perc'] = 0.25
            stat_obj['car_perc'] = 0.2
            stat_obj['fat_perc'] = 0.55
        }
        this.props.saveUserStat(stat_obj)
    }

    onUpdateMacros = () =>{
        let macro_sum = parseFloat(this.props.user_stats.fat_perc) + parseFloat(this.props.user_stats.pro_perc) + parseFloat(this.props.user_stats.car_perc)
        if((macro_sum!=1.0)){
            alert("Macro Proportions Must Add Up To One")
        }else{
            this.props.saveMacros()
        }
    }

    render(){        
        const classes = this.props
        return (
            <div
                style={{
                    margin:"auto",
                }}
            >
            <div
                style={{
                    width:"80%",
                    margin:"auto",
                    marginBottom:10,
                }}
            >
                <Button
                        fullWidth
                        variant="contained"
                        style={{
                            backgroundColor:"var(--main_bright)",
                            color:"white",
                        }}
                        fullWidth
                        onClick={(event)=>{this.fillInMacros("fnb")}}
                >
                    Food And Nutrition Board
                </Button>
            </div>
            <div
                style={{
                    width:"80%",
                    margin:"auto",
                    marginBottom:10,
                }}                
            >
                <Button
                        fullWidth
                        variant="contained"
                        style={{
                            backgroundColor:"var(--main_bright)",
                            color:"white",
                        }}
                        
                        onClick={(event)=>{this.fillInMacros("bodymass")}}
                    >
                    Bodymass First
                </Button>
            </div>
            <div
                style={{
                    width:"80%",
                    margin:"auto",
                    marginBottom:10,
                }}    
            >
                    <Button
                        fullWidth
                        variant="contained"
                        style={{
                            backgroundColor:"var(--main_bright)",
                            color:"white",
                        }}
                        onClick={(event)=>{this.fillInMacros("keto")}}
                    >
                    Keto
                </Button>
            </div>
            <div
                style={{
                    width:"80%",
                    margin:"auto",
                    marginBottom:10,
                }}                  
            >
                <Button
                        fullWidth
                        variant="contained"
                        style={{
                            backgroundColor:"var(--main_bright)",
                            color:"white",
                        }}
                        onClick={(event)=>{this.fillInMacros("low-carb")}}
                    >
                        Low Carb
                    </Button>
            </div>
            <div
                        style={{
                            textAlign:"center",
                            width:"100%",
                            color:"darkGrey",
                            marginBottom:10,
                        }}
            >
                        <Typography
                            variant="body2"
                        >
                            Or, enter your choice below
                        </Typography>
            </div>
            <div
                style={{
                    display:"flex",
                    margin:"auto",
                    width:350,
                }}
            >
                <TextField
                    style={{
                        width:115
                    }}
                    type="tel"
                    label="Protein"
                    variant="outlined"
                    size="small"
                    InputLabelProps ={{
                        shrink:true
                    }}
                    value={this.props.user_stats.pro_perc}
                    endAdornment={<InputAdornment position="end">%</InputAdornment>}
                    onChange={(event)=>{this.onChangeMacro('pro',event.target.value)}}
                />
                <TextField
                    style={{
                        width:115
                    }}
                    InputLabelProps ={{
                        shrink:true
                    }}
                    type="tel"
                    label="Fat"
                    variant="outlined"
                    size="small"
                    value={this.props.user_stats.fat_perc}
                    endAdornment={<InputAdornment position="end">%</InputAdornment>}
                    onChange={(event)=>{this.onChangeMacro('fat',event.target.value)}}
                />
                <TextField
                    style={{
                        width:115
                    }}
                    InputLabelProps ={{
                        shrink:true
                    }}
                    type="tel"
                    label="Carbs"
                    variant="outlined"
                    size="small"
                    value={this.props.user_stats.car_perc}
                    endAdornment={<InputAdornment position="end">%</InputAdornment>}
                    onChange={(event)=>{this.onChangeMacro('car',event.target.value)}}
                />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state,ownProps) => ({
    user_stats:state.user.user_stats
})

export default connect(mapStateToProps,{saveMacros,saveUserStat,updateUserMacro})(withStyles(useStyles)(MacroForm));