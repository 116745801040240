import initialState from '../initialState'

//TODO - get rid of combine state so can access state by doing this.state.columns.searchResults.food, etc

export default function(state=initialState, action){
    switch(action.type){
        case 'INGRED_SEARCH_RESULTS_LOADING':
            return{
               ...state,
                ingredientSearch:{
                    ...state.ingredientSearch,
                    hasSearchResults:action.payload.hasSearchResults,
                    isLoading:action.payload.isLoading
                }
            }
        case 'INGRED_SEARCH_RESULTS_RETURNED':
            return{
                ...state,
                ingredientSearch:{
                    ...state.ingredientSearch,
                    hasSearchResults:action.payload.hasSearchResults,
                    isLoading:action.payload.isLoading,
                    results_list:action.payload.results_list,
                }
            }
        case 'RECIPE_SEARCH_RESULTS_LOADING':
        return{
            ...state,
            recipeSearch:{
                ...state.recipeSearch,
                hasSearchResults:action.payload.hasSearchResults,
                isLoading:action.payload.isLoading
            }
        } 
        case 'RECIPE_SEARCH_RESULTS_RETURNED':
            return{
                ...state,
                recipeSearch:{
                    ...state.recipeSearch,
                    hasSearchResults:action.payload.hasSearchResults,
                    isLoading:action.payload.isLoading,
                    results_list:action.payload.results_list,
                }    
            }
        case 'LOAD_SINGLE_RECIPE':
            return{
                ...state,
                singleRecipeSearch:{
                    ...state.singleRecipeSearch,
                    ...action.payload,
                }
            }
        case 'RESETTING_SINGLE_RECIPE_SEARCH':
            return{
                ...state,
                singleRecipeSearch:{
                    ...state.singleRecipeSearch,
                    ...{
                    food_key:'',
                    recipe_name:'',
                    recipe_description:'',
                    yield:'',
                    serving_description:'',
                    cook_time_hours:'',
                    cook_time_minutes:'',
                    ingredients_list:[],
                    instructions_list:[],
                    recipe_image:'',
                    recipe_image_url:'',
                    max_servings:'',
                    default_dish_num:"Whole Meals",
                }
            }
        }
        case 'MEAL_ITEM_SEARCH_RESULTS_LOADING':
            return{
                ...state,
                mealSearch:{
                    ...state.mealSearch,
                    hasSearchResults:action.payload.hasSearchResults,
                    isLoading:action.payload.isLoading
                }
            }
        case 'MEAL_ITEM_SEARCH_RESULTS_RETURNED':
            return{
                ...state,
                mealSearch:{
                    ...state.mealSearch,
                    hasSearchResults:action.payload.hasSearchResults,
                    isLoading:action.payload.isLoading,
                    results_list:action.payload.results_list,
                }    
            }      
        case 'LOAD_SINGLE_MEAL':
            return{
                ...state,
                singleMealSearch:{
                    ...state.singleMealSearch,
                    ...action.payload,
                }
            }
        case 'RESETTING_SINGLE_MEAL_SEARCH':
            return{
                ...state,
                singleSearchMeal:{
                    ...state.singleSearchMeal,
                    meal_id:null,
                    mealname:'',
                    foods_list:[],
                    default_meal:"Dinner",
                }
            }
        case 'MENU_SEARCH_RESULTS_LOADING_MENU_PAGE':
            return{
                ...state,
                buildMenuSearch:{
                    ...state.buildMenuSearch,
                    isLoading:action.payload.isLoading,
                }
            }
        case 'MENU_SEARCH_RESULTS_RETURNED_MENU_PAGE':
            return{
                ...state,
                buildMenuSearch:{
                    ...state.buildMenuSearch,
                    ...action.payload,                    
                }
            }
        default:
            return state;
    }
}
    