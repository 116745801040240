import axios from 'axios'// since fetch api not supported in all browsers
import store from '../store'
import {url_root, configHeaders,updateSingleMeal, is_prod} from './util';

export const toggleMealNameDialog =(is_open, plannum, meal_type)=>{
    return dispatch =>{
        dispatch(togglingMealNameDialog({
        open:is_open,
        plannum:plannum,
        meal_type:meal_type
    }))
    }
}

export const changeMealNameInDialog =(mealname)=>{
    return dispatch =>{
        dispatch(togglingMealNameDialog({
        mealname:mealname,
    }))
    }
}

export const helpTextMealNameDialog =(show,new_text)=>{
    return dispatch =>{
        dispatch(togglingMealNameDialog({
        help_text:new_text,
        show_help_text:show,
    }))
    }
}

const togglingMealNameDialog = dataObj => ({
    type:'MODIFYING_MEAL_NAME_DIALOG',
    payload: dataObj
})

export const modifyAutoplanFailDialog = (open,message) =>{
    return dispatch =>{
        dispatch(togglingAutoplanFailDialog({
            open:open,
            message:message,
        }))
    }
}

const togglingAutoplanFailDialog = dataObj => ({
    type:'MODIFYING_AUTOPLAN_FAIL_DIALOG',
    payload: dataObj
})

export const swapAlt =(idx, list_type)=>{
    let alternatives_dialog = store.getState().dialog.alternatives_dialog
    let plannum = alternatives_dialog.plannum
    let meal_type = alternatives_dialog.meal_type
    let new_foods_list = Array.from(store.getState().columns.present[plannum][meal_type].foods_list)
    let alt_food = alternatives_dialog[list_type][idx]
    new_foods_list.splice(alternatives_dialog.alt_idx, 1, alt_food)
    updateSingleMeal(plannum,meal_type,new_foods_list)
    return dispatch =>{
        dispatch(updatingFoodsList({
            col:alternatives_dialog.plannum,
            subcol:alternatives_dialog.meal_type,
            foods_list:new_foods_list
        }))
        dispatch(resetNthSolve({}))
    }
}

const updatingFoodsList = mealRemObj => ({
    type:'UPDATE_FOODS_LIST',
    payload: mealRemObj
})

const resetNthSolve = nthObj => ({
    type:'RESET_NTH_SOLVE',
    payload: nthObj
})

export const toggleTermsAndConditions =(open)=>{
    return dispatch =>{
        dispatch(togglingTermsAndConditions({
            open:open,
        }))
    }
}

const togglingTermsAndConditions = obj => ({
    type:'TOGGLE_TERMS_MODAL',
    payload: obj
})

export const toggleCancelDialog =(obj)=>{
    return dispatch =>{
        dispatch(togglingCancelDialog(obj))
    }
}
const togglingCancelDialog = obj => ({
    type:'TOGGLE_CANCEL_DIALOG',
    payload: obj
})

export const togglePaymentSnackbar =(obj)=>{
    return dispatch =>{
        dispatch(togglingPaymentSnackbar(obj))
    }
}

const togglingPaymentSnackbar = obj => ({
    type:'TOGGLE_PAYMENT_SNACKBAR',
    payload: obj
})

export const togglePasswordDialog =(obj)=>{
    return dispatch =>{
        dispatch(togglingPasswordDialog(obj))
    }
}

const togglingPasswordDialog = obj => ({
    type:'TOGGLE_PASSWORD_DIALOG',
    payload: obj
})

export const toggleGenericSnackbar =(obj)=>{
    return dispatch =>{
        dispatch(togglingGenericSnackbar(obj))
    }
}

const togglingGenericSnackbar = obj => ({
    type:'TOGGLE_GENERIC_SNACKBAR',
    payload: obj
})