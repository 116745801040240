import axios from 'axios'// since fetch api not supported in all browsers
import store from '../store'
import {url_root,configHeaders, is_prod} from './util'
import {resetNthSolve} from './types';
import {recievedWeekPlan} from './start';

export const add_menu_item = (addObj) =>{
    let menu_id =0
    let new_items_list=[]
    if(addObj.display_page ==='plan'){
        menu_id = store.getState().user.default_menu_dict[addObj.meal_type].id
        if(!addObj.default){
            menu_id = addObj.menu_id
        }
        new_items_list = Array.from(store.getState().columns.present.menus[addObj.meal_type][addObj.dish_num].items_list)
    }else{
        menu_id = store.getState().menu_page.build_menu.pref_menu_dict[addObj.meal_type].id
        new_items_list = Array.from(store.getState().menu_page.build_menu[addObj.meal_type][addObj.dish_num].items_list)
    }
    new_items_list.push({
        unique_id:addObj.unique_id,
        meal_type:addObj.meal_type,
        dish_num:addObj.dish_num,
        name:addObj.name,
        item_type:addObj.item_type
    })
    return dispatch=>{
        dispatch(resetNthSolve({}))
        if(addObj.display_page ==='plan'){
            dispatch(adding_menu_item({
                meal_type:addObj.meal_type,
                dish_num:addObj.dish_num,
                items_list:new_items_list
            }))
        }else{
            dispatch(adding_menu_item_menu_page({
                meal_type:addObj.meal_type,
                dish_num:addObj.dish_num,
                items_list:new_items_list
            }))  
        }
        //post
        if(menu_id!=0){
        axios.post(
            url_root+'/core/add_menu_item/', // address
            {                                               // body
                params:{
                    menu_id: menu_id,
                    meal_type: addObj.meal_type,
                    dish_num:addObj.dish_num,
                    unique_id:addObj.unique_id,
                    item_type:addObj.item_type
                }
            },
            {                                               // config
                headers:configHeaders(is_prod),
            }
        )
        .then(res=>{
            
        })
        .catch(err=>{})
        }// if statement
    }

}

const adding_menu_item = addObj => ({
    type:'MENU_ADD',
    payload: addObj
})

const adding_menu_item_menu_page = addObj => ({
    type:'MENU_ADD_MENU_PAGE',
    payload: addObj
})

export const remove_menu_item = (remObj) =>{
    let menu_id =0
    let new_items_list=[]
    if(remObj.display_page ==='plan'){
        menu_id = store.getState().user.default_menu_dict[remObj.meal_type].id
        if(!remObj.default){
            menu_id = remObj.menu_id
        }
        new_items_list = Array.from(store.getState().columns.present.menus[remObj.meal_type][remObj.dish_num].items_list)
    }else{
        menu_id = store.getState().menu_page.build_menu.pref_menu_dict[remObj.meal_type].id
        new_items_list = Array.from(store.getState().menu_page.build_menu[remObj.meal_type][remObj.dish_num].items_list)
    }
    new_items_list.splice(remObj.idx, 1)
    return dispatch => {
        dispatch(resetNthSolve({})) // always resets bc user can update personal menu from either page
        if(remObj.display_page==='plan'){
            dispatch(adding_menu_item({
                meal_type:remObj.meal_type,
                dish_num:remObj.dish_num,
                items_list:new_items_list
            }))
        }else{
            dispatch(adding_menu_item_menu_page({
                meal_type:remObj.meal_type,
                dish_num:remObj.dish_num,
                items_list:new_items_list
            }))            
        }
        axios.post(
            url_root+'/core/remove_menu_item/', // address
            {                                               // body
                params:{
                    menu_id: menu_id,
                    meal_type: remObj.meal_type,
                    dish_num:remObj.dish_num,
                    unique_id:remObj.unique_id,
                    item_type:remObj.item_type
                }
            },
            {                                               // config
                headers:configHeaders(is_prod),
            }
        )
        .then(res=>{})
        .catch(err=>{})
    }
}

export const getInitialMenus =()=>{
    return dispatch =>{
        axios.post(
            url_root+'/core/get_initial_menu_options/', // address
            {                                               // body
                params:{
                }
            },
            {                                               // config
                headers:configHeaders(is_prod),
            }
        )
        .then(res=>{
            let data = res['data']
            if(data['no_results']!=true){
                dispatch(adding_menu_search_results({
                    menu_search:data['menus_list'],
                    id:data['menus_list'][0]['id'],
                    pref_menu_dict:data['pref_menu_dict'],
                    menu_dict: data["menu_dict"],
                    display_idx:0,
                }))
            }
        })
        .catch(err=>{})
    }
}

export const getInitialMenusUserOnly =()=>{
    return dispatch =>{
        axios.post(
            url_root+'/core/get_initial_menu_options_user_only/', // address
            {                                               // body
                params:{
                }
            },
            {                                               // config
                headers:configHeaders(is_prod),
            }
        )
        .then(res=>{
            let data = res['data']
            if(data['no_results']!=true){
                dispatch(adding_menu_search_results({
                    menu_search:data['menus_list'],
                    id:data['menus_list'][0]['id'],
                    pref_menu_dict:data['pref_menu_dict'],
                    menu_dict: data["menu_dict"],
                    display_idx:0,
                }))
            }
        })
        .catch(err=>{})
    }
}

const adding_menu_search_results = addObj => ({
    type:'ADD_MENU_NAME_SEARCH_RESULTS',
    payload: addObj
})

export const addMenuToPreview =(idx)=>{
    let menu_search_list = store.getState().menu_page.menu_search
    let new_menu = menu_search_list[idx]
    let id = new_menu.id
    //  add menu type
    return dispatch=>{
        axios.post(
            url_root+'/core/get_public_menu_from_id/', // address
            {                                               // body
                params:{
                    id:id,
                    user_id:new_menu.user_id,
                    menu_type:new_menu.menu_type,
                }
            },
            {                                               // config
                headers:configHeaders(is_prod),
            }
        )
        .then(res=>{
            let data = res['data']
            dispatch(adding_menu_to_preview({
                idx:idx,
                id:id,
                pref_menu_dict:data['pref_menu_dict'],
                menu_dict: data["menu_dict"],
            }))
        })
        .catch(err=>{})
    }
}

const adding_menu_to_preview = addObj => ({
    type:'ADD_MENU_TO_PREVIEW',
    payload: addObj
})

export const clearBuildMenu =()=>{
    return dispatch =>{
        dispatch(adding_menu_search_results({
            menu_search:[],
            idx:0,
            id:0,
            pref_menu_dict:{
                Breakfast:{id:0},
                Lunch:{id:0},
                Dinner:{id:0},
                Snack:{id:0}
            },
            menu_dict:{
                Breakfast:{
                    whole_meals:{items_list:[]},
                    main_courses:{items_list:[]},
                    sides:{items_list:[]}
                },
                Lunch:{
                    whole_meals:{items_list:[]},
                    main_courses:{items_list:[]},
                    sides:{items_list:[]}
                },
                Dinner:{
                    whole_meals:{items_list:[]},
                    main_courses:{items_list:[]},
                    sides:{items_list:[]}
                },
                Snack:{
                    snacks:{items_list:[]}
                },
            }
        }))
    }
}

export const saveNewMenu = (menuObj)=>{
    // should check if id = 0
    return dispatch=>{
    axios.post(
        url_root+'/core/new_public_menu/', // address
        {                                               // body
            params:{
                menu_name:menuObj.menu_name,
                menu_description:menuObj.menu_description,
            }
        },
        {                                               // config
            headers:configHeaders(is_prod),
        }
    )
    .then(res=>{
        let data = res['data']
        dispatch(adding_menu_to_preview({
            idx:0,
            id:data['menu_id'],
            pref_menu_dict:data['pref_menu_dict'],
            menu_dict:{
                    Breakfast:{
                        whole_meals:{items_list:[]},
                        main_courses:{items_list:[]},
                        sides:{items_list:[]}
                    },
                    Lunch:{
                        whole_meals:{items_list:[]},
                        main_courses:{items_list:[]},
                        sides:{items_list:[]}
                    },
                    Dinner:{
                        whole_meals:{items_list:[]},
                        main_courses:{items_list:[]},
                        sides:{items_list:[]}
                    },
                    Snack:{
                        snacks:{items_list:[]}
                    },
                }
        }))
    })
    .catch(err=>{})
    }
}

// next
export const cloneMenu=(overwrite, return_menu)=>{
    let menu_id = store.getState().menu_page.build_menu.menu_id
    return dispatch =>{
        axios.post(
            url_root+'/core/clone_public_menu/', // address
            {                                               // body
                params:{
                    menu_id:menu_id,
                    overwrite:overwrite,
                    return_menu:return_menu,
                }
            },
            {                                               // config
                headers:configHeaders(is_prod),
            }
        )
        .then(res=>{
            let data = res['data']
            // dispatch snackbar to 'true'
            dispatch(toggling_success_snackbar({
                snackbar_open:true,
            }))
            dispatch(resetNthSolve({}))
            if(return_menu){
                console.log('dispatching menu')
                console.log(data['menu_dict'])
                dispatch(recievedWeekPlan({columns:{menus:data['menu_dict']}}))
            }
        })
        .catch(err=>{})
    }
}

const toggling_success_snackbar = addObj => ({
    type:'TOGGLE_SUCCESS_SNACKBAR',
    payload: addObj
})

export const closeSnackBar = ()=>{
    return dispatch=>{
        dispatch(toggling_success_snackbar({
            snackbar_open:false
        }))
    }
}

export const getOnboardingMenus= () =>{
    // menu ids: 2->Keto, 4->All Purpose, 5->High Protein
    let user_stats = store.getState().user.user_stats
    let sugg_menu = 4
    if(user_stats.pro_perc>0.25){
        sugg_menu = 5
    }else if (user_stats.car_perc<=0.15){
        sugg_menu = 2
    }
    return dispatch =>{
        axios.post(
            url_root+'/core/get_starter_menus/', // address
            {                                               // body
                params:{
                    sugg_menu:sugg_menu
                }
            },
            {                                               // config
                headers:configHeaders(is_prod),
            }
        )
        .then(res=>{
            let data = res['data']
            dispatch(adding_menu_search_results({
                    menu_search:data['menus_list'],
                    id:data['menus_list'][0]['id'],
                    pref_menu_dict:data['pref_menu_dict'],
                    menu_dict: data["menu_dict"],
                    display_idx:0,
            }))
        })
        .catch(err=>{})
    }
}