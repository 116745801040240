import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
}from '@material-ui/core/';
import {toggleCancelDialog} from '../../actions/dialog';
import {cancelSubscription} from '../../actions/account';
import kittenpic from '../auth/assets/images/cute_kitten1.jpg'

class CancelDialog extends Component{
// props - circle/square, image
    constructor(props){
        super(props)
        this.state = {
        } 
    }

    handleClose = () =>{
        this.props.toggleCancelDialog({
            open:false
        })
    }

    handleClick = () =>{     
        this.props.cancelSubscription()   
        this.props.toggleCancelDialog({
            open:false
        })
    }

    render(){       
        return (
            <Dialog
                open={this.props.open}
                onClose={(event)=>{this.handleClose()}}
                style={{
                    width:"100%",
                    // width:320,
                    margin:"auto",
                }}
            >
                <DialogTitle
                >
                {this.props.message}
                </DialogTitle>
                <DialogContent
                    style={{
                        textAlign:"center"
                    }}
                >
                    <img
                        src={kittenpic}
                        width={320}
                        height={160}
                        style={{
                            margin:"auto"
                        }}
                    />
                </DialogContent>
                <DialogActions>
                <Button
                    variant={"outlined"}
                    style={{
                        // color:"green"
                    }}
                    onClick={this.handleClick}
                >Of course, that's why I clicked the button
                </Button>
                    <Button 
                        variant={"outlined"}
                        onClick={this.handleClose}
                    >
                        No, I don't want to unsubscribe.
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

const mapStateToProps = (state,ownProps) => {

    return{
    open:state.dialog.cancelation_dialog.open,
    message: state.dialog.cancelation_dialog.message,

}}

export default connect(mapStateToProps,{toggleCancelDialog,cancelSubscription})(CancelDialog);