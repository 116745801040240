import React, {Component} from 'react';
import {connect} from 'react-redux';
import MyNutrients from './my_nutrients'
import NavBar from '../common/navbar';
import {
    Typography
} from '@material-ui/core/';
import {mountNutrientsPage} from '../../actions/start';

class NutrientPage extends Component{

    constructor(props){
        super(props)
        this.state = {
        }
    }

    componentDidMount = () =>{
        this.props.mountNutrientsPage()
    }

    render(){
        return(
            <div>
                <NavBar/>
                <div>
                    <MyNutrients/>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
})

export default connect(null, {mountNutrientsPage})(NutrientPage);
