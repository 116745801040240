import store from '../store'
import {url_root, configHeaders,is_prod} from './util';
import axios from 'axios';

export const addNewRecipe =(recipeObj) =>{
    // post food & recipe details
    // in return function, get food_key and post/put file to upload
    let params={
        food_key:recipeObj.food_key,
        recipe_name:recipeObj.recipe_name,
        recipe_description:recipeObj.recipe_description,
        yield:recipeObj.yield,
        serving_description:recipeObj.serving_description,
        cook_time_hours:recipeObj.cook_time_hours,
        cook_time_minutes:recipeObj.cook_time_minutes,
        ingredients_list:recipeObj.ingredients_list,
        instructions_list:recipeObj.instructions_list,
        max_servings:recipeObj.max_servings,
        default_dish_num:recipeObj.default_dish_num,
        recipe_image_url:recipeObj.recipe_image_url,
    }
    let body_form_data = new FormData()
    for(var key in params){
        // body_form_data.set(key,params[key])
        if(key=='ingredients_list'){
            let new_list = params[key].map((item,idx)=>(
                JSON.stringify(item)
            ))
            body_form_data.set(key,JSON.stringify(new_list))
        }else if(key ==='instructions_list'){
            body_form_data.set(key,JSON.stringify(params[key]))
        }else{
            body_form_data.set(key,params[key])
        }
    }
    body_form_data.append('recipe_image', recipeObj.recipe_image)
    return dispatch =>{
     axios({
        method:'post', 
        url:url_root+'/food/save_recipe/',
        data:body_form_data,
        headers:{
         ...configHeaders(is_prod),
         ...{'Content-Type': 'multipart/form-data' }
        }
    }).then((response)=>{
        // Whole other axios post
        let result = response["data"]
        console.log(result.food_key)
    }).catch((err)=>{
        console.log(err)
    }).then(()=>{
        // show successful upload image
        dispatch(resetSingleRecipe({}))
    })
    }//end of dispatch
}

export const addNewMeal = (mealObj)=>{
    let params = {
        mealname: mealObj.mealname,
        foods_list: mealObj.foods_list,
        default_meal:mealObj.default_meal,
    }
    return dispatch =>{
        axios({
           method:'post', 
           url:url_root+'/plan/save_meal/',
           data:{
               params:params,
           },
           headers:configHeaders(is_prod),
       }).then((response)=>{
           // Whole other axios post
           let result = response["data"]
       }).catch((err)=>{
           console.log(err)
       }).then(()=>{
           // show successful upload image
           dispatch(resetSingleMeal({}))
       })
       }//end of dispatch
}

export const addEditedRecipe =(recipeObj) =>{
    // post food & recipe details
    // in return function, get food_key and post/put file to upload
    let params={
        food_key:recipeObj.food_key,
        recipe_name:recipeObj.recipe_name,
        recipe_description:recipeObj.recipe_description,
        yield:recipeObj.yield,
        serving_description:recipeObj.serving_description,
        cook_time_hours:recipeObj.cook_time_hours,
        cook_time_minutes:recipeObj.cook_time_minutes,
        ingredients_list:recipeObj.ingredients_list,
        instructions_list:recipeObj.instructions_list,
        max_servings:recipeObj.max_servings,
        default_dish_num:recipeObj.default_dish_num,
        recipe_image_url:recipeObj.recipe_image_url,
    }
    let body_form_data = new FormData()
    for(var key in params){
        // body_form_data.set(key,params[key])
        if(key=='ingredients_list'){
            let new_list = params[key].map((item,idx)=>(
                JSON.stringify(item)
            ))
            body_form_data.set(key,JSON.stringify(new_list))
        }else if(key ==='instructions_list'){
            body_form_data.set(key,JSON.stringify(params[key]))
        }else{
            body_form_data.set(key,params[key])
        }
    }
    body_form_data.append('recipe_image', recipeObj.recipe_image)
    return dispatch =>{
     axios({
            method:'post', 
            url:url_root+'/food/edit_recipe/',
            data:body_form_data,
            headers:{
                ...configHeaders(is_prod),
                ...{'Content-Type': 'multipart/form-data' }
            }
        },
    ).then((response)=>{
        // Whole other axios post
        let result = response["data"]
        
    }).catch((err)=>{
        console.log(err)
    }).then(()=>{
        // show successful upload image
        dispatch(resetSingleRecipe({}))
    })
    }//end of dispatch
}

export const addEditedMeal = (mealObj) =>{
    let params = {
        mealname: mealObj.mealname,
        foods_list: mealObj.foods_list,
        default_meal:mealObj.default_meal,
        meal_id:mealObj.meal_id
    }
    return dispatch =>{
        axios({
           method:'post', 
           url:url_root+'/plan/edit_meal/',
           data:{
               params:params,
           },
           headers:configHeaders(is_prod),
       }).then((response)=>{
           // Whole other axios post
           let result = response["data"]
       }).catch((err)=>{
           console.log(err)
       }).then(()=>{
           // show successful upload image
           dispatch(resetSingleMeal({}))
       })
       }//end of dispatch
}

// Get Recipe for Clone Or Edit
export const getRecipe = (food_key) =>{
    return dispatch=>{
        axios.post(url_root+'/food/get_recipe_for_edit/',
        {
            params:{
                food_key:food_key
            }},
            {headers:configHeaders(is_prod),
        }).then((response)=>{
            // Whole other axios post
            let result = response["data"]
            let recipe_obj = result['recipe_dict']
            dispatch(loadingSingleRecipe({
                food_key:recipe_obj.food_key,
                recipe_name:recipe_obj.food_description,
                recipe_description:recipe_obj.description,
                yield:recipe_obj.recipe_yield,
                serving_description:null,
                cook_time_hours:recipe_obj.cook_time['hrs'],
                cook_time_minutes:recipe_obj.cook_time['mins'],
                ingredients_list:result['ingred_list'],
                instructions_list:recipe_obj['instructions'],
                recipe_image_url:recipe_obj['image_url'],
                max_servings:recipe_obj['max_servings'],
                default_dish_num:recipe_obj['default_dish_num'],
            }))
        }).catch((err)=>{
            console.log(err)
        }).then(()=>{
            // show successful upload image
        })
        }//end of dispatch
}
const loadingSingleRecipe = searchObj => ({
    type:'LOAD_SINGLE_RECIPE',
    payload: searchObj
})


export const updateRecipeField = (recipeObj)=>{
    return dispatch =>{
        dispatch(loadingSingleRecipe(recipeObj))
    }
}

export const updateMealField = (mealObj)=>{
    return dispatch =>{
        dispatch(loadingSingleMeal(mealObj))
    }
}

export const updateRecipeIngredientsField = (idx)=>{
    let new_ingredients_list = Array.from(store.getState().searchWidgets.singleRecipeSearch.ingredients_list)
    let new_ingred = store.getState().searchWidgets.ingredientSearch.results_list[idx]
    new_ingredients_list.push(new_ingred)
    return dispatch =>{
        dispatch(loadingSingleRecipe({
            ingredients_list:new_ingredients_list
        }))
    }
}

export const updateMealFoodsField = (idx)=>{
    let new_foods_list = Array.from(store.getState().searchWidgets.singleMealSearch.foods_list)
    let new_ingred = store.getState().searchWidgets.ingredientSearch.results_list[idx]
    new_foods_list.push(new_ingred)
    return dispatch =>{
        dispatch(loadingSingleMeal({
            foods_list:new_foods_list
        }))
    }
}

export const removeRecipeIngredient =(idx)=>{
    let new_ingredients_list = Array.from(store.getState().searchWidgets.singleRecipeSearch.ingredients_list)
    new_ingredients_list.splice(idx,1)
    return dispatch =>{
        dispatch(loadingSingleRecipe({
            ingredients_list:new_ingredients_list
        }))
    }
}

export const removeMealFood =(idx)=>{
    let new_foods_list = Array.from(store.getState().searchWidgets.singleMealSearch.foods_list)
    new_foods_list.splice(idx,1)
    return dispatch =>{
        dispatch(loadingSingleMeal({
            foods_list:new_foods_list
        }))
    }
}

export const changeIngredientAmt =(list_idx, new_amt)=>{
        let emp_obj = {}
        let old_obj = store.getState().searchWidgets.singleRecipeSearch.ingredients_list[list_idx]
        let new_obj = Object.assign(emp_obj, old_obj)
        new_obj['amt'] = new_amt
        let new_ingredients_list = Array.from(store.getState().searchWidgets.singleRecipeSearch.ingredients_list)
        new_ingredients_list.splice(list_idx,1, new_obj)
        return dispatch =>{
            dispatch(loadingSingleRecipe({
                ingredients_list:new_ingredients_list
            }))
        }
}


export const changeMealFoodAmt =(list_idx, new_amt)=>{
    let emp_obj = {}
    let old_obj = store.getState().searchWidgets.singleMealSearch.foods_list[list_idx]
    let new_obj = Object.assign(emp_obj, old_obj)
    new_obj['amt'] = new_amt
    let new_foods_list = Array.from(store.getState().searchWidgets.singleMealSearch.foods_list)
    new_foods_list.splice(list_idx,1, new_obj)
    return dispatch =>{
        dispatch(loadingSingleMeal({
            foods_list:new_foods_list
        }))
    }
}

export const changeRecipeServingSizeIndex =(list_idx, new_serving_size_index)=>{
    let new_obj = {}
    let old_obj = store.getState().searchWidgets.singleRecipeSearch.ingredients_list[list_idx]
    Object.assign(new_obj, old_obj)
    // need to change amt
    let new_amt = Math.round(((old_obj.amt/old_obj.serving_sizes.ss_amts[old_obj.serving_size_idx])*new_obj.serving_sizes.ss_amts[new_serving_size_index])*100)/100
    new_obj['serving_size_idx'] = new_serving_size_index
    new_obj['amt'] = new_amt
    // swap into ingredients list
    let new_ingredients_list = Array.from(store.getState().searchWidgets.singleRecipeSearch.ingredients_list)
    new_ingredients_list.splice(list_idx,1, new_obj)
    return dispatch =>{
        dispatch(loadingSingleRecipe({
            ingredients_list:new_ingredients_list
        }))
    }
}

export const changeMealServingSizeIndex =(list_idx, new_serving_size_index)=>{
    let new_obj = {}
    let old_obj = store.getState().searchWidgets.singleMealSearch.foods_list[list_idx]
    Object.assign(new_obj, old_obj)
    // need to change amt
    let new_amt = Math.round(((old_obj.amt/old_obj.serving_sizes.ss_amts[old_obj.serving_size_idx])*new_obj.serving_sizes.ss_amts[new_serving_size_index])*100)/100
    new_obj['serving_size_idx'] = new_serving_size_index
    new_obj['amt'] = new_amt
    // swap into foods list
    let new_foods_list = Array.from(store.getState().searchWidgets.singleMealSearch.foods_list)
    new_foods_list.splice(list_idx,1, new_obj)
    return dispatch =>{
        dispatch(loadingSingleMeal({
            foods_list:new_foods_list
        }))
    }
}

export const addRecipeInstruction = (instructions_input)=>{
    let new_instructions_list = Array.from(store.getState().searchWidgets.singleRecipeSearch.instructions_list)
    new_instructions_list.push(instructions_input)
    return dispatch =>{
        dispatch(loadingSingleRecipe({
            instructions_list:new_instructions_list
        }))
    }
}

export const deleteRecipeInstruction = (idx)=>{
    let new_instructions_list = Array.from(store.getState().searchWidgets.singleRecipeSearch.instructions_list)
    new_instructions_list.splice(idx,1)
    return dispatch =>{
        dispatch(loadingSingleRecipe({
            instructions_list:new_instructions_list
        }))
    }
}
const resetSingleRecipe = searchObj => ({
    type:'RESETTING_SINGLE_RECIPE_SEARCH',
    payload: searchObj
})

const resetSingleMeal = searchObj => ({
    type:'RESETTING_SINGLE_MEAL_SEARCH',
    payload: searchObj
})

export const getMeal = (meal_id)=>{
    // get a single meal
    return dispatch=>{
        axios.post(url_root+'/plan/get_meal/',
        {
            params:{
                meal_id:meal_id
            }
        },
            {headers:configHeaders(is_prod),
        }).then((response)=>{
            // Whole other axios post
            let result = response["data"]
            let meal_obj = result['meal_dict']
            dispatch(loadingSingleMeal({
                meal_id:meal_obj.id,
                mealname:meal_obj.mealname,
                foods_list:result['meal_foods_list'],
                default_meal:meal_obj.meal_type,
            }))
        }).catch((err)=>{
            console.log(err)
        }).then(()=>{
            
        })
        }//end of dispatch
}

const loadingSingleMeal = searchObj => ({
    type:'LOAD_SINGLE_MEAL',
    payload: searchObj
})

