
import React, {Component} from 'react';
import {withStyles, fade } from '@material-ui/core/styles';
import {searchFoods, searchMeals} from '../../actions/search';
import FoodSearchResults from './search_results';
import {
        IconButton,
        MenuItem,
        Toolbar,
        Typography,
        FormControl,
        FormControlLabel,
        Switch,
        Select,
        Input,
        InputBase,
    } from '@material-ui/core/';
import SearchIcon from '@material-ui/icons/Search';
import {connect} from 'react-redux';

const useStyles = theme =>({
//logic about mobile menu vs reg menu goes here
    sectionDesktop:{
        display:'none',
        [theme.breakpoints.up('md')]:{
            display:'flex',
        },
    },
    sectionMobile:{
        display:'flex',
        [theme.breakpoints.up('md')]:{
            display:'none',
        },
    },
    searchSec:{
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
          backgroundColor: fade(theme.palette.common.white, 0.25),
        },
    }
})

class SearchSubBar extends Component {
    
    constructor(props){
        super(props)
        this.state = {
              search_qry:'',
              search_type:"food",
              food_type:["recipe","grocery","restaurant","raw ingredient"],
              previous_foods:false,
              user_added_only:false, 
              brand:null,
              brand_add_qry:null,
              food_tags:true,
          }
    }

    getSearchResult = event =>{
        if(this.state.search_qry===''){
            alert("Please enter a search term")
        }
        else if(this.state.search_type=='food'){
            this.props.searchFoods(this.state)
        }
        else if(this.state.search_type=='meal'){
            this.props.searchMeals({
                ...this.state,
                ...{destination:'searchResults'}
            })
        }
    }

    searchOptions(){
        switch(this.state.search_type){
            case 'food': return (<React.Fragment>
                <FormControl
                style={{ "width":"100px"}}
                >
                    {/*<InputLabel htmlFor="food_type">Food Type</InputLabel>*/}
                    <Select
                    style={{
                        margin:5
                    }}
                    value={this.state.food_type}
                    name="Food Type"
                    onChange = {(event)=>{this.setState({food_type:event.target.value})}}
                    inputProps ={{
                        name:'food_type',
                        id:'food_type'
                    }}
                    multiple={true}
                    renderValue= { (value) =>{
                        if(value.length ==  4){
                            return "All"
                        }
                        else if(value.length==1){
                            switch (value[0]) {
                                case "recipe": return "Recipes"
                                case "grocery": return "Grocery"
                                case "restaurant": return "Restaurant"
                                case "raw ingredient": return "Raw Ingredients"
                                }
                        }
                        else{
                            return "(Multiple)"
                        }

                        }
                    }
                    >
                        {/*<MenuItem value={["recipe","grocery","restaurant"," "]}>All</MenuItem>*/}
                        <MenuItem value={"recipe"}>Recipes</MenuItem>
                        <MenuItem value={"grocery"}>Grocery</MenuItem>
                        <MenuItem value={"restaurant"}>Restaurant</MenuItem>
                        <MenuItem value={"raw ingredient"}>Raw Ingredient</MenuItem>
                    </Select>

                </FormControl>
                <div
                    style={{
                        backgroundColor: fade("#fff", 0.15),
                        verticalAlign:"bottom",
                        margin:5,
                        // borderBottom:"1px solid black"
                    }}
                >
                <InputBase
                    placeholder={"Brand"}
                    fullWidth ={false}
                    style={{
                        verticalAlign:"bottom",
                        width:75,
                    }}
                    onChange={(event)=>{this.setState({brand:event.target.value})}}
                    />
                </div>
                </React.Fragment>)
            case 'meal': return (
                <React.Fragment
                >
                    <Typography variant="caption">
                        All
                    </Typography>
                    <FormControl
                        style={{textAlign:"center",
                            padding:0,
                            margin:0}}
                    >
                        <FormControlLabel
                        control={
                            <Switch 
                                color="primary"
                                checked = {Boolean(this.state.previous_foods)}
                                value={this.state.previous_foods}
                                onChange={(event)=>{this.setState({previous_foods:!this.state.previous_foods})}}
                            />
                        }
                        >
                        </FormControlLabel>
                    </FormControl>
                    <Typography variant="caption">
                        Mine
                    </Typography>
                </React.Fragment>
            )
            case 'brand': return null
            case 'tag': return null
            default: return null
        }
    }

    render(){
        return (
            <div style={{
                maxWidth:360,
                margin:"auto",
                backgroundColor:"var(--sec_light)",
                paddingLeft:5,
                paddingRight:5,
                // border:"5px solid var(--sec_light)",
            }}
            >
            <div style={{
                    backgroundColor:"var(--sec_light)",
                    maxWidth:360,
                    margin:"auto",
                    // boxShadow:"0px 5px 7px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0)",
                    paddingRight:"5px",
                    paddingLeft: "5px",
                    display:"flex",
                    flexDirection:"row",
                    alignItems:"center", 
                    justifyContent:"space-evenly",
                    }}
            >
                <FormControl
                style={{ "width":"100px"}}
                >
                    {/*<InputLabel htmlFor="search_type">Search Type</InputLabel>*/}
                    <Select
                        style={{
                            margin:5
                        }}
                        value={this.state.search_type}
                        name="search type"
                        onChange = {(event)=>{this.setState({search_type:event.target.value})}}
                        inputProps ={{
                            name:'search_type',
                            id:'search_type'
                        }}
                        renderValue={value =>{
                            const names_dict = {
                                "food": "Foods",
                                "meal":"Meals",
                                "tag":"Tags",
                                "brand":"Brands"
                            }
                            return names_dict[value]
                        }}
                    >
                        <MenuItem value={"food"}>Foods</MenuItem>
                        <MenuItem value={"meal"}>Meals</MenuItem>
                        {/* <MenuItem value={"tag"}>Tags</MenuItem>
                        <MenuItem value={"brand"}>Brands</MenuItem> */}
                    </Select>
                </FormControl>
                {this.searchOptions()}
                </div>
                <div
                style={{
                    margin:10,
                    backgroundColor: fade("#fff", 0.15),
                    verticalAlign:"bottom",
                }}
                >
                    <InputBase
                    style={{
                        verticalAlign:"bottom",
                        paddingLeft:"3px",
                        // "maxWidth":"75px",
                        width:"85%"

                    }}
                    placeholder={"Search..."}
                    fullWidth={false}
                    onChange={(event)=>{this.setState({search_qry:event.target.value})}}
                    />
                    <IconButton
                        size= {"small"}
                        onClick={this.getSearchResult}
                    >
                        <SearchIcon/>
                    </IconButton>
                </div>
           
            {/*bring inside of div */}
            <FoodSearchResults/>
            </div>
        )
    }
}

export default connect(null, {searchFoods, searchMeals})(withStyles(useStyles)(SearchSubBar));

