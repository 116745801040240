import axios from 'axios'// since fetch api not supported in all browsers
import store from '../store'
import {resetNthSolve} from './types';
import {updatingSingleDayNutrients} from './drag';
import {url_root, meals, sumNutrientTotals, configHeaders,is_prod} from './util';


export const clearMeals = (plannum, meal_type_list) =>{

    return dispatch=>{
        meal_type_list.map((meal_type, idx)=>{
            dispatch(clearingMeal({
                plannum:plannum,
                meal_type: meal_type,
                foods_list:[]
            }))
            dispatch(resetNthSolve({}))
        })
        let new_nutrient_totals = sumNutrientTotals(store.getState().columns.present[plannum], null, [])
        // update user nutrients
        dispatch(updatingSingleDayNutrients({
            plannum:plannum,
            nutrient_totals:new_nutrient_totals
        }))

    // update backend
    axios.post(url_root+'/plan/clear_meals/',
    {
        params:{
            plan_date:store.getState().util.days_dict[plannum],
            meal_type_list:meal_type_list
        }
    },
    {                                               // config
        headers:configHeaders(is_prod),
    }
    )
    .then(res =>{
        console.log(res)
    })
    .catch(err=>{
        console.log(err)
    })
    .finally(()=>{
    })
    }// end of dispatch return 
}

const clearingMeal = nutObj => ({
    type:'CLEAR_MEAL',
    payload: nutObj
})
