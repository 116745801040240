import axios from 'axios'// since fetch api not supported in all browsers
import store from '../store';
import {resetNthSolve} from './types';
import {meals, sumNutrientTotals, updateSingleMeal,is_prod,configHeaders} from './util';

export const updateFoodAmount = foodObj =>{
    // Check if item comes from a meal obj
    if(foodObj.subcol){
        let state_slice = store.getState().columns.present[foodObj.col]
        let foods_list = Array.from(state_slice[foodObj.subcol].foods_list)
        // change amt and idx in foods_list
        foods_list[foodObj.drag_idx].amt=foodObj.amt
        foods_list[foodObj.drag_idx].serving_size_idx=foodObj.serving_size_idx
        // update amounts
        updateSingleMeal(foodObj.col, foodObj.subcol, foods_list)
        if(foodObj.change_item=='amt'){
            let new_nutrient_totals = sumNutrientTotals(state_slice,foodObj.subcol, foods_list)
            return dispatch=>{
                dispatch(updatingFoodAmount({
                    col:foodObj.col,
                    subcol:foodObj.subcol,
                    foods_list:foods_list
                }))
                dispatch(updatingSingleDayNutrients({
                    plannum:foodObj.col,
                    nutrient_totals:new_nutrient_totals
                }))
                dispatch(resetNthSolve({}))
            }
        }else{
            return dispatch=>{
                dispatch(updatingFoodUnit({
                    col:foodObj.col,
                    subcol:foodObj.subcol,
                    foods_list:foods_list
                }))
            }
        }
        }else{
            let state_slice = store.getState().columns.present[foodObj.col]
            let foods_list = state_slice.foods_list
            foods_list[foodObj.drag_idx].amt=foodObj.amt
            foods_list[foodObj.drag_idx].serving_size_idx=foodObj.serving_size_idx
            return dispatch=>{
                dispatch(updatingFoodAmountSearch({
                    col:foodObj.col,
                    foods_list:foods_list
                }))
            }
    }
}

const updatingFoodAmount = FoodObj => ({
    type:'UPDATE_FOOD_AMOUNT',
    payload: FoodObj
})

const updatingFoodUnit = FoodObj => ({
    type:'UPDATE_FOOD_UNIT',
    payload: FoodObj
})

const updatingFoodAmountSearch = FoodObj => ({
    type:'UPDATE_FOOD_AMOUNT_SEARCHH',
    payload: FoodObj
})

const updatingSingleDayNutrients = nutObj => ({
    type:'UPDATE_SINGLE_DAY_NUTRIENTS',
    payload: nutObj
})
