import initialState from '../initialState'

export default function(state=initialState, action){
    switch(action.type){
        case 'MODIFYING_MEAL_NAME_DIALOG':
            return{
                ...state,
                mealname_dialog:{
                    ...state.mealname_dialog,
                    ...action.payload
                }
            }
        case 'MODIFYING_AUTOPLAN_FAIL_DIALOG':
            return{
                ...state,
                autoplan_snackbar:{
                    ...state.autoplan_snackbar,
                    open:action.payload.open,
                    message:action.payload.message,
                }
            }
        case 'SHOW_ALTERNATIVES_DIALOG':
            return{
                ...state,
                alternatives_dialog:{
                    ...state.alternatives_dialog,
                    open:true,
                    alts_menu_list:action.payload.alts_menu_list,
                    alts_other_list:action.payload.alts_other_list,
                    similar_list:action.payload.similar_list,
                    alt_idx:action.payload.alt_idx,
                    plannum:action.payload.plannum,
                    meal_type:action.payload.meal_type,
                }
            }
        case 'CLOSE_ALTERNATIVES_DIALOG':
            return{
                ...state,
                alternatives_dialog:{
                    ...state.alternatives_dialog,
                    open:false,
                    alts_menu_list:[],
                    alts_other_list:[],
                    similar_list:[],
                    alt_idx:0,
            }
        }
        case 'AUTH_SNACKBAR':
            return{
                ...state,
                auth_snackbar:{
                    ...state.auth_snackbar,
                    open:action.payload.open,
                    message:action.payload.message,
                }
            }
        case 'TOGGLE_PAYMENT_SNACKBAR':
            return{
                ...state,
                payment_snackbar:{
                    ...state.payment_snackbar,
                    open:action.payload.open,
                    message:action.payload.message,
                }
            }
            case 'TOGGLE_CANCEL_DIALOG':
                return {
                ...state,
                cancelation_dialog:{
                    ...state.cancelation_dialog,
                    open:action.payload.open,
                },
            }
            case 'TOGGLE_PASSWORD_DIALOG':
                return {
                    ...state,
                    password_dialog:{
                        ...state.password_dialog,
                        open:action.payload.open,
                    }
                }
            case 'TOGGLE_GENERIC_SNACKBAR':
                return {
                    ...state,
                    generic_snackbar:{
                        ...state.generic_snackbar,
                        open:action.payload.open,
                        message:action.payload.message,
                    }
                }
        default:
            return state;
    }
}
    