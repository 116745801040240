import React, { Component } from 'react';
import './auth.css';
import {connect} from 'react-redux';
import {NavLink} from 'react-router-dom';
import {Button, 
        IconButton,
        Input, 
        TextField,
        Typography,
        Tooltip,
        FormControlLabel,
        Checkbox,
    } from '@material-ui/core/';
import {userPostFetch,postUser} from '../../actions/auth';
import {toggleTermsAndConditions} from '../../actions/dialog';
import TermsAndConditionsModal from '../legal/terms_and_conditions';
import InfoIcon from '@material-ui/icons/Info';
import CheckIcon from '@material-ui/icons/Check';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';

// import FacebookIcon from '@material-ui/icons/Facebook';

class SignUp extends Component {
    constructor(props){
        super(props)
        this.state = {
          email:null,
          password1: null,
          password2: null,
          ts_agree:true,
          privacy_agree:true,
        } 
    }

    sendUserInfo =() =>{
        if(!this.state.ts_agree){
            return alert("Please Accept The Terms and Conditions to Continue")
        }
        if((this.state.email==='')|(this.state.email===' ')|(this.state.email===null)){
            return alert("Please enter your email address")
        }
        else if ((this.state.password1==='')|(this.state.password1===' ')|(this.state.password1===null)){
            return alert("Please enter a password")
        }
        else if (this.state.password1 != this.state.password2){
            return alert("Passwords do not match")
        }
        else{
            this.props.postUser({
                username:this.state.email,
                email:this.state.email,
                password1: this.state.password1,
                password2: this.state.password2,
            })
        }
    }

    onToggleCheckbox = ()=>{
        this.setState({ts_agree:!this.state.ts_agree})
      }

    onTogglePrivacyCheckbox = ()=>{
        this.setState({privacy_agree:!this.state.privacy_agree})
      }
  
    openTermsAndConditions =() =>{
        this.props.toggleTermsAndConditions(true)
      }

    render(){
        return (
            <div style={{
                    marginTop:20,
                    margin:"auto",
                    display:'flex', 
                    flexDirection:'column', 
                    justifyContent:'center',
                    alignItems:'center'}}>
                {/* <Typography 
                    variant='subheader1'
                    style={{
                        color:"var(--main_dark)",
                    }}
                >
                    Create A Login
                </Typography> */}
                <Input
                    style={{width:"320px", marginBottom:"10px"}}
                    // variant="filled"
                    // label="Email"
                    placeholder={'Email'}
                    margin="none"
                    onChange={(event)=>{this.setState({email:event.target.value})}}
                />
                <Input
                    style={{width:"320px", marginBottom:"10px"}}
                    // variant="filled"
                    // label="Password"
                    type={'password'}
                    placeholder={'Password'}
                    margin="none"
                    onChange={(event)=>{this.setState({password1:event.target.value})}}
                />
                <Input
                    style={{width:"320px", marginBottom:"10px"}}
                    // variant="filled"
                    // label="Confirm Password"
                    type={'password'}
                    placeholder={'Confirm Password'}
                    margin="none"
                    onChange={(event)=>{this.setState({password2:event.target.value})}}
                />
                <Button
                    variant="outlined"
                    className="SignUpButton"
                    style ={{
                        color:'white',
                        textTransform:"none",
                        backgroundColor:"var(--main_bright)",  
                        fontWeight:"bold",
                        fontSize:16,
                        height:45,

                }}
                onClick={this.sendUserInfo}
                >Sign Up</Button>
                <TermsAndConditionsModal />
                <div
                  style={{
                    display:"flex",
                    flexDirection:"row",
                  }}
                >
                <FormControlLabel
                    style={{
                            padding:0, 
                            paddingRight:10,
                            margin:0,
                            color:"grey",
                            fontSize:8
                        }}
                    control={
                    <Checkbox
                        style={{padding:0,margin:0}}
                        icon={<CheckBoxOutlineBlankIcon style={{color:"grey"}} />}
                        checkedIcon={<CheckBoxIcon style={{color:"grey"}} />}
                        checked={this.state.ts_agree}
                        onChange={this.onToggleCheckbox}
                    />
                    }
                />
                <Typography 
                  variant="subtitle1"
                  style={{
                    color:"grey",
                  }}
                >I agree to the 
                <NavLink to="/terms_and_conditions">
                    Terms And Conditions
                </NavLink>
                </Typography>
                {/* <IconButton
                    size="small"
                    edge="end"
                    onClick={(event)=>{this.props.toggleTermsAndConditions(true)}} 
                >
                    <InfoIcon 
                        style={{
                            fontSize:18,
                            color:"grey"
                        }}
                    />
                </IconButton> */}
                </div>
                <div
                  style={{
                    display:"flex",
                    flexDirection:"row",
                  }}
                >
                <FormControlLabel
                    style={{
                            padding:0, 
                            paddingRight:10,
                            margin:0,
                            color:"grey",
                            fontSize:8
                        }}
                    control={
                    <Checkbox
                        style={{padding:0,margin:0}}
                        icon={<CheckBoxOutlineBlankIcon style={{color:"grey"}} />}
                        checkedIcon={<CheckBoxIcon style={{color:"grey"}} />}
                        checked={this.state.privacy_agree}
                        onChange={this.onTogglePrivacyCheckbox}
                    />
                    }
                />
                <Typography 
                  variant="subtitle1"
                  style={{
                    color:"grey",
                  }}
                >I agree to the <NavLink to="/privacy">Privacy Policy</NavLink></Typography>
                </div>
        </div>
        )
    }
}

export default connect(null, {postUser,toggleTermsAndConditions})(SignUp);