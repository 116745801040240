import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
  Button,
  Input,
  Typography,
}from '@material-ui/core/';
import {
    ElementsConsumer, 
    CardElement,
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement,
    PaymentRequestButtonElement,
  } from '@stripe/react-stripe-js';
import {stripePaymentMethodHandler} from '../../actions/account';

class CheckoutForm extends Component{
// props - circle/square, image
    constructor(props){
        super(props)
        this.state = {
          email:this.props.email,
        } 
    }


    handleSubmit = async (event) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();
    
        const {stripe, elements} = this.props
        if (!stripe || !elements) {
          // Stripe.js has not yet loaded.
          // Make  sure to disable form submission until Stripe.js has loaded.
          return;
        }
        
        if((this.state.email===null)|(this.state.email==='')){
          alert("Please enter your email")
          return;
        }
        const result = await stripe.createPaymentMethod({
          type: 'card',
          card: elements.getElement(CardElement),
          billing_details: {
            email: this.state.email, 
          },
        });
        this.props.stripePaymentMethodHandler(result);
      };

    render(){   
        const {stripe} = this.props;
        const CARD_OPTIONS = {
          iconStyle: 'solid',
          style: {
            base: {
              height:24,
              iconColor: 'lightGrey',
              color: '#fff',
              fontWeight: 500,
              fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
              fontSize: '16px',
              fontSmoothing: 'antialiased',
              ':-webkit-autofill': {
                color: '#fce883',
              },
              '::placeholder': {
                color: 'lightGrey',
                fontSize:'16px',
                // fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
              },
            },
            invalid: {
              iconColor: '#FFC7EE',
              color: '#FFC7EE',
            },
          },
        };
        
        return (
            <div
                style={{
                  // marginTop:50,
                  width:320,
                  // height:450,
                  marginBottom:10,
                }}
            >
              <div
                style={{
                  marginBottom:20,
                }}
              >
              <Input
                style={{
                  width:"100%",
                  textAlign:"left",
                  color:"darkGrey",
                }}
                value={this.state.email}
                placeholder={"Email"}
                onChange={(event)=>{this.setState({email:event.target.value})}}
                inputProps={{
                  style:{
                    textAlign:"left",
                    fontSize:16,
                    color:"darkGrey",
                    fontWeight: 500,
                    fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
                  }
                }}
              />
              </div>
                <form onSubmit={this.handleSubmit}>
                <label>
                <div
                  style={{
                    display:"flex",
                    flexDirection:"column",
                    marginBottom:20,
                  }}
                >
                  <CardElement
                    options={CARD_OPTIONS}
                  />
                </div>
                </label>
                <Button 
                  variant="contained"
                  component="button" 
                  type="submit" 
                  disabled={!stripe}
                  style={{
                    color:"white",
                    backgroundColor:"var(--main_bright)",
                    width:"100%",
                    marginBottom:20,
                  }}
                >
                  Subscribe</Button>
                </form>
                <Typography 
                  variant="subtitle1"
                  style ={{
                    color:"darkGrey",
                    fontSize:14,
                  }}
                >Your card will immediately be charged $9.00 and will be billed monthly thereafter.
                </Typography>
                
            </div>
        )
    }
}

class InjectedCheckoutForm extends Component{
    
    render(){
        
    const {stripe, elements} = this.props
    return (
        <div
            style={{
                margin:"auto",
                width:320,
                display: this.props.status=='full'? "none" : "",
            }}
        >
      <ElementsConsumer>
        {({stripe, elements}) => (
          <CheckoutForm  
            stripe={stripe} 
            elements={elements} 
            stripePaymentMethodHandler = {this.props.stripePaymentMethodHandler}
            email={this.props.email}
            status={this.props.status}
          />
        )}
      </ElementsConsumer>
      </div>
    )
    }
  }

  const mapStateToProps = state => ({
    email:state.user.account.email,
    status: state.user.account.status,
  })

export default connect(mapStateToProps,{stripePaymentMethodHandler})(InjectedCheckoutForm);