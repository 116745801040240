import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Typography,
        ExpansionPanel,
        ExpansionPanelSummary,
        ExpansionPanelDetails,
        Table,
        TableBody,
        TableCell,
        TableHead,
        TableRow,
}from '@material-ui/core/';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

class MacroFacts extends Component{

    constructor(props){
        super(props)
        this.state = {
            overview:true,
            protein:false,
            fat:false,
            carb:false,
            approaches:false,
          }
    }

    render(){        
        return (
            <div
                style={{
                    overflowY:"auto",
                    scollbarWidth:"thin",
                }}
            >
                <ExpansionPanel 
                    expanded={this.state.overview}
                    onChange={(event)=>{this.setState({overview:!this.state.overview})}}
                >
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon/>}
                    >
                        <Typography>Overview</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails
                    >
                        <Typography>
                            Generally every calorie we consume (besides alcohol) is either a carbohydrate, protein, or fat. Thus the most fundamental aspect of a diet is the balance between the three. Popular diets like Keto, Atkins and IIFMM (If It Fits MY Macros) are based on defining an optimal macro balance. On the following page, we’ll look at what options that might be right for you. 
                        </Typography>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel 
                    expanded={this.state.protein}
                    onChange={(event)=>{this.setState({protein:!this.state.protein})}}
                >
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon/>}
                    >
                        <Typography>Protein</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails
                    >
                        <Typography>
                        Proteins are used to build, grow and repair tissues throughout the body, including muscle. However, they can also be used as energy if the body is not getting enough calories from dietary sources or fat tissue stores.  
                        </Typography>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel 
                    expanded={this.state.fat}
                    onChange={(event)=>{this.setState({fat:!this.state.fat})}}
                >
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon/>}
                    >
                        <Typography>Fat</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Typography>
                        Fats are required for tissue growth and hormone production. And along with carbs are of the main  sources of dietary energy. Certain fats, called essential fatty acids are mandatory in one’s diet as they are important for the body to function but aren’t produced by your body – they can only be consumed from food. Other fats, such as saturated fats and trans fats tend to play a negative role in the body and these are recommended to be limited to less than 10% of total calories each day. 
                        </Typography>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel 
                    expanded={this.state.carb}
                    onChange={(event)=>{this.setState({carb:!this.state.carb})}}
                >
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon/>}
                    >
                        <Typography>Carbohydrates</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Typography>
                        Carbohydrates provide energy. Specifically, they break down into glucose which provides the energy to our cells is usually the main energy source for the body. Carbohydrates can also be converted into glycogen which is stored for later use as an energy source.                        </Typography>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel 
                    expanded={this.state.approaches}
                    onChange={(event)=>{this.setState({approaches:!this.state.approaches})}}
                >
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon/>}
                    >
                        <Typography>Approaches</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails
                        style={{
                            display:"flex",
                            flexDirection:"column",
                        }}
                    >
                    <div
                        style={{
                            marginBottom:10,
                        }}
                    >
                    <Typography 
                        variant="h6"
                        style={{
                            textDecoration:"underline",
                        }}
                    >
                        Food & Nutrition Board: Institute of Medicine
                    </Typography>
                    <div
                        style={{
                            marginBottom:5,
                        }}                    
                    >
                    <Typography
                        variant="body1"
                    >
                        For those looking for a healthy and well-recommended  macro balance
                    </Typography>
                    </div>
                    <div
                        style={{
                            display:"flex",
                            justifyContent:"space-between",
                            textAlign:"center",
                        }}
                    >
                        <div
                        >
                            <div
                                style={{
                                    textDecoration:"underline",
                                    textAlign:"center",
                                }}
                            >Protein</div>
                            <div
                                style={{
                                    textAlign:"center"
                                }}
                            >10-35%</div>
                        </div>
                        <div                   
                        >
                            <div
                                style={{
                                    textDecoration:"underline",
                                    textAlign:"center",
                                }}
                            >Fat</div>
                            <div
                                style={{
                                    textAlign:"center"
                                }}                            
                            >20-35%</div>
                        </div>
                        <div
                        
                        >
                            <div
                                style={{
                                    textDecoration:"underline",
                                    textAlign:"center",
                                }}
                            >Carbohydrates</div>
                            <div
                                style={{
                                    textAlign:"center"
                                }}                            
                            >45-65%</div>
                        </div>
                    </div>
                    </div>
                    <div
                        style={{
                            marginBottom:10,
                        }}
                    >
                    <Typography 
                        variant="h6"
                        style={{
                            textDecoration:"underline",
                        }}
                    >
                        Bodymass-First Approach
                    </Typography>
                    <div
                        style={{
                            marginBottom:5,
                        }}                    
                    >
                    <Typography
                        variant="body1"
                    >
                        For those looking to build or maintain muscle
                    </Typography>
                    </div>
                    <div
                        style={{
                            display:"flex",
                            justifyContent:"space-between",
                        }}
                    >
                        <div
                            style={{
                                textAlign:"center"
                            }}
                        >
                            <div
                                style={{
                                    textDecoration:"underline",
                                }}
                            >Protein</div>
                            <div
                                style={{
                                    // width:60,
                                }}
                            >1 gram/lb</div>
                        </div>
                        <div
                            style={{
                                textAlign:"center"
                            }}                        
                        >
                            <div
                                style={{
                                    textDecoration:"underline",
                                }}
                            >Fat</div>
                            <div>25%</div>
                        </div>
                        <div
                            style={{
                                textAlign:"center"
                            }}                        
                        >
                            <div
                                style={{
                                    textDecoration:"underline",
                                }}
                            >Carbohydrates</div>
                            <div>Remaining cals</div>
                        </div>
                    </div>
                    </div>
                    <div
                        style={{
                            marginBottom:10,
                        }}
                    >
                    <Typography 
                        variant="h6"
                        style={{
                            textDecoration:"underline",
                        }}
                    >
                        Keto Diet
                    </Typography>
                    <div
                        style={{
                            marginBottom:5,
                        }}                    
                    >
                    <Typography
                        variant="body1"
                    >
                        For those looking for fast weight loss and understand the process of Ketosis
                    </Typography>
                    </div>
                    <div
                        style={{
                            display:"flex",
                            justifyContent:"space-between",
                        }}
                    >
                        <div
                            style={{
                                textAlign:"center",
                            }}
                        >
                            <div
                                style={{
                                    textDecoration:"underline",
                                }}
                            >Protein</div>
                            <div>20-30%</div>
                        </div>
                        <div
                            style={{
                                textAlign:"center",
                            }}               
                        >
                            <div
                                style={{
                                    textDecoration:"underline",
                                }}
                            >Fat</div>
                            <div>60-75%</div>
                        </div>
                        <div
                        style={{
                            textAlign:"center",
                        }}                        
                        >
                            <div
                                style={{
                                    textDecoration:"underline",
                                }}
                            >Carbohydrates</div>
                            <div>5-10%</div>
                        </div>
                    </div>
                    </div>
                    <div
                        style={{
                            marginBottom:10,
                        }}
                    >
                    <Typography 
                        variant="h6"
                        style={{
                            textDecoration:"underline",
                        }}
                    >
                        Low Carb
                    </Typography>
                    <div
                        style={{
                            marginBottom:5,
                        }}                    
                    >
                    <Typography
                        variant="body1"
                    >
                        For those who have trouble controlling sugar intake or feel better without carbs
                    </Typography>
                    </div>
                    <div
                        style={{
                            display:"flex",
                            justifyContent:"space-between",
                        }}
                    >
                        <div
                            style={{
                                textAlign:"center",
                            }}
                        >
                            <div
                                style={{
                                    textDecoration:"underline",
                                }}
                            >Protein</div>
                            <div>15-30%</div>
                        </div>
                        <div
                            style={{
                                textAlign:"center",
                            }}               
                        >
                            <div
                                style={{
                                    textDecoration:"underline",
                                }}
                            >Fat</div>
                            <div>40-70%</div>
                        </div>
                        <div
                        style={{
                            textAlign:"center",
                        }}                        
                        >
                            <div
                                style={{
                                    textDecoration:"underline",
                                }}
                            >Carbohydrates</div>
                            <div>15-30%</div>
                        </div>
                    </div>
                    </div>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </div>
        )
    }
}

export default connect(null,null)(MacroFacts);