import React, {Component} from 'react';
import {connect} from 'react-redux';
// import { makeStyles, useTheme } from '@material-ui/core/styles';
import {closedOnboardingModal} from '../../actions/start';
import {updateCalReq,saveMacros} from '../../actions/nutrients';
import {getOnboardingMenus, cloneMenu} from '../../actions/menu';
import {
    Button,
    MobileStepper,
    Modal,
    Typography,
}from '@material-ui/core/';
// Components
import CalorieQuestionaire from '../nutrient_page/cal_questionaire';
import MacroFacts from '../nutrient_page/macro_facts';
import MacroForm from '../nutrient_page/macro_form';
import AutoplannerHowTo from './autoplanner_how_to';
import CalorieConfirm from './calorie_confirm';
import MenuSelect from './onboarding_menu_select';
// Material Icons
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';


class OnboardingModal extends Component{
// props - circle/square, image
    constructor(props){
        super(props)
        this.state = {
            step:0,
        } 
    }
    onClickClose =() =>{
        this.props.closedOnboardingModal()
    }

    handleNext = ()=>{
        let curr_step = this.state.step
        // State saves
        if(curr_step===0){
            this.props.updateCalReq(this.props.user_stats)
        }else if(curr_step===2){
            this.props.saveMacros()
            this.props.getOnboardingMenus()
        }else if (curr_step===3){
            this.props.cloneMenu(true, true)
        }
        // update step
        this.setState({step:curr_step+1})
    }

    handleBack = () =>{
        let curr_step = this.state.step
        this.setState({step:curr_step-1})
    }

    displayStep = () =>{
        let step = this.state.step
        switch(step){
            case 0:
                return (
                    <CalorieQuestionaire show_button={false}/>
                )
            // case 1:
            //     return(
            //         <CalorieConfirm/>
            //     )
            case 1:
                return(
                    <MacroFacts />
                )
            case 2:
                return(
                    <MacroForm />
                )
            case 3:
                return(
                    <MenuSelect/>
                )
            case 4:
                return(
                    <AutoplannerHowTo/>
                )
        }
    }

    render(){     
        let messages = [
            "First, let's determine your daily calorie needs",
            // "Let's see how that affected your calories",
            "Perfect! Next, let's learn a little about macros",
            "Now let's choose your macro balance",
            // "Let's make sure the autoplanner is suggesting foods you like",
            "The autoplanner will pull from a menu of foods you love. You can fully customize it in a moment, but let's pick one menu to start with.",
            "Finally, let's learn how the auto planner works",
        ]   
        return (
            <Modal
                style={{
                    // width:360
                }}
                onClose={this.onClickClose}
                open={this.props.open}
            >
                <div
                    style={{
                        width:360,
                        margin:"auto",
                        backgroundColor:"white"
                    }}
                >
                    <Typography
                        variant="h6"
                        style={{
                            color:"grey",
                            width:320,
                            textAlign:"center",
                            margin:"auto",
                        }}
                    >
                        {messages[this.state.step]}
                    </Typography>
                    {this.displayStep()}
                    <MobileStepper
                        style={{
                            // color:"var(--main_bright)",
                        }}
                        steps={messages.length}
                        position="static"
                        variant="text"
                        activeStep={this.state.step}
                        nextButton={
                        <Button size="small" 
                            onClick={this.handleNext} 
                            disabled={this.state.step === messages.length - 1}>
                            Next
                            {/* {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />} */}
                            <KeyboardArrowRight                         
                            />
                        </Button>
                        }
                        backButton={
                        <Button 
                            size="small" 
                            onClick={this.handleBack} 
                            disabled={this.state.step === 0}>
                            {/* {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />} */}
                            {<KeyboardArrowLeft 
                            />}
                            Back
                        </Button>
                    }
                    />
                </div>
            </Modal>
        )
    }
}

const mapStateToProps = (state,ownProps) => ({
    open:state.user.user_stats.first_session,
    user_stats:state.user.user_stats,
})

export default connect(mapStateToProps,{closedOnboardingModal,
                                        updateCalReq,
                                        saveMacros,
                                        getOnboardingMenus,
                                        cloneMenu,
                                    })(OnboardingModal);