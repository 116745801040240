import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Avatar,
    IconButton,
    Input,
    TextField,
    Typography
}from '@material-ui/core/';
import NavBar from '../common/navbar';
import EditIcon from '@material-ui/icons/Edit';
import {getProfile,updateProfileItem, updateProfilePhoto} from '../../actions/profile';
import ReactImageFallback from "react-image-fallback";
import AccountCircleIcon from '@material-ui/icons/AccountCircle'; // Account avatar (placeholder)



class ProfilePage extends Component{
// props - circle/square, image
    constructor(props){
        super(props)
        this.state = {
            avatar_url:null,
            avatar_file:null,
            avatar_cropped:false,
            cropper_open:false,
        } 
    }

    componentDidMount =()=>{
        this.props.getProfile()
    }

    onChangeInput = (event)=>{

    }

    onImageUpload =(event)=>{
        this.props.updateProfilePhoto(event.target.files[0])
    }

    render(){        
        return (
            <div>
                <NavBar/>
                <div
                    style={{
                        maxWidth:450,
                        margin:"auto",
                        display:"flex",
                        flexDirection:"column",
                    }}
                >
                <div
                    style={{
                        display:"flex",
                        justifyContent:"center",
                        marginTop:50,
                    }}
                >
                    <input
                        accept="image/*"
                        id="contained-button-file"
                        multiple
                        type="file"
                        style={{
                            display:"none",
                            zindex:2,
                        }}
                        onChange={this.onImageUpload}
                    />
                <label htmlFor="contained-button-file">
                    {/* <IconButton> */}
                    <Avatar 
                        src={this.props.avatar}
                        style={{
                            width:200,
                            height:200,
                            cursor:"pointer",
                            // show edit overlay on hover
                        }}
                    >

                    </Avatar>
                    {/* </IconButton> */}
                </label>

                </div>
                <div
                    style={{
                        display:"flex",
                        justifyContent:"center",
                    }}
                >
                    <TextField 
                        style={{
                            width:320,
                            marginTop:10,
                        }}
                        inputProps={{
                            style:{textAlign:"center"},
                        }}
                        label={"Preferred Name"}
                        variant="outlined"
                        value={this.props.preferred_name}
                        InputLabelProps ={{
                            shrink:true,
                            style:{textAlign:"center"},
                        }}
                        onChange={(event)=>{this.props.updateProfileItem('preferred_name',event.target.value)}}
                    />
                    </div>
                    <div
                        style={{
                            display:"flex",
                            justifyContent:"center",
                        }}
                    >
                        <TextField
                        multiline
                        style={{
                            width:320,
                            marginTop:10,
                        }}
                        inputProps={{
                            // style:{textAlign:"center"},
                        }}
                        label={"Bio"}
                        variant="outlined"
                        value={this.props.bio}
                        InputLabelProps ={{
                            shrink:true,
                            style:{textAlign:"center"},
                        }}
                        rows={5}
                        onChange={(event)=>{this.props.updateProfileItem('bio',event.target.value)}}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state,ownProps) => ({
    preferred_name:state.personal_profile.preferred_name,
    website:state.personal_profile.website,
    bio:state.personal_profile.bio,
    avatar:state.personal_profile.avatar,

})

export default connect(mapStateToProps,{getProfile,updateProfileItem,updateProfilePhoto})(ProfilePage);