import React, {Component} from 'react';
import {connect} from 'react-redux';
import {remove_menu_item} from '../../actions/menu';
import {
    Paper,
    Toolbar,
    IconButton,
    Typography,
    Table,
    TableBody,
    TableHead,
    TableRow,
    TableCell,
    Checkbox
} from '@material-ui/core/';
import DeleteIcon from '@material-ui/icons/Delete';
import InfoIcon from '@material-ui/icons/Info';
import {openFoodModal} from '../../actions/food_modal';

class DishMenu extends Component{

    constructor(props){
        super(props)
        this.state = {

        } 
    }

    removeMenuItem = (remObj) =>{
        this.props.remove_menu_item(remObj)
    }

    showItemModal(item_type,unique_id){
        if(item_type==='food'){
            this.props.openFoodModal(unique_id)
        }
    }

    //shouldComponentUpdate
    render(){        
        return (
            <div
                style={{
                    //width:{if this.props.dishnum=='Snack'?}
                    // width:320,
                    width:"100%",
                    height:110,
                    overflowY:"scroll",
                    scrollbarWidth:"thin"
                }}
            >
            <Table size="small" padding="none" stickyHeader={true}>
                <TableHead>
                    <TableRow>
                        <TableCell
                            style={{width:"10%"}}
                        >
                        </TableCell>
                        <TableCell
                            style={{width:"10%"}}
                        >

                        </TableCell>
                        <TableCell
                            style={{width:"65%"}}
                        >Item</TableCell>
                        <TableCell
                            style={{width:"15%"}}
                        >Type</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                   {this.props.items_list.map( (item, idx)=>(
                       <TableRow
                            key={item.unique_id}
                       >
                           <TableCell
                            style={{width:"10%"}}
                           >
                               <IconButton 
                                size="small" 
                                edge="end"
                                onClick={(event)=>{this.removeMenuItem({
                                    default:true,
                                    idx:idx,
                                    display_page:this.props.display_page,
                                    ...item
                                })}}
                                disabled={this.props.exclude_search}
                            >
                                   <DeleteIcon
                                        fontSize="small"
                                        style={{
                                            fontSize:18,
                                        }}
                                   />
                               </IconButton>
                            </TableCell>
                            <TableCell
                                style={{
                                    width:"10%"
                                }}
                            >
                                <IconButton 
                                    size="small"
                                    edge="end"
                                    onClick={(event)=>{this.showItemModal(item.item_type,item.unique_id)}}
                                >
                                    <InfoIcon
                                        fontSize="small"
                                        style={{
                                            fontSize:17,
                                        }}
                                    />
                                </IconButton>
                            </TableCell>
                           <TableCell
                                style={{width:"65%", 
                                    overflow:"hidden",
                                    textOverflow:"ellipsis"
                                    // overflowX:"scroll", 
                                    // scrollbarWidth:"thin"
                                }}
                           >
                               <div
                                style={{
                                    width:"100%",
                                    overflow:"hidden",
                                    textOverflow:"ellipsis"
                                }}
                               >
                                <Typography variant="caption" noWrap={true}
                                    
                                >
                                        {item.name}
                                </Typography>
                               </div>
                            </TableCell>
                           <TableCell
                           style={{width:"15%"}}
                           >
                               <Typography variant="caption" noWrap={true}>
                                    {item.item_type}
                               </Typography>
                            </TableCell>
                            {/* Max Servings, Max "N" per week */}
                       </TableRow>
                   ))}
                </TableBody>
            </Table>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    // Leaving space in case logic is needed here
    let items_list=[]
    if(ownProps.display_page==='plan'){
        items_list = state.columns.present.menus[ownProps.meal_type][ownProps.dish_num].items_list
    }
    else{
        items_list = state.menu_page.build_menu[ownProps.meal_type][ownProps.dish_num].items_list
    }
    return ({
        items_list:items_list
    })
}

export default connect(mapStateToProps, {remove_menu_item,openFoodModal})(DishMenu);