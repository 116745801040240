import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Drawer,
    IconButton
}from '@material-ui/core/';
import SearchSubBar from './search';
import {searchFoods, searchMeals,togglePlanSearch} from '../../actions/search';
import ClearIcon from '@material-ui/icons/Clear';

class SearchDrawer extends Component{
// props - circle/square, image
    constructor(props){
        super(props)
        this.state = {
            
        } 
    }


    render(){        
        return (
           <Drawer
                variant="persistent"
                anchor="top"
                open={this.props.search}
                style={{
                }}
            >
                <div
                style={{
                    display:"flex",
                    flexDirection:"column",
                    alignItems:"center",
                    justifyContent:"center",
                    backgroundColor:"white",
                    width:"100%",
                }}
                >
                <SearchSubBar
                    searchFoods={this.props.searchFoods}
                    searchMeals={this.props.searchMeals}
                />
                <IconButton
                    size="small"
                    style={{
                        margin:"0 auto",
                        padding:0,
                    }}
                    onClick={(event)=>{this.props.togglePlanSearch({search:false})}}
                >
                    <ClearIcon
                        // fontSize="small"
                    />
                </IconButton>
                </div>
           </Drawer>
        )
    }
}

const mapStateToProps = (state,ownProps) => ({
    search:state.user.search
})

export default connect(mapStateToProps,{searchFoods,searchMeals,togglePlanSearch})(SearchDrawer);