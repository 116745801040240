import React, {Component} from 'react';
import {connect} from 'react-redux';
import NavBar from '../common/navbar';
import DayPlan from './dayplan';
import PlanMenu from './menu';
import FoodModal from './food_modal';
import OnboardingModal from '../how_to/onboarding_modal';
import MealNameDialog from './mealname_dialog';
import FoodAltsDialog from './food_alts_dialog';
import AutoplanSnackbar from './autoplan_snackbar';
import SuggestionSubBar from '../common/autoplan_subbar';
import SearchDrawer from '../common/search_drawer';
import {
    Backdrop,
    CircularProgress,
  }from '@material-ui/core/';
import {addToMeal,reorderSameColumn, removeFromMeal,mealToMeal} from '../../actions/drag';
import {getUserInfo} from '../../actions/start';
import { DragDropContext} from 'react-beautiful-dnd';

class Plan extends Component{

    constructor(props){
        super(props)
        this.state = {
            backdrop:true
        } 
    }

    componentDidMount(){
        this.setState({backdrop:false})
        let today = new Date()
        let n_days = 7
        let days_list = []
        let days_dict = {}
        for(let i = 0; i<n_days; i++){
            let loop_day = new Date(+today+i*86400000)
            let db_date = String(loop_day.getFullYear()) +"-"+ String(loop_day.getMonth()+1).padStart(2,'0') +"-"+ String(loop_day.getDate()).padStart(2,'0')
            days_list.push(db_date)
            days_dict["day"+String(i+1)] = db_date
        }
        let today_db_date = String(today.getFullYear()) +"-"+ String(today.getMonth()+1).padStart(2,'0') +"-"+ String(today.getDate()).padStart(2,'0')
        this.props.getUserInfo(today_db_date,days_list, days_dict)
    }

    onBeforeDragStart = () =>{
    }

    onDragStart = () =>{
        // Turn all the meal backgrsounds a different colors
        // Turn from background a different color
        // Turn trash zones red`
    }

    onDragUpdate = () =>{
    }

    onDragEnd = result =>{
        const {destination, source, draggableId} = result
        //CASE WHEN DESTINATION IS NULL
        if (result.type=='MEAL'){
            if(result.combine){
                this.props.mealToMeal(result)
                return
            }
            else{
                return
            }
        }else if (result.type=='FOOD'){
            if(!destination){ 
                if(source.droppableId.split("_")[0].includes("day")){
                    this.props.removeFromMeal({source:source})
                    return 
                }else{
                    return
                }
            }
            // CASE WHEN DESTINATION SAME AS SOURCE
            if(destination.droppableId===source.droppableId){
                if(source.index===destination.index){
                    return
                }
                else{
                    //reorder
                    return this.props.reorderSameColumn({
                        draggableId:draggableId,
                        source:source,
                        destination:destination
                    })
                }
            }
            let col_list = destination.droppableId.split("_")
            // CASE WHEN DROPPED INTO MEAL
            if(col_list[0].includes('day')){
                let addMealObj = {
                    destination:destination,
                    source:source,
                    draggableId:draggableId
                }
                // TODO - add logic to reset the draggable id
                // TODO - add logic to check if in list
                return this.props.addToMeal(addMealObj)
            }
            // CASE WHEN DROPPED INTO MENU
            else if(col_list[0].includes('menu')){
                // call an addToMenu prop
                return
            }
            else{
                // will turn droppable off for search results
                return
            }
        }
    }

    render(){        
        let today = new Date()

        return (
            <DragDropContext
                onDragEnd={this.onDragEnd}
            >
            <div style={{
                backgroundColor:"var(--sec_light)",
                // backgroundColor:"white",
                margin:0,
                padding:0,
                // height:'100vh', // trying to get the menu search results to show up
                }}>
                <NavBar
                    has_search={true}
                    has_autoplan={true}
                />
            <div
                style={{
                    // display:"grid", 
                    // gridGap:"10px 20px",
                    // gridTemplateColumns:" repeat(auto-fit,320px)",
                    // justifyContent:"center",
                    display:"flex",
                    justifyContent:"center",
                    flexWrap:"wrap",
                    marginTop:"20px",
                    // backgroundColor:"var(--sec_light)"
                }}
                >
                    <DayPlan  today={today} daynum={"0"} plannum={"day1"} />
                    <DayPlan today={today} daynum={"1"} plannum={"day2"} />
                    <DayPlan today={today} daynum={"2"} plannum={"day3"} />
                    <DayPlan today={today} daynum={"3"} plannum={"day4"} />
                    <DayPlan today={today} daynum={"4"} plannum={"day5"} />
                    <DayPlan today={today} daynum={"5"} plannum={"day6"} />
                    <DayPlan today={today} daynum={"6"} plannum={"day7"} />
                    <PlanMenu menu_name={"Default Menu"} display_page={"plan"} />
                    <FoodModal/>
                <MealNameDialog />
                <FoodAltsDialog />
                <SearchDrawer/>
                <SuggestionSubBar free_user={!this.props.full_acct}/>
                <AutoplanSnackbar/>
                <OnboardingModal/>
                <Backdrop 
                     open={this.state.backdrop}
                     invisible={true}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                </div>
                {/* <div
                    style={{
                        height:150,

                    }}
                ></div> */}
            </div>
            </DragDropContext>
        )
    }
}

const mapStateToProps = (state,ownProps) => ({
    // full_acct:state.user.account.status==='full',
})


export default connect(null, {addToMeal,reorderSameColumn,removeFromMeal,mealToMeal, getUserInfo})(Plan);