import React, {Component} from 'react';
import {connect} from 'react-redux';
import NavBar from '../common/navbar';
import {
    Button,
    ExpansionPanel,
    ExpansionPanelActions,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
    Paper,
    Typography,
}from '@material-ui/core/';
import {stripePaymentMethodHandler, getUserAccountInfo} from '../../actions/account';
import {toggleCancelDialog} from '../../actions/dialog';
import TermsAndConditionsModal from '../legal/terms_and_conditions';
import InjectedCheckoutForm from './payment';
import PaymentSnackBar from './payment_snackbar';
import logo from '../auth/assets/images/02-01.png';
import CancelDialog from './cancel_dialog';


class Account extends Component{
// props - circle/square, image
    constructor(props){
        super(props)
        this.state = {
        } 
    }

    componentDidMount =() =>{
        this.props.getUserAccountInfo()
    }

    render(){        
        return (
            <div
                style={{
                    backgroundColor:"var(--sec_light)",
                    height:"100vw",
                    height: "calc(var(--vh, 1vh) * 100)",

                }}
            >
                <NavBar/>
                <Paper
                style={{
                    maxWidth:360,
                    margin:"auto",
                    marginTop:20,
                    textAlign:"center",
                }}
                >
                <img
                    style={{
                        margin:"auto"
                    }}
                    src={logo}
                    wide={320}
                    height={160}
                />
                {/* current status */}
                <div
                    style={{
                        textAlign:"center",
                        marginBottom:20,
                    }}
                >
                <Typography
                    variant="h5"
                    style={{
                        color:"darkGrey",
                        fontSize:16,
                    }}
                >
                    {this.props.membership_message}
                </Typography>
                </div>
                <div>
                <InjectedCheckoutForm />
                <Button 
                  variant="contained"
                  component="button" 
                  type="submit" 
                  style={{
                    color:"white",
                    backgroundColor:"red",
                    width:320,
                    margin:"auto",
                    marginBottom:20,
                    display:this.props.status=='full'?"":"none",
                  }}
                onClick={(event)=>{this.props.toggleCancelDialog({open:true})}}
                >
                  Cancel Subscription</Button>
                </div>
                <PaymentSnackBar />
                <CancelDialog />
            </Paper>
            </div>
        )
    }
}

const mapStateToProps = (state,ownProps) => {
    return {
            status:state.user.account.status,
            membership_message: state.user.account.membership_message,
    }
}

export default connect(mapStateToProps,{toggleCancelDialog,getUserAccountInfo})(Account);