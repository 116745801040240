import { ActionCreators } from 'redux-undo';
import store from '../store'
import {url_root,sumNutrientTotalsFromList,sumNutrientTotals,configHeaders, is_prod } from './util'
import axios from 'axios'// since fetch api not supported in all browsers


export const undoCols =()=>{
    return dispatch =>{
        dispatch(ActionCreators.undo()) // undo the last action
        // post to db
        // recalc nutrients
        let day1_nutrient_totals = sumNutrientTotals(store.getState().columns.present['day1'], "none", [])
        let day2_nutrient_totals = sumNutrientTotals(store.getState().columns.present['day2'], "none", [])
        let day3_nutrient_totals = sumNutrientTotals(store.getState().columns.present['day3'], "none", [])
        let day4_nutrient_totals = sumNutrientTotals(store.getState().columns.present['day4'], "none", [])
        let day5_nutrient_totals =  sumNutrientTotals(store.getState().columns.present['day5'], "none", [])
        let day6_nutrient_totals = sumNutrientTotals(store.getState().columns.present['day6'], "none", [])
        let day7_nutrient_totals = sumNutrientTotals(store.getState().columns.present['day7'], "none", [])

        dispatch(updatingAllNutrientTotals({
            day1_nutrient_totals:day1_nutrient_totals,
            day2_nutrient_totals:day2_nutrient_totals,
            day3_nutrient_totals:day3_nutrient_totals,
            day4_nutrient_totals:day4_nutrient_totals,
            day5_nutrient_totals:day5_nutrient_totals,
            day6_nutrient_totals:day6_nutrient_totals,
            day7_nutrient_totals:day7_nutrient_totals,
        }))
    }

}
export const redoCols =() =>{
    return dispatch=>{
        dispatch(ActionCreators.redo()) // redo the last action
        let day1_nutrient_totals = sumNutrientTotals(store.getState().columns.present['day1'], "none", [])
        let day2_nutrient_totals = sumNutrientTotals(store.getState().columns.present['day2'], "none", [])
        let day3_nutrient_totals = sumNutrientTotals(store.getState().columns.present['day3'], "none", [])
        let day4_nutrient_totals = sumNutrientTotals(store.getState().columns.present['day4'], "none", [])
        let day5_nutrient_totals =  sumNutrientTotals(store.getState().columns.present['day5'], "none", [])
        let day6_nutrient_totals = sumNutrientTotals(store.getState().columns.present['day6'], "none", [])
        let day7_nutrient_totals = sumNutrientTotals(store.getState().columns.present['day7'], "none", [])

        dispatch(updatingAllNutrientTotals({
            day1_nutrient_totals:day1_nutrient_totals,
            day2_nutrient_totals:day2_nutrient_totals,
            day3_nutrient_totals:day3_nutrient_totals,
            day4_nutrient_totals:day4_nutrient_totals,
            day5_nutrient_totals:day5_nutrient_totals,
            day6_nutrient_totals:day6_nutrient_totals,
            day7_nutrient_totals:day7_nutrient_totals,
        }))
    // recalc nutrients
    }
}

export const postWeek = () =>{
    return dispatch =>{
        let columns = store.getState().columns['present']
        let days_dict = store.getState().util.days_dict
        axios.post(url_root+'/plan/update_week_from_list/',
            {
                params:{
                    columns:columns,
                    days_dict:days_dict,
                }
            },
            {                                               // config
                headers:configHeaders(is_prod),
            }
        )
        .then(res=>{
        })
        .catch(err=>{
        })
        .finally(()=>{
        })
    }
}

const updatingAllNutrientTotals = nutObj => ({
    type:'UPDATE_ALL_NUTRIENT_TOTALS',
    payload: nutObj
})