import axios from 'axios'// since fetch api not supported in all browsers
import {url_root, configHeaders, is_prod} from './util';
import {updatingAccountStatus} from './start';

export const togglePaymentModal =(toggleObj)=>{
    return dispatch =>{
        dispatch(togglingPaymentModal({
            open:toggleObj.open
        }))
    }
}

const togglingPaymentModal = toggleObj =>({
    type:'TOGGLE_PAYMENT_MODAL',
    payload:toggleObj
})

export const stripePaymentMethodHandler = (result) => {
    return dispatch =>{
    if (result.error) {
      // Show error in payment form
    } else {
      // Otherwise send paymentMethod.id to your server
      fetch(url_root+'/account/setup_payment/', {
        method: 'post',
        headers:configHeaders(is_prod),
        body: JSON.stringify({
          payment_method: result.paymentMethod.id,
          reactivate:true,
          plan_id:'plan_H4xG9khIDlHEYU',
          email:result.paymentMethod.billing_details.email,
        }),
      }).then(res=>res.json())
      .then(data =>  {
        // let data = res['data']
        dispatch(togglingPaymentSnackbar({
          open:true,
          message: data['message'],
        }))
        // add the 
       // show success dialog and re-route to plan page
       dispatch(adding_user_acct_info({
        status: data['status'],
        email: data['email'],
        free_trial_days: data['free_trial_days'],
        membership_message: data['membership_message'],
      }))
      })
    }
  } // dispatch
}

export const cancelSubscription = () =>{
  return dispatch=>{
    fetch(url_root+'/account/cancel_subscription/', {
      method: 'post',
      headers:configHeaders(is_prod),
      body: JSON.stringify({
      }),
    }).then(resp => resp.json())
      .then(data => {
        // TODO - check if status
        if(data['outcome']==='success'){
          dispatch(adding_user_acct_info({
            status: data['status'],
            email: data['email'],
            free_trial_days: data['free_trial_days'],
            membership_message: data['membership_message'],
          }))
          dispatch(togglingPaymentSnackbar({
            open:true,
            message: data['message'],
          }))
      }else{
        dispatch(togglingPaymentSnackbar({
          open:true,
          message: data['message'],
        }))
      }
    });
  }
}

const togglingPaymentSnackbar = toggleObj =>({
  type:'TOGGLE_PAYMENT_Snackbar',
  payload:toggleObj
})

export const getUserAccountInfo =() =>{
  return dispatch =>{
    axios.post(
      url_root+'/account/load_membership/', // address
      {                                               // body
          params:{
          }
      },
      {                                               // config
          headers:configHeaders(is_prod),
      }
  )
  .then(res=>{
      let data = res['data']
      dispatch(adding_user_acct_info({
        status: data['status'],
        email: data['email'],
        free_trial_days: data['free_trial_days'],
        membership_message: data['membership_message'],
      }))
  })
  .catch(err=>{}) // axios
  } // return dispatch
} // function

const adding_user_acct_info = toggleObj =>({
  type:'ADD_USER_ACCT_INFO',
  payload:toggleObj
})