import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
    Button,
    FormControl,
    FormControlLabel,
    Input,
    InputBase,
    IconButton,
    List,
    MenuItem,
    RadioGroup,
    Radio,
    Select,
    Snackbar,
    SnackbarContent,
    Switch,
    TextField,
    Typography,
} from '@material-ui/core/';
import SearchIcon from '@material-ui/icons/Search';
import InfoIcon from '@material-ui/icons/Info';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import NavBar from '../common/navbar';
import Menu from '../plan/menu';
import MenuSearchItem from './menu_search_item';
import FoodModal from '../plan/food_modal';
import {getInitialMenus,
        addMenuToPreview,
        clearBuildMenu,
        saveNewMenu,
        getInitialMenusUserOnly,
        cloneMenu,
        closeSnackBar,} from '../../actions/menu';

class MenusBrowsePage extends Component{
    constructor(props){
        super(props)
        this.state = {
            action_type:"browse",
            search_type:"name",
            overwrite:false,
            overwrite_sure:false,
            menu_name:null,
            menu_description:null,
        } 
    }

    componentDidMount =()=>{
        // get first menus
        if(this.state.action_type==='browse'){
            this.props.getInitialMenus()
        }else if(this.action_type==='edit'){
            // get all menus for user
            this.props.getInitialMenusUserOnly()
        }
    }

    changeActionType = (new_type)=>{
        this.props.clearBuildMenu()
        this.setState({action_type:new_type})
        //
        if(new_type==='browse'){
            this.props.getInitialMenus()

        }else if(new_type==='edit'){
            // get all menus for user
            this.props.getInitialMenusUserOnly()
        }
    }

    // Browse functions
    previewMenu=(idx)=>{
        this.props.addMenuToPreview(idx)
    }

    cloneToMyMenu = ()=>{
        // could also include a drop-down to just clone a meal
        this.props.cloneMenu(this.state.overwrite, false)
    }

    renderBrowse = (action_type) =>{
        if(action_type==='browse'){
            return(
                <div>
                <div
                style={{
                    margin:"auto",
                    display:"flex",
                    justifyContent:"center",
                    marginTop:20,
                }}
                >
                <Select
                    label="Search Type"
                    value={this.state.search_type}
                    onChange={(event)=>{this.setState({search_type:event.target.value})}}
                >
                    <MenuItem
                        value={"name"}
                    >Menu Name</MenuItem>
                    <MenuItem
                        value={"author"}
                    >Author</MenuItem>
                    <MenuItem
                        value={"tags"}
                    >Tags</MenuItem>
                </Select>
                <InputBase
                    style={{"verticalAlign":"bottom",
                        // paddingLeft:3,
                        maxWidth:"85%",
                    }}
                    placeholder={"Search Menus"}
                    fullWidth={false}
                    // onChange={(event)=>{this.setState({item_search_qry:event.target.value})}}
                    />
                    <IconButton
                        size= {"small"}
                        // onClick={this.getRecipeItemSearchResult}
                    >
                        <SearchIcon/>
                    </IconButton>  
                </div>
            <div
                style={{
                    maxHeight:200,
                    overflowY:"scroll",
                }}
            >
            <List>
                {this.props.options_list.map((menu,idx)=>(
                    <div
                        style={{
                            display:"flex"
                        }}
                    >
                        {/* <IconButton 
                            size="small"
                            onClick={(event)=>{this.previewMenu(idx)}}
                        >
                            <AddIcon 
        
                            />
                        </IconButton> */}
                        <div
                        onClick={(event)=>{this.previewMenu(idx, 'public')}}
                        >
                        <MenuSearchItem
                            menu_dict={menu}
                            display_idx={idx}
                            // highlighted={this.props.display_idx===idx?true:false}
                        />
                        </div>
                    </div>
                ))}
            </List>
            </div>
            <div
                style={{
                    width:"100%",
                    display:"flex",
                    marginTop:20,
                }}
            >
                    <Button 
                        style={{
                            color:"var(--sec_white)",
                            backgroundColor:"var(--main_bright)",
                            width:"50%",
                        }}
                        onClick={this.cloneToMyMenu}
                    >Copy To My Menu</Button>
                    <div
                        style={{
                            width:"50%",
                            backgroundcolor:"var(--sec_mid)",
                            color:"var(--main_dark)",
                            display:"flex",
                            justifyContent:"center",
                        }}
                    >
                        <FormControlLabel
                        style={{
                            // width:"50%"
                            margin:"auto",

                        }}
                        label={"Keep Existing"}
                        labelPlacement={"start"}
                        control={
                            <Switch 
                                style={{
                                    color:this.state.overwrite?"darkRed":"darkGreen",
                                }}
                                // color="primary"
                                checked = {Boolean(this.state.overwrite)}
                                value={this.state.overwrite}
                                onChange={(event)=>{this.setState({overwrite:!this.state.overwrite})}}
                            />
                        }
                        >
                        </FormControlLabel>
                    </div>
                </div>
            <div
                style={{
                    margin:"auto",
                    display:"flex",
                    justifyContent:"center"
                }}
            >
                <Menu 
                    menu_name={this.props.menu_name} 
                    display_page={"menu_page"} 
                    exclude_search={this.state.action_type==='browse'?true:false} 
                    />
            </div>        
            </div>
            )
        }else{
            return(<div></div>)
        }
    }
 
    // Build Functions

    renderBuild =(action_type)=>{
        if(action_type==='build'){
        return (
            <div>

                {/* more elaborate menu search */}
                <TextField
                    fullWidth
                    placeholder={"Menu Name"}
                    variant={"outlined"}
                    label={"Menu Name"}
                    value={this.state.menu_state}
                    onChange={(event)=>{this.setState({menu_name:event.target.value})}}
                />
                <TextField
                    fullWidth
                    placeholder={"Menu Description"}
                    variant={"outlined"}
                    label={"Menu Description"}
                    multiline={true}
                    rows={3}
                    value={this.state.menu_description}
                    onChange={(event)=>{this.setState({menu_description:event.target.value})}}
                />
                <Button
                    style={{
                        backgroundColor:"var(--main_bright)",
                        color:"var(--sec_white)",
                        width:"100%",
                    }}
                    disabled={!(Boolean(this.state.menu_name)&Boolean(this.state.menu_description))}
                    onClick={(event)=>{this.props.saveNewMenu({
                        menu_name:this.state.menu_name,
                        menu_description:this.state.menu_description,
                    })}}
                >Save And Start Building</Button>
                {/* add tags */}
                <div
                    style={{
                        width:"100%",
                        display:this.props.br_pref_menu_id!=0?"flex":"none",
                        justifyContent:"center",
                        // display:this.state.
                        // display if pref_menu_id's not 0
                    }}
                >
               <Menu 
                menu_name={"New Menu"} 
                display_page={"menu_page"} 
                exclude_search={false} 
                />
               </div>
            </div>
        )
        }else{
            return(<div></div>)
        }
    }

    renderEdit=(action_type)=>{
        if(action_type==='edit'){
            return(
            <div>
            <div
                style={{
                    maxHeight:200,
                    overflowY:"scroll",
                }}
            >
                <List>
                {this.props.options_list.map((menu,idx)=>(
                        <div
                        onClick={(event)=>{this.previewMenu(idx)}}
                        >
                        <MenuSearchItem
                            menu_dict={menu}
                            display_idx={idx}
                            // highlighted={this.props.display_idx===idx?true:false}
                        />
                    </div>
                ))}
                </List>
            </div>
                <div
                    style={{
                        display:"flex",
                        justifyContent:"center",
                    }}
                >
                <Menu
                    menu_name={this.props.menu_name} 
                    display_page={"menu_page"} 
                    exclude_search={this.state.action_type==='browse'?true:false} 
                />
                </div>
            </div>)
        }else{
            return(
            <div>
            </div>)
        }
    }
    
    render(){   
        return (
            <div>
                <NavBar />
                {/* differentiate between browse and build */}
                <div
                    style={{
                        maxWidth:350,
                        margin:"auto",
                    }}
                >
                <div>
                <RadioGroup
                        value={this.state.action_type}
                        onChange={(event)=>{this.changeActionType(event.target.value)}}
                        style={{
                            display:"flex",
                             flexDirection:"row",
                             alignItems:"center",
                             justifyContent:"center",
                        }}
                    >
                        <FormControlLabel
                            style={{fontSize:12,}}
                            size="small"
                            control={<Radio/>}
                            label="Browse"     
                            value={"browse"}                  
                        />
                        <FormControlLabel
                            style={{
                                fontSize:12,
                            }}       
                            size="small"
                            control={<Radio/>}
                            label="Build"              
                            value={"build"}                  
                       />
                        <FormControlLabel
                            style={{
                                fontSize:12,
                            }}       
                            size="small"
                            control={<Radio/>}
                            label="Edit"              
                            value={"edit"}                  
                       />
                    </RadioGroup>
                </div>
                {/* search menus by name or tags */}
                {this.renderBrowse(this.state.action_type)}
                {this.renderBuild(this.state.action_type)}
                {this.renderEdit(this.state.action_type)}
                </div>
                <FoodModal/>
                <Snackbar 
                    anchorOrigin={{
                        vertical:"top",
                        horizontal:"center",
                    }}
                    open={this.props.snackbar_open}
                    message={"Menu Successfuly Copied!"}
                    onClose={(event)=>{this.props.closeSnackBar()}}
                ></Snackbar>
            </div>
        ) 
    }

}

const mapStateToProps = (state, ownProps)=>{
    let menu_name = ''
    if(state.menu_page.menu_search.length!=0){
        menu_name = state.menu_page.menu_search[state.menu_page.display_idx].menu_name
    }
        
    return({
        options_list:state.menu_page.menu_search,
        menu_name: menu_name,
        br_pref_menu_id:state.menu_page.build_menu.pref_menu_dict.Breakfast.id,
        snackbar_open:state.menu_page.snackbar_open,
        display_idx:state.menu_page.build_menu.display_idx,
    })
}

export default connect(mapStateToProps, {getInitialMenus,
                                        addMenuToPreview,
                                        clearBuildMenu,
                                        saveNewMenu,
                                        getInitialMenusUserOnly,
                                        cloneMenu,
                                        closeSnackBar,
                                    })(MenusBrowsePage);