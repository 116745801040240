import axios from 'axios'// since fetch api not supported in all browsers
import store from '../store'
import {url_root, configHeaders,is_prod} from './util'

// Food Search
export const searchFoods = searchObj =>{
    return dispatch => {
        // set isLoading flag in search
        dispatch(fetchingSearchResults({
            isLoading:true,    
            results_type:'food'
        }))
        axios.post(url_root+'/search/food_raw/',
                {
                    params:{
                        search_qry:searchObj.search_qry,
                        food_type_grp:searchObj.food_type,
                        nth_search:store.getState().columns.present.searchResults.nth_search + 1,
                        brand:searchObj.brand,
                    },
                    headers:configHeaders(is_prod),
                }
            )
            .then(function (response){
                var results = response['data']
                if(results['search_results_by_id'].length===0){
                    dispatch(foodSearchResultsNone({
                        isLoading:false,
                        fetched_search_results: true,
                        search_returned_empty: true,               
                    }))
                }
                else{
//                    localStorage.setItem('food_search_results', results)
                    dispatch(foodSearchResults({
                        search_results_by_id: results['search_results_by_id'],
//                        search_results_dict: results['search_results_dict'],
                        fetched_search_results: true,
                        search_returned_empty:false,
                        nth_search: store.getState().columns.present.searchResults.nth_search+1   
                    }))
                }
            })
            .catch(function (error){
                console.log(error)
            })
            .finally(function(){
                console.log("request complete")
            })
    }
}

const fetchingSearchResults = loadingFlag => ({
    type:'FETCHING_SEARCH_RESULTS',
    payload: loadingFlag
})

const foodSearchResultsNone = searchObj => ({
    type:'FOOD_SEARCH_RESULTS_NONE',
    payload: searchObj
})

const foodSearchResults = searchObj => ({
    type:'FOOD_SEARCH_RESULTS',
    payload: searchObj
})

export const searchMeals = searchObj =>{
    return dispatch => {
        if(searchObj.destination=='searchResults'){
            dispatch(fetchingSearchResults({
                isLoading:true,
                results_type:'meal'
            }))
        }else{
            dispatch(mealItemSearchResultsLoading({
                isLoading:true,
                hasSearchResults:false,
            })) 
        }   
        axios.post(url_root+'/search/meals/',
                {
                    params:{
                        search_qry:searchObj.search_qry,
                        nth_search:store.getState().columns.present.searchResults.nth_search + 1,
                        user_added_only:searchObj.user_added_only, // change to variable in search revamp
                    },
                },
                {                                 
                        headers:configHeaders(is_prod),
                },
            )
        .then(function(response){
            var results = response['data']
            if(searchObj.destination==='searchResults'){
                if(results['meal_search_results'].length===0){
                    dispatch(foodSearchResultsNone({
                        isLoading:false,
                        fetched_search_results: true,
                        search_returned_empty: true,   
                        results_type:'meal'
                    }))
                }else{
                    dispatch(mealSearchResults({
                        meals_list: results['meal_search_results'],
                        fetched_search_results: true,
                        search_returned_empty:false,
                        results_type:'meal',
                        nth_search: store.getState().columns.present.searchResults.nth_search+1 
                    }))
                }
            }else if(searchObj.destination==='clone_or_edit'){
                dispatch(mealItemSearchResultsReturned({
                        hasSearchResults:true,
                        isLoading:false,
                        results_list: results['meal_search_results'],
                }))
            }
        })
        .catch(function(error){
            console.log(error)
        })
        .finally(function(response){
            // don't know what would go here
        })
    }
}

const mealSearchResultsNone = searchObj => ({
    type:'MEAL_SEARCH_RESULTS_NONE',
    payload: searchObj
})

const mealSearchResults = searchObj => ({
    type:'MEAL_SEARCH_RESULTS',
    payload: searchObj
})

// Search For Menu
export const search_menu_items = (searchObj) =>{
    return dispatch => {
        // set isLoading flag in menu search results
        if(searchObj.display_page==='plan'){
            dispatch(menuSearchResultsLoading({
                isLoading:true,    
            }))
        }else{
            dispatch(menuSearchResultsLoadingMenuPage({
                isLoading:true,    
            })) 
        }

        axios.post(url_root+'/core/search_menu_items/',
                {
                    params:{
                        search_qry:searchObj.search_qry,
                        item_type:searchObj.item_type,
                     },
                },
                {                                 
                    headers:configHeaders(is_prod),
                }
            )
            .then(function (response){
                var results = response['data']
                if(searchObj.display_page==='plan'){
                dispatch(menuSearchResultsReturned({
                    isLoading:false,
                    results_list:results["menu_search_results"]
                }))
                }else{
                    dispatch(menuSearchResultsReturnedMenuPage({
                        isLoading:false,
                        results_list:results["menu_search_results"]                        
                    }))
                }
            })
            .catch(function (error){
                console.log(error)
            })
            .finally(function(){
                console.log("request complete")
            })
    }
}

const menuSearchResultsLoading = searchObj => ({
    type:'MENU_SEARCH_RESULTS_LOADING',
    payload: searchObj
})


const menuSearchResultsLoadingMenuPage = searchObj => ({
    type:'MENU_SEARCH_RESULTS_LOADING_MENU_PAGE',
    payload: searchObj
})

const menuSearchResultsNone = searchObj => ({
    type:'MENU_SEARCH_RESULTS_NONE',
    payload: searchObj
})
const menuSearchResultsReturned = searchObj => ({
    type:'MENU_SEARCH_RESULTS_RETURNED',
    payload: searchObj
})

const menuSearchResultsReturnedMenuPage = searchObj => ({
    type:'MENU_SEARCH_RESULTS_RETURNED_MENU_PAGE',
    payload: searchObj
})

export const searchIngredients =(search_qry)=>{
    return dispatch =>{
    dispatch(ingredSearchResultsLoading({
        hasSearchResults:false,
        isLoading:true
    }))

    axios.post(url_root+'/search/food_raw/',
    {
        params:{
            search_qry:search_qry,
            food_type_grp:['raw ingredient','grocery'],
            nth_search:0
        },
    },
    {                                 
        headers:configHeaders(is_prod),
    },
    ).then((response)=>{
        let results = response['data']
        dispatch(ingredSearchResultsReturned({
            isLoading:false,
            hasSearchResults:true,
            results_list:results["search_results_by_id"]
        }))
    }).catch((err)=>{
        
    }).finally(()=>{

    })
    }
}

const ingredSearchResultsLoading = searchObj => ({
    type:'INGRED_SEARCH_RESULTS_LOADING',
    payload: searchObj
})

const ingredSearchResultsReturned = searchObj => ({
    type:'INGRED_SEARCH_RESULTS_RETURNED',
    payload: searchObj
})

export const searchMealFoods =(search_qry)=>{
    return dispatch =>{
    dispatch(ingredSearchResultsLoading({
        hasSearchResults:false,
        isLoading:true
    }))

    axios.post(url_root+'/search/food_raw/',
    {
        params:{
            search_qry:search_qry,
            food_type_grp:['recipe','restaurant','raw ingredient','grocery'],
            nth_search:0
        },
    },
    {                                 
        headers:configHeaders(is_prod),
    },
    ).then((response)=>{
        let results = response['data']
        dispatch(ingredSearchResultsReturned({
            isLoading:false,
            hasSearchResults:true,
            results_list:results["search_results_by_id"]
        }))
    }).catch((err)=>{
        
    }).finally(()=>{

    })
    }
}

const mealItemSearchResultsLoading = searchObj => ({
    type:'MEAL_ITEM_SEARCH_RESULTS_LOADING',
    payload: searchObj
})

const mealItemSearchResultsReturned = searchObj => ({
    type:'MEAL_ITEM_SEARCH_RESULTS_RETURNED',
    payload: searchObj
})

export const searchRecipesEdit =(search_qry)=>{
    return dispatch =>{
    dispatch(recipeSearchResultsLoading({
        hasSearchResults:false,
        isLoading:true
    }))

    axios.post(url_root+'/food/search_recipes_for_edit/',
    {
        params:{
            search_qry:search_qry,
            food_type_grp:['recipe'],
        },
    },
    {                                 
            headers:configHeaders(is_prod),
    },
    ).then((response)=>{
        let results = response['data']
        dispatch(recipeSearchResultsReturned({
            isLoading:false,
            hasSearchResults:true,
            results_list:results["search_results_by_id"]
        }))
    }).catch((err)=>{
        
    }).finally(()=>{

    })
    }
}

const recipeSearchResultsLoading = searchObj => ({
    type:'RECIPE_SEARCH_RESULTS_LOADING',
    payload: searchObj
})

const recipeSearchResultsReturned = searchObj => ({
    type:'RECIPE_SEARCH_RESULTS_RETURNED',
    payload: searchObj
})

export const searchRecipesClone =(search_qry) =>{
    return dispatch =>{
        dispatch(recipeSearchResultsLoading({
            hasSearchResults:false,
            isLoading:true
        }))
    
        axios.post(url_root+'/search/food_raw/',
        {
            params:{
                search_qry:search_qry,
                food_type_grp:['recipe'],
                nth_search:0,
            },
        },
        {                                 
                headers:configHeaders(is_prod),
        },
        ).then((response)=>{
            let results = response['data']
            dispatch(recipeSearchResultsReturned({
                isLoading:false,
                hasSearchResults:true,
                results_list:results["search_results_by_id"]
            }))
        }).catch((err)=>{
            
        }).finally(()=>{
    
        })
        }
}

export const togglePlanSearch =(searchObj)=>{
    return dispatch =>{
        dispatch(togglingPlanSearch(searchObj))
    }
}

const togglingPlanSearch = searchObj => ({
    type:'TOGGLING_PLAN_SEARCH',
    payload: searchObj
})