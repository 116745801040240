import initialState from '../initialState'

export default function(state=initialState, action){
    switch(action.type){
        case 'RESET_NTH_SOLVE':
            return{
                ...state,
                nth_solve:0,
            }
        case 'INCREMENT_NTH_SOLVE':
            return{
                ...state,
                nth_solve:state.nth_solve+1,
            }
        case'CHANGE_NUTRIENT_REQ':
            return{
                ...state,
                requirements_dict:{
                    ...state.requirements_dict,
                    [action.payload.nut_abrev+"_lb"]:action.payload.req_list[0],
                    [action.payload.nut_abrev+"_ub"]:action.payload.req_list[1],
                }
            }
        case 'CHANGE_NUTRIENTS_ALL':
            return{
                ...state,
                requirements_dict:{
                    ...state.requirements_dict,
                    ...action.payload.requirements_dict
                }
            }
        case 'CHANGE_NUTRIENTS_BY_NAME':
            return{
                ...state,
                requirements_dict:{
                    ...state.requirements_dict,
                    ...action.payload,
                }
        }
        case 'CHANGE_NUTRIENT_DEFAULT_REQ':
            return{
                ...state,
                autoplan_nutrients:{
                    ...state.autoplan_nutrients,
                    ...action.payload,
                }
            }
        case 'CHANGE_NUTRIENT_DAY_REQ':
            return{
                ...state,
                nth_solve:0,
                [action.payload.plannum]:{
                    ...state[action.payload.plannum],
                    autoplan_nutrients:{
                        ...state[action.payload.plannum].autoplan_nutrients,
                        [action.payload.nutrient]:action.payload.incl,
                    }
                }
            }
        case 'CHANGE_USER_STATS':
            return{
                ...state,
                user_stats:{
                    ...state.user_stats,
                    ...action.payload.user_stats
                }
            }
            case 'CHANGE_SINGLE_USER_STAT':
                return{
                    ...state,
                    user_stats:{
                        ...state.user_stats,
                        ...action.payload,
                    }
                }
        case 'ADD_USER_INFO':
            return{
                ...state,
                requirements_dict:action.payload.requirements_dict,
                autoplan_nutrients: action.payload.autoplan_nutrients,
                days_list:action.payload.days_list,
                default_menu_dict:{
                    ...state.default_menu_dict,
                    Breakfast:{
                        ...state.default_menu_dict.Breakfast,
                        ...action.payload.br_menu
                    },
                    Lunch:{
                        ...state.default_menu_dict.Lunch,
                        ...action.payload.lu_menu
                    },
                    Dinner:{
                        ...state.default_menu_dict.Dinner,
                        ...action.payload.di_menu
                    },
                    Snack:{
                        ...state.default_menu_dict.Snack,
                        ...action.payload.sn_menu
                    },
                },
                day1:{
                    ...state['day1'],
                    autoplan_nutrients:action.payload.autoplan_nutrients,
                    Breakfast:{
                        ...state['day1']['Breakfast'],
                        menus:[action.payload.br_menu]
                    },
                    Lunch:{
                        ...state['day1']['Lunch'],
                        menus:[action.payload.lu_menu]
                    },
                    Dinner:{
                        ...state['day1']['Dinner'],
                        menus:[action.payload.di_menu]
                    },
                    Snack:{
                        ...state['day1']['Snack'],
                        menus:[action.payload.sn_menu]
                    },
                },
                day2:{
                    ...state['day2'],
                    autoplan_nutrients:action.payload.autoplan_nutrients,
                    Breakfast:{
                        ...state['day2']['Breakfast'],
                        menus:[action.payload.br_menu]
                    },
                    Lunch:{
                        ...state['day2']['Lunch'],
                        menus:[action.payload.lu_menu]
                    },
                    Dinner:{
                        ...state['day2']['Dinner'],
                        menus:[action.payload.di_menu]
                    },
                    Snack:{
                        ...state['day2']['Snack'],
                        menus:[action.payload.sn_menu]
                    },
                },
                day3:{
                    ...state['day3'],
                    autoplan_nutrients:action.payload.autoplan_nutrients,
                    Breakfast:{
                        ...state['day3']['Breakfast'],
                        menus:[action.payload.br_menu]
                    },
                    Lunch:{
                        ...state['day3']['Lunch'],
                        menus:[action.payload.lu_menu]
                    },
                    Dinner:{
                        ...state['day3']['Dinner'],
                        menus:[action.payload.di_menu]
                    },
                    Snack:{
                        ...state['day3']['Snack'],
                        menus:[action.payload.sn_menu]
                    },
                },
                day4:{
                    ...state['day4'],
                    autoplan_nutrients:action.payload.autoplan_nutrients,
                    Breakfast:{
                        ...state['day4']['Breakfast'],
                        menus:[action.payload.br_menu]
                    },
                    Lunch:{
                        ...state['day4']['Lunch'],
                        menus:[action.payload.lu_menu]
                    },
                    Dinner:{
                        ...state['day4']['Dinner'],
                        menus:[action.payload.di_menu]
                    },
                    Snack:{
                        ...state['day4']['Snack'],
                        menus:[action.payload.sn_menu]
                    },
                },
                day5:{
                    ...state['day5'],
                    autoplan_nutrients:action.payload.autoplan_nutrients,
                    Breakfast:{
                        ...state['day5']['Breakfast'],
                        menus:[action.payload.br_menu]
                    },
                    Lunch:{
                        ...state['day5']['Lunch'],
                        menus:[action.payload.lu_menu]
                    },
                    Dinner:{
                        ...state['day5']['Dinner'],
                        menus:[action.payload.di_menu]
                    },
                    Snack:{
                        ...state['day5']['Snack'],
                        menus:[action.payload.sn_menu]
                    },
                },
                day6:{
                    ...state['day6'],
                    autoplan_nutrients:action.payload.autoplan_nutrients,
                    Breakfast:{
                        ...state['day6']['Breakfast'],
                        menus:[action.payload.br_menu]
                    },
                    Lunch:{
                        ...state['day6']['Lunch'],
                        menus:[action.payload.lu_menu]
                    },
                    Dinner:{
                        ...state['day6']['Dinner'],
                        menus:[action.payload.di_menu]
                    },
                    Snack:{
                        ...state['day6']['Snack'],
                        menus:[action.payload.sn_menu]
                    },
                },
                day7:{
                    ...state['day7'],
                    autoplan_nutrients:action.payload.autoplan_nutrients,
                    Breakfast:{
                        ...state['day7']['Breakfast'],
                        menus:[action.payload.br_menu]
                    },
                    Lunch:{
                        ...state['day7']['Lunch'],
                        menus:[action.payload.lu_menu]
                    },
                    Dinner:{
                        ...state['day7']['Dinner'],
                        menus:[action.payload.di_menu]
                    },
                    Snack:{
                        ...state['day7']['Snack'],
                        menus:[action.payload.sn_menu]
                    },
                },
            }
        case 'TOGGLING_PLAN_SEARCH':
            return{
                ...state,
                search:action.payload.search
            }
        case 'TOGGLING_AUTO_PLAN':
            return{
                ...state,
                autoplan:action.payload.autoplan
            }
        case 'TOGGLING_INCLUDE_DAY':
            return{
                ...state,
                nth_solve:0,
                [action.payload.plannum]:{
                    ...state[action.payload.plannum],
                    autoplan:action.payload.autoplan,
                    ['Breakfast']:{
                        ...state[action.payload.plannum]['Breakfast'],
                        autoplan_include:action.payload.br_incl,
                    },
                    ['Lunch']:{
                        ...state[action.payload.plannum]['Lunch'],
                        autoplan_include:action.payload.lu_incl,
                    },
                    ['Dinner']:{
                        ...state[action.payload.plannum]['Dinner'],
                        autoplan_include:action.payload.di_incl
                    },
                    ['Snack']:{
                        ...state[action.payload.plannum]['Snack'],
                        autoplan_include:action.payload.autoplan
                    },
                }
            }
        case 'TOGGLING_INCLUDE_MEAL':
            return{
                ...state,
                nth_solve:0,
                [action.payload.plannum]:{
                    ...state[action.payload.plannum],
                    [action.payload.meal_type]:{
                        ...state[action.payload.plannum][action.payload.meal_type],
                        autoplan_include:action.payload.autoplan_include
                    }
                }
           }
        case 'TOGGLING_SOLVE_IN_PROGRESS':
            return{
                ...state,
                solve_in_progress:action.payload.solve_in_progress,
            }
        case 'RECIEVED_MENUS':
            return{
                ...state,
                [action.payload.plannum]:{
                    ...state[action.payload.plannum],
                    [action.payload.meal_type]:{
                        ...state[action.payload.plannum][action.payload.meal_type],
                        search_results:action.payload.search_results
                    }
                }
            }
        case 'CHANGE_MENU':
            return{
                ...state,
                nth_solve:0,
                [action.payload.plannum]:{
                    ...state[action.payload.plannum],
                    [action.payload.meal_type]:{
                        ...state[action.payload.plannum][action.payload.meal_type],
                        menus:action.payload.menus
                    }
                }
            }  
        case 'UPDATE_SINGLE_DAY_NUTRIENTS':
            return{
                ...state,
                [action.payload.plannum]:{
                    ...state[action.payload.plannum],
                    nutrient_totals: action.payload.nutrient_totals
                }
            }
        case 'UPDATE_ALL_NUTRIENT_TOTALS':
            //console.log("updating nutrients in reducer")
            return{
                ...state,
                day1:{
                    ...state.day1,
                    nutrient_totals:action.payload.day1_nutrient_totals
                },
                day2:{
                    ...state.day2,
                    nutrient_totals:action.payload.day2_nutrient_totals
                },
                day3:{
                    ...state.day3,
                    nutrient_totals:action.payload.day3_nutrient_totals
                },
                day4:{
                    ...state.day4,
                    nutrient_totals:action.payload.day4_nutrient_totals
                },
                day5:{
                    ...state.day5,
                    nutrient_totals:action.payload.day5_nutrient_totals
                },
                day6:{
                    ...state.day6,
                    nutrient_totals:action.payload.day6_nutrient_totals
                },
                day7:{
                    ...state.day7,
                    nutrient_totals:action.payload.day7_nutrient_totals
                },
            }
        case 'UPDATING_EACH_DAY_AUTO_PLAN':
            return{
                ...state,
                nth_solve:0,
                day1:{
                    ...state.day1,
                    autoplan:action.payload.day1_incl,
                    Breakfast:{
                        ...state.day1.Breakfast,
                        autoplan_include:action.payload.day1_br_incl,
                    },
                    Lunch:{
                        ...state.day1.Lunch,
                        autoplan_include:action.payload.day1_lu_incl,
                    },
                    Dinner:{
                        ...state.day1.Dinner,
                        autoplan_include:action.payload.day1_di_incl,
                    },
                    Snack:{
                        ...state.day1.Snack,
                        autoplan_include:action.payload.day1_sn_incl,
                    },
                },
                day2:{
                    ...state.day2,
                    autoplan:action.payload.day2_incl,
                    Breakfast:{
                        ...state.day2.Breakfast,
                        autoplan_include:action.payload.day2_br_incl,
                    },
                    Lunch:{
                        ...state.day2.Lunch,
                        autoplan_include:action.payload.day2_lu_incl,
                    },
                    Dinner:{
                        ...state.day2.Dinner,
                        autoplan_include:action.payload.day2_di_incl,
                    },
                    Snack:{
                        ...state.day2.Snack,
                        autoplan_include:action.payload.day2_sn_incl,
                    },
                },
                day3:{
                    ...state.day3,
                    autoplan:action.payload.day3_incl,
                    Breakfast:{
                        ...state.day3.Breakfast,
                        autoplan_include:action.payload.day3_br_incl,
                    },
                    Lunch:{
                        ...state.day3.Lunch,
                        autoplan_include:action.payload.day3_lu_incl,
                    },
                    Dinner:{
                        ...state.day3.Dinner,
                        autoplan_include:action.payload.day3_di_incl,
                    },
                    Snack:{
                        ...state.day3.Snack,
                        autoplan_include:action.payload.day3_sn_incl,
                    },
                },
                day4:{
                    ...state.day4,
                    autoplan:action.payload.day4_incl,
                    Breakfast:{
                        ...state.day4.Breakfast,
                        autoplan_include:action.payload.day4_br_incl,
                    },
                    Lunch:{
                        ...state.day4.Lunch,
                        autoplan_include:action.payload.day4_lu_incl,
                    },
                    Dinner:{
                        ...state.day4.Dinner,
                        autoplan_include:action.payload.day4_di_incl,
                    },
                    Snack:{
                        ...state.day4.Snack,
                        autoplan_include:action.payload.day4_sn_incl,
                    },
                },
                day5:{
                    ...state.day5,
                    autoplan:action.payload.day5_incl,
                    Breakfast:{
                        ...state.day5.Breakfast,
                        autoplan_include:action.payload.day5_br_incl,
                    },
                    Lunch:{
                        ...state.day5.Lunch,
                        autoplan_include:action.payload.day5_lu_incl,
                    },
                    Dinner:{
                        ...state.day5.Dinner,
                        autoplan_include:action.payload.day5_di_incl,
                    },
                    Snack:{
                        ...state.day5.Snack,
                        autoplan_include:action.payload.day5_sn_incl,
                    },
                },
                day6:{
                    ...state.day6,
                    autoplan:action.payload.day6_incl,
                    Breakfast:{
                        ...state.day6.Breakfast,
                        autoplan_include:action.payload.day6_br_incl,
                    },
                    Lunch:{
                        ...state.day6.Lunch,
                        autoplan_include:action.payload.day6_lu_incl,
                    },
                    Dinner:{
                        ...state.day6.Dinner,
                        autoplan_include:action.payload.day6_di_incl,
                    },
                    Snack:{
                        ...state.day6.Snack,
                        autoplan_include:action.payload.day6_sn_incl,
                    },
                },
                day7:{
                    ...state.day7,
                    autoplan:action.payload.day7_incl,
                    Breakfast:{
                        ...state.day7.Breakfast,
                        autoplan_include:action.payload.day7_br_incl,
                    },
                    Lunch:{
                        ...state.day7.Lunch,
                        autoplan_include:action.payload.day7_lu_incl,
                    },
                    Dinner:{
                        ...state.day7.Dinner,
                        autoplan_include:action.payload.day7_di_incl,
                    },
                    Snack:{
                        ...state.day7.Snack,
                        autoplan_include:action.payload.day7_sn_incl,
                    },
                },
            }
            case 'UPDATE_ACCOUNT':
                return {
                    ...state,
                    account:{
                        ...state.account,
                        email:action.payload.email,
                        status: action.payload.status,
                    }
                }
            case 'ADD_USER_ACCT_INFO':
                return{
                    ...state,
                    account:{
                        ...state.account,
                        ...action.payload,
                    }
                }
            case 'UPDATE_ACCOUNT_STATUS':
                return {
                    ...state,
                    account:{
                        ...state.account,
                        status:action.payload.status,
                    }
                }
        default: return state
    }
}