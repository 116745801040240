import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Snackbar} from '@material-ui/core/';
import {togglePaymentSnackbar} from '../../actions/dialog';
import {withStyles} from '@material-ui/core/styles';
// Alert can come from material ui

const styles = {
    root: {
        background: 'red'
    }
};

class PaymentSnackBar extends Component{
    // props - circle/square, image
        constructor(props){
            super(props)
            this.state = {
            } 
        }

        render(){        
            return (
                <Snackbar
                    anchorOrigin={{
                        vertical:"top",
                        horizontal:"center",
                    }}
                    open={this.props.open}
                    message={this.props.message}
                    onClose={(event)=>{this.props.togglePaymentSnackbar({
                        open:false,
                        message:null,
                    })}}
                >
                </Snackbar>
            )
        }
    }
    
    const mapStateToProps = (state,ownProps) => ({
        open:state.dialog.subscription_snackbar.open,
        message:state.dialog.subscription_snackbar.message,
    })
    
    export default connect(mapStateToProps,{togglePaymentSnackbar})(withStyles(styles)(PaymentSnackBar));