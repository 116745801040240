import React, {Component} from 'react';
import {connect} from 'react-redux';
import {List,
}from '@material-ui/core/';
import Menu from '../plan/menu';
import MenuSearchItem from '../menus/menu_search_item';
import {getInitialMenus,
    addMenuToPreview,} from '../../actions/menu';

class MenuSelect extends Component{
// props - circle/square, image
    constructor(props){
        super(props)
        this.state = {
        } 
    }

    previewMenu=(idx)=>{
        this.props.addMenuToPreview(idx)
        // TODO - when to copy menu? Each time?
    }

    render(){      
        // if pro? 0.25 -> high protein
        // if car <= 0.15 -> keto
        // else -> regular   
        return (
            <div>
                <div
                style={{
                    maxHeight:400,
                    overflowY:"scroll",
                }}
            >
                <List>
                {this.props.menu_search.map((menu,idx)=>(
                    <div
                        style={{
                            display:"flex"
                        }}
                    >
                        <div
                        onClick={(event)=>{this.previewMenu(idx, 'public')}}
                        >
                        <MenuSearchItem
                            menu_dict={menu}
                            display_idx={idx}
                        />
                        </div>
                    </div>
                ))}
                </List>
            </div>
           </div>
        )
    }
}

const mapStateToProps = (state,ownProps) => {
    let rec_name = "Plan Your Meals General Purpose Menu"
    if(state.menu_page.menu_search.length>0){
        rec_name=state.menu_page.menu_search[0].menu_name
        // console.log(state.menu_page.menu_search[0])
    }
    return {
        userstats:state.user.userstats,
        menu_search: state.menu_page.menu_search,
        rec_name:rec_name,
    }
}

export default connect(mapStateToProps,{addMenuToPreview})(MenuSelect);