import initialState from '../initialState'

export default function(state=initialState, action){
    switch(action.type){
        case 'UPDATE_PERSONAL_PROFILE':
            return{
                ...state,
                ...action.payload,
            }
        default:
            return state;
    }
}