import React, {Component, useEffect} from 'react';
import {connect} from 'react-redux';
import {withStyles, makeStyles} from '@material-ui/core/styles';
import ReactImageFallback from "react-image-fallback";
import {
    Tooltip,
    Typography,
    Select,
    FormControl,
    MenuItem,
    TextField,
    IconButton,
    Menu,
} from '@material-ui/core/';
import AssessmentIcon from '@material-ui/icons/Assessment';// Nutrition
import InfoIcon from '@material-ui/icons/Info';
import MoreVert from '@material-ui/icons/MoreVert';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import {updateFoodAmount} from '../../actions/food_obj';
import {openFoodModal} from '../../actions/food_modal';
import {singleFoodAlternatives} from '../../actions/autoplan'

//import {Draggable} from 'react-beautiful-dnd';

//Creates a unique clickable and draggable food object that sits in a table cell in a plan or search results
// Will have local state for modal pop-up
// Will take Redux state for draggable, etc. And will take Food Info from parent component

const useStyles = makeStyles(theme=>({
    root:{
        backgroundColor:'#fff'
    },
    food_obj_div:{
        display:"grid"
    },
    icon:{
        fill: "white !important"
    }
}))

class FoodObj extends Component{

    // Store food nutrients
    // Store nutrient multiplier for each serving size unit
    constructor(props){
        super(props) 
        this.state={
            isSideMenuOpen:false,
            menu_anchor_el:null,
        }
    }

    handleServingSizeAmtChange = (event)=>{
        this.props.updateFoodAmount({
            amt:event.target.value,
            serving_size_idx:this.props.food_dict.serving_size_idx,
            drag_idx:this.props.drag_idx,
            col:this.props.col,
            subcol:this.props.subcol, //may be null
            drag_id: this.props.drag_id,            
            change_item:'amt'
        })
    }
    handleServingSizeUnitChange =(event)=>{
        // get food objects
        let new_amt = Math.round(((this.props.food_dict.amt/this.props.food_dict.serving_sizes.ss_amts[this.props.food_dict.serving_size_idx])*this.props.food_dict.serving_sizes.ss_amts[event.target.value])*100)/100
        this.props.updateFoodAmount({
            amt:new_amt,
            serving_size_idx: event.target.value,
            drag_idx:this.props.drag_idx,
            col:this.props.col,
            subcol:this.props.subcol, //may be null
            drag_id: this.props.drag_id,
            change_item:'unit'
        })
    }

    handleModalClick = () =>{
        this.setState({isSideMenuOpen:false})
        this.props.openFoodModal(this.props.food_dict.food_key)
    }

    openSideMenu = (event) =>{
        this.setState({
            menu_anchor_el:event.target,
            isSideMenuOpen:true})
    }

    handleSideMenuClose =() =>{
        // this might be the source for the rendering slowness
        this.setState({menu_anchor_el:null,
            isSideMenuOpen:false})
    }

    onClickAlternatives = (idx) =>{
        this.setState({
            menu_anchor_el:null,
            isSideMenuOpen:false
        })
        this.props.singleFoodAlternatives(this.props.food_dict, 
            this.props.col, this.props.subcol, idx)

    }

    render(){
        // const {classes}=this.props
        return( 
                    <div
                    style={{
                        width:100,// needs to be short enough to show 3-wide with full scrollbar
                        height:90,
                        margin:1,
                        verticalAlign:"bottom",
                        backgroundColor:"var(--sec_white)",
                        color:"var(--main_dark)",
                        border:"2px solid var(--sec_light)",
                        borderRadius:"2px",
                     }}
                    >
                        <div
                            style={{
                                display:"flex",
                                flexDirection:"row",
                                justifyContent:"space-between"
                            }}
                        >
                        {/* <IconButton 
                            size="small" 
                            edge="start"
                            onClick={this.handleModalClick}
                        >
                            <InfoIcon
                                fontSize="small"
                            />
                        </IconButton> */}
                        {/*Card header - name*/}
                        <Tooltip
                            title={this.props.food_dict.food_description+", "+this.props.food_dict.brand}
                        >
                            <Typography 
                                    m={0}
                                    p={0}
                                    variant="caption" 
                                    noWrap
                                >{this.props.food_dict.food_description}
                            </Typography>
                        </Tooltip>
                         <IconButton
                            size="small"
                            edge="end"
                            onClick = {this.openSideMenu}
                            disabled={this.props.isChangeDisabled}
                        >
                            <MoreVert
                                fontSize="small"
                                style={{
                                    fontSize:12,
                                }}
                            />
                        </IconButton>
                        <Menu
                            open={this.state.isSideMenuOpen}
                            onClose={this.handleSideMenuClose}
                            anchorEl={this.state.menu_anchor_el}
                        >
                            <MenuItem
                                    onClick={this.handleModalClick}
                                >
                                Details
                            </MenuItem>
                            <MenuItem
                                onClick={(event)=>{
                                    if(this.props.col!="searchResults"){
                                        this.onClickAlternatives(this.props.drag_idx)
                                    }
                                    this.handleSideMenuClose()
                                    }}
                            >See Alternatives</MenuItem>
                        </Menu>
                        </div> 
                        <div
                            style={{
                                position:"relative",
                                // display:"flex",
                                // flexDirection:"column",
                                // alignItems:"space-between"
                            }}
                        >   
                        {/*<div
                                 style={{
                                     display:"flex",
                                     width:70,
                                     justifyContent:"left"
                                 }}
                        >
                            <AssessmentIcon style={{color:"var(--main_bright"}} fontSize={"small"}/>
                                </div>*/}
                            {/*<img*/}
                            <ReactImageFallback
                                src={this.props.food_dict.image_url} 
                                fallbackImage={"https://planyourmealsmedia.s3.amazonaws.com/food/default_thumbnail.png"}
                                initialImage={"https://planyourmealsmedia.s3.amazonaws.com/food/default_thumbnail.png"}
                                alt={'Food'}
                                style={{
                                    width:100,
                                    height:75,
                                    margin:"auto"
                                }}
                            />
                        <div
                            style={{
                                width:100,
                                overflow:"hidden",
                                textOverflow:"ellipsis"
                            }}
                        >
                        {/* <Tooltip
                            title={this.props.food_dict.brand}
                        >
                            <Typography 
                            style={{
                                width:106,
                            }}
                            m={0}
                            p={0}
                            variant="caption"
                            noWrap
                            >
                                {this.props.food_dict.brand}
                            </Typography>
                        </Tooltip> */}
                        <div
                        style={{
                            position:"absolute",
                            color:"#000",
                            backgroundColor:"#fff",
                            background: "rgb(0, 0, 0)",
                            background: "rgba(0, 0, 0, 0.5)", /* Black see-through */
                            color: "#f1f1f1", 
                            display:"flex",
                            width:100, 
                            flexDirection:"row",
                            zIndex:10,
                            bottom:5,
                        }}
                        >    
                        <FormControl 
                            style={{verticalAlign:"bottom",
                                padding:0,
                                margin:0,
                                // height:"1.3em"
                            }}
                        >
                            <TextField 
                                style={{
                                        fontSize:12,
                                        width:30,
                                        display:"flex",
                                        verticalAlign:"bottom",
                                        marginBottom:1}}
                                    value={this.props.food_dict.amt}
                                    onChange={this.handleServingSizeAmtChange}
                                    inputProps={{
                                        style: {
                                            fontSize: 12, 
                                            width:"100%",
                                            color:"white",
                                            marginLeft:2,
                                        }
                                    }} 
                                    disabled={this.props.isChangeDisabled}
                            />
                        </FormControl>
                        <FormControl 
                            variant="outlined" 
                            label="Unit" 
                            style={
                                {margin:0,
                                flexDirection:"column",
                                verticalAlign:"bottom",
                                fontSize:"12px", 
                                padding:0, 
                                display:"flex",
                                color:"white",
                                fill:"white !important",
                            }}
                                disabled={this.props.isChangeDisabled}
                            >
                                    <Select
                                    style={{
                                        fontSize:12,
                                        width:70, 
                                        marginLeft:0, 
                                        marginBottom:0,
                                        color:"white",
                                        fill:"white",
                                    }}

                                    value={this.props.food_dict.serving_size_idx}
                                    name="Serving Size Unit"
                                    onChange = {this.handleServingSizeUnitChange}
                                    multiple={false}
                                    renderValue={(value)=>{return this.props.food_dict.serving_sizes.ss_units[value]}}
                                    inputProps={{
                                        style:{
                                            color:"white",
                                            fill:"white !important",
                                            width:"100%",
                                        },
                                    }}
                                    // IconComponent={()=>(<ArrowDropDownIcon 
                                    //     style={{
                                    //         color:"white",
                                    //         // fontSize:16,
                                    //         margin:0,
                                    //         padding:0,
                                    //         // width:10,
                                    //         left:0,
                                    //     }} 
                                    // />)}
                                    >
                                    {this.props.food_dict.serving_sizes.ss_units.map( (result, idx) => (
                                        <MenuItem
                                            value={idx}
                                            key={result}
                                        >{result}
                                        </MenuItem>
                                    ))}
                                    </Select>
                                 </FormControl>
                                </div>
                        </div>
                </div>
            </div>
        )
    }
}

// const mapStateToProps = (state,ownProps) => ({
//     full_acct:state.user.account.status==='full',
// })

export default connect(null, {updateFoodAmount, openFoodModal,singleFoodAlternatives})(FoodObj);