import React, {Component} from 'react';
import {connect} from 'react-redux';
import DishMenu from './dish_menu';
import {
    Paper,
    Toolbar,
    IconButton,
    Typography,
    Select,
    MenuItem
} from '@material-ui/core/';


class MealMenu extends Component{

    constructor(props){
        super(props)
        this.state = {
            dish_num:this.props.dishnums[0]
        } 
    }
    render(){        
        return (
            <div
                style={{
                    display:"flex",
                    // width:320,
                    width:"100%",
                    height:140,
                    flexDirection:"column"
                }}
            >   
            <div
                style={{
                    display:"flex",
                    justifyContent:"space-between",
                    width:"100%",
                }}
            >
                <Typography variant="subtitle1">
                    {this.props.meal_type}
                </Typography>
                <Select 
                    value={this.state.dish_num}
                    name={"Dish Type"}
                    onChange = {(event)=>{this.setState({dish_num:event.target.value})}}
                >
                    {this.props.dishnums.map((dish_num, idx)=>(
                        <MenuItem value={dish_num}>{dish_num.toLowerCase().replace('_',' ')}</MenuItem>
                    ))}
                </Select>
            </div>
            <div
                style={{
                    width:"100%"
                }}
            >
                <DishMenu 
                    meal_type={this.props.meal_type}
                    dish_num={this.state.dish_num}
                    display_page ={this.props.display_page}
                    exclude_search={this.props.exclude_search}
                />
            </div>

            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    // Leaving space in case logic is needed here
        return ({

        })
}

export default connect(null, {})(MealMenu);