import React, {Component} from 'react';
import { makeStyles, withStyles} from '@material-ui/core/styles';
import {connect} from 'react-redux';
import {
    Paper,
    Card,
    Typography,
    Checkbox
} from '@material-ui/core/';
// icons
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import {updateDayNutReq} from '../../actions/nutrients';

class Nutrient extends Component{
    constructor(props){
        super(props)
    }

    shouldComponentUpdate(nextProps,nextState){
        if((this.props.nut_total==nextProps.nut_total)&
        (this.props.autoplan==nextProps.autoplan)&
        (this.props.lower_bound==nextProps.lower_bound)&
        (this.props.upper_bound==nextProps.upper_bound)&
        (this.props.autoplan_include==nextProps.autoplan_include)){
            return false
        }else{
            return true
        }
    }

    renderStatusIndicator = () =>{
        switch(this.props.status){
            case 'under':return <Typography variant="caption" style={{color:"black"}}>-</Typography>
            //case 'under':return <RemoveIcon/>
            case 'over': return <Typography variant="caption" style={{color:"black"}}>+</Typography>
            case 'achieved': return <CheckIcon style={{fontSize:14,marginTop:0.5,}}/>
        }
    }

    render() {
        const {classes} = this.props;
        return(
            <section
                style={{
                    width:"300px",
                    border:"solid 3px var(--sec_light)"
                }}
            >
                <div
                style={{
                    textAlign:this.props.autoplan?"left":"center",
                    verticalAlign:"bottom",
                    backgroundColor:this.props.autoplan?"var(--main_contrast)":"white",
                    color:this.props.autoplan?"var(--sec_contrast)":"black",
                    
                }}
                className={"nut_label"}
                >
                    <Checkbox
                        className={"nut_checkbox"}
                        style={{
                            padding:0,
                            display:this.props.autoplan ? "":"none"
                        }}
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" style={{color:"var(--sec_contrast)"}}/>}
                        checkedIcon={<CheckBoxIcon fontSize="small" style={{color:"var(--sec_contrast)"}}/>}
                        checked={this.props.autoplan_include}
                        value={this.props.nut_abrev}
                        onChange={(event)=>{this.props.updateDayNutReq(this.props.plannum,
                                                                        this.props.nut_abrev,
                                                                        !this.props.autoplan_include)}}

                    />
                    <Typography style={{fontWeight:"regular"}} variant="caption">
                        {this.props.nut_name}
                    </Typography>
                </div>
                <div
                    style={{
                        borderTop:"1px dotted lightGrey",
                    }}
                >
                <div
                    style={{
                        textAlign:"center",
                        color:"black",
                    }}
                >
                    <Typography variant="body1">{this.props.nut_total}</Typography>
                </div>
                <div
                style={{
                    display:"flex", 
                    flexDirection:"row", 
                    justifyContent:"center", 
                    backgroundColor:this.props.bg_color,
                    width:85,
                    height:18,
                    verticalAlign:"middle",
                }}
                >
                    {this.renderStatusIndicator()}
                    <Typography variant="caption"
                    style={{
                        padding:0,
                        margin:0,
                        color:"black",
                        verticalAlign:"center",
                    }}
                    >
                        {/* Add logic here to show minus, check or plus */}
                        {this.props.achieve_delta}
                    </Typography>
                </div>
                </div>
            </section>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let nut_total = Math.round(state.user[ownProps.plannum].nutrient_totals[ownProps.nut_db_name])
    let lower_bound = state.user.requirements_dict[ownProps.nut_abrev+"_lb"]
    let upper_bound = state.user.requirements_dict[ownProps.nut_abrev+"_ub"]
    let lb_delta = lower_bound - nut_total
    let ub_delta = nut_total - upper_bound
    let status = 'under'
    let achieve_delta = Math.round(lb_delta*100)/100
    let tgt = Math.round((lower_bound+upper_bound)/2)
    let bg_color = "var(--goal_neg_dark)"
    if((lb_delta > 0) && (ub_delta <0)){
        // TODO - move this to action
        status = 'under'
        achieve_delta = Math.round(lb_delta*100)/100
        let prop_amt = achieve_delta/tgt
        if(prop_amt<0.10){
            bg_color = "var(--goal_pos_light)"
        }else if(prop_amt<0.20){
            bg_color = "var(--goal_pos_nuet)"
        }else if(prop_amt<0.50){
            bg_color = "var(--goal_neg_light)"
        }else{
            bg_color = "var(--goal_neg_dark)"
        }
    }
    else if((lb_delta < 0) && (ub_delta > 0)){
        status = 'over'
        achieve_delta = Math.round(ub_delta*100)/100
    }
    else{
        status = 'achieved'
        achieve_delta = null
        bg_color = "var(--goal_pos_dark)"
    }

    return({
        lower_bound:lower_bound,
        upper_bound:upper_bound,
        status:status,
        achieve_delta:achieve_delta,
        bg_color:bg_color,
        unit:state.util.abrev_to_units_dict[ownProps.nut_abrev],
        nut_total:nut_total,
        autoplan_include:state.user[ownProps.plannum].autoplan_nutrients[ownProps.nut_abrev],
        autoplan:state.user.autoplan
        
    })
}

export default connect(mapStateToProps,{updateDayNutReq} )(Nutrient);