import React, {Component} from 'react';
import {Provider} from 'react-redux';
import ReactDOM from 'react-dom';
import store from './store';
import './App.css';
// import Auth from './components/auth/auth';
import HomePage from './components/landing_page/home_page';
import Login from './components/auth/login';
import SignUp from './components/auth/signup';
import LandingPage from './components/common/landing_page';
import Podcast from './components/podcast/podcast';
// import BlogPage from './components/blog/blog_page';
// import BlogSearchPage from './components/blog/blog_search';
import Plan from './components/plan/plan';
import NutrientPage from './components/nutrient_page/nutrient_page';
import InputPage from './components/inputs/input_page';
import ShoppingListPage from './components/shopping/shopping_list_page';
import MenusBrowsePage from './components/menus/menu_browse_page';
import ProfilePage from './components/profile/profile_page';
import AccountPage from './components/membership/account';
import PaymentPage from './components/membership/payment_page';
import PasswordTokenPage from './components/auth/password_token_page';
import PrivateRoute from './components/common/privateRoute';
import PrivacyPage from './components/legal/privacy';
import ContactPage from './components/common/contact_page';
import TermsAndConditionsPage from './components/legal/terms_and_conditions_page';
import {
  BrowserRouter as Router, // sometimes BrowserRouter
  Route,
  Redirect,
  Switch
} from 'react-router-dom';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';


const stripePromise = loadStripe("pk_live_EkuiULtyHvR9pbuYvbksjjas00chfsA9mt") // live

class App extends Component{

  render() {
    return (
      <Elements stripe={stripePromise}>
        <Provider store= {store}>
          <Router >
              <Switch >
                <Route exact path="/" component={LandingPage}>
                  <Redirect to="/plan" />
                </Route>
                <Route exact path="/podcast" component={Podcast} />
                {/* <Route exact path="/blog/:slug" component={BlogPage}/>
                <Route exact path="/blog/:qry" component={BlogSearchPage}/> */}
                <Route exact path="/auth" component={HomePage}/>
                <Route exact path="/signup" component={SignUp}/>
                <Route exact path="/login" component={Login}/>
                <Route exact path="/privacy" component={PrivacyPage}/>
                <Route exact path="/terms_and_conditions" component={TermsAndConditionsPage}/>
                <Route path = "/password_reset_token/:token" component={PasswordTokenPage}/>
                <PrivateRoute exact path="/plan" component={Plan}/>
                <PrivateRoute exact path="/my_nutrients" component={NutrientPage}/>
                <PrivateRoute exact path="/input_page" component={InputPage}/>
                <PrivateRoute exact path="/shopping_list" component={ShoppingListPage}/>
                <PrivateRoute exact path="/browse_menus" component={MenusBrowsePage}/>
                <PrivateRoute exact path="/profile" component={ProfilePage}/>
                <PrivateRoute exact path="/payment" component={PaymentPage}/>
                <PrivateRoute exact path="/account" component={AccountPage}/>
                <PrivateRoute exact path="/contact" component={ContactPage}/>
              </Switch>
          </Router>
        </Provider>
      </Elements>
    )
  }
}

// ReactDOM.render(<App />, document.getElementById("root"));
export default App