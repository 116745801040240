import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Snackbar} from '@material-ui/core/';
import {toggleGenericSnackbar} from '../../actions/dialog';
import {withStyles} from '@material-ui/core/styles';

class GenericSnackbar extends Component{

        render(){        
            return (
                <Snackbar
                    anchorOrigin={{
                        vertical:"top",
                        horizontal:"center",
                    }}
                    ContentProps={{
                        classes:{
                            // root:classes.root
                        }
                    }}
                    open={this.props.open}
                    message={this.props.message}
                    onClose={(event)=>{this.props.toggleGenericSnackbar({
                        open:false,
                        message:null,
                    })}}
                >
                </Snackbar>
            )
        }
    }
    
    const mapStateToProps = (state,ownProps) => ({
        open:state.dialog.generic_snackbar.open,
        message:state.dialog.generic_snackbar.message,
    })
    
    export default connect(mapStateToProps,{toggleGenericSnackbar})(GenericSnackbar);