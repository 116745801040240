// This will include both the login and signup component

import React, {Component} from 'react';
import {connect} from 'react-redux';
// Router
import {NavLink} from 'react-router-dom';
// Material UI
import {withStyles} from '@material-ui/core/styles';
import {Button,
        Paper, 
        Slide,
        Tab,
        Tabs,
        Typography,
}from '@material-ui/core/';
// Components
import Login from './login';
import Signup from './signup';
import TermsAndConditionsModal from '../legal/terms_and_conditions';
import AuthSnackbar from './auth_snackbar';
// import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import FacebookLogin from 'react-facebook-login';
import {GoogleLogin} from 'react-google-login';
// Actions
import {fetchTokenFacebook, fetchTokenGoogle} from '../../actions/auth';
// Images
import logo from '../auth/assets/images/02-01.png';
import icon_only from '../auth/assets/images/IconOnly-01.png';
import desktop_demo from '../how_to/desktop_landing_page_placeholder.png';
// Icons
import AccessAlarmsIcon from '@material-ui/icons/AccessAlarms';
import CheckIcon from '@material-ui/icons/Check';
import NetworkCheckIcon from '@material-ui/icons/NetworkCheck'; // could be for calibration
import DevicesIcon from '@material-ui/icons/Devices'; // all devices
import InsertEmoticonIcon from '@material-ui/icons/InsertEmoticon'; // happy face
import RestaurantMenuIcon from '@material-ui/icons/RestaurantMenu';
//import MenuBookIcon from '@material-ui/icons/MenuBook';

const useStyles = theme=> ({
    desktop_only:{
        width:"75%",
        [theme.breakpoints.down('md')]:{
            display:"none",
        }
    },
    mobile_only:{
        display:"none",
        [theme.breakpoints.up('md')]:{
            display:"",
        }
    },
    fb_button: {
        width:"100%",
        textTransform:"none",
        height:45,  
        borderRadius: 4,
        backgroundColor: "#3b5998",
        color:"white",
        border:"1px solid black",  
        textAlign: "center",
        margin:"auto",
        fontSize:16,
        fontWeight:"bold",
        marginBottom:5,
        // display: "flex",
        // justifyContent:"space-around",
    },
    gg_button: {
        width:"100%",
        textTransform:"none",
        height:45,  
        borderRadius: 4,
        backgroundColor: "#db4a39",
        color:"white",
        border:"1px solid black",  
        textAlign: "center",
        margin:"auto",
        fontSize:16,
        fontWeight:"bold",
        marginBottom:5,
        display: "flex",
        justifyContent:"space-around",
    },
    bullet_boxes:{
        width:320,
        backgroundColor:"white",
        // border:"1px solid var(--sec_light)",
        textAlign:"center",
        color:"grey",
    }

});

class Auth extends Component{
// props - circle/square, image
    constructor(props){
        super(props)
        this.state = {
            tab:'login'
        } 
    }

    responseFacebook =(response)=>{
        this.props.fetchTokenFacebook(response)
    }

    clickFacebook =(event)=>{
    }

    responseGoogle = (response) => {
        this.props.fetchTokenGoogle(response)
    }

    clickGoogle = (event) =>{
    }

    render(){        
        const {classes} = this.props
        return (
            // <div
            //     style={{
            //         backgroundColor:"var(--sec_light)",
            //         // minHeight:"100vw",
            //         height: "100vh",/* Fallback for browsers that do not support Custom Properties */
            //         height: "calc(var(--vh, 1vh) * 100)",
            //     }}
            // >
                <Paper
                    style={{
                        width:330,// TODO - change to dynamic
                        margin:"auto",
                        // marginTop:20,
                        padding:5,
                        height: 450,
                    }}
                >
                <div
                    style={{
                        verticalAlign:"middle",
                        // marginTop:50,
                    }}
                >
                    <div
                        style={{
                            display:"flex",
                            justifyContent:"space-evenly",
                        }}
                    >
                        {/* <NavLink
                            to={"/podcast"}
                            style={{
                                color:"var(--main_dark)",
                            }}
                        >
                            <Typography
                                variant="body2"
                                style={{
                                    color:"var(--main_dark)",
                                }}
                            >
                            Podcast
                            </Typography>
                        </NavLink> */}
                        {/* <NavLink
                            to="/FAQ"
                            style={{
                                color:"var(--main_dark)",
                            }}
                        >
                        <Typography
                                variant="body2"
                                style={{
                                    color:"var(--main_dark)",

                                }}
                        >
                            FAQs
                            </Typography>
                        </NavLink> */}
                    </div>
                    <div
                        style={{
                            textAlign:"center"
                        }}
                    >
                        <img
                            src={logo}
                            width={320}
                            height={160}
                        />
                        
                    </div>
                    <Tabs 
                        value={this.state.tab}
                        onChange={(event, value)=>{this.setState({tab:value})}}
                        variant="fullWidth"
                        style={{
                            color:"var(--main_dark)",
                            fill:"var(--main_dark)",
                        }}
                        indicatorColor="primary"
                    >
                        <Tab 
                            value={'login'}
                            label={'Login'}
                        />
                        <Tab 
                            value={'signup'}
                            label={'Signup'}
                        />
                    </Tabs>
                    <div
                        style={{
                            display:this.state.tab==='login'?"":"none",
                        }}
                    >
                    <Login/>
                    </div>
                    <div
                        style={{
                            display:this.state.tab==='signup'?"":"none",
                        }}                    
                    >
                
                    <Signup/>
                    </div>
                    <div
                        style={{
                            textAlign:"center"
                        }}
                    >
                {/* <FacebookLogin
                    cssClass={classes.fb_button}
                    appId="836343366759737"
                    autoLoad={false}
                    fields="name,email,picture"
                    onClick={this.clickFacebook}
                    callback={this.responseFacebook} 
                    // icon="fa-facebook"
                    textButton={"Continue With Facebook"}
                /> */}
                    {/* <FacebookLogin
                        appID="836343366759737"
                        // autoLoad={false}
                        fields="name,email,picture"
                        onClick={this.clickFacebook}
                        callback={this.responseFacebook}
                        render={renderProps=>(
                            <Button 
                                fullWidth
                                variant="outlined"
                                onClick={renderProps.onClick}
                                style={{
                                    backgroundColor:"#3b5998",
                                    color:"white",
                                    // backgroundColor:"white",
                                    textTransform:"none",
                                    fontWeight:"bold",
                                    fontSize:16,
                                    height:45,
                                    marginBottom:5,
                                }}
                            >
                                Continue With Facebook</Button>
                        )}
                    /> */}
                <GoogleLogin
                    clientId="171680741054-pkick42vs0lguato7ncane00jaqf3djh.apps.googleusercontent.com"
                    onClick={this.clickGoogle}
                    callBack={this.responseGoogle}
                    icon={false}
                    render={renderProps => (
                    <Button 
                        variant="outlined"
                        fullWidth
                        onClick={renderProps.onClick} 
                        disabled={renderProps.disabled}
                        style={{
                            // backgroundColor:"#white",
                            color:"white",
                            backgroundColor:"#db4a39",
                            textTransform:"none",
                            fontWeight:"bold",
                            fontSize:16,
                            marginBottom:5,
                            height:45,
                        }}    
                    >
                        <Typography
                            variant="subheader2"
                        >
                            Continue With Google
                        </Typography>
                        
                    </Button>
                    )}
                    style={{
                        width:"100%"
                    }}
                    buttonText="Continue With Google"
                    onSuccess={this.responseGoogle}
                    onFailure={this.responseGoogle}
                    cookiePolicy={'single_host_origin'}
                />
                {/* <GoogleLogin
                    clientId="171680741054-pkick42vs0lguato7ncane00jaqf3djh.apps.googleusercontent.com"
                    buttonText="Continue With Google"
                    onSuccess={this.responseGoogle}
                    onFailure={this.responseGoogle}
                    cookiePolicy={'single_host_origin'}
                    icon={true}
                    className={classes.gg_button}
                    style={{
                        backgroundColor: "#db4a39",
                        color:"white",
                        border:"1px solid black",  
                        textAlign: "center",
                        margin:"auto",
                        fontSize:16,
                        fontWeight:"bold",     
                    }}
                /> */}
                
                    </div>
                    </div>
                    <AuthSnackbar />
                    <TermsAndConditionsModal />
                </Paper> 
        )
    }
}

const mapStateToProps = (state,ownProps) => ({

})

export default connect(null,{fetchTokenGoogle, fetchTokenFacebook})(withStyles(useStyles)(Auth));