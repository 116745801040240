import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Snackbar} from '@material-ui/core/';
import {closeAuthDialog} from '../../actions/auth';
import {withStyles} from '@material-ui/core/styles';
// Alert can come from material ui

const styles = {
    root: {
        background: 'red'
    }
};

class AuthSnackbar extends Component{
    // props - circle/square, image
        constructor(props){
            super(props)
            this.state = {
            } 
        }
    
        onChangeInput = (event)=>{
            
        }
    
        render(){        
            const {classes} = this.props
            return (
                <Snackbar
                    anchorOrigin={{
                        vertical:"top",
                        horizontal:"center",
                    }}
                    ContentProps={{
                        classes:{
                            // root:classes.root
                        }
                    }}
                    open={this.props.open}
                    message={this.props.message}
                    onClose={(event)=>{this.props.closeAuthDialog()}}
                >
                </Snackbar>
            )
        }
    }
    
    const mapStateToProps = (state,ownProps) => ({
        open:state.dialog.auth_snackbar.open,
        message:state.dialog.auth_snackbar.message,
    })
    
    export default connect(mapStateToProps,{closeAuthDialog})(withStyles(styles)(AuthSnackbar));