import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
    Avatar,
    Typography,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    ListItemSecondaryAction,
} from '@material-ui/core/';
import AccountCircleIcon from '@material-ui/icons/AccountCircle'; // Account avatar (placeholder)
import InfoIcon from '@material-ui/icons/Info';


class MenuSearchItem extends Component{
    constructor(props){
        super(props)
        this.state = {
            action_type:"browse",
            search_type:"name"
        } 
    }
    
    render(){   
        return (
            <ListItem
                style={{
                    // display:"flex",
                    backgroundColor:this.props.highlighted?"var(--sec_mid)":"var(--sec_white)",
                }}
            >
                <ListItemAvatar>
                    <Avatar 
                        alt={"M"}
                        src={this.props.menu_dict.image_url}
                    />
                </ListItemAvatar>
                <ListItemText 
                    primary={this.props.menu_dict.menu_name}
                    secondary={this.props.menu_dict.menu_description}
                />
            </ListItem>
        ) 
    }
}

const mapStateToProps = (state, ownProps)=>{
    let highlighted = state.menu_page.display_idx===ownProps.display_idx
    return({
        highlighted:highlighted
    })
}

export default connect(mapStateToProps, {})(MenuSearchItem);