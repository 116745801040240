import React, {Component} from 'react';
import {withStyles, fade } from '@material-ui/core/styles';
import SearchSubBar from './search';
import {logout} from '../../actions/auth';
import {searchFoods, searchMeals,togglePlanSearch} from '../../actions/search';
import {solveWeekPlan} from '../../actions/autoplan';
import {toggleAutoplan} from '../../actions/autoplan';
import {NavLink} from 'react-router-dom';
import {
    Avatar,
        AppBar,     
        Button,
        Drawer,
        List,
        ListItem,
        IconButton,
        Menu,
        MenuItem,
        Toolbar,
        Typography,
        Divider,
        withWidth,
    } from '@material-ui/core/';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import AccountCircleIcon from '@material-ui/icons/AccountCircle'; // Account avatar (placeholder)
import ExtensionIcon from '@material-ui/icons/Extension'; // puzzle  piece
import {connect} from 'react-redux';
import icon64 from '../auth/assets/images/64 x 64.ico';

const useStyles = theme =>({
//logic about mobile menu vs reg menu goes here
    sectionDesktop:{
        display:'none',
        [theme.breakpoints.up('md')]:{
            display:'flex',
            justifyContent:"center",
            alignContent:"center",
        },

    },
    sectionMobile:{
        display:'flex',
        justifyContent:"center",
        alignContent:"center",
        [theme.breakpoints.up('md')]:{
            display:'none',
        },
    },
    siteTitle:{
        display:'none',
        [theme.breakpoints.up('md')]:{
            display:'flex',
            justifyContent:"center",
            alignContent:"center",
            alignItems:"center",
        },
    },
    searchSec:{
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
          backgroundColor: fade(theme.palette.common.white, 0.25),
        },
    }
})


class NavBar extends Component{

    constructor(props){
        super(props)
        this.state = {
              profileAnchorEl:null,
              subbar:null,
              isHamburgerOpen:false,
          }
    }

    handleHamburgerClick = (event) =>{
        this.setState({isHamburgerOpen:true})
    }

    handleHamburgerClose =() =>{
        this.setState({isHamburgerOpen:false})
    }

    handleProfileClick = (event) =>{
        this.setState({profileAnchorEl:event.target})
    }

    handleProfileClose =() =>{
        this.setState({profileAnchorEl:null})
    }

    handleSubBarClick = (subbarname) =>{
        // toggle if same name, otherwise set to new subbar
         if(subbarname=='suggest'){
                this.props.toggleAutoplan({autoplan:true})
                this.props.togglePlanSearch({search:false})
                this.setState({subbar:"null"})
         }
         else{
                this.props.toggleAutoplan({autoplan:false})
                this.props.togglePlanSearch({search:true})
                this.setState({subbar:"search"})
         }
        // this.state.subbar ===subbarname ? this.setState({subbar:null}) : this.setState({subbar:subbarname});
    }

    renderSubBar = ()=>{
        switch(this.state.subbar){
            // case 'search': return (<SearchSubBar 
            //                             searchFoods={this.props.searchFoods}
            //                             searchMeals={this.props.searchMeals}
            //                         />)
            case 'search': return (<div
                    >
                    <div
                    // offset search subbar. Offsets overflow size of search box
                    style={{
                        height:40,
                    }}
                    />
                    <div
                    // offset loading
                    />
                    <div
                    // offset search results. Offsets overflow size of search results
                        style={{
                            display:(this.props.fetched_search_results)&(this.props.search)?"":"none",
                            width:"100%",
                            height: 200,
                        }}
                    />
                    </div>
            )
        }
    }

    onClickLogout = (event) =>{
        event.preventDefault();
        this.props.logout(this.state)
    }

    onClickMyNutrients = (event)=>{
        this.props.history.push('/my_nutrients')
    }

    render(){
    
    const isProfileOpen =  Boolean(this.state.profileAnchorEl)

    const {classes} = this.props;
    
    return (
            <div style={{
                "backgroundColor":"var(--sec_light)", 
                //position:"fixed"
            }}>
            <div 
                style={{
                    height:45
                }}
            >
            <AppBar position="fixed" // or "sticky"
                style={{backgroundColor:"var(--sec_white)"}}>
                <Toolbar
                    variant={"dense"}
                    style={{
                        display:"flex",
                        justifyContent:"space-between",
                    }}
                >
                    <section style={{
                        display:"flex",
                    }}>
                        <IconButton 
                            onClick={this.handleHamburgerClick}
                        >
                        <MenuIcon
                            style={{color:"var(--main_dark)"}}
                            />
                        </IconButton>
                        <Drawer
                            anchor={"left"}
                            open={this.state.isHamburgerOpen}
                            variant="temporary"
                            onClose={this.handleHamburgerClose}
                        >
                            <List>
                                <ListItem>
                                    <NavLink to='/plan'  style={{textDecoration:"none"}}>
                                        Plan
                                    </NavLink>
                                </ListItem>
                                <ListItem style={{textDecoration:"none"}}>
                                    <NavLink to='/my_nutrients' style={{textDecoration:"none"}}>
                                        My Nutrients
                                    </NavLink>
                                </ListItem>
                                <ListItem>
                                    <NavLink to='/input_page' style={{textDecoration:"none"}}>
                                        Add Recipes and Meals
                                    </NavLink>
                                </ListItem>
                                <ListItem>
                                    <NavLink to='/shopping_list' style={{textDecoration:"none"}}>
                                        Shopping List
                                    </NavLink>
                                </ListItem>
                                <ListItem>
                                    <NavLink to='/browse_menus' style={{textDecoration:"none"}}>
                                        Build & Browse Menus
                                    </NavLink>
                                </ListItem>
                                <ListItem>
                                    <NavLink to='/contact' style={{textDecoration:"none"}}>
                                        Contact & Feedback
                                    </NavLink>
                                </ListItem>
                                {/* should be a seperate resources section */}
                                <Divider />
                                <ListItem>
                                    <NavLink to='/podcast' style={{textDecoration:"none"}}>
                                        Podcast
                                    </NavLink>
                                </ListItem>
                            </List>
                        </Drawer>
                        <div
                            style={{
                                height:40,
                                display:"inline-block",
                                verticalAlign:"center",
                            }}
                        >
                        <NavLink 
                            to="/plan"
                            activeStyle={{
                                textDecoration:"none"
                            }}
                        >
                        <img 
                            style={{
                                margin:"auto",
                                textAlign:"center",
                                verticalAlign:"middle",
                                display:"inline-block",

                            }}
                            src={icon64}
                            width={45}
                            height={45}
                        />
                        </NavLink>
                        </div>
                        <div
                            className={classes.siteTitle}
                            style={{
                                verticalAlign:"middle",
                            }}
                        >
                        {/* <Typography
                            style={{
                                color:"var(--main_contrast)",
                                fontWeight:"bold",
                            }}
                            variant="h5"
                        >
                            PLAN
                        </Typography>
                        <Typography
                            style={{
                                color:"var(--main_bright)",
                                fontWeight:"bold",
                            }}     
                            variant="h5"                   
                        >
                            YOUR
                        </Typography>
                        <Typography
                            style={{
                                color:"var(--main_contrast)",
                                fontWeight:"bold",
                            }}      
                            variant="h5"                  
                        >
                            MEALS
                        </Typography> */}
                        </div>
                    </section>
                    {/* middle section */}
            <section 
                style={{
                    display:"flex",
                    justifyContent:"center",
                }}
            >
                     {/*START DESKTOP SECTION */}
                    <section 
                        className={classes.sectionDesktop}
                    >
                    <Button
                    onClick={(event)=>{this.handleSubBarClick('search')}}
                    style={{
                        visibility:this.props.has_search?"":"hidden",
                        border:"1px solid var(--main_bright)",
                        // backgroundColor:"var(--sec_light)",
                        width:200,
                        height:"100%",
                        marginRight:10,
                    }}
                    >
                        <SearchIcon
                        style={{color:"var(--main_bright)"}}
                        />
                        <Typography style={{color:"var(--main_bright"}}>SEARCH</Typography>
                    </Button>
                    <Button
                    onClick={(event)=>{this.handleSubBarClick('suggest')}}
                    style={{
                        visibility:this.props.has_autoplan?"":"hidden",
                        border:"1px solid var(--main_contrast)",
                        width:200,
                    }}
                    >
                        <ExtensionIcon
                        style={{color:"var(--main_contrast)",marginRight:7}}
                        />
                        <Typography style={{color:"var(--main_contrast"}}>  AUTOPLAN WEEK</Typography>
                    </Button>
                    </section>
                     {/* END DESKTOP SECTION */}
                     {/* START MOBILE SECTION */}
            <section 
                className={classes.sectionMobile}
                // style={{display:"flex", justifyContent:"center"}}
            >
                <Button
                    onClick={(event)=>{this.handleSubBarClick('search')}}
                    style={{
                        visibility:this.props.has_search?"":"hidden",
                        border:"1px solid var(--main_bright)",
                        width:20,
                        marginRight:5,
                    }}
                >
                <SearchIcon
                    style={{color:"var(--main_bright)"}}
                />
                    </Button>
                    <Button
                    onClick={(event)=>{this.handleSubBarClick('suggest')}}
                    style={{
                        visibility:this.props.has_autoplan?"":"hidden",
                        border:"1px solid var(--main_contrast)",
                        width:20,
                    }}
                    >
                        <ExtensionIcon
                        style={{color:"var(--main_contrast)"}}
                        />
                    </Button>
            </section>

                    {/* END MOBILE SECTION */}
            </section >
            {/* right section */}
            <section style={{display:"flex", alignItems:"end",}}>
                <IconButton 
                    onClick={this.handleProfileClick}
                >
                    <AccountCircleIcon
                        style={{color:"var(--main_dark)"}}
                    />
                    {/* <Avatar 
                        src={""}
                    /> */}
                </IconButton> 
                    <Menu
                        open={isProfileOpen}
                        onClose={this.handleProfileClose}
                        anchorEl = {this.state.profileAnchorEl}
                    >
                    <MenuItem>
                        <NavLink to='/profile' style={{textDecoration:"none"}}>
                            Profile
                        </NavLink>
                    </MenuItem> 
                    <MenuItem>
                        <NavLink to='/account' style={{textDecoration:"none"}}>
                         Account
                         </NavLink>
                    </MenuItem>  
                    <MenuItem
                        onClick={this.onClickLogout}
                    >
                        Logout
                    </MenuItem>    
                    </Menu> 
            </section>
                </Toolbar>
            </AppBar>
            </div>
                <div style={{}}>
                    {this.renderSubBar()}
                </div>
            </div>
        )
    }
}



const mapStateToProps = state => ({
    //username: state.auth.username
    // might be able to get away with removing all of these
    requirements_list: state.user.requirements_order,
    abrev_to_display_dict: state.util.abrev_to_display_dict,
    autoplan:state.user.autoplan,
    search:state.user.search,
    fetched_search_results: state.columns.present.searchResults.fetched_search_results,

  })

  
export default connect(mapStateToProps, {logout, 
                                        searchFoods, 
                                        searchMeals,
                                        toggleAutoplan,
                                        togglePlanSearch,
                                        solveWeekPlan})(withStyles(useStyles)(NavBar));

