import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withStyles, makeStyles} from '@material-ui/core/styles';
import MealMenu from './meal_menu';
import {search_menu_items} from '../../actions/search';
import {add_menu_item} from '../../actions/menu';
import {
    Paper,
    Toolbar,
    IconButton,
    Typography,
    Input,
    Select,
    InputBase,
    Menu,
    MenuItem,
    Table,
    TableBody,
    TableHead,
    TableRow,
    TableCell,
    CircularProgress
} from '@material-ui/core/';
import SearchIcon from '@material-ui/icons/Search';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import AddIcon from '@material-ui/icons/Add';

const useStyles = theme=>({
    menu_class:{
        width:320,
        margin:10,
        [theme.breakpoints.down('md')]:{
            width:"100%",
            marginLeft:0,
            marginRight:0
        }
    }
}
)

class PlanMenu extends Component{

    constructor(props){
        super(props)
        this.state = {
            search_qry:null,
            search_type:"food",
            has_search_results:false,
            search_results_loading:false,
            item_add_anchor_el:null,
            isAddMenuOpen:false,
            curr_search_result:null
        } 
    }

    getMenuSearchResult = () =>{
        this.props.search_menu_items({
            search_qry:this.state.search_qry,
            item_type:this.state.search_type,
            display_page:this.props.display_page,
        })
    }

    handleMenuAdd = (event, result) =>{
        this.setState({curr_search_result:result,
                        item_add_anchor_el:event.target,
                        isAddMenuOpen:true})
    }

    handleAddMenuClose =() =>{
        // this might be the source for the rendering slowness
        this.setState({item_add_anchor_el:null,
                        isAddMenuOpen:false})
    }

    addMenuClick = (event,menuObj) =>{
        this.props.add_menu_item({
            ...menuObj,
            ...this.state.curr_search_result,
            display_page:this.props.display_page,
        })
    }

    render(){        
        let menu_parts=[{display_name:'Breakfast - Whole Meals',meal_type:'Breakfast',dish_num:'whole_meals'},
        {display_name:'Breakfast - Main Courses',meal_type:'Breakfast',dish_num:'main_courses'},
        {display_name:'Breakfast - Sides',meal_type:'Breakfast',dish_num:'sides'},
        {display_name:'Lunch - Whole Meals',meal_type:'Lunch',dish_num:'whole_meals'},
        {display_name:'Lunch - Main Courses',meal_type:'Lunch',dish_num:'main_courses'},
        {display_name:'Lunch - Sides',meal_type:'Lunch',dish_num:'sides'},
        {display_name:'Dinner - Whole Meals',meal_type:'Dinner',dish_num:'whole_meals'},
        {display_name:'Dinner - Main Courses',meal_type:'Dinner',dish_num:'main_courses'},
        {display_name:'Dinner - Sides',meal_type:'Dinner',dish_num:'sides'},
        {display_name:'Snack - Snacks',meal_type:'Snack',dish_num:'snacks'},
        ]

        const {classes} = this.props;

        return (
            <Paper
                className={classes.menu_class}
                style={{
                    // overflowY:"hidden",
                    scrollbarWidth:"thin",
                    // backgroundColor:"var(--sec_contrast)", // needs to remain an opposite color to the plan days
                    backgroundColor:"white",
                    display:"flex",
                    flexDirection:"column",
                }}
            >                
                <div
                    style={{
                        display: this.props.exclude_search?"none":"flex",
                        justifyContent:"space-between",
                    }}
                >
                    <Select
                        value={this.state.search_type}
                        name="search type"
                        onChange = {(event)=>{this.setState({search_type:event.target.value})}}
                        inputProps ={{
                            name:'search_type',
                            id:'search_type'
                        }}
                    >
                        <MenuItem value={"food"}>Foods</MenuItem>
                        <MenuItem value={"meal"}>Meals</MenuItem>
                        <MenuItem value={"tag"}>Tags</MenuItem>
                        <MenuItem value={"brand"}>Brands</MenuItem>
                </Select>
                <InputBase
                    style={{"verticalAlign":"bottom",
                        paddingLeft:3,
                        width:250
                    }}
                    placeholder={"Search..."}
                    fullWidth={false}
                    onChange={(event)=>{this.setState({search_qry:event.target.value})}}
                    />
                <IconButton
                        size= {"small"}
                        onClick={this.getMenuSearchResult}
                >
                        <SearchIcon/>
                    </IconButton>
                </div>
                <div
                    style={{
                        height:40,
                        width:"100%",
                        display:this.props.searchResultsLoading? "flex":"None",
                        justifyContent:"center",
                    }}
                >
                    <CircularProgress
                        style={{
                            margin:"auto",
                            color:"var(--main_bright)",
                        }}
                    />
                </div>
                <div
                    style={{
                        height:100,
                        width:"100%",
                        overflowY:"scroll",
                        display:this.props.hasSearchResults? "block":"none",
                    }}
                >
                    {/* switch to a div */}
                    {/* <List
                    > */}
                    {this.props.search_results.map((result,idx)=>(
                        <div
                            style={{
                                display:"flex"
                            }}
                        >
                             <IconButton size="small" edge="end"
                                onClick={(event)=>{this.handleMenuAdd(event,result)}}
                                style={{
                                    height:"100%",
                                }}
                            >
                            <AddIcon
                                style={{
                                    margin:"auto"
                                }}
                                fontSize="small"
                            />
                            </IconButton>
                           <Typography 
                                style={{
                                    verticalAlign:"bottom",
                                }}
                                noWrap={true}
                                // display="block"
                                variant="caption"
                            >{result.name}</Typography>
                            <Typography 
                                variant="caption"
                                style={{
                                    marginLeft:10,
                                }}
                                noWrap={true}
                            >
                                {result.item_type}
                                
                            </Typography> 
                        </div> 
                    ))}
                    {/* replace with dialog & button group */}
                    <Menu
                        multiple
                        open={this.state.isAddMenuOpen}
                        onClose={this.handleAddMenuClose}
                        anchorEl={this.state.item_add_anchor_el}
                    >
                        {menu_parts.map((item, idx)=>(
                        <MenuItem
                            key={item.display_name}
                            onClick={(event)=>{this.addMenuClick(event,{
                                ...item,
                                default:true
                            })}}
                        >
                            <Typography
                            >
                                {item.display_name}
                            </Typography>
                        </MenuItem>
                        ))}
                    </Menu>
                </div>
                <div
                    style={{
                        textAlign:"center",
                        display:this.props.display_page=="plan"?"none":"",
                    }}
                >
                    <Typography>{this.props.menu_name}</Typography>
                </div>
                <MealMenu
                    meal_type={'Breakfast'}
                    dishnums={['whole_meals', 'main_courses','sides']}
                    display_page ={this.props.display_page}
                    exclude_search={this.props.exclude_search}
                />
                <MealMenu
                    meal_type={'Lunch'}
                    dishnums={['whole_meals', 'main_courses','sides']}
                    display_page ={this.props.display_page}
                    exclude_search={this.props.exclude_search}
                />
                <MealMenu
                    meal_type={'Dinner'}
                    dishnums={['whole_meals', 'main_courses','sides']}
                    display_page ={this.props.display_page}
                    exclude_search={this.props.exclude_search}
                />
                <MealMenu
                    meal_type={'Snack'}
                    dishnums={['snacks']}
                    display_page ={this.props.display_page}
                    exclude_search={this.props.exclude_search}
                />
            </Paper>
        )
    }
}
const mapStateToProps = (state, ownProps) => {
    // Leaving space in case logic is needed here
    let search_results = []
    let hasSearchResults=false
    let returnedEmpty = false
    let searchResultsLoading=false
    if(ownProps.display_page==='plan'){
        search_results = state.columns.present.menuSearchResults.results_list
        hasSearchResults = search_results.length===0 ? false:true
        returnedEmpty  = false
        searchResultsLoading = state.columns.present.menuSearchResults.isLoading
    }else{
        search_results = state.searchWidgets.buildMenuSearch.results_list
        hasSearchResults = search_results.length===0 ? false:true
        returnedEmpty  = false
        searchResultsLoading = state.searchWidgets.buildMenuSearch.isLoading
    }
    return ({
            searchResultsLoading:searchResultsLoading,
            hasSearchResults: hasSearchResults,
            search_results:search_results
    })
}

export default connect(mapStateToProps, {search_menu_items,add_menu_item})(withStyles(useStyles)(PlanMenu));