import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {solveWeekPlan, solveAdjustAmounts, toggleAutoplan} from '../../actions/autoplan';
import {undoCols, redoCols,postWeek} from '../../actions/undo_redo';
import {
        IconButton,
        Toolbar,
        Typography,
        Drawer,
    } from '@material-ui/core/';
import LoopIcon from '@material-ui/icons/Loop'; // Get Plan
import VerticalAlignCenterIcon from '@material-ui/icons/VerticalAlignCenter'; // Better adjust
import SwapHorizIcon from '@material-ui/icons/SwapHoriz'; // Alternatives
import UndoIcon from '@material-ui/icons/Undo';
import RedoIcon from '@material-ui/icons/Redo';
import ClearIcon from '@material-ui/icons/Clear';
import {connect} from 'react-redux';

class SuggestionSubBar extends Component {

    constructor(props){
        super(props)
        this.state = {

          }
    }

    getAutoPlanSolve = event =>{
        this.props.solveWeekPlan()
    }

    undoSubBar = event =>{
        this.props.undoCols()
        this.props.postWeek()
    }

    redoSubBar = event =>{
        this.props.redoCols()
        this.props.postWeek()
    }

    render(){
        return (
            <Drawer 
                variant="persistent"
                anchor="top"
                open={this.props.autoplan}
                style={{
                    backgroundColor:"var(--sec_contrast)",
                    // height:55,
                }}
            >
                <div
                    style={{
                        margin:"auto",
                        backgroundColor:"var(--sec_contrast)",
                        width:"100%",
                        display:"flex",
                        justifyContent:"center",
                        height:55,
                    }}
                >
                    <div
                        style={{
                            width:320,
                            display:"flex",
                            justifyContent:"space-between",                            
                        }}
                    >
                    
                    <div
                        style={{
                            display:"flex",
                            justifyContent:"flex-start",
                        }}
                    >
                    <IconButton 
                        onClick={this.undoSubBar}
                        size="small"
                        style={{
                            display:"flex",
                            flexDirection:"column",
                            backgroundColor:"white",
                            opacity:0.5,
                            width:60,
                            visibility:this.props.no_past|this.props.solve_in_progress?"hidden":"",

                        }}
                        disabled={(this.props.no_past|this.props.solve_in_progress)}
                    >
                    <div
                        style={{
                            display:"flex",
                            flexDirection:"column",
                            justifyContent:"center",
                            
                        }}
                    >
                        <UndoIcon
                            fontSize="default"
                            style={{
                                color:"var(--main_contrast)",
                                margin:"auto",
                            }}
                        />
                        <Typography
                            variant="subtitle2"
                            style={{
                                color:"var(--main_contrast)",
                            }}
                        >
                            Undo
                        </Typography>
                        </div>
                    </IconButton>
                    <IconButton
                        onClick={this.getAutoPlanSolve}
                        size="small"
                        style={{
                            display:"flex",
                            flexDirection:"column",
                            backgroundColor:"white",
                            opacity:0.5,
                            width:60,
                            visibility:this.props.solve_in_progress?"hidden":"",
                        }}
                    >
                        <div
                        style={{
                            display:"flex",
                            flexDirection:"column",
                            justifyContent:"center",
                        }}
                        disabled={this.props.solve_in_progress}
                        >
                        <LoopIcon
                            fontSize="default"
                            style={{
                                color:"var(--main_contrast)",
                                margin:"auto",
                            }}
                        />
                        <Typography 
                            variant="subtitle2" 
                            style={{
                                color:"var(--main_contrast)",
                            }}
                        >
                        Suggest
                        </Typography>
                        </div>
                        {/* Get Suggestions */}
                    </IconButton>
                    <IconButton
                        size="small"
                        style={{
                            display:"flex",
                            flexDirection:"column",
                            backgroundColor:"white",
                            opacity:0.5,
                            width:60,
                            visibility:this.props.solve_in_progress?"hidden":"",
                        }}
                        onClick={(event)=>{this.props.solveAdjustAmounts()}}
                        disabled={this.props.solve_in_progress}
                    >
                        <div
                        style={{
                            display:"flex",
                            flexDirection:"column",
                            justifyContent:"center",
                        }}
                        >
                        <VerticalAlignCenterIcon
                            fontSize="default"
                            style={{
                                color:"var(--main_contrast)",
                                margin:"auto",
                            }}
                        />
                        <Typography
                            variant="subtitle2"
                            style={{
                                color:"var(--main_contrast)",
                            }}
                        >
                        Adjust
                        </Typography>
                        </div>
                        {/* Adjust */}
                    </IconButton>
                    <IconButton 
                        onClick={this.redoSubBar}
                        size="small"
                        style={{
                            display:"flex",
                            flexDirection:"column",
                            backgroundColor:"white",
                            opacity:0.5,
                            width:60,
                            visibility:this.props.no_future|this.props.solve_in_progress?"hidden":"",
                        }}
                        disabled={this.props.no_future|this.props.solve_in_progress}
                    >
                    <div
                        style={{
                            display:"flex",
                            flexDirection:"column",
                            justifyContent:"center",
                        }}
                    >
                        <RedoIcon
                            fontSize="default"
                            style={{
                                color:"var(--main_contrast)",
                                margin:"auto",
                            }}
                        />
                        <Typography
                            variant="subtitle2"
                            style={{
                                color:"var(--main_contrast)",
                            }}
                        >
                            Redo
                        </Typography>
                        </div>
                    </IconButton>
                    </div>
                        <IconButton
                            onClick={(event)=>{this.props.toggleAutoplan({autoplan:false})}}
                            style={{
                                // translucent, like search
                            }}
                            size="small"
                        >
                            <ClearIcon/>
                        </IconButton>
                    </div>
                </div>
            </Drawer>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    // check if past len > 0
    // check if check if future len > 0
    return{
    //username: state.auth.username
    // might be able to get away with removing all of these
    requirements_list:  state.user.requirements_order,
    abrev_to_display_dict: state.util.abrev_to_display_dict,
    autoplan:state.user.autoplan,
    no_past: state.columns.past.length === 0,
    no_future: state.columns.future.length ===0,
    solve_in_progress: state.user.solve_in_progress,
  }
}

  
export default connect(mapStateToProps, {toggleAutoplan,
                                        solveWeekPlan,
                                        solveAdjustAmounts,
                                        undoCols, 
                                        redoCols,
                                        postWeek,
                                    })(SuggestionSubBar);

