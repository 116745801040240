import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
    IconButton,
    Input,
    TextField,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Typography,
} from '@material-ui/core/';
import InfoIcon from '@material-ui/icons/Info';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import NavBar from '../common/navbar';
import {get_shopping_list,deleteShoppingListItem, addShoppingListItem,changeShoppingListItemAmt} from '../../actions/shopping_list';

class ShoppingListPage extends Component{
    constructor(props){
        super(props)
        this.state = {
            add_amt:null,
            add_unit:null,
            add_food:null,
        } 
    }

    componentDidMount=()=>{
        this.props.get_shopping_list()
    }

    onDeleteItem =(idx)=>{
        this.props.deleteShoppingListItem(idx)
    }

    onAddItem =() =>{
        if((this.state.add_amt==='')||(this.state.add_amt===null)){
            alert("Please enter an amount")
        }else if((this.state.add_unit==='')||(this.state.add_unit===null)){
            alert("Please enter a unit")
        }else if((this.state.add_food==='')||(this.state.add_food===null)){
            alert("Please enter a food")
        }else{
            this.props.addShoppingListItem({
                id:0,
                total_amount:this.state.add_amt,
                serving_size_unit:this.state.add_unit,
                food_description:this.state.add_food,
            })
        }
    }

    onChangeItemAmt = (value, idx)=>{
        this.props.changeShoppingListItemAmt(value,idx)
    }
    
    render(){   
        return (
            <div>
                <NavBar />
            <div
                style={{
                    //  width:320, should shrink with viewport
                    maxWidth:450,
                    margin:"auto"
                }}
            >
            <div
                style={{
                    display:"flex"
                }}
            >
                {/* add item */}
                <div
                    style={{
                        width:"10%",
                        verticalAlign:"bottom"
                    }}                
                >
                <IconButton
                    size="small"
                    style={{
                        marginTop:"30%"
                    }}
                    edge="end"
                    onClick={this.onAddItem}
                >
                    <AddIcon 
                        // fontSize="small"
                    />
                </IconButton>
                </div>
                <TextField
                    size="small"
                    variant="outlined"
                    style={{
                        width:"20%"
                    }}
                    placeholder={"Amount"}
                    onChange={(event)=>{this.setState({add_amt:event.target.value})}}
                />
                <TextField
                    variant="outlined"
                    style={{
                        width:"20%"
                    }}
                    placeholder={"Unit"}
                    onChange={(event)=>{this.setState({add_unit:event.target.value})}}
                />
                <TextField
                    variant="outlined"
                    style={{
                        width:"50%"
                    }}
                    placeholder={"Food"}
                    onChange={(event)=>{this.setState({add_food:event.target.value})}}
                />
            </div>
            <div
                style={{
                    width:"100%",
                    margin:"auto",
                }}
            >
                        {this.props.list.map((item,idx)=>(
                            <div
                                style={{
                                    width:"100%",
                                    display:"flex",
                                    justifyContent:"start",
                                    borderBottom:"1px solid lightGrey",
                                }}
                            >

                            <div
                                style={{
                                    width:"10%",
                                }}
                            >
                                <IconButton
                                        size="small"
                                        onClick={(event)=>{this.onDeleteItem(idx)}}
                                    >
                                        <DeleteIcon
                                            fontSize="small"
                                        />
                                    </IconButton>
                            </div>
                            <div
                                style={{
                                    width:"20%",
                                    textAlign:"center"
                                }}
                            >
                                    <TextField
                                        style={{
                                            width:50,
                                            textAlign:"center",
                                            margin:"auto",
                                        }}
                                        value={item.total_amount}
                                        onChange={(event)=>{this.onChangeItemAmt(event.target.value, idx)}}
                                    />
                            </div>
                            <div
                                style={{
                                    width:"20%",
                                    // paddingLeft:10,
                                }}
                            >
                                    <Typography
                                        style={{
                                            // paddingLeft:10
                                        }}
                                    >{item.serving_size_unit}</Typography>
                                </div>
                                <div
                                    style={{
                                        width:"50%",
                                        // paddingLeft:10,
                                    }}
                                >
                                <Typography
                                        noWrap="true"
                                    >
                                        {item.food_description}
                                    </Typography>
                                </div>
                            </div>
                        ))}
            </div>
            </div>
            </div>
        ) 
    }

}

const mapStateToProps = (state, ownProps) => ({
    list: state.shopping_list.list
})

export default connect(mapStateToProps, {get_shopping_list,
                                    deleteShoppingListItem,
                                    addShoppingListItem,
                                    changeShoppingListItemAmt})(ShoppingListPage);