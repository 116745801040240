import initialState from '../initialState'

//TODO - get rid of combine state so can access state by doing this.state.columns.searchResults.food, etc

export default function(state=initialState, action){
    switch(action.type){
        case 'FETCHING_SEARCH_RESULTS':
            return { 
                ...state,
                'searchResults':{
                    ...state.searchResults,
                    isLoading:true,
                    results_type: action.payload.results_type
                }
            }
        case 'FOOD_SEARCH_RESULTS_NONE':
            return {
                ...state,
                searchResults:{
                    ...state.searchResults,
                    isLoading:false,
                    fetched_search_results:true,
                    search_returned_empty:true,   
                }
            }
        case 'FOOD_SEARCH_RESULTS':
                return{
                   ...state,
                    searchResults:{
                        ...state.searchResults,
                            isLoading:false,
                            results_type:'food',
                            nth_search: action.payload.nth_search,
                            foods_list: action.payload.search_results_by_id,
                            //foods_dict: action.payload.search_results_dict,
                            search_returned_empty:action.payload.search_returned_empty,
                            fetched_search_results:action.payload.fetched_search_results,
                    }
                }
        case 'MEAL_SEARCH_RESULTS':
            return{
                ...state,
                searchResults:{
                    ...state.searchResults,
                    isLoading:false,
                    results_type:'meal',
                    nth_search: action.payload.nth_search,
                    meals_list: action.payload.meals_list,
                    fetched_search_results: action.payload.fetched_search_results,
                    search_returned_empty:action.payload.search_returned_empty,

                }
            }
        case 'ADD_TO_MEAL_FROM_SEARCH':
            return{
                // Source
                ...state,
                    [action.payload.destination_col]:{
                    ...state[action.payload.destination_col],
                    //nutrient_totals:action.payload.nutrient_totals,
                    [action.payload.destination_subcol]:{
                        ...state[action.payload.destination_col][action.payload.destination_subcol],
                        foods_list: action.payload.destination_list
                    }
            }
        }
        case 'ADD_TO_MEAL_FROM_MEAL':
            return {
                ...state,
                    [action.payload.destination_col]:{
                        ...state[action.payload.destination_col],
                        [action.payload.destination_subcol]:{
                            ...state[action.payload.destination_col][action.payload.destination_subcol],
                            foods_list: action.payload.destination_list
                        }
                    },
                    [action.payload.source_col]:{
                        ...state[action.payload.source_col],
                        [action.payload.source_subcol]:{
                            ...state[action.payload.source_col][action.payload.source_subcol],
                            foods_list: action.payload.source_list
                        }
                    },
        }
        case 'ADD_TO_MEAL_FROM_MEAL_SAME_DAY':
            return{
                ...state,
                [action.payload.col]:{
                    ...state[action.payload.col],
                    [action.payload.source_subcol]:{
                        ...state[action.payload.col][action.payload.source_subcol],
                        foods_list:action.payload.source_list
                    },
                    [action.payload.destination_subcol]:{
                        ...state[action.payload.col][action.payload.destination_subcol],
                        foods_list:action.payload.destination_list
                    }
                }
            }
        case 'REORDER_IN_SAME_COLUMN':
            return {
                ...state,
                    [action.payload.col]:{
                        ...state[action.payload.col],
                        [action.payload.subcol]:{
                            ...state[action.payload.col][action.payload.subcol],
                            foods_list: action.payload.foods_list
                        }
                }
        }
        case 'REORDER_IN_SAME_COLUMN_SEARCH':
            return {
                ...state,
                searchResults:{
                    ...state.searchResults,
                    foods_list:action.payload.foods_list
                }
            }
        case 'UPDATE_FOODS_LIST':
                return {
                    ...state,
                        [action.payload.col]:{
                            ...state[action.payload.col],
                            //nutrient_totals:action.payload.nutrient_totals,
                            [action.payload.subcol]:{
                                ...state[action.payload.col][action.payload.subcol],
                                foods_list: action.payload.foods_list
                            }
                    }
            }
        case 'UPDATE_FOOD_AMOUNT':
            /*
            Updates new_nutrient totals and foods list
            */
            return {
                ...state,
                    [action.payload.col]:{
                        ...state[action.payload.col],
                        //nutrient_totals:action.payload.nutrient_totals,
                        [action.payload.subcol]:{
                            ...state[action.payload.col][action.payload.subcol],
                            foods_list: action.payload.foods_list
                        }
                    }
            }
            case 'UPDATE_FOOD_UNIT':
            return {
                    ...state,
                        [action.payload.col]:{
                            ...state[action.payload.col],
                        [action.payload.subcol]:{
                                ...state[action.payload.col][action.payload.subcol],
                                foods_list: action.payload.foods_list
                            }
                    }
            }
            case 'UPDATE_FOOD_AMOUNT_SEARCH':
                    /*
                    Updates new_nutrient totals and foods list
                    */
                    return {
                        ...state,
                        [action.payload.col]:{
                            ...state[action.payload.col],
                            foods_list: action.payload.foods_list
                        }
                    }
            case 'MEAL_TO_MEAL':
                return {
                    ...state,
                        [action.payload.col]:{
                            ...state[action.payload.col],
                            //nutrient_totals:action.payload.nutrient_totals,
                            [action.payload.subcol]:{
                                ...state[action.payload.col][action.payload.subcol],
                                foods_list: action.payload.foods_list,
                                mealname: action.payload.mealname
                            }
                    }
            }
            case 'MOUNT_DAY_PLAN':
                return {
                    ...state,
                        [action.payload.col]:{
                            ...state[action.payload.col],
                            date: action.payload.date
                        }
                }
        case 'LOAD_DAY_PLAN':
                return {
                    ...state,
                    [action.payload.col]:{
                        ...state[action.payload.col],
                        date: action.payload.date,
                        ['Breakfast']:{
                            ...state[action.payload.col]['Breakfast'],
                            foods_list:action.payload.br_foods_list,
                            meal_drag_key: action.payload.br_drag_key
                        },
                        ['Lunch']:{
                            ...state[action.payload.col]['Lunch'],
                            foods_list:action.payload.lu_foods_list,
                            meal_drag_key: action.payload.lu_drag_key
                        },
                        date: action.payload.date,
                        ['Dinner']:{
                            ...state[action.payload.col]['Dinner'],
                            foods_list:action.payload.di_foods_list,
                            meal_drag_key: action.payload.di_drag_key
                        },
                        ['Snack']:{
                            ...state[action.payload.col]['Snack'],
                            foods_list:action.payload.sn_foods_list,
                            meal_drag_key: action.payload.sn_drag_key
                        },
                        //nutrient_totals:action.payload.nutrient_totals
                    }
            }
        case 'CLEAR_MEAL':
            return{
                ...state,
                [action.payload.plannum]:{
                    ...state[action.payload.plannum],
                    [action.payload.meal_type]:{
                        ...state[action.payload.plannum][action.payload.meal_type],
                        foods_list:action.payload.foods_list
                    }
                }
            }
        case 'MENU_SEARCH_RESULTS_LOADING':
            return{
                ...state,
                menuSearchResults:{
                    ...state.menuSearchResults,
                    isLoading:action.payload.isLoading
                }
            }
            case 'MENU_SEARCH_RESULTS_RETURNED':
                return{
                    ...state,
                    menuSearchResults:{
                        ...state.menuSearchResults,
                        isLoading:action.payload.isLoading,
                        results_list:action.payload.results_list
                    }
                }
        case 'MENU_ADD':
            return{
                ...state,
                menus:{
                    ...state.menus,
                    [action.payload.meal_type]:{
                        ...state.menus[action.payload.meal_type],
                        [action.payload.dish_num]:{
                            ...state.menus[action.payload.meal_type][action.payload.dish_num],
                            items_list:action.payload.items_list
                        }
                    }
                }
            }
        case 'UPDATE_WEEK':
            return {
            ...state,
            ...action.payload.columns
        }

        case 'UPDATE_WEEK_AUTOPLAN':
            return {
            ...state,
            ...action.payload.columns
        }

        default:
            return state;
    }
}