import axios from 'axios'// since fetch api not supported in all browsers
import store from '../store'
import {resetNthSolve} from './types';
import {url_root, configHeaders,is_prod} from './util';
import {changingUserStats, changingNutrientsAll} from './start';

const addingToken = tokenObj => ({
    type:'ADD_TOKEN',
    payload: tokenObj
})

export const updateNutrientReq = (nut_abrev, req_list)=>{
    // Send to state
    // check if cal
    return dispatch =>{
        dispatch(changingNutrientReq({
            nut_abrev:nut_abrev,
            req_list: req_list
        }))

        axios.post(
                    url_root+'/core/update_nutrient/', // address
                    {                                               // body
                        params:{
                            nut_abrev:nut_abrev,
                            req_list, req_list
                        }
                    },
                    {                                               // config
                        headers:configHeaders(is_prod),
                    }
                )
                .then(res=>{dispatch(resetNthSolve({}))})
                .catch(err=>{console.log(err)})
                .finally(res=>{})
    }
}


const changingNutrientReq = nutObj => ({
    type:'CHANGE_NUTRIENT_REQ',
    payload: nutObj
})

export const updateCalReq = (user_stats) =>{
    let weight_kg = user_stats.weight/2.204623
    if(user_stats.weight_unit==='kg'){
        weight_kg = weight_kg*2.204623
    }
    let height_cm = (parseInt(user_stats.height_feet)*12+parseInt(user_stats.height_inches))*2.54
    let sex_adj = 5
    if(user_stats.sex==='female'){
        sex_adj=-161
    }

    let age = parseInt(user_stats.age)
    let bmr = 10*weight_kg+6.25*height_cm-5*user_stats.age+sex_adj
    const activity_dict = [1.2, 1.375, 1.55,1.725,1.9]
    let tdee = activity_dict[parseInt(user_stats.activity)]*bmr
    const cal_adj_dict = [-500, -250 , 0,  250, 500]
    var daily_cal = Math.round(tdee+cal_adj_dict[parseInt(user_stats.cal_adj)])
    user_stats['daily_cal'] = daily_cal
    return dispatch =>{
    // update user info
    dispatch(changingUserStats({
        user_stats:user_stats
    }))

    let pro_tgt = (daily_cal*user_stats.pro_perc)/4
    let fat_tgt = (daily_cal*user_stats.fat_perc)/9
    let car_tgt = (daily_cal*user_stats.car_perc)/4

    let requirements_dict = {
        cal_lb:Math.round(daily_cal*0.95),
        cal_ub:Math.round(daily_cal*1.05),
        pro_lb:Math.round(pro_tgt*0.95),
        pro_ub:Math.round(pro_tgt*1.05),
        fat_lb:Math.round(fat_tgt*0.95),
        fat_ub:Math.round(fat_tgt*1.05),
        car_lb:Math.round(car_tgt*0.95), 
        car_ub:Math.round(car_tgt*1.05),
        fib_lb:Math.round(daily_cal/2000)*25, 
        fib_ub:Math.round((daily_cal/2000)*45),
        clc_lb:Math.round((daily_cal/2000)*1000),
        clc_ub:Math.round((daily_cal/2000)*2000),
        irn_lb:Math.round((daily_cal/2000)*18),
        irn_ub:Math.round((daily_cal/2000)*45),
        vta_lb:Math.round((daily_cal/2000)*5000),
        vta_ub:Math.round((daily_cal/2000)*10000),
        vtc_lb:Math.round((daily_cal/2000)*90),
        vtc_ub:Math.round((daily_cal/2000)*2000),
        sug_lb:Math.round((daily_cal/2000)*0),
        sug_ub:Math.round((daily_cal/2000)*75),
        stf_lb:Math.round((daily_cal/2000)*0),
        stf_ub:Math.round((daily_cal/2000)*20),
        sod_lb:Math.round((daily_cal/2000)*500),
        sod_ub:Math.round((daily_cal/2000)*2400),
        cho_lb:Math.round((daily_cal/2000)*0),
        cho_ub:Math.round((daily_cal/2000)*1000),
    }
    // update nutrients
    dispatch(changingNutrientsAll({
        requirements_dict:requirements_dict
    }))

    // POST to server
    axios.post(
        url_root+'/core/update_user_stats/', // address
        {params:{user_stats:user_stats}},
        {headers:configHeaders(is_prod),
        }
    )
    .then(res=>{dispatch(resetNthSolve({}))})
    .catch(err=>{console.log(err)})
    .finally(res=>{})

    axios.post(
        url_root+'/core/update_nutrients/', // address
        {params:{requirements_dict:requirements_dict}},
        {headers:configHeaders(is_prod),
        }
    )
    .then(res=>{console.log(res)})
    .catch(err=>{console.log(err)})
    .finally(res=>{})
    }
}

export const updateCalReqFromSlider = (value)=>{
    return dispatch =>{
        let curr_requirements_dict = store.getState().user.requirements_dict
        // let pro_perc =store.getState().user.user_stats.pro_perc
        // let fat_perc =store.getState().user.user_stats.fat_perc
        // let car_perc =store.getState().user.user_stats.car_perc
        let old_cal_tgt = Math.round((curr_requirements_dict.cal_lb+ curr_requirements_dict.cal_ub)/2)
        let new_cal_tgt = Math.round((value[0] +value[1])/2)
        let adj_ratio = new_cal_tgt / old_cal_tgt
        let new_requirements_dict = {
            cal_lb:value[0],
            cal_ub:value[1],
            pro_lb:Math.round(curr_requirements_dict.pro_lb*adj_ratio),
            pro_ub:Math.round(curr_requirements_dict.pro_ub*adj_ratio),
            fat_lb:Math.round(curr_requirements_dict.fat_lb*adj_ratio),
            fat_ub:Math.round(curr_requirements_dict.fat_ub*adj_ratio),
            car_lb:Math.round(curr_requirements_dict.car_lb*adj_ratio),
            car_ub:Math.round(curr_requirements_dict.car_ub*adj_ratio),
            fib_lb:Math.round(curr_requirements_dict.fib_lb*adj_ratio), 
            fib_ub:Math.round(curr_requirements_dict.fib_ub*adj_ratio), 
            clc_lb:Math.round(curr_requirements_dict.clc_lb*adj_ratio),  
            clc_ub:Math.round(curr_requirements_dict.clc_ub*adj_ratio), 
            irn_lb:Math.round(curr_requirements_dict.irn_lb*adj_ratio),  
            irn_ub:Math.round(curr_requirements_dict.irn_ub*adj_ratio),   
            vta_lb:Math.round(curr_requirements_dict.vta_lb*adj_ratio),    
            vta_ub:Math.round(curr_requirements_dict.vta_ub*adj_ratio),    
            vtc_lb:Math.round(curr_requirements_dict.vtc_lb*adj_ratio),   
            vtc_ub:Math.round(curr_requirements_dict.vtc_ub*adj_ratio),   
            sug_lb:Math.round(curr_requirements_dict.sug_lb*adj_ratio),  
            sug_ub:Math.round(curr_requirements_dict.sug_ub*adj_ratio), 
            stf_lb:Math.round(curr_requirements_dict.stf_lb*adj_ratio), 
            stf_ub:Math.round(curr_requirements_dict.stf_ub*adj_ratio), 
            sod_lb:Math.round(curr_requirements_dict.sod_lb*adj_ratio), 
            sod_ub:Math.round(curr_requirements_dict.sod_ub*adj_ratio),  
            cho_lb:Math.round(curr_requirements_dict.cho_lb*adj_ratio), 
            cho_ub:Math.round(curr_requirements_dict.cho_ub*adj_ratio),        
        }
        dispatch(changingNutrientsAll({
            requirements_dict:new_requirements_dict
        }))

        axios.post(
            url_root+'/core/update_nutrients/', // address
            {params:{requirements_dict:new_requirements_dict}},
            {headers:configHeaders(is_prod),
            }
        )
        .then(res=>{
            dispatch(resetNthSolve({}))
        })
        .catch(err=>{console.log(err)})
        .finally(res=>{})
        }
}

export const saveUserStat = statObj =>{
    return dispatch=>{
        dispatch(changingSingleUserStat(statObj))
    }
}

export const updateUserMacro = (macro_abrev, new_value) =>{
    return dispatch =>{
        dispatch(changingSingleUserStat({
            [macro_abrev+"_perc"]:new_value
        }))
    }
}

const changingSingleUserStat = userObj => ({
    type:'CHANGE_SINGLE_USER_STAT',
    payload: userObj
})

export const saveMacros = () =>{
    let cal = Math.round((store.getState().user.requirements_dict.cal_ub + store.getState().user.requirements_dict.cal_lb)/2)
    
    let pro_perc = store.getState().user.user_stats.pro_perc
    let fat_perc = store.getState().user.user_stats.fat_perc
    let car_perc = store.getState().user.user_stats.car_perc
    let pro_ub = Math.round(((cal*pro_perc)/4)*1.05)
    let pro_lb = Math.round(((cal*pro_perc)/4)*0.95)
    let fat_ub = Math.round(((cal*fat_perc)/9)*1.05)
    let fat_lb = Math.round(((cal*fat_perc)/9)*0.95)
    let car_ub = Math.round(((cal*car_perc)/4)*1.05)
    let car_lb = Math.round(((cal*car_perc)/4)*0.95)

    return dispatch =>{
        // dispatch to ui
        dispatch(changingNutrientsByName({
            pro_ub:pro_ub,
            pro_lb:pro_lb,
            fat_ub:fat_ub,
            fat_lb:fat_lb,
            car_ub:car_ub,
            car_lb:car_lb,
        }))
        dispatch(changingUserStats({
            user_stats:{
                pro_perc:pro_perc,
                fat_perc:fat_perc,
                car_perc:car_perc,
            }
        }))
        axios.post(
            url_root+'/core/update_macro_perc/', // address
            {params:{
                pro_perc:pro_perc,
                fat_perc:fat_perc,
                car_perc:car_perc,
                pro_ub:pro_ub,
                pro_lb:pro_lb,
                fat_ub:fat_ub,
                fat_lb:fat_lb,
                car_ub:car_ub,
                car_lb:car_lb,
            }},
            {headers:configHeaders(is_prod),
            }
        )
        .then(res=>{
            dispatch(resetNthSolve({}))
        })
        .catch(err=>{console.log(err)})
        .finally(res=>{})
            
        }
}

const changingNutrientsByName = userObj => ({
    type:'CHANGE_NUTRIENTS_BY_NAME',
    payload: userObj
})

export const changeDefaultReq = (nut_abrev) =>{
    let old_bool = store.getState().user.autoplan_nutrients[nut_abrev]
    let new_bool = !old_bool
    return dispatch =>{
        dispatch(changingNutrientDefaultReq({
            [nut_abrev]:new_bool
        }))
        // post to db
        axios.post(
            url_root+'/core/update_default_req/', // address
            {params:{
                nut_abrev:nut_abrev,
                new_bool:new_bool
            }},
            {headers:configHeaders(is_prod),
            }
        )
        .then(res=>{console.log(res)})
        .catch(err=>{console.log(err)})
        .finally(res=>{
            dispatch(resetNthSolve({}))
        })
    }
}

const changingNutrientDefaultReq = userObj => ({
    type:'CHANGE_NUTRIENT_DEFAULT_REQ',
    payload: userObj
})

export const updateDayNutReq =(plannum, nutrient, include_toggle)=>{

    return dispatch=>{
        dispatch(changingNutrientDayReq({
            plannum:plannum,
            nutrient:nutrient,
            incl:include_toggle
        }))
        dispatch(resetNthSolve({}))
    }
}

const changingNutrientDayReq = userObj => ({
    type:'CHANGE_NUTRIENT_DAY_REQ',
    payload: userObj
})
