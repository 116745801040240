
const initialState = {
    auth:{
        username:null,
        user_id:null,
        accessToken:null,
        email:null,
        redirect_to_auth:null,
        token_valid: null,
        isLoading:false,
        isAuthenticated:false,
    },
    // stateful_input:{
    //     // input so that only text input boxes will be re-rendered
    //     recipeSearch:null,
    //     ingredSearch:null,
    //     recipeInstructions:null,
    // },
    modal:{
        food_modal:{
            open:false,
            loading:false,
            food_key:null,
            is_recipe:false,
            food_dict:{},
            tag_list:[],
            pref_list:[],
            prob_r_dict:[],
            recipe_list:[],
            tag_search_list:[],
        },
        terms_and_conditions:{
            open:false,
        },
        meal_modal:{

        },
        add_new_modal:{

        },
        nutrients_modal:{
        },
        payment_modal:{
            open:false,
            payment_type:"ff_monthly",
        }
    },
    dialog:{
        mealname_dialog:{
            open:false,
            mealname:null,
            plannum:'day1',
            meal_type:'Breakfast',
            show_help_text:false,
            help_text:'',
        },
        autoplan_snackbar:{
            open:false,
            message:'',
        },
        alternatives_dialog:{
            alts_menu_list:[],
            alts_other_list:[],
            similar_list:[],
            open:false,
            alt_idx:0,
            plannum:"day1",
            meal_type:"Breakfast",
        },
        auth_snackbar:{
            open:false,
            message:null,
        },
        cancelation_dialog:{
            open:false,
            message:"We're gonna miss ya. Are you sure?" 
        },
        subscription_snackbar:{
            open:false,
            message:null,
        },
        password_dialog:{
            open:false,
        },
        generic_snackbar:{
            open:false,
            message:null,
        },
        onboarding_poppers:{
            open:false,
        },
    },
    user:{
        account:{
            status:"trial",
            email:null,
            free_trial_days:7,
            membership_message:"7 Days Left In Your Free Trial",
        },
        requirements_dict:{
            cal_ub:2100,
            cal_lb:1900,
            pro_ub:131,
            pro_lb:119,
            car_ub:210,
            car_lb:190,
            fat_ub:82,
            fat_lb:74,
            stf_ub:20,
            stf_lb:0,
            fib_ub:45,
            fib_lb:25,
            sug_ub:50,
            sug_lb:0,
            sod_ub:2400,
            sod_lb:500,
            cho_ub:1000,
            cho_lb:0,
            clc_ub:2000,
            clc_lb:1000,
            irn_ub:45,
            irn_lb:18,
            vta_ub:10000,
            vta_lb:5000,
            vtc_ub:2000,
            vtc_lb:90
        },
        requirements_order:[
            'cal',
            'pro',
            'fat',
            'car',
            'fib',
            'clc',
            'irn',
            'vta',
            'vtc',
            'sug',
            'stf',
            'sod'
        ],
        search:false,
        autoplan:false,
        nth_solve:0,
        solve_in_progress:false,
        autoplan_nutrients:{
            'cal':true,
            'pro':true,
            'fat':true,
            'car':true,
            'fib':false,
            'clc':false,
            'irn':false,
            'vta':false,
            'vtc':false,
            'sug':false,
            'stf':false,
            'sod':false,
            'cho':false
        },
        default_menu_dict:{
            Breakfast:{id:1},
            Lunch:{id:2},
            Dinner:{id:3},
            Snack:{id:4}
        },
        
            day1:{
                autoplan:true,
                autoplan_nutrients:{
                    'cal':true,
                    'pro':true,
                    'fat':true,
                    'car':true,
                    'fib':false,
                    'clc':false,
                    'irn':false,
                    'vta':false,
                    'vtc':false,
                    'sug':false,
                    'stf':false,
                    'sod':false,
                    'cho':false
                },
                'nutrient_totals':{
                    calories:0,
                    protein_g:0,
                    fat_g:0,
                    carb_g:0,
                    fiber_g:0,
                    calcium_mg:0,
                    iron_mg:0,
                    vit_a_mcg:0,
                    vit_c_mg:0,
                    sugar_g:0,
                    saturated_fat_g:0,
                    sodium_mg:0,
                    cholesterol_mg:0
                },
                Breakfast:{
                // List of dictionary objects with two keys, "type" ("menu","tag","restaurant" ) and "id"
                    autoplan_include:true,
                    restaurants:[],
                    menus:[],
                    tags:[]
                },
                Lunch:{
                    autoplan_include:true,
                    restaurants:[],
                    menus:[],
                    tags:[]
                },
                Dinner:{
                    autoplan_include:true,
                    restaurants:[],
                    menus:[],
                    tags:[]
                },
                Snack:{
                    autoplan_include:true,
                    restaurants:[],
                    menus:[],
                    tags:[]
                },

            },         
            day2:{
                autoplan:true,
                autoplan_nutrients:{
                    'cal':true,
                    'pro':true,
                    'fat':true,
                    'car':true,
                    'fib':false,
                    'clc':false,
                    'irn':false,
                    'vta':false,
                    'vtc':false,
                    'sug':false,
                    'stf':false,
                    'sod':false,
                    'cho':false
                },
                'nutrient_totals':{
                    calories:0,
                    protein_g:0,
                    fat_g:0,
                    carb_g:0,
                    fiber_g:0,
                    calcium_mg:0,
                    iron_mg:0,
                    vit_a_mcg:0,
                    vit_c_mg:0,
                    sugar_g:0,
                    saturated_fat_g:0,
                    sodium_mg:0,
                    cholesterol_mg:0
                },
                Breakfast:{
                    // List of dictionary objects with two keys, "type" ("menu","tag","restaurant" ) and "id"
                        autoplan_include:true,
                        restaurants:[],
                        menus:[],
                        tags:[]
                },
                Lunch:{
                    autoplan_include:true,
                    restaurants:[],
                    menus:[],
                    tags:[]
                },
                Dinner:{
                    autoplan_include:true,
                    restaurants:[],
                    menus:[],
                    tags:[]
                },                
                Snack:{
                    autoplan_include:true,
                    restaurants:[],
                    menus:[],
                    tags:[]
                },   
            }, 
            day3:{
                autoplan:true,
                autoplan_nutrients:{
                    'cal':true,
                    'pro':true,
                    'fat':true,
                    'car':true,
                    'fib':false,
                    'clc':false,
                    'irn':false,
                    'vta':false,
                    'vtc':false,
                    'sug':false,
                    'stf':false,
                    'sod':false,
                    'cho':false
                },
                'nutrient_totals':{
                    calories:0,
                    protein_g:0,
                    fat_g:0,
                    carb_g:0,
                    fiber_g:0,
                    calcium_mg:0,
                    iron_mg:0,
                    vit_a_mcg:0,
                    vit_c_mg:0,
                    sugar_g:0,
                    saturated_fat_g:0,
                    sodium_mg:0,
                    cholesterol_mg:0
                },
                Breakfast:{
                    // List of dictionary objects with two keys, "type" ("menu","tag","restaurant" ) and "id"
                    autoplan_include:true,
                    restaurants:[],
                    menus:[],
                    tags:[]
                },
                Lunch:{
                    autoplan_include:true,
                    restaurants:[],
                    menus:[],
                    tags:[]
                },
                Dinner:{
                    autoplan_include:true,
                    restaurants:[],
                    menus:[],
                    tags:[]
                },
                Snack:{
                    autoplan_include:true,
                    restaurants:[],
                    menus:[],
                    tags:[]
                }
            },
            day4:{
                autoplan:true,
                autoplan_nutrients:{
                    'cal':true,
                    'pro':true,
                    'fat':true,
                    'car':true,
                    'fib':false,
                    'clc':false,
                    'irn':false,
                    'vta':false,
                    'vtc':false,
                    'sug':false,
                    'stf':false,
                    'sod':false,
                    'cho':false
                },
                'nutrient_totals':{
                    calories:0,
                    protein_g:0,
                    fat_g:0,
                    carb_g:0,
                    fiber_g:0,
                    calcium_mg:0,
                    iron_mg:0,
                    vit_a_mcg:0,
                    vit_c_mg:0,
                    sugar_g:0,
                    saturated_fat_g:0,
                    sodium_mg:0,
                    cholesterol_mg:0
                },
                Breakfast:{
                    // List of dictionary objects with two keys, "type" ("menu","tag","restaurant" ) and "id"
                    autoplan_include:true,
                    restaurants:[],
                    menus:[],
                    tags:[]
                },
                Lunch:{
                    autoplan_include:true,
                    restaurants:[],
                    menus:[],
                    tags:[]
                },
                Dinner:{
                    autoplan_include:true,
                    restaurants:[],
                    menus:[],
                    tags:[]
                },
                Snack:{
                    autoplan_include:true,
                    restaurants:[],
                    menus:[],
                    tags:[]
                }
            }, 
            day5:{
                autoplan:true,
                autoplan_nutrients:{
                    'cal':true,
                    'pro':true,
                    'fat':true,
                    'car':true,
                    'fib':false,
                    'clc':false,
                    'irn':false,
                    'vta':false,
                    'vtc':false,
                    'sug':false,
                    'stf':false,
                    'sod':false,
                    'cho':false
                },
                'nutrient_totals':{
                    calories:0,
                    protein_g:0,
                    fat_g:0,
                    carb_g:0,
                    fiber_g:0,
                    calcium_mg:0,
                    iron_mg:0,
                    vit_a_mcg:0,
                    vit_c_mg:0,
                    sugar_g:0,
                    saturated_fat_g:0,
                    sodium_mg:0,
                    cholesterol_mg:0
                },
                Breakfast:{
                    // List of dictionary objects with two keys, "type" ("menu","tag","restaurant" ) and "id"
                    autoplan_include:true,
                    restaurants:[],
                    menus:[],
                    tags:[]
                },
                Lunch:{
                    autoplan_include:true,
                    restaurants:[],
                    menus:[],
                    tags:[]
                },
                Dinner:{
                    autoplan_include:true,
                    restaurants:[],
                    menus:[],
                    tags:[]
                },
                Snack:{
                    autoplan_include:true,
                    restaurants:[],
                    menus:[],
                    tags:[]
                }
            }, 
            day6:{
                autoplan:true,
                autoplan_nutrients:{
                    'cal':true,
                    'pro':true,
                    'fat':true,
                    'car':true,
                    'fib':false,
                    'clc':false,
                    'irn':false,
                    'vta':false,
                    'vtc':false,
                    'sug':false,
                    'stf':false,
                    'sod':false,
                    'cho':false
                },
                'nutrient_totals':{
                    calories:0,
                    protein_g:0,
                    fat_g:0,
                    carb_g:0,
                    fiber_g:0,
                    calcium_mg:0,
                    iron_mg:0,
                    vit_a_mcg:0,
                    vit_c_mg:0,
                    sugar_g:0,
                    saturated_fat_g:0,
                    sodium_mg:0,
                    cholesterol_mg:0
                },
                Breakfast:{
                    // should include list of meal-level nutrient levels here
                    
                    // List of dictionary objects with two keys, "type" ("menu","tag","restaurant" ) and "id"
                    autoplan_include:true,
                    restaurants:[],
                    menus:[],
                    tags:[]
                },
                Lunch:{
                    autoplan_include:true,
                    restaurants:[],
                    menus:[],
                    tags:[]
                },
                Dinner:{
                    autoplan_include:true,
                    restaurants:[],
                    menus:[],
                    tags:[]
                },
                Snack:{
                    autoplan_include:true,
                    restaurants:[],
                    menus:[],
                    tags:[]
                }
            }, 
            day7:{
                autoplan:true,
                autoplan_nutrients:{
                    'cal':true,
                    'pro':true,
                    'fat':true,
                    'car':true,
                    'fib':false,
                    'clc':false,
                    'irn':false,
                    'vta':false,
                    'vtc':false,
                    'sug':false,
                    'stf':false,
                    'sod':false,
                    'cho':false
                },
                'nutrient_totals':{
                    calories:0,
                    protein_g:0,
                    fat_g:0,
                    carb_g:0,
                    fiber_g:0,
                    calcium_mg:0,
                    iron_mg:0,
                    vit_a_mcg:0,
                    vit_c_mg:0,
                    sugar_g:0,
                    saturated_fat_g:0,
                    sodium_mg:0,
                    cholesterol_mg:0
                },
                Breakfast:{
                    // List of dictionary objects with two keys, "type" ("menu","tag","restaurant" ) and "id"
                    autoplan_include:true,
                    restaurants:[],
                    menus:[],
                    tags:[]
                },
                Lunch:{
                    autoplan_include:true,
                    restaurants:[],
                    menus:[],
                    tags:[]
                },
                Dinner:{
                    autoplan_include:true,
                    restaurants:[],
                    menus:[],
                    tags:[]
                },
                Snack:{
                    autoplan_include:true,
                    restaurants:[],
                    menus:[],
                    tags:[]
                }
            }, 
            days_list:[],
            user_stats:{
                first_session:false,
                weight:null,
                weight_unit:"lb",
                height_feet: null,
                height_inches:null,
                age:null,
                sex:"male",
                activity:1,
                cal_adj:1,
                fat_perc:null,
                car_perc:null,
                pro_perc:null,
                daily_cal:null,
            }
        },
        util:{
            all_nutrients_list:[
                'cal',
                'pro',
                'fat',
                'car',
                'fib',
                'clc',
                'irn',
                'vta',
                'vtc',
                'sug',
                'stf',
                'sod',
                'cho'
            ],
            abrev_to_nut_dict:{
                'cal':'calories',
                'pro':'protein_g',
                'fat':'fat_g',
                'car':'carb_g',
                'fib':'fiber_g',
                'clc':'calcium_mg',
                'irn':'iron_mg',
                'vta':'vit_a_mcg',
                'vtc':'vit_c_mg',
                'sug':'sugar_g',
                'stf':'saturated_fat_g',
                'sod':'sodium_mg'
            },
            abrev_to_display_dict:{
                'cal':'Calories',
                'pro':'Protein',
                'fat':'Fat',
                'car':'Carbs',
                'fib':'Fiber',
                'clc':'Calcium',
                'irn':'Iron',
                'vta':'Vitamin A',
                'vtc':'Vitamin C',
                'sug':'Sugar',
                'stf':'Sat. Fat',
                'sod':'Sodium'  
            },
            abrev_to_units_dict:{
                'cal':'',
                'pro':'grams',
                'fat':'grams',
                'car':'grams',
                'fib':'grams',
                'clc':'milligrams',
                'irn':'milligrams',
                'vta':'micrograms',
                'vtc':'milligrams',
                'sug':'grams',
                'stf':'grams',
                'sod':'milligrams'  
            },
            nutrient_ranges:{
                cal:[0,4000],
                pro:[0,350],
                fat:[0,200],
                car:[0,400],
                fib:[0,60],
                clc:[0,2500],
                irn:[0,60],
                vta:[0,11000],
                vtc:[0,2500],
                sug:[0,300],
                stf:[0,100],
                sod:[0,3000],
                cho:[0,3000]
            },
            nutrient_pallette:{
                cal:""
            },
            sample_autocomplete:[
                {id:1, name:'fruit', type:'tag'},
                {id:2, name:'fruit juice', type:'tag'},
                {id:3, name:'fruit salad', type:'tag'},
                {id:1, name:'fruit bros', type:'restaurant'},
                {id:1, name:"fruit man's menu", type:'menu'}

            ],
            days_dict:{

            }
        },
        searchWidgets:{
            ingredientSearch:{
                hasSearchResults:false,
                isLoading:false,
                results_list:[],
                errorMessage:null,
                returnedEmpty:false,
            },
            recipeSearch:{
                hasSearchResults:false,
                isLoading:false,
                results_list:[],
                errorMessage:null,
                returnedEmpty:false,
            },
            singleRecipeSearch:{
                food_key:null,
                recipe_name:null,
                recipe_description:null,
                yield:null,
                serving_description:null,
                cook_time_hours:null,
                cook_time_minutes:null,
                ingredients_list:[],
                instructions_list:[],
                recipe_image:null,
                recipe_image_url:null,
                max_servings:null,
                default_dish_num:"Whole Meals",
            },
            mealSearch:{
                hasSearchResults:false,
                isLoading:false,
                results_list:[],
                errorMessage:null,
                returnedEmpty:false,
            },
            singleMealSearch:{
                meal_id:null,
                mealname:null,
                foods_list:[],
                default_meal:"Dinner",
            },
            menuSearch:{
                isLoading:false,
                menus_list:[],
            },
            buildMenuSearch:{
                isLoading:false,
                results_list:[],
            }
        },

        columns:{
            searchResults:{
                // TODO - follow logic of splitting strings by making seperate food and meal search results
                nth_search:0,
                id:"search_results",
                isLoading:false,
                fetched_search_results:false,
                results_type:'food', //food / meals / restaurants / tags
                foods_list:[], // list of food_keys
                meals_list:[]
            },
            menuSearchResults:{
                isLoading:false,
                results_type:'food',
                results_list:[]
            },
            menus:{
                Breakfast:{
                    whole_meals:{items_list:[]},
                    main_courses:{items_list:[]},
                    sides:{items_list:[]}
                },
                Lunch:{
                    whole_meals:{items_list:[]},
                    main_courses:{items_list:[]},
                    sides:{items_list:[]}
                },
                Dinner:{
                    whole_meals:{items_list:[]},
                    main_courses:{items_list:[]},
                    sides:{items_list:[]}
                },
                Snack:{
                    snacks:{items_list:[]}
                },
            },
            day1:{
                date:null,
                Breakfast:{
                    meal_drag_key:'0_day1_Breakfast',
                    mealname: "Unnamed",
                    isLoading:false,
                    foods_list:[],
                    meal_alts:[]
                },
                Lunch:{
                    meal_drag_key:'0_day1_Lunch',
                    mealname: "Unnamed",
                    isLoading:false,
                    foods_list:[],
                    meal_alts:[]
                },
                Dinner:{
                    meal_drag_key:'0_day1_Dinner',
                    mealname: "Unnamed",
                    isLoading:false,
                    foods_list:[],
                    meal_alts:[]
                },
                Snack:{
                    meal_drag_key:'0_day1_Snack',
                    mealname: "Unnamed",
                    isLoading:false,
                    foods_list:[],
                    meal_alts:[]
                },
            },
        day2:{
                date:null,
                Breakfast:{
                    meal_drag_key:'0_day2_Breakfast',
                    mealname: "Unnamed",
                    isLoading:false,
                    foods_list:[],
                    meal_alts:[]
                },
                Lunch:{
                    meal_drag_key:'0_day2_Lunch',
                    mealname: "Unnamed",
                    isLoading:false,
                    'foods_list':[],
                    meal_alts:[]
                },
                Dinner:{
                    meal_drag_key:'0_day2_Dinner',
                    mealname: "Unnamed",
                    isLoading:false,
                    'foods_list':[],
                    meal_alts:[]
                },
                Snack:{
                    meal_drag_key:'0_day2_Snack',
                    mealname: "Unnamed",
                    isLoading:false,
                    'foods_list':[],
                    meal_alts:[]
                },
            },        
            'day3':{
                date:null,
                Breakfast:{
                    meal_drag_key:'0_day3_Breakfast',
                    mealname: "Unnamed",
                    isLoading:false,
                    'foods_list':[],
                    meal_alts:[]
                },
                Lunch:{
                    meal_drag_key:'0_day3_Lunch',
                    mealname: "Unnamed",
                    isLoading:false,
                    'foods_list':[],
                    meal_alts:[]
                },
                Dinner:{
                    meal_drag_key:'0_day3_Dinner',
                    mealname: "Unnamed",
                    isLoading:false,
                    'foods_list':[],
                    meal_alts:[]
                },
                Snack:{
                    meal_drag_key:'0_day3_Snack',
                    mealname: "Unnamed",
                    isLoading:false,
                    'foods_list':[],
                    meal_alts:[]
                },
            },    
            'day4':{
                date:null,
                Breakfast:{
                    meal_drag_key:'0_day4_Breakfast',
                    mealname: "Unnamed",
                    isLoading:false,
                    'foods_list':[],
                    meal_alts:[]
                },
                Lunch:{
                    meal_drag_key:'0_day4_Lunch',
                    mealname: "Unnamed",
                    isLoading:false,
                    'foods_list':[],
                    meal_alts:[]
                },
                Dinner:{
                    meal_drag_key:'0_day4_Dinner',
                    mealname: "Unnamed",
                    isLoading:false,
                    'foods_list':[],
                    meal_alts:[]
                },
                Snack:{
                    meal_drag_key:'0_day4_Snack',
                    mealname: "Unnamed",
                    isLoading:false,
                    'foods_list':[],
                    meal_alts:[]
                },

            },            
            'day5':{
                date:null,
                Breakfast:{
                    meal_drag_key:'0_day5_Breakfast',
                    mealname: "Unnamed",
                    isLoading:false,
                    'foods_list':[],
                    meal_alts:[]
                },
                Lunch:{
                    meal_drag_key:'0_day5_Lunch',
                    mealname: "Unnamed",
                    isLoading:false,
                    'foods_list':[],
                    meal_alts:[]
                },
                Dinner:{
                    meal_drag_key:'0_day5_Dinner',
                    mealname: "Unnamed",
                    isLoading:false,
                    'foods_list':[],
                    meal_alts:[]
                },
                Snack:{
                    meal_drag_key:'0_day5_Snack',
                    mealname: "Unnamed",
                    isLoading:false,
                    'foods_list':[],
                    meal_alts:[]
                },
            },    
            'day6':{
                date:null,
                Breakfast:{
                    meal_drag_key:'0_day6_Breakfast',
                    mealname: "Unnamed",
                    isLoading:false,
                    'foods_list':[],
                    meal_alts:[]
                },
                Lunch:{
                    meal_drag_key:'0_day6_Lunch',
                    mealname: "Unnamed",
                    isLoading:false,
                    'foods_list':[],
                    meal_alts:[]
                },
                Dinner:{
                    meal_drag_key:'0_day6_Dinner',
                    mealname: "Unnamed",
                    isLoading:false,
                    'foods_list':[],
                    meal_alts:[]
                },
                Snack:{
                    meal_drag_key:'0_day6_Snack',
                    mealname: "Unnamed",
                    isLoading:false,
                    'foods_list':[],
                    meal_alts:[]
                },
            },       
            'day7':{
                date:null,
                Breakfast:{
                    meal_drag_key:'0_day7_Breakfast',
                    mealname: "Unnamed",
                    isLoading:false,
                    'foods_list':[],
                    meal_alts:[]
                },
                Lunch:{
                    meal_drag_key:'0_day7_Lunch',
                    mealname: "Unnamed",
                    isLoading:false,
                    'foods_list':[],
                    meal_alts:[]
                },
                Dinner:{
                    meal_drag_key:'0_day7_Dinner',
                    mealname: "Unnamed",
                    isLoading:false,
                    'foods_list':[],
                    meal_alts:[]
                },
                Snack:{
                    meal_drag_key:'0_day7_Snack',
                    mealname: "Unnamed",
                    isLoading:false,
                    'foods_list':[],
                    meal_alts:[]
                },
            },     
        },
        shopping_list:{
            list:[],
        },
        menu_page:{
            snackbar_open:false,
            menu_search:[], 
            display_idx:0,
            build_menu:{
                menu_id:0,
                pref_menu_dict:{
                    Breakfast:{id:0},
                    Lunch:{id:0},
                    Dinner:{id:0},
                    Snack:{id:0}
                },
                Breakfast:{
                    whole_meals:{items_list:[]},
                    main_courses:{items_list:[]},
                    sides:{items_list:[]}
                },
                Lunch:{
                    whole_meals:{items_list:[]},
                    main_courses:{items_list:[]},
                    sides:{items_list:[]}
                },
                Dinner:{
                    whole_meals:{items_list:[]},
                    main_courses:{items_list:[]},
                    sides:{items_list:[]}
                },
                Snack:{
                    snacks:{items_list:[]}
                },
            }
        },
        personal_profile:{
            first_name:null,
            last_name:null,
            preferred_name:null,
            website:null,
            avatar:null,
            bio:null,
            metadata:{
                recipes_added:null,
                public_menus_added:null,
                planmaker_streak:null,
                calorie_goals_streak:null,
                macro_goals_streak:null,
            }
        },
};

export default initialState