import React, {PureComponent} from 'react';
import {withStyles, makeStyles} from '@material-ui/core/styles';
// import withWidth from '@material-ui/core/withWidth'
import {connect} from 'react-redux';
import {
    Checkbox,
    FormControlLabel,
    Paper,
    IconButton,
    Menu,
    MenuItem,
    Typography,
} from '@material-ui/core/';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import MoreVert from '@material-ui/icons/MoreVert';
import ExtensionIcon from '@material-ui/icons/Extension';
import MealPlan from './mealplan';
import Nutrient from './nutrient';
import {mountDayPlan} from '../../actions/start';
import {toggleIncludeDay,toggleSingleDaySolve} from '../../actions/autoplan';
import {clearMeals} from '../../actions/plan';

const useStyles = theme=>({
    '@global': {
        '*': {
            'scrollbar-width': 'thin',
          },
        '*::-webkit-scrollbar': {
          width: '0.4em'
        },
        '*::-webkit-scrollbar-track': {
          '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '*::-webkit-scrollbar-thumb': {
          backgroundColor: 'rgba(0,0,0,.1)',
          outline: '1px solid slategrey'
        }
      },
    dayplan_class:{
        // color:"var(--main_dark)",
        color:"black",
        width:320,
        marginLeft:5,
        marginRight:5,
        margin:10,
        [theme.breakpoints.down('md')]:{
            // backgroundColor:"blue",
            width:"100%",
            borderRadius:0,
            // marginLeft:0,
            // marginRight:0,
        }
    },
}
)

class DayPlan extends PureComponent{
    /*
    Takes in props:
        plan_date: date object

    */
    constructor(props){
        super(props)
        let this_day = new Date(+this.props.today+this.props.daynum*86400000)
        let weekdays = ['Sunday', 'Monday','Tuesday','Wednesday','Thursday','Friday','Saturday']
        this.state = {
            plannum: this.props.plannum,
            daynum:this.props.daynum,
            date: String(this_day.getMonth()+1)+"-"+String(this_day.getDate())+"-"+String(this_day.getFullYear()),// Full date for sending to server
            weekday:weekdays[this_day.getDay()],
            menu_anchor_el:null,
            isSideMenuOpen:false,
          }
    }
    //TODO - add shouldComponentUpdate 

    componentDidMount(){
        //TODO - move this to plan.js's componentDidMount
        let this_day = new Date(+this.props.today+this.props.daynum*86400000)
        let weekdays = ['Sunday', 'Monday','Tuesday','Wednesday','Thursday','Friday','Saturday']
        let display_date = String(this_day.getMonth()+1).padStart(2,'0')+"-"+String(this_day.getDate()).padStart(2,'0') +"-"+String(this_day.getFullYear())
        let db_date = String(this_day.getFullYear()) +"-"+ String(this_day.getMonth()+1).padStart(2,'0') +"-"+ String(this_day.getDate()).padStart(2,'0')
        this.setState({
            date:display_date, // Full date for sending to server
            weekday:weekdays[this_day.getDay()]
        })
        //this.props.mountDayPlan(this.props.plannum, db_date)
    }
    
    onToggleInclude = value =>{
        let curr_incl = this.props.autoplan_day
        return this.props.toggleIncludeDay({
                    autoplan:!curr_incl,
                    plannum:this.props.plannum
        })
    }

    onClickDelete = () =>{
        this.props.clearMeals( this.props.plannum,['Breakfast','Lunch','Dinner','Snack'])
        this.setState({
            menu_anchor_el:null,
            isSideMenuOpen:false
        })
    }

    openSideMenu = (event) =>{
        this.setState({
            menu_anchor_el:event.target,
            isSideMenuOpen:true
        })
    }

    handleSideMenuClose =() =>{
        this.setState({
            menu_anchor_el:null,
            isSideMenuOpen:false
        })
    }
    // Function to convert plan date to weekday, also "today" or "tomorrow
    render(){
        const {classes} = this.props
        return (
            <Paper
            className={classes.dayplan_class}
            style={{
                // width:"320px",
                // width:"100%",
                // maxHeight:"600px",
                // color:"var(--main_dark)",
                margin:10,
                backgroundColor:"var(--sec_white)"}}
            >
                <section
                    style={{
                        display:"flex",
                        flexDirection:"row",
                        alignContent:"space-between",
                        justifyContent:"space-between",
                        verticalAlign:"bottom",
                        height:"2em",
                        backgroundColor:"var(--sec_white)"
                }}
                >   
                    <section
                        style={{display:"flex", "paddingLeft":"5px"}}
                    >
                    <FormControlLabel
                    style={{padding:0, 
                            paddingRight:10,
                            margin:0,
                            display:this.props.autoplan ? "": "none",
                            color:"var(--main_contrast)",
                            fontSize:8
                        }}
                    control={
                    <Checkbox
                        style={{padding:0,margin:0}}
                        icon={<CheckBoxOutlineBlankIcon style={{color:"var(--main_contrast)"}} />}
                        checkedIcon={<CheckBoxIcon style={{color:"var(--main_contrast)"}} />}
                        value={this.props.plannum}
                        checked={this.props.autoplan_day}
                        onChange={this.onToggleInclude}
                    />
                    }
                    //label="Include"
                    />
                        <Typography variant="h6">{this.state.weekday} {this.state.date.substr(0,5)}</Typography>
                    </section>
                    <section
                        style={{"display":"flex", 
                        "flexDirection":"row",
                        "alignContent":"flex-end"}}
                    >
                        <IconButton 
                            style={{
                                display:this.props.autoplan? "none":"",
                            }}
                            size="small"
                            edge="end"
                            >
                            <ExtensionIcon 
                                style={{color:"var(--main_contrast)", 
                                marginRight:7}}
                                onClick={(event)=>{this.props.toggleSingleDaySolve(this.props.plannum)}}
                            />
                            {/* <Typography style={{color:"var(--main_contrast)"}} variant="button">  AUTOPLAN DAY</Typography> */}
                        </IconButton>

                        {/* <IconButton size="small" edge="end"
                            onClick={this.onClickDelete}
                        >
                            <DeleteIcon/>
                        </IconButton> */}
                        <IconButton
                            size="small" 
                            edge="end"
                            onClick={this.openSideMenu}
                        >
                            <MoreVert
                            />
                        </IconButton> 
                        <Menu
                            open={this.state.isSideMenuOpen}
                            onClose={this.handleSideMenuClose}
                            anchorEl={this.state.menu_anchor_el}
                        >
                            {/* <MenuItem
                                onClick={this.onClickAlternatives}
                            >See Alternatives</MenuItem> */}
                            <MenuItem
                                onClick={this.onClickDelete}
                            >
                                Clear
                            </MenuItem>
                        </Menu>
                    </section>
                </section>
                <div
                    style={{
                        overflowX:"scroll",
                        display:"flex",
                    }}
                >
                {this.props.requirements_list.map( (req, idx) => {
                    return(
                        <Nutrient 
                            nut_abrev = {req}
                            plannum = {this.props.plannum}
                            nut_name = {this.props.abrev_to_display_dict[req]}
                            nut_db_name = {this.props.abrev_to_nut_dict[req]}
                            autoplan = {this.props.autoplan}
                            key={req}
                        />
                    )
                })}
                </div>
                <MealPlan
                    plannum={this.props.plannum} 
                    meal_type={"Breakfast"}
                    weekday ={this.state.weekday}
                    width_prop ={''}
                />
                <MealPlan
                    plannum={this.props.plannum} 
                    meal_type={"Lunch"}
                    weekday ={this.state.weekday}
                />
                <MealPlan
                    plannum={this.props.plannum} 
                    meal_type={"Dinner"}
                    weekday ={this.state.weekday}
                />
                <MealPlan
                    plannum={this.props.plannum} 
                    meal_type={"Snack"}
                    weekday ={this.state.weekday}
                />
            </Paper>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return{
    requirements_list: state.user.requirements_order,
    requirements_dict: state.user.requirements_dict,
    abrev_to_nut_dict: state.util.abrev_to_nut_dict,
    abrev_to_display_dict: state.util.abrev_to_display_dict,
    autoplan: state.user.autoplan,
    autoplan_day:state.user[ownProps.plannum].autoplan,
    autoplan_nutrients:state.user[ownProps.plannum].autoplan_nutrients,
  }}
export default connect(mapStateToProps,{mountDayPlan,
                                toggleIncludeDay,
                                clearMeals,
                                toggleSingleDaySolve,})(withStyles(useStyles)(DayPlan));