import axios from 'axios'// since fetch api not supported in all browsers
import store from '../store'
import {url_root, configHeaders,is_prod} from './util';

export const getProfile=()=>{
    return dispatch =>{
        axios.post(
            url_root+'/core/get_personal_profile/', // address
            {                                               // body
                params:{
                }
            },
            {                                               // config
                headers:configHeaders(is_prod),
            }
        )
        .then(res=>{
            let data = res['data']
            dispatch(updating_personal_profile(data['prof_dict']))
        })
        .catch(err=>{})
    }
}

const updating_personal_profile = addObj => ({
    type:'UPDATE_PERSONAL_PROFILE',
    payload: addObj
})

export const updateProfileItem = (item_key, item_value)=>{
    let update_obj = {}
    update_obj[item_key] = item_value
    return dispatch=>{
        dispatch(updating_personal_profile(update_obj))
        axios.post(
            url_root+'/core/update_personal_profile/', // address
            {                                               // body
                params:{
                    item_key:item_key,
                    item_value:item_value
                }
            },
            {                                               // config
                headers:configHeaders(is_prod),
            }
        )
        .then(res=>{
            let data = res['data']
        })
        .catch(err=>{})
    }
}

export const updateProfilePhoto =(profile_image)=>{
    let body_form_data = new FormData()
    body_form_data.append('profile_image', profile_image)
    return dispatch=>{
        // dispatch(updating_personal_profile(update_obj))
        axios(
            {method:"post",
             url:url_root+'/core/update_profile_photo/', 
             data:body_form_data,
             headers:{
                ...configHeaders(is_prod),
                ...{'Content-Type': 'multipart/form-data' }
               }
            }
        )
        .then(res=>{
            let data = res['data']
            dispatch(updating_personal_profile(data))
        })
        .catch(err=>{})
    }
} 