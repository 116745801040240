import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {connect} from 'react-redux';
import {CircularProgress} from '@material-ui/core/';
import { Droppable, Draggable} from 'react-beautiful-dnd';
import FoodObj from '../plan/food';
import Meal from '../plan/meal';
import MealClone from '../plan/meal_drag_clone.js';


const useStyles = theme =>({
    //logic about mobile menu vs reg menu goes here
    
})

class FoodSearchResults extends Component {
    render() {
        if (this.props.isLoading){
            return (<div style={{"width":"100%","marginTop":"15px","textAlign":"center"}}><CircularProgress style={{color:"var(--main_bright)", margin:"auto"}}/></div>)
        }
        else if(!(this.props.fetched_search_results)){
            return null
        }
        else if((this.props.search_returned_empty)&(this.props.results_type=='food')){
            return (<div><div className={"search_results_div"}>Sorry, no foods matched your search</div></div>)
        }
        else if((this.props.search_returned_empty)&(this.props.results_type=='meal')){
            return (<div><div className={"search_results_div"}>Sorry, no meals matched your search</div></div>)
        }
        else if((!(this.props.search_returned_empty))&(this.props.results_type=='meal')){
            return (
                <div>
                    <Droppable
                        droppableId={"mealSearchResults"}
                        isDropDisabled={true}
                        direction="vertical"
                        type="MEAL"
                        style={{
                            maxWidth:460,
                        }}
                    >
                        {(provided, snapshot)=>(
                            <div
                                style={{
                                    maxWidth:460,
                                    maxHeight:200,
                                    overflowY:"auto", 
                                    scrollbarWidth:"thin",
                                    backgroundColor:"var(--seclight)",
                                }}
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                            >
                                {/* pulling meal results out of meal_list*/}
                                {this.props.meals_list.map( (result, idx)=>{
                                    return (
                                    <Draggable
                                        draggableId={result['meal_drag_key']}
                                        index={idx}
                                        key={result['meal_drag_key']}
                                    >
                                        {(provided, snapshot)=>(
                                            <React.Fragment>
                                                <div
                                                    style={{
                                                        transform:"none!important"
                                                    }}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    ref={provided.innerRef}
                                                >
                                                    <Meal
                                                        meal_drag_key = {result['meal_drag_key']}
                                                        plannum={"searchResults"} 
                                                        meal_type={result['mealname']}
                                                        foods_list={result['foods']}
                                                        isLoading={false}  
                                                        foodChangeDisabled={true}                          
                                                    />
                                                </div>
                                            
                                            {snapshot.isDragging && (    
                                                <div
                                                    //style={{display:"none!important"}}
                                                >
                                                    <MealClone
                                                        plannum={"searchResults"} 
                                                        meal_type={result['mealname']}
                                                        foods_list={result['foods']}
                                                        isLoading={false}  
                                                        foodChangeDisabled={true} 
                                                    />
                                                </div>
                                            )}
                                            </React.Fragment>
                                        )}
                                    </Draggable>
                                )})}

                            </div>
                        )}

                    </Droppable>
                </div>
            )
        }
        else {
        return (
            <div>
            <Droppable
                droppableId= {"searchResults"}
                isDropDisabled={true}
                direction="horizontal"
                type="FOOD"
                >
                {(provided, snapshot)=>(
                <div 
                    style={{
                        maxWidth:460,
                        maxHeight:200,
                        overflowY:"auto", 
                        scrollbarWidth:"thin",
                        backgroundColor:"var(--sec_mid)",
                        display:"flex",
                        flexWrap:"wrap"
                    }}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                >
                    {this.props.search_results_by_id.map( (result, idx) => {
                        
                            return (
                                <Draggable
                                    draggableId = {result['drag_id']}
                                    index={idx}
                                    key={result['drag_id']}
                                >
                                    {(provided, snapshot)=>(
                                        <React.Fragment
                                        >
                                        <div
                                            style={{
                                                backgroundColor:"white",
                                                transform:"none!important"
                                            }}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            ref={provided.innerRef}
                                        >
                                            <FoodObj
                                                food_dict = {result}
                                                amt = {result['amt']}
                                                serving_size_idx = {result['serving_size_idx']}
                                                component_num ={0}
                                                col={"searchResults"}
                                                drag_idx = {idx}
                                                isChangeDisabled={false}
                                            />
                                        </div>
                                        {snapshot.isDragging && (
                                            <div
                                                //style={{display:"none!important"}}
                                            >
                                                <FoodObj 
                                                    food_dict = {result}
                                                    amt = {result['amt']}
                                                    serving_size_idx = {result['serving_size_idx']}
                                                    component_num ={0}
                                                    col={this.props.plannum}
                                                    subcol={this.props.meal_type}
                                                    drag_idx = {idx}
                                                />
                                            </div>
                                        )}
                                        </React.Fragment>
                                    )}
                                </Draggable>
                            )
                    })}
                    {provided.placeholder}
                </div>
                )}
                </Droppable>
            </div>
            )
        }
    }
}

const mapStateToProps = state => ({
    search_results_by_id: state.columns.present.searchResults.foods_list,
    search_results_dict: state.columns.present.searchResults.foods_dict,
    fetched_search_results:state.columns.present.searchResults.fetched_search_results,
    search_returned_empty:state.columns.present.searchResults.search_returned_empty,
    isLoading:state.columns.present.searchResults.isLoading,
    results_type:state.columns.present.searchResults.results_type,
    nth_search: state.columns.present.searchResults.nth_search,
    meals_list:state.columns.present.searchResults.meals_list
})

export default connect(mapStateToProps,null)(withStyles(useStyles)(FoodSearchResults));