import React,{useState, useEffect} from 'react';
import {Route, Redirect} from 'react-router-dom';
import { withRouter, useLocation } from "react-router";
import {connect,useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {attemptLogin, 
        loginUser, 
        updateAccountStatus,
        togglingAuthLoading,} from '../../actions/auth';
import axios from 'axios'// since fetch api not supported in all browsers
import {url_root, configHeaders, is_prod} from '../../actions/util';

// this is a hook
const PrivateRoute = ({component:Component,...rest}, attemptLogin) => {

    const auth = useSelector(state=>state.auth)
    const status = useSelector(state=>state.user.account.status)
    let location = useLocation();
    const dispatch = useDispatch()

    // if auth.isAuthenticated is null and localStorage has token
    useEffect(()=>{
        if((!auth.isAuthenticated)&(localStorage.getItem('accessToken')!=null)
            &(!sessionStorage.getItem('tokenValid'))
            &(!auth.isLoading)){
            // verify access on server
            console.log("validating token")
            dispatch(togglingAuthLoading({isLoading:true})) // blocks re-running
            axios.post(url_root+'/user/return_acct_status/',
            {params:{}},
            {headers:configHeaders(is_prod),})
            .then(res=>{
                // dispatch status
                console.log("dispatching login")
                let data = res['data']
                dispatch(updateAccountStatus({
                    status:data.status
                }))
                dispatch(loginUser({
                    accessToken:data.key,
                    username:data.username,
                    isAuthenticated:true
                }))
                sessionStorage.setItem('tokenValid', true)
            }) 
            .catch(err=>{
                console.log("removing token")
                localStorage.removeItem("accessToken")
                dispatch(loginUser({
                    isAuthenticated:false,
                })) // AUTOMATICALLY SETS isLoading to false
            })
        } // end of axios post
    })


    return (
    <Route
        {...rest}
        render={props=>{
            if((auth.isAuthenticated)||(localStorage.getItem('accessToken') != null)){
                if(status==="free"){
                    if(location.pathname==="/account"){
                        return <Component {...props}/>
                    }else {
                        return <Redirect to="/account"/>
                    }
                }else{
                    return <Component {...props}/>
                }
            }
            else{
                return <Redirect to="/auth/"/>;
            }
        }}
    />
    )
};


export default (withRouter(PrivateRoute));

// export default connect(mapStateToProps, {attemptLogin})(withRouter(PrivateRoute));